import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel, Image } from 'react-bootstrap';
import { getWebContentList, getWebContent } from '../components/webContentRequests';
import { timeConverter } from '../utils/NiceStrings';
import { useParams } from 'react-router-dom';
import EomMarkdown from '../components/EomMarkdown';

function ContentViewer({ extraParams }) {

  let match = useParams();
  let defaultArticle = -1;

  if (match[extraParams.type] !== undefined) {
    defaultArticle = match[extraParams.type];
  }
  let showdates = true;
  if (extraParams.showdates !== undefined) {
    showdates = extraParams.showdates;
  }

  const [blogs, setBlogs] = useState([]);
  const [currentBlog, setCurrentBlog] = useState();
  const [blogNotes, setBlogNotes] = useState('');


  const getBlogList = async e => {
    const data = await getWebContentList(extraParams.content);
    //console.log('Retreived Story List', data);
    if (data.result) {
      //console.log('Retreived Content List', data);
      data.content.sort((a, b) => b.content_publishdate - a.content_publishdate);
      setBlogs(data.content);
    }
  }

  const loadBlog = (e) => {
    const selectedBlog = e.target.getAttribute('name');
    setCurrentBlog(selectedBlog);
  }

  useEffect(() => {
    getBlogList();
  }, []);

  useEffect(() => {
    getWebContent(currentBlog).then(data => {
      setBlogNotes(data.content_text);
    })
  }, [currentBlog]);

  useEffect(() => {
    setCurrentBlog(defaultArticle);
  }, [defaultArticle]);


  return (
    <div>
      <br></br>
      <Row className={"align-items-left flex-nowrap"}>
        <Col xs="3">
          {blogs.map(blog =>
            <div className="p-2 m-2" onClick={loadBlog} name={blog.content_id} key={blog.content_id}>
              {
                (showdates ? <span >{timeConverter(blog.content_publishdate, true)} - </span> : null)
              }  <span onClick={loadBlog} name={blog.content_id} key={blog.content_id}>{blog.content_title}</span>
            </div>
          )}
        </Col>
        <Col xs={12} md={8} lg={6}>
          <EomMarkdown markdown={blogNotes} />
        </Col>
      </Row>
      <br></br>
    </div>
  );
}

export default ContentViewer;