import { getProfessions, getProducts, getSchematics, getSchematicData } from '../../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import { getFieldMappingsForIngredient } from './SchematicFieldManager';
import EditableField from '../../EditableField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CraftingIngredient(props) {
    //console.log(props.ingredient);

    let updateIngredient = props.updateIngredient;
    let index = props.index;
    const [ingredient, setIngredient] = useState(props.ingredient);
    const [ingredientFields, setIngredientFields] = useState([]);
    const [headerFields, setHeaderFields] = useState([]);
    const [header, setHeader] = useState(true);
    const [editing, setEditing] = useState(true);

    useEffect(() => {
        setIngredient(props.ingredient);
    }, [props.ingredient]);

    useEffect(() => {
        setEditing(props.editing);
    }, [props.editing]);


    useEffect(() => {
        setIngredientFields(getFieldMappingsForIngredient(ingredient));
        setHeaderFields(getFieldMappingsForIngredient(ingredient, 'header'));
    }, [ingredient])


    const displayHeader = () => {
        return (<Row className={"ingredient-header "} onClick={() => setHeader(!header)}>
            {headerFields.map((field, index) =>
                <Col xs={2} key={index}>
                    {index==2?"(":null}
                    {ingredient[field.name]}
                    {index==2?")":null}
                </Col>
                
            )}
            <Col xs={2} sm={1} md={2}>
                <FontAwesomeIcon onClick={() => props.deleteIngredient(index)} data-tip data-tooltip-id="ttDeleteProcess"
                    className={"fa fa-lg fa-eom-inverse m-1" + (props.editing ? "" : " xyz")} icon={"times-circle"} />
            </Col>
        </Row>)
    }

    const callDispatcher = (field, value) => {
        updateIngredient(index, field, value);
    }

    const displayDetail = () => {
        return <>
            {displayHeader()}
            {ingredientFields.map((field, index) =>
            (field.split ? <br key={index} /> :
                <EditableField key={index}
                    editing={editing}
                    field={field.name}
                    value={ingredient[field.name]}
                    callDispatcher={callDispatcher}
                    description={field.title!==undefined?field.title:field.name}
                    fieldType={field.type}
                    choices={field.choices}
                    addRow={true} />)
            )}

        </>;
    }
    return (
        <div className={"ingredient-border my-1"}>
            {
                header ? displayHeader() : displayDetail()}
        </div>)
}

export default CraftingIngredient;