import React, { Component } from 'react';

function GameplayShopping() {

    return (
        <div className="container" id="divMainContent">
<h1>Shopkeepers</h1>
<p>Shopkeepers in the realm provide a wide variety of goods and services but they all work through four key commands&nbsp;<strong>List, View, Buy, and Sell</strong>.</p>
<h2>List Command</h2>
<p>Entering the&nbsp;<strong>list</strong>&nbsp;command when a shopkeeper is present will present you with a list of the goods in your characters level range that they have for sale.</p>
<p>If there are multiple shopkeepers present you can add their name to the command to list only those items they have for sale, otherwise you will see the inventory all shopkeepers have for sale.</p>
<pre>{`You review Evie Morrelos's inventory.

+----------+-----------------+-----+----------------------------------------+ 
| Cost     | Type            | Lvl | Product                                |
+----------+-----------------+-----+----------------------------------------+ 
| 57       | Resource        | 0   | 20 leather squares                     |
| 142      | Resource        | 0   | a 20 yard bolt of cotton               |
| 14       | Resource        | 0   | a 20 yard bolt of leather              |
| 28       | Resource        | 0   | a 5 yard spool of cotton thread        |
| 35500    | Container       | 100 | an elegant sewing box                  |
| 71       | Container       | 0   | a sewing box                           |
| 7100     | Container       | 50  | a well made sewing box                 |
+----------+-----------------+-----+----------------------------------------+
 			`}</pre>
<h2>Viewing an Item</h2>
<p>When a shopkeeper is present in the same room or area as your character, this command will allow you to ask about an item being sold by that shopkeeper.</p>
<p>The level of the item, as well as any available descriptive information will be given.</p>
<p>If more than one shopkeeper is in the room, you will have to specify the shopkeeper name in the parameters. You can also specify -detail to use the study command to see more detail about the item.</p>
<pre>{`Sei Fon says 'Interested in a ring of combat prowess? Here is some information for you:
Level      : 30
Material   : adamantite
Weight     : 0.0 pounds
Wear Info  : Worn on Left finger or Right finger
Class Info : jewelry
Description: This is a black metal ring crowned with a splay of rubies the color of blood, 
it's engraved with the words, 'With my power and in my name you shall persevere.'' to you.
`}</pre>
<pre>{`Sei Fon says 'You want to take a closer look at a ring of combat prowess?' to you.

O=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=o
| Report for a ring of combat prowess                                       |
o---------------------------------------------------------------------------o
| Level               30             TPS       50 (0.77% of your TPA)       |
| This is a black metal ring crowned with a splay of rubies the color of    |
| blood, it's engraved with the words, 'With my power and in my name you    |
| shall persevere.'                                                         |
o=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=o
| Martial report                     Accuracy 5%                            |
o---------------------------------------------------------------------------o
| Type                Jewelry        Subtype             Bespoke            |
| It has little to offer in attack or defence.                              |
o=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=o
| Magical report                     Accuracy 5%                            |
o---------------------------------------------------------------------------o
| Attack              10             Damage              16                 |
o=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=o
| Materials report                   Accuracy 5%                            |
o---------------------------------------------------------------------------o
| Material            Adamantite     Type                Metal              |
| Quality             95             Unit Toughness      91                 |
o=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=o
`}</pre>
<h2>Buying an Item</h2>
<p>When a shopkeeper is present in the same room or area as your character, the&nbsp;<strong>buy</strong>&nbsp;command will allow you to purchase an item from that shopkeeper.</p>
<p>The price for the item, as well as the availability of certain items can be determined by using the&nbsp;<strong>list</strong>&nbsp;command.</p>
<p>You may also purchase items for someone else by adding the "for" syntax to the end of the command. e.g.&nbsp;<strong>buy shirt for suki</strong>.</p>
<h2>Selling an item</h2>
<p>If you are in the same room as a shopkeeper who is selling and buying goods, The&nbsp;<strong>sell</strong>&nbsp;command will sell the specified item in your carried inventory to the shopkeeper for the price he names.</p>
<p>This is also the command used with an auctioneer to auction an item. If more than one shopkeeper is in the room, you may have to specify the shopkeeper name in the parameters.</p>
<p>Shopkeepers will only buy the items they are interested in. This usually means the kinds of items they sel; and they may refuse an item because of its low worth, or because the shopkeeper has enough of them.</p>
<h1>Banks</h1>
<p>Banks can be used to store items, using the&nbsp;<strong>deposit</strong>&nbsp;and&nbsp;<strong>withdraw</strong>&nbsp;commands a player can put items or money into storage.</p>
<h2>Deposit</h2>
<p>When a banker or postal clerk is present in the same room as your character, this command will allow you to deposit an item into your account with that banker, or to deposit a letter or item to ship with the postal clerk</p>
<h2>Withdraw</h2>
<p>When a banker or postal clerk is present in the same room or area as your character, this command will allow you to withdraw an item from your account or postal box with that banker. Stating if you want cash or an item will resolve a conflict where the item is named like cash.</p>
<h1>Auction Channel</h1>
<p>This versatile command is used by players wishing to auction items at real time over the AUCTION channel, as well as by those wishing to bid on items up for auction. To start an auction, the player enters AUCTION UP plus the name of the item, and an optional starting bid. If there is no other auction going on at the time, the auction will begin. Auctions last for a silent 5 minutes, plus 1 minute for each going once/twice/sold. If no bids have been received after the first 3 minutes, the item will proceed directly to the going once/twice/sold period. The person putting on the auction may, at any time, close the auction without penalty by entering "auction close", or communicate on the auction channel (to give a better item description perhaps) using "auction channel [message]".</p>
<p>To place a bid on an auction that is ongoing, a player need only enter "auction bid [amount]". Where amount is the maximum amount the player will pay for the item. A proxy bidding system is in place for all auctions. The current bid on the item will always be less than or equal to the bid of the high bidder, and at least 1 gold more than the bid of the previous high bidder. This means that, if the current bid on an item is 5, and a player bids 10, the new bid will become 6 (one more than the previous high bid). If another player then bids 20, the new bid will become 11.</p>
<p>&nbsp;</p>
<p>When an auction ends, the system will attempt to automatically complete the transaction for both the bidder and the auctioneer. The proper money will be transferred (in converted notes) from the high bidder to the auctioneer. The item up for auction will be automatically transferred from the auctioneer to the high bidder. The auction house may take a cut both when then auction is placed up, and on the final price.</p>
        </div>
    );
}

export default GameplayShopping;