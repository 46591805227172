import React, { useState, useEffect } from 'react';
import { getSystemLogFromMud } from '../components';

function AdminLogView() {
    const [systemLog, setSystemLog] = useState([]);
    const [filter, setFilter] = useState([]);

    const getSystemLog = async () => {
        let data = await getSystemLogFromMud();
        console.log('System Log', data.data);
        if (data.result) {
            setSystemLog(data.data);
        } else {
            setSystemLog([]);
        }
    }

    useEffect(() => {
        getSystemLog();
    }, []);

    return (
        <div className="container container-eom" id="divMainContent">
            <div style={{
                display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignItems: "flex-start",
                justifyContent: "flex-start"
            }}>
                <div className="input-group mb-3">
                    <div className="input-group-prepend" >
                        <button className="btn btn-themed-dark" type="submit">Filter</button>
                    </div>
                    <input type="text" className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                </div>

                <pre className="eom-box eom-log">
                    {systemLog.filter(line=>line.rawline.indexOf(filter)>-1).map((line, index) =>
                        <div key={index}>
                            <span >{line.rawline}<br /></span>
                            
                        </div>)}
                </pre>
            </div>
        </div>
    )
}

export default AdminLogView;