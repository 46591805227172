import React from 'react';
import { Group, Circle } from 'react-konva';
import getLocaleColour from './MapColouring'

const spacing = 50;
const roomsize = 12;

function SimpleRoom(props) {

    let room = props.room;
    let piX = room.x;
    let piY = room.y;
    let roomid = room.roomid;
    let locale = room.locale;
    let mobcount = room.mobcount;
    let activeroom = props.activeroom;
    let clickHandler = props.clickHandler;

    piY = piY * -1;

    let roomColor = getLocaleColour(locale);
    let borderColour = 'grey';
    let textColour = 'red'
    let borderWidth = 1;

    if (activeroom != undefined) {

        if (activeroom.id === roomid) {
            borderColour = 'gold';
        }
    }

    const x = piX * spacing;
    const y = piY * spacing;

    return (

        <Group key={roomid}>
            <Circle id={roomid} x={x} y={y} radius={roomsize} fill={roomColor} stroke={borderColour} strokeWidth={borderWidth} onClick={()=>clickHandler(roomid)} />
        </Group>

    )
}

export default SimpleRoom;