import { getSchematic, updateSchematic, createSchematic, previewSchematic } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import EditableField from '../EditableField';
import { getCustomFieldMappingsForTemplate, getFieldMappingsForTemplate } from './editor/SchematicFieldManager';
import CraftingIngredient from './editor/CraftingIngredient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { getCraftingIngredientTypes } from '../BuilderUtils';
import CraftingListEditor from './editor/CraftingListEditor';
import { useParams } from 'react-router-dom';

const SchematicEditor = ({ onCloseEditor, schematic_id }) => {
    console.log(schematic_id);
    const [schematic, setSchematic] = useState(null);
    const [schematicId, setSchematicId] = useState();
    const [editing, setEditing] = useState(false);
    const [creating, setCreating] = useState(false);
    const [cloned, setCloned] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedIngredient, setSelectedIngredient] = useState('');

    const callDispatcher = (fieldName, newValue) => {
        schematic[fieldName] = newValue;
        setSchematic({ ...schematic });
    }

    const customPropertyCallDispatcher = (fieldName, newValue) => {
        schematic['custom_properties'][fieldName] = newValue;
        setSchematic({ ...schematic });
    }


    const getSchematicFromServer = async (schematicId) => {
        let data = await getSchematic(schematicId);
        if (data.result) {
            console.table(data.schematic, 'Value');
            //console.log(data.schematic);
            setSchematic(data.schematic);
        } else {
            setSchematic(null);
        }
    }

    useEffect(() => {
        console.log('We have chosen', schematic_id);
        if (schematic_id === undefined) {
            setSchematicId(null);
        } else
            setSchematicId(schematic_id);
    }, [schematic_id]);

    useEffect(() => {
        console.log('We need to load', schematicId);
        if (schematicId !== null)
            getSchematicFromServer(schematicId);
        else
            setSchematic(null);
    }, [schematicId]);

    const toggleEditing = () => {
        setEditing(true);
    }

    const saveChanges = async () => {
        //console.log(creating,schematic.SCHEMAID );
        let data = await updateSchematic(creating ? schematic.SCHEMAID : schematicId, schematic);
        if (data.result) {
            setEditing(false);
        }
    }

    const onUndoChanges = () => {
        setEditing(false);
        if (creating)
            setSchematic(cloned);
        else
            getSchematicFromServer(schematicId);
    }

    const cloneFeature = () => {
        setCloned(schematic);
        setCreating(true);
        setEditing(true);
        schematic.SCHEMAID = schematic.SCHEMAID + "Copy";
        schematic.NAME = schematic.NAME + " Copy";
    }

    const cancelChanges = () => {
        setCloned(null);
        setCreating(false);
        setEditing(false);
        setSchematic(null);
        setSchematicId(null);
        onCloseEditor();
    }

    const createSchematicFromTemplate = async () => {
        let data = await createSchematic(selectedTemplate);
        if (data.result) {
            console.log('Schematic Data', data.schematic);
            setSchematic(data.schematic);
        } else {
            setSchematic(null);
        }
    }

    const previewChanges = async () => {
        copyToClipboard();
        let data = await previewSchematic(schematic);
        if (data.result) {
            console.log('Preview  Data', data);
        }
    }

    const changeProcessOrder = (processIndex, direction) => {
        let processes = schematic.processes;
        let movedProcess = schematic.processes[processIndex];

        processes.splice(processIndex, 1);

        processes = [
            ...processes.slice(0, processIndex + 1),
            movedProcess,
            ...processes.slice(processIndex + direction)
        ];

        //    processIndex
        schematic.processes = processes;
        setSchematic({ ...schematic });
    }

    const demoteProcess = (processIndex) => {
        //Lower the priority of the process.
        if (processIndex >= schematic.processes.length - 1)
            return;
        changeProcessOrder(processIndex, 1);
    }

    const promoteProcess = (processIndex) => {
        //Lower the priority of the process.
        if (processIndex <= 0)
            return;
        changeProcessOrder(processIndex, -1);
    }

    const addProcess = () => {
        console.log('Adding Process');
        let newProcess = {};
        newProcess.process = "";
        newProcess.duration = 5;
        schematic.processes = [
            newProcess,
            ...schematic.processes
        ];
        setSchematic({ ...schematic });
    }

    const updateEntry = (listid, entryIndex, field, value) => {
        schematic[listid][entryIndex][field] = value;
        setSchematic({ ...schematic });
    }

    const updateIngredient = (index, field, value) => {
        schematic.ingredients[index][field] = value;
        setSchematic({ ...schematic });
    }

    const deleteIngredient = (processIndex) => {
        let ingredients = schematic.ingredients;
        ingredients.splice(processIndex, 1);
        schematic.ingredients = ingredients;
        setSchematic({ ...schematic });
    }


    const addEffect = () => {
        console.log('Adding Effect');
        let newEffect = {};
        newEffect.effect_id = "New Effect";
        newEffect.parameters = "";
        schematic.effects = [
            newEffect,
            ...schematic.effects
        ];
        setSchematic({ ...schematic });
    }

    const addInput = () => {
        console.log('Adding Effect');
        let newEffect = {};
        newEffect.key = 'New Entry';
        newEffect.list = 'New List';
        schematic.inputs = [
            newEffect,
            ...schematic.inputs
        ];
        setSchematic({ ...schematic });
    }

    const addRequirement = () => {
        console.log('Adding Requirement');
        let newEffect = {};
        newEffect.requirement_type = 'New Requirement';
        newEffect.requirment_id = 'New List';
        schematic.requirements = [
            newEffect,
            ...schematic.requirements
        ];
        setSchematic({ ...schematic });
    }

    const deleteEntry = (listid, entryIndex) => {
        console.log('Delete', listid, entryIndex);
        let list = schematic[listid];
        list.splice(entryIndex, 1);
        schematic[listid] = list;
        setSchematic({ ...schematic });
    }

    const addIngredient = () => {
        console.log('Adding Ingredient');
        let newingredient = {};



        newingredient.IngredientType = selectedIngredient;
        newingredient.IngredientName = selectedIngredient;
        newingredient.Resource = 'BASE';

        if(newingredient.IngredientType=='EMBELLISHMENT') {
            newingredient.IngredientName = 'clothing detail';
            newingredient.embellishment= 'TYPE_ANYTHING';
        }

        newingredient.Quantity = 1;
        newingredient.Index = 999;

        console.log('Adding Ingredient', newingredient);
        schematic.ingredients = [

            ...schematic.ingredients, newingredient
        ];
        setSchematic({ ...schematic });
    }

    const copyToClipboard = async () => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(JSON.stringify(schematic, null, 2));
        } else {
            document.execCommand("copy", true, JSON.stringify(schematic));
        }
    };

    const importFromClipboard = async () => {
        if ("clipboard" in navigator) {
            navigator.clipboard.readText().then((clipText) => {
                try {
                    var importedSchematic = JSON.parse(clipText);
                    setCloned(null);
                    //setSchematicId(importedSchematic.SCHEMAID);
                    setSchematic(importedSchematic);
                    //setSchematicId(null);
                    setCreating(true);
                    setEditing(true);
                } catch (e) {
                    console.log('Invalid JSON object');
                }
            });
        } else {
            console.log('Not supported in this browser');
        }
    };

    const getCustomPropertyEditors = () => {
        const schemaFields = getCustomFieldMappingsForTemplate(schematic.TYPE, schematic.ITEMCLASS);
        console.log(schemaFields);
        if (schemaFields.length == 0)
            return null;
        return (
            <><Row className={"section-header"}>
                <h4 className={"px-3"}>Custom Fields</h4>
            </Row>
                <Row className={"m-1"}>
                    {schemaFields.map((field, index) =>
                        <EditableField key={index}
                            editing={editing}
                            field={field.name}
                            value={schematic['custom_properties'][field.name]}
                            callDispatcher={customPropertyCallDispatcher}
                            description={field.description}
                            fieldType={field.type}
                        />
                    )}
                </Row></>);

    }

    const craftingListSection = (title, listId, addEntry, deleteEntry, updateEntry, demoteEntry, promoteEntry) => {
        const countText = (schematic[listId].length === 0 ? "None" : schematic[listId].length);
        return (
            <>
                <Row className={"section-header"}>
                    <h3 className={"px-3"}>{title} ({countText})</h3>
                </Row>
                {
                    editing ?
                        <Row className={"m-1"}>
                            <Col xs={3}>
                                <button onClick={addEntry} className="btn btn-themed-dark btn-small my-lg-0" disabled={!editing}>Add {title}</button>
                            </Col>
                        </Row>
                        : null
                }
                {
                    schematic[listId].map((record, index) =>

                        <CraftingListEditor key={index} index={index} entry={record} editing={editing} list={listId}
                            deleteEntry={(entryIndex) => { deleteEntry(listId, entryIndex) }}
                            demoteEntry={demoteEntry}
                            promoteEntry={promoteEntry}
                            updateEntry={updateEntry}
                        />
                    )
                }
            </>
        )
    }

    const displayEditor = () => {
        const schemaFields = getFieldMappingsForTemplate(schematic.TYPE, schematic.ITEMCLASS);

        return (
            <div>
                <Row>
                    <h3>Schematic: {schematicId}</h3>
                </Row>

                <Row className={"professionheader"}>
                    <Col>

                        <FontAwesomeIcon onClick={toggleEditing} data-tip data-tooltip-id="ttEditSchematic" className={"fa fa-lg fa-eom m-1" + (editing ? " xyz" : "")} icon={"edit"} />
                        <FontAwesomeIcon onClick={saveChanges} data-tip data-tooltip-id="ttSaveChanges" className={"fa fa-lg fa-eom m-1" + (editing ? "" : " xyz")} icon={"check-circle"} />
                        <FontAwesomeIcon onClick={onUndoChanges} data-tip data-tooltip-id="ttUndoChanges" className={"fa fa-lg fa-eom m-1" + (editing ? "" : " xyz")} icon={"undo"} />
                        <FontAwesomeIcon onClick={cloneFeature} data-tip data-tooltip-id="ttCopyFeature" className={"fa fa-lg fa-eom m-1" + (editing ? " xyz" : "")} icon={"clone"} />
                        <FontAwesomeIcon onClick={cancelChanges} data-tip data-tooltip-id="ttCancelChanges" className={"fa fa-lg fa-eom m-1"} icon={"times-circle"} />
                        <FontAwesomeIcon onClick={previewChanges} data-tip data-tooltip-id="ttPreviewChanges" className={"fa fa-lg fa-eom m-1"} icon={"box-open"} />
                        <FontAwesomeIcon onClick={copyToClipboard} data-tip data-tooltip-id="ttPreviewChanges" className={"fa fa-lg fa-eom m-1"} icon={"file-export"} />
                        <FontAwesomeIcon onClick={importFromClipboard} data-tip data-tooltip-id="ttPreviewChanges" className={"fa fa-lg fa-eom m-1"} icon={"file-import"} />

                    </Col>
                </Row>

                {schemaFields.map((field, index) =>
                (field.split ? <br key={index} /> :
                    <EditableField key={index}
                        editing={editing}
                        field={field.name}
                        value={schematic[field.name]}
                        callDispatcher={callDispatcher}
                        description={field.name}
                        fieldType={field.type}
                        choices={field.choices} />)
                )
                }
                {getCustomPropertyEditors()}
                <Row className={"section-header"}>
                    <h3 className={"px-3"}>Ingredients</h3>

                </Row>
                {editing ?
                    <Row className={"m-1"}>
                        <Col xs={4}>
                            <DropdownButton className={"px-2"}
                                variant="outline-secondary"
                                title={"Select Ingredient Type"}
                                id="input-group-dropdown-1"
                                disabled={!editing}
                            >
                                {getCraftingIngredientTypes().map(ingredient =>
                                    <Dropdown.Item key={ingredient} name={ingredient} onClick={() => setSelectedIngredient(ingredient)}>{ingredient}</Dropdown.Item>
                                )}
                            </DropdownButton>
                        </Col>
                        <Col xs ={4}>
                            <button onClick={addIngredient} className="btn btn-themed-dark btn-small my-lg-0" disabled={!editing}>Add {selectedIngredient} Ingredient</button>
                        </Col>
                    </Row>
                    : null}
                {schematic.ingredients.map((ingredient, index) =>
                    <CraftingIngredient key={index} index={index}
                        ingredient={ingredient}
                        editing={editing}
                        updateIngredient={updateIngredient}
                        deleteIngredient={deleteIngredient} />
                )
                }

                {craftingListSection('Crafting Processes', 'processes', addProcess, deleteEntry, updateEntry, demoteProcess, promoteProcess)}
                {craftingListSection('Item Effects', 'effects', addEffect, deleteEntry, updateEntry)}
                {craftingListSection('User Inputs', 'inputs', addInput, deleteEntry, updateEntry)}
                {craftingListSection('Requirements', 'requirements', addRequirement, deleteEntry, updateEntry)}

                <ReactTooltip id="ttDeleteProcess" place="top" effect="solid">
                    Delete Entry
                </ReactTooltip>
                <ReactTooltip id="ttAddProcess" place="top" effect="solid">
                    Add Process
                </ReactTooltip>
                <ReactTooltip id="ttPromoteProcess" place="top" effect="solid">
                    Move Entry Up
                </ReactTooltip>
                <ReactTooltip id="ttDemoteProcess" place="top" effect="solid">
                    Move Entry Down
                </ReactTooltip>
                <ReactTooltip id="ttCopyFeature" place="top" effect="solid">
                    Clone Schematic
                </ReactTooltip>
                <ReactTooltip id="ttEditSchematic" place="top" effect="solid">
                    Edit Schematic
                </ReactTooltip>
                <ReactTooltip id="ttUndoChanges" place="top" effect="solid">
                    Undo Changes
                </ReactTooltip>
                <ReactTooltip id="ttSaveChanges" place="top" effect="solid">
                    Save Changes
                </ReactTooltip>
                <ReactTooltip id="ttCancelChanges" place="top" effect="solid">
                    Close & Abandon Changes
                </ReactTooltip>
                <ReactTooltip id="ttPreviewChanges" place="top" effect="solid">
                    Preview Changes
                </ReactTooltip>
            </div>
        );
    }

    const createOptions = () => {

        const templates = [
            'GenArmorTemplate',
            'GenBookDesign',
            'GenPaperDesign',
            'GenCageDesign',
            'GenCageRideableDesign',
            'GenClothingDesign',
            'GenComponentDesign',
            'GenConsumableRecipe',
            'GenContainerDesign',
            'GenEmbellishDesign',
            'GenFabricRecipe',
            'GenFountainDesign',
            'GenHeelDesign',
            'GenInstrumentDesign',
            'GenItemDesign',
            'GenLimbDesign',
            'GenRideableDesign',
            'GenScrollDesign',
            'GenSoleDesign',
            'GenTubDesign',
            'GenWeaponTemplate'];

        return (
            <div>
                <Row>

                    <DropdownButton className={"px-2"}
                        variant="outline-secondary"
                        title={"Select Template"}
                        id="input-group-dropdown-1"
                        disabled={templates.length === 0}
                    >
                        {templates.map(template =>
                            <Dropdown.Item key={template} name={template} onClick={
                                () => {
                                    console.log('Selecting Template', template);
                                    setSelectedTemplate(template);

                                }

                            }>{template}</Dropdown.Item>
                        )}
                    </DropdownButton>
                    <button onClick={() => createSchematicFromTemplate()} className="btn btn-themed-dark btn-small my-lg-0" disabled={selectedTemplate === ''}>Create {selectedTemplate}</button>

                </Row>

            </div>
        );
    }

    console.log('Current Schematic is: ', schematic);
    if (schematic === null) {
        return createOptions();
    } else
        return displayEditor();
}

export default SchematicEditor;