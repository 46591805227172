import React, { Component } from 'react';

class IntroLoggingIn extends Component {
  // Initialize the state
  constructor(props){
    super(props);
    this.state = {
      list: null
    }
  }

  render() {
    const { list } = this.state;

    return (
      <div className="container" id="divMainContent">
            <br/>
            <h1>Connecting to the MUD</h1>
            <br/>
            <p>In order to play The Edge of Midnight you will need to either log in though the Play Now link on our home page or 
            download a mud client.</p> 
            <p>The are a number of good clients out there though if you are new to mudding I suggest MUSHclient as it is a good free client.</p>
      		<p>Once you have a client you will need to set up a new world, some clients like zMUD offer a list of MUDs that they will help you connect to. If your client offers this Edge of Midnight should be on the list. If not you will need to ender the info following information manually.</p> 
            <br/>
            <div className="jumbotron">	
                <h3>Live and Test Servers!</h3>  
            	<li>                                       
                    <span className="field"><i className="general foundicon-cloud icon"></i> Live Server: 
                    <a className="eomLink" href="telnet:edgeofmidnight.org:8010" title="Email">edgeofmidnight.org port 8010</a>  </span>                               
                </li>

                <li>                                       
                    <span className="field"><i className="general foundicon-cloud icon"></i> Secure Port:</span>
                    <span>edgeofmidnight.org port 8011</span>                               
                </li>

                <li>                                    
                    
                    <span className="field"><i className="general foundicon-cloud icon"></i> Test Server:
                    <a className="eomLink" href="telnet:edgeofmidnight.org:8020" title="Email">edgeofmidnight.org port 8020</a></span>                                                                   
                </li>
            </div>
            <h2>MUD Clients</h2>
            <h3>For Windows</h3>
            <ul>
                <li><a className="eomLink" href="https://www.mudlet.org/download/" target="_blank" rel="noreferrer">Mudlet <strong>recommended</strong></a></li>
                <li><a className="eomLink" href="http://www.gammon.com.au/mushclient/mushclient.htm" target="_blank" rel="noreferrer">MushClient</a></li>
                <li><a className="eomLink" href="http://www.zuggsoft.com/index.php?p=cmud" target="_blank" rel="noreferrer">CMUD</a></li>
                <li><a className="eomLink" href="http://www.zuggsoft.com/page.php?file=zmud/zmudinfo.htmtarget=" target="_blank" rel="noreferrer">ZMUD</a></li>
                <li><a className="eomLink" href="https://sourceforge.net/projects/g-mud/" target="_blank" rel="noreferrer">GMUD</a></li>
                <li><a className="eomLink" href="http://download.cnet.com/SimpleMU/3000-2381_4-10009782.html/" target="_blank" rel="noreferrer">SimpleMU</a></li>
                <li><a className="eomLink" href="https://social.technet.microsoft.com/wiki/contents/articles/910.windows-7-enabling-telnet-client.aspx" target="_blank" rel="noreferrer">Telnet</a></li>
            </ul>
            <br/>
            <h3>For Mac</h3>
            <ul>
              <li><a className="eomLink" href="https://www.mudlet.org/download/" target="_blank" rel="noreferrer">Mudlet <strong>recommended</strong></a></li>
              <li><a className="eomLink" href="http://www.riverdark.net/atlantis/" target="_blank" rel="noreferrer">Atlantis</a></li>    
                <li><a className="eomLink" href="https://www.heynow.com/Savitar/" target="_blank" rel="noreferrer">Savitar</a></li>
            </ul>
            <br/>
            <h3>For Android</h3> 
            <ul>
                <li><a className="eomLink" href="https://play.google.com/store/apps/details?id=com.happygoatstudios.bt&amp;hl=en_GB" target="_blank" rel="noreferrer">Blowtorch <strong>recommended</strong>.</a></li>
            </ul>
            <h3>For Linux</h3> 
            <ul>
                <li><a className="eomLink" href="https://www.mudlet.org/download/" target="_blank" rel="noreferrer">Mudlet <strong>recommended</strong></a></li>
                <li><a className="eomLink" href="https://github.com/Blightmud/Blightmud" target="_blank" rel="noreferrer">Blightmud.</a></li>
                <li><a className="eomLink" href="https://tintin.mudhalla.net/" target="_blank" rel="noreferrer">Tintin++.</a></li>
            </ul>

            <h3>Supports Secure Connections</h3> 
            <ul>
                <li><a className="eomLink" href="https://www.mudlet.org/download/" target="_blank" rel="noreferrer">Mudlet.<strong>confirmed</strong></a></li>
                <li><a className="eomLink" href="https://github.com/Blightmud/Blightmud" target="_blank" rel="noreferrer">Blightmud.<strong>confirmed</strong></a></li>
            </ul>
			<br/>	
		</div>
    );
  }
}

export default IntroLoggingIn;