import React, { Component } from 'react';

function GameplayCombat() {

    return (
        <div className="container" id="divMainContent">
<h1>Initiating Combat</h1>
<p>Combat can be initiated in a number of ways, the primary and most simple way is to use the&nbsp;<strong>kill</strong>&nbsp;command. This will cause your character to enter combat with the chosen target.</p>
<h2>Melee Combat</h2>
<p>The default combat mode is melee, your character will engage enemies in close quarters and if engaged at range will seek to close the distance in subsequent rounds.</p>
<p>This behaviour can be overridden by using the&nbsp;<strong>AUTOMELEE</strong>&nbsp;command to toggle this off, when off your character will remain at range</p>
<h2>Ranged Combat</h2>
<p>Ranged combat will place your character at the maximum range of the wielded weapon or spell used to initiate combat and the enemy will either engage at that range or try to close the distance.</p>
<h1>The Combat Round</h1>
<p>Each round of combat is broken down into several phases</p>
<ol>
<li>
<p>Initiative Rolls</p>
</li>
<li>
<p>In order of initiative rolls for each combatant:</p>
</li>
<li>
<p>Run Queued Abilities</p>
</li>
<li>
<p>Default attack target (melee/ranged)</p>
</li>
</ol>
<h1>Death</h1>
<p>Sometimes a foe is too strong to overcome, to brutal to escape and your character will be killed - when this happens you will turn into a corpse and your body sent to the morge.</p>
<p>If you are of low level the Morge Technician will revive you and you may continue your adventure, if you are a high level you will need the attention of another player to revive you, use of the roleplay channel at this point and haunting people is recommended.</p>
<h3>Penalties</h3>
<p>Should you die, you will lose 500 experience - but no other penalties are incurred.</p>
<h1>Experience &amp; Loot</h1>
<p>Upon killing a foe you will be rewarded with experience dependant on the level of the target slain and you may loot their body!</p>
<p><strong>AUTOLOOT</strong>&nbsp;and&nbsp;<strong>AUTOGOLD</strong>&nbsp;will automatically strip items from the deceased for you, although autoloot is not very discerning!</p>
        </div>
    );
}

export default GameplayCombat;