


const getNewIssues = (issueType, searchAssignee, reporter) => {
    let issueSearch = {};
    issueSearch.issue_types = [issueType];
    issueSearch.issue_statuses = ['New'];
    if (searchAssignee.length > 0)
        issueSearch.issue_assignee = searchAssignee;
    if (reporter.length > 0)
        issueSearch.issue_reporter = reporter;
    return issueSearch;
}

const getBacklog = (issueType, searchAssignee, reporter) => {
    let issueSearch = {};
    issueSearch.issue_types = [issueType];
    issueSearch.issue_statuses = ['Backlog'];
    if (searchAssignee.length > 0)
        issueSearch.issue_assignee = searchAssignee;
    if (reporter.length > 0)
        issueSearch.issue_reporter = reporter;
    return issueSearch;
}

const getOpenIssues = (issueType, searchAssignee, reporter) => {
    let issueSearch = {};
    issueSearch.issue_types = [issueType];
    issueSearch.issue_statuses = ['New', 'Backlog', 'InProgress', 'Reopened', 'Todo', 'NextUp', 'WaitingOnPlayer','PlayerResponded'];
    if (searchAssignee.length > 0)
        issueSearch.issue_assignee = searchAssignee;

    if (reporter.length > 0)
        issueSearch.issue_reporter = reporter;
    return issueSearch;
}

const getInFlightIssues = (issueType, searchAssignee, reporter) => {
    let issueSearch = {};
    issueSearch.issue_types = [issueType];
    issueSearch.issue_statuses = ['New', 'InProgress', 'Reopened', 'NextUp', 'WaitingOnPlayer', 'PlayerResponded',];
    if (searchAssignee.length > 0)
        issueSearch.issue_assignee = searchAssignee;

    if (reporter.length > 0)
        issueSearch.issue_reporter = reporter;
    return issueSearch;
}

const getDoneIssues = (issueType, searchAssignee, reporter) => {
    let issueSearch = {};
    issueSearch.issue_types = [issueType];
    issueSearch.issue_statuses = ['Done'];
    if (searchAssignee.length > 0)
        issueSearch.issue_assignee = searchAssignee;

    if (reporter.length > 0)
        issueSearch.issue_reporter = reporter;
    return issueSearch;
}
//,  'WorkingAsIntended'
const getClosedIssues = (issueType, searchAssignee, reporter) => {
    let issueSearch = {};
    issueSearch.issue_types = [issueType];
    issueSearch.issue_statuses = ['Done', 'Released', 'Closed', 'Rejected', 'Resolved', 'CantFix', 'WontFix', 'Duplicate','WorkingAsIntended'];
    if (searchAssignee.length > 0)
        issueSearch.issue_assignee = searchAssignee;

    if (reporter.length > 0)
        issueSearch.issue_reporter = reporter;
    return issueSearch;
}


const getWaiting = (issueType, searchAssignee, reporter) => {
    let issueSearch = {};
    issueSearch.issue_types = [issueType];
    issueSearch.issue_statuses = ['WaitingOnPlayer'];
    if (searchAssignee.length > 0)
        issueSearch.issue_assignee = searchAssignee;

    if (reporter.length > 0)
        issueSearch.issue_reporter = reporter;
    return issueSearch;
}


export const getSearchCriteria = (searchName, issueType, assignee, reporter, currentUser) => {
    switch (searchName) {
        case 'INFLIGHT' : return getInFlightIssues(issueType, currentUser, reporter);
        case 'NEW': return getNewIssues(issueType, assignee, reporter);
        case 'BACKLOG': return getBacklog(issueType, assignee, reporter);
        case 'MYBACKLOG': return getBacklog(issueType, currentUser, reporter);
        case 'OPEN': return getOpenIssues(issueType, assignee, reporter);
        case 'MYOPEN': return getOpenIssues(issueType, currentUser, reporter);
        case 'MYPENDING': return getDoneIssues(issueType, currentUser, reporter);
        case 'RAISEDBY': return getOpenIssues(issueType, '', reporter);
        case 'ALLOPEN': return getOpenIssues(issueType, assignee, reporter);
        case 'ALLCLOSED': return getClosedIssues(issueType, assignee, reporter);
        case 'WAITING': return getWaiting(issueType, assignee, reporter);
        default: return getNewIssues('Bug', '', '');
    }
}

export const getSearchoptions = () => {
    return [
        { description: 'My Active Issues', name: 'INFLIGHT' },
        { description: 'My Open Issues', name: 'MYOPEN' },
        { description: 'My Backlog', name: 'MYBACKLOG' },
        { description: "New Issues", name: 'NEW' },
        { description: 'Player Response', name: 'WAITING' },
        { description: 'Backlog', name: 'BACKLOG' },
        { description: 'Open Issues', name: 'OPEN' },
        { description: 'Pending Release', name: 'MYPENDING' },
        { description: 'Reported By Me', name: 'RAISEDBY' },
        { description: 'All Open Issues', name: 'ALLOPEN' },
        { description: "All Closed Issues", name: 'ALLCLOSED' }
    ];
};