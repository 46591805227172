import React from 'react';
import { colourMap } from '../utils/ColourMap';

import { Row, Col, Container } from 'react-bootstrap';

function ColourCol(code, onClick) {
    let colourCode = colourMap[code];
    //console.log(code, colourCode);
    return (
        <Col key={code} style={{ textAlign: "center" }} onClick={() => onClick({ code })}>
            <font color={colourCode}>{code}</font>
        </Col>
    );
}

function ColouredMatrix(props) {
    const codes = ["~M", "~R", "~Y", "~O", "~G", "~B", "~C", "~W", "~V", "~N", "~L", "~P"];
    let clickCode = props.clickCode;
    //console.log(props.clickCode);
    var rows = [];

    codes.forEach((code) => {
        var cols = [];
        for (var i = 1; i < 10; i++) {
            cols.push(ColourCol(code + i, clickCode));
        }
        rows.push(cols);
    });

    return (
        <div style={{ backgroundColor: 'black' }} className="eom-box mb-5">
            {rows.map((row, index) => (
                <Row key={index}>
                    {row}
                </Row>
            ))}
        </div>
    );
}

export default ColouredMatrix;