import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Row, Col, Accordion, Card, Button, Form } from 'react-bootstrap';
import { createItem } from '../../components/builderRequests';
import { findSchematic, metacraftItem } from '../../components/professionRequests';

function CreateItemPrompt(props) {

    let show = props.show;
    let closeModal = props.closeModal;
    let createItem = props.createItem;

    const [selectedType, setSelectedType] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [schemaIndex, setSchemaIndex] = useState(0);
    const [material, setMaterial] = useState('');
    const [level, setLevel] = useState(1);
    const [quantity, setQuantity] = useState(1);
    const [quality, setQuality] = useState(125);

    const [error, setError] = useState('');

    const [schemas, setSchemas] = useState([]);
    const itemTypes = ['Schematic', 'GenItem'];

    useEffect(() => {
        if (searchString === '') {
            setSchemas([]);
        } else {
            const delayDebounceFn = setTimeout(() => {
                // console.log(searchString)
                searchForSchematic();
                // Send Axios request here
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchString]);

    // const debouncedHandleResize = debounce(function handleResize() {
    //     var myDiv = document.getElementById("#mapcontainer");
    //     if (myDiv != null) {
    //         //  console.log('Div Resized to: ', myDiv.offsetWidth, 'x', myDiv.offsetHeight);
    //         setWidth(myDiv.offsetWidth);
    //         setHeight(myDiv.offsetHeight);
    //     }
    // }, 100);


    const handleSave = async () => {

    }

    const handleClose = async () => {
        closeModal();
    }

    const createItemFromSelection = () => {
        console.log("We want to create ", itemTypes[selectedType]);
        createItem(itemTypes[selectedType]).then(data => {
            //console.log(data);
            if (data.result) {
                closeModal();
            } else {
                //error
                setError('Could not create item.');
            }
        }).catch(
            error => {
                setError('Failed to create item.');
                console.log(error);
            }
        );
    }

    const metacraftChosenSchematic = () => {
        metacraftItem(schemas[schemaIndex].SCHEMAID, material, level, quality, quantity).then(data => {
            console.log(data.data);
            createItem(data.data);
        }).catch(error => {
            console.log(error);
        })
    }
    const searchForSchematic = () => {
        setError('');
        console.log("Searching for", itemTypes[selectedType], searchString);
        if (searchString.length < 3) {
            setSchemas([]);
            return;
        }
        findSchematic(searchString).then(data => {
            //console.log(data);
            if (data.result) {
                console.log(data);
                setSchemas(data.schemas);
            } else {
                //error
                setSchemas([]);
                setError(`Failed to find schema for ${searchString}.`);
            }
        }).catch(
            error => {
                setError(`Could not find schema for ${searchString}.`);
                console.log(error);
            }
        );
    }

    const getContent = () => {
        switch (selectedType) {
            case 0:
                return (
                    <>
                        <Row className={"p-1"}>
                            <Col xs={2}>
                                <strong>Search</strong>
                            </Col>
                            <Col>
                                <Form.Control type="text" placeholder="Enter Schematic" value={searchString} onChange={(e) => setSearchString(e.currentTarget.value)} />
                            </Col>

                        </Row>
                        {schemas.length > 0 ?
                            <>
                                <Row classname={"p-1"}>
                                    <Col xs={2}>
                                        <strong>Schematic</strong>
                                    </Col>
                                    <Col>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="tool-prompt" id="dropdown-basic" placeholder="Enter Schematic">
                                                {schemas[schemaIndex].NAME}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {schemas.map((schema, index) =>
                                                    <Dropdown.Item key={index}
                                                        onClick={() => {
                                                            setSchemaIndex(index);

                                                            setError('');
                                                        }}>
                                                        {schema.NAME}
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>

                                </Row>

                                <Row className="eomEditorValues">
                                    <Col><strong>Schema Id</strong></Col>
                                    <Col><strong>{schemas[schemaIndex].SCHEMAID}</strong></Col>
                                </Row>

                                <Row>
                                    <Col><strong>Material</strong></Col>
                                    <Col>
                                        <input type="text" className="form-control"
                                            value={material}
                                            onChange={(e) => setMaterial(e.currentTarget.value)} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col><strong>Level</strong></Col>
                                    <Col>
                                        <input type="text" className="form-control"
                                            value={level}
                                            onChange={(e) => setLevel(e.currentTarget.value)} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col><strong>Quality</strong></Col>
                                    <Col>
                                        <input type="text" className="form-control"
                                            value={quality}
                                            onChange={(e) => setQuality(e.currentTarget.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><strong>Quantity</strong></Col>
                                    <Col>
                                        <input type="text" className="form-control"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.currentTarget.value)} />
                                    </Col>
                                </Row>
                            </>
                            : null}
                    </>)
            default:
                return null;

        }
    }

    return (

        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Create an item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Dropdown>
                        <Dropdown.Toggle variant="tool-prompt" id="dropdown-basic">
                            {itemTypes[selectedType]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {itemTypes.map((choice, index) =>
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => {
                                        setSelectedType(index);
                                        setError('');
                                    }}>
                                    {choice}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <br />
                    {getContent()}

                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <span className="error"><font color="red">{error}</font></span>

                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>

                {selectedType > 0 ?
                    <Button variant="secondary" onClick={createItemFromSelection}>
                        Create Item
                    </Button>
                    : <Button variant="secondary" onClick={metacraftChosenSchematic}>
                        Next
                    </Button>}
            </Modal.Footer>
        </Modal>
    );
}

export default CreateItemPrompt;