import React from 'react';

const colourMap = {
    '^B' : '#000099',
    '~B1' : '#87AFD7',
    '~B2' : '#5FAFFF',
    '~B3' : '#00AFFF',
    '~B4' : '#005FFF',
    '~B5' : '#0000FF',
    '~B6' : '#0000D7',
    '~B7' : '#0000AF',
    '~B8' : '#000087',
    '~B9' : '#00005F',
    '^C' : '#007F7F',
    '~C1' : '#AFFFFF',
    '~C2' : '#87D7D7',
    '~C3' : '#5FFFD7',
    '~C4' : '#00D7FF',
    '~C5' : '#00FFFF',
    '~C6' : '#00AFAF',
    '~C7' : '#007F7F',
    '~C8' : '#00FFD7',
    '~C9' : '#00D787',
    '^E' : '#FF00FF',
    '^F' : '#870000',
    '^G' : '#00D700',
    '~G1' : '#AFFF5F',
    '~G2' : '#87D75F',
    '~G3' : '#87FF5F',
    '~G4' : '#00FF5F',
    '~G5' : '#87D700',
    '~G6' : '#87FF00',
    '~G7' : '#00FF00',
    '~G8' : '#00D700',
    '~G9' : '#005F00',
    '^H' : '#00FFFF',
    '^I' : '#00FF00',
    '^J' : '#FFFFFF',
    '^L' : '#FFFFFF',
    '~L1' : '#00FFD7',
    '~L2' : '#00FF87',
    '~L3' : '#DFAFFF',
    '~L4' : '#AF5FFF',
    '~L5' : '#FFAFFF',
    '~L6' : '#FFDFFF',
    '~L7' : '#FFFFAF',
    '~L8' : '#5FD7FF',
    '~L9' : '#87D7FF',
    '^M' : '#FF00FF',
    '~M1' : '#FFAFFF',
    '~M2' : '#FF87FF',
    '~M3' : '#FF5FFF',
    '~M4' : '#FF5FD7',
    '~M5' : '#FF00FF',
    '~M6' : '#D700D7',
    '~M7' : '#AF00AF',
    '~M8' : '#87005F',
    '~M9' : '#5F005F',
    '^N' : '#949494',
    '^n' : '#949494',
    '^?' : '#949494',
    '~N1' : '#DFAF87',
    '~N2' : '#DFAF5F',
    '~N3' : '#AFAF5F',
    '~N4' : '#AFAF00',
    '~N5' : '#878700',
    '~N6' : '#AF8700',
    '~N7' : '#AF8700',
    '~N8' : '#875F00',
    '~N9' : '#5F5F00',
    '^O' : '#00FFFF',
    '~O1' : '#FFD700',
    '~O2' : '#FFAF00',
    '~O3' : '#FFD75F',
    '~O4' : '#FF8700',
    '~O5' : '#FF5F00',
    '~O6' : '#D75F00',
    '~O7' : '#AF5F00',
    '~O8' : '#875F00',
    '~O9' : '#D7AF00',
    '^P' : '#5F005F',
    '~P1' : '#FFD7D7',
    '~P2' : '#FF5F87',
    '~P3' : '#FF0087',
    '~P4' : '#FF005F',
    '~P5' : '#D7005F',
    '~P6' : '#AF5F5F',
    '~P7' : '#FF8787',
    '~P8' : '#D75F5F',
    '~P9' : '#FF5F5F',
    '^Q' : '#00FFFF',
    '^R' : '#870000',
    '~R1' : '#FFD7AF',
    '~R2' : '#D7875F',
    '~R3' : '#FFAF87',
    '~R4' : '#FF875F',
    '~R5' : '#FF0000',
    '~R6' : '#D70000',
    '~R7' : '#AF0000',
    '~R8' : '#870000',
    '~R9' : '#5F0000',
    '^S' : '#FFFF00',
    '^T' : '#FFFFFF',
    '~V1' : '#AF5FAF',
    '~V2' : '#AF5FD7',
    '~V3' : '#AF5FFF',
    '~V4' : '#AF00D7',
    '~V5' : '#AF00FF',
    '~V6' : '#8700FF',
    '~V7' : '#5F00AF',
    '~V8' : '#5F00D7',
    '~V9' : '#5F00FF',
    '^W' : '#BCBCBC',
    '~W1' : '#FFFFFF',
    '~W2' : '#DADADA',
    '~W3' : '#BCBCBC',
    '~W4' : '#AFAFAF',
    '~W5' : '#9E9E9E',
    '~W6' : '#8A8A8A',
    '~W7' : '#767676',
    '~W8' : '#626262',
    '~W9' : '#4E4E4E',
    '^Y' : '#878700',
    '~Y1' : '#AFD700',
    '~Y2' : '#AFD75F',
    '~Y3' : '#D7FF5F',
    '~Y4' : '#FFFF5F',
    '~Y5' : '#FFFF00',
    '~Y6' : '#D7D700',
    '~Y7' : '#AFAF00',
    '~Y8' : '#878700',
    '~Y9' : '#5F5F00',
    '^b' : '#0000FF',
    '^c' : '#00FFFF',
    '^d' : '#FFFFFF',
    '^e' : '#FF0000',
    '^f' : '#FF00FF',
    '^g' : '#00FF00',
    '^h' : '#00FFFF',
    '^k' : '#767676',
    '^m' : '#00FFFF',
    '^p' : '#FF00FF',
    '^q' : '#00FFFF',
    '^r' : '#FF0000',
    '^t' : '#007F7F',
    '^u' : '#BCBCBC',
    '^v' : '#00FFFF',
    '^w' : '#FFFFFF',
    '^y' : '#FFFF00',
    '¬A' : '#007F7F',
    '¬B' : '#00FF00',
    '¬C' : '#0000FF',
    '¬D' : '#FFFF00',
    '¬DEFAULT' : '#00FFFF',
    '¬E' : '#00FFFF',
    '¬F' : '#878700',
    '¬G' : '#FFFFFF',
    '¬H' : '#00FFFF',
    '¬I' : '#0000FF',
    '¬J' : '#FF0000',
    '¬K' : '#FF0000',
    '¬L' : '#FFFF00',
    '¬M' : '#878700',
    '¬N' : '#FFFFFF',
    '¬a' : '#00FFFF',
    '¬b' : '#00D700',
    '¬c' : '#767676',
    '¬d' : '#FFFFFF',
    '¬e' : '#00FFFF',
    '¬f' : '#00D700',
    '¬g' : '#FF0000',
    '¬h' : '#007F7F',
    '¬i' : '#767676',
    '¬j' : '#FFFFFF',
    '¬k' : '#00FF00',
    '¬l' : '#FF0000',
    '¬m' : '#FFFF00',
    '¬n' : '#FF0000'
}

const keyletters = ["P", "L"];

const escapeRegExp= (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const transformText = (text) => {
    if(text===undefined)
        return "";
    let output = "";
    var prevCode = '~W1';
    var lastCode = '~W1';
    for(let i = 0; i < text.length;i++) {
        if((text.charAt(i)=='^')&&(i < text.length-1)){
            if(text.charAt(i+1)=='^'){
                let colourCode = text.substring(i,i+2);
                output +=colourCode; 
                i++;
                continue;
            } else if(text.charAt(i+1)=='~'){
                let colourCode = text.substring(i+1,i+2);
                output += colourCode; 
                i++;
                continue;
            } else if(text.charAt(i+1)=='?'){
                output += '</font><font color ="'+ colourMap[prevCode] +'">';
                i++;
                continue;
            } else {
                let colourCode = text.substring(i,i+2).toUpperCase();
                if(colourCode in colourMap) {
                    prevCode= lastCode;
                    lastCode = colourCode;
                    output += '</font><font color ="'+ colourMap[colourCode] +'">';
                }
                else 
                    output +=colourCode; 
                i+=1;
                continue;        
            }
        } else if ((text.charAt(i)=='~')&&(i < text.length-2)){
            let colourCode = text.substring(i,i+3).toUpperCase();
            if(colourCode in colourMap) {
                prevCode= lastCode;
                lastCode = colourCode;                    
                output += '</font><font color ="'+ colourMap[colourCode] +'">';
            }
            else 
              output +=colourCode; 
            i+=2;
            continue;
        }
        output += text.charAt(i);
    }
    text = '<font>' + output + '</font>';
    text = text.replace((/\n/g)||[], '<BR/>');
    
    return text;
}

const transformTextOld = (text) => {
    text = '<font>' + text;
    Object.entries(colourMap).map(([colourCode, colourValue]) => {
        let code = escapeRegExp(colourCode);

        var regEx = new RegExp(code, "g");
        text = text.replace(regEx, '</font><font color ="'+ colourValue +'">');
     
        if(colourCode.startsWith("~")) {
            regEx = new RegExp(colourCode.toLowerCase(), "g");
            text = text.replace(regEx, '</font><font color ="'+ colourValue +'">');
        }
    });

    text = text + '</font>';

    //let enteredText = text;
    //let numberOfLineBreaks = (enteredText.match(/\n/g)||[]).length;
    //let characterCount = enteredText.length + numberOfLineBreaks;
    text = text.replace((/\n/g)||[], '<BR/>');
    //console.log('Line Breaks ' + numberOfLineBreaks);

    return text;
}


const removeColourCodes = (text) => {
    Object.entries(colourMap).map(([colourCode, colourValue]) => {
        let code = escapeRegExp(colourCode);

        var regEx = new RegExp(code, "g");
        text = text.replace(regEx, '');
     
        if(colourCode.startsWith("~")) {
            regEx = new RegExp(colourCode.toLowerCase(), "g");
            text = text.replace(regEx, '');
        }
    });

    
    //let enteredText = text;
    //let numberOfLineBreaks = (enteredText.match(/\n/g)||[]).length;
    //let characterCount = enteredText.length + numberOfLineBreaks;
    text = text.replace((/\n/g)||[], '<BR/>');
    //console.log('Line Breaks ' + numberOfLineBreaks);

    return text;
}

export {transformText, removeColourCodes, colourMap};