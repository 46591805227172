import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ProcGenTemplates from './ProcGenTemplates';
import ProcGenParameters from './ProcGenParameters';
import ProcGenFeatures from './ProcGenFeatures';
import { populateConfig } from './../BuilderUtils';
import ProcGenMutators from './ProcGenMutators';
function RandomGeneration() {
    const [mode, selectMode] = useState(2);
    const showMainScreen = () => {
        switch (mode) {
            case 0:
                return <ProcGenTemplates />
            case 1:
                return <ProcGenFeatures />
            case 3:
                return <ProcGenMutators />
            default:
                return <ProcGenParameters />
        }
    }

    useEffect(() => {
        populateConfig();
    }, [])

    return (
        <div className="container-eom" id="divMainContent">
            <h2>Procedural Generation</h2>
            <div className="flexbox-container text-center">
                <Row >
                    <Col onClick={() => { selectMode(2) }}>Generate</Col>
                    <Col onClick={() => { selectMode(3) }}>Mutators</Col>
                    <Col onClick={() => { selectMode(1) }}>Features</Col>
                    <Col onClick={() => { selectMode(0) }}>Templates</Col>
                </Row>
            </div>
            {showMainScreen()}
        </div>

    );
}

export default RandomGeneration;

