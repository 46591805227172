import React, { useState, useEffect } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { Row, Col } from 'react-bootstrap';
import AdminWealthActivity from './AdminWealthActivity';
import { getWealthActivity } from '../components';
import { timeConverter } from '../utils/NiceStrings'

function PlayerWealthOverview(props) {
    //console.log(props);

    const [playerWealthOverview, setPlayerWealthOverview] = useState();
    const [dateRange, setDateRange] = useState(props.dateRange);

    if ((props.playerWealthOverview === undefined) && (playerWealthOverview !== undefined)) {
        setPlayerWealthOverview();
    } else if ((props.playerWealthOverview !== undefined) && (playerWealthOverview === undefined)) {
        setPlayerWealthOverview(props.playerWealthOverview);
    } else if ((props.playerWealthOverview !== playerWealthOverview)) {
        setPlayerWealthOverview(props.playerWealthOverview);
    }

    useEffect(() => {
        //console.log('use Effect => Player Wealth Overview', playerWealthOverview);
    }, [playerWealthOverview]);

    if (playerWealthOverview === undefined)
        return null;

    return (<>
        <Row>
            <h2>Recent Activity</h2>
        </Row>
        <Row>
            <div>

                {playerWealthOverview.deltas.map((delta, index) =>
                    <Row key={index}>
                        <Col>
                            <a className="dryLink p-2" href={`/admin/wealth/${delta.player}/${props.dateRange}`}>
                                {delta.player}
                            </a>
                        </Col>
                        <Col style={{ textAlign: "right" }}>{delta.change.toLocaleString()}</Col>

                        <Col>{moment.unix(delta.start.time / 1000).format("DD/MM/YYYY")}</Col>
                        <Col>{moment.unix(delta.end.time / 1000).format("DD/MM/YYYY")}</Col>



                    </Row>


                )}
            </div>
        </Row>
    </>
    )
}

export default PlayerWealthOverview;