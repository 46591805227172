import React, { useState, useEffect } from 'react';
import { getSystemReportFromMud } from '../components';
import {timeConverter, elapsedTime} from './../utils/NiceStrings'
import { Row } from 'react-bootstrap';

function AdminSystemReview() {
    const [systemReview, setSystemReview] = useState([]);

    const getSystemLog = async () => {
        let data = await getSystemReportFromMud();
        console.log('System Review', data);
        if (data.result) {
            setSystemReview(data.reports);
        } else {
            setSystemReview([]);
        }
    }

    const getServicesReport = (report) => {
        return (
            <div>
                <h4> Edge of Midnight {report.reporttype} Report</h4>
                <span>The system has been running for {elapsedTime(report.uptime)}</span> <br />
                <span>The system has been running since {timeConverter( report.starttime)}</span> <br />
                <span>The system is utilizing {report.freememory}kb of {report.totalmemory}kb</span> <br />
            </div>
        );
    }

    const getTickablesReport = (report) => {
        return (
            <div>
                <br/>
                <h5>Edge of Midnight {report.reporttype} Report</h5>
                <span>There are {report.totaltickers} ticking objects in {report.tickgroupsize}  groups.</span> <br />
                <span>The ticking objects have consumed: {elapsedTime(report.totalmillis)}, Average is {elapsedTime(report.totalmillis/report.totalticks)} .</span>
            </div>
        );
    }

    const getThreadsReport = (report) => {
        return (
            <div>
                <br/>
                <h5>Edge of Midnight {report.reporttype} Report</h5>
                <span>There are {report.activethreads} active out of {report.livethreads}  live threads.</span> <br/>
                {report.threads.map(thread => 
                    <div key={thread.threadname}>
                        <span>Thread {thread.threadname} has consumed: {thread.consumed} ({thread.status}).</span>
                        <br/>
                    </div>
                )}
            </div>
        );
    }

    const getSessionsReport = (report) => {
        return (
            <div>
                <br/>
                <h5>Edge of Midnight {report.reporttype} Report</h5>

                <span>There are {report.online} ticking players logged on.</span> <br/>
                <span>The ticking players have consumed: {elapsedTime(report.totalmobmillis)}.</span>

            </div>
        );
    }


    const getReport = (report) => {
        console.log(report.reporttype);
        switch (report.reporttype) {
            case 'services':
                return getServicesReport(report);
            case 'tickables':
                return getTickablesReport(report);
            case 'sessions':
                return getSessionsReport(report);            
            case 'threads':
                return getThreadsReport(report);
                }
        return (<div><span>{report.reporttype}</span></div>);
    }

    useEffect(() => {
        getSystemLog();
    }, []);

    return (
        <div className="container container-eom" id="divMainContent">
            {systemReview.map(report =>
                <Row key={report.reportype}>
                    {getReport(report)}
                    <br />
                    <br />
                </Row>
            )}
        </div>
    )
}

export default AdminSystemReview;