import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { Row, ButtonGroup, ToggleButton, Col } from 'react-bootstrap';
import PlayerWealth from './PlayerWealth';
import PlayerWealthOverview from './PlayerWealthOverview';
import { getPlayerWealth, getPlayerWealthOverview } from '../components';
import { useParams } from 'react-router-dom';

import { ArcElement, CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto';
Chart.register(ArcElement);
Chart.register(CategoryScale);

const ONE_DAY = 1;
const ONE_WEEK = 2;
const ONE_MONTH = 3;
const THREE_MONTHS = 4;
const ONEDAY_AS_MILLISECONDS = 1 * 24 * 60 * 60 * 1000;

const radios = [
    { name: 'One Day', value: 1 },
    { name: 'One Week', value: 2 },
    { name: 'One Month', value: 3 },
    { name: 'Three Months', value: 4 },
];

function AdminWealthPortal() {
    let defaultPlayer = undefined;
    const params = useParams();

    if (params.playername !== undefined) {
        defaultPlayer = params.playername;
    }

    let defaultDateRange = ONE_WEEK;
    if (params.daterange !== undefined) {
        defaultDateRange = params.daterange;
    }
    const [playerName, setPlayerName] = useState(defaultPlayer);
    const [playerWealth, setPlayerWealth] = useState();
    const [playerWealthOverview, setPlayerWealthOverview] = useState();
    const [dateRange, setDateRange] = useState(defaultDateRange);

    const onKeyUpEvent = async e => {
        if (e.key === 'Enter') {
            getPlayerWealthLocal();
        }
    }

    const clearData = () => {
        setPlayerWealth();
    }

    const getPlayerWealthLocal = async () => {
        setPlayerWealth();
        let date = new Date();
        let adjust = 0;
        //console.log('Date Range', dateRange);
        let i = parseInt(dateRange);
        switch (i) {
            case ONE_DAY:
                adjust = ONEDAY_AS_MILLISECONDS;
                break;
            case ONE_WEEK:
                adjust = ONEDAY_AS_MILLISECONDS * 7;
                break;
            case ONE_MONTH:
                adjust = ONEDAY_AS_MILLISECONDS * 28;
                break;
            case THREE_MONTHS:
                adjust = ONEDAY_AS_MILLISECONDS * 90;
                break;
        }
        let start = new Date(date.getTime() - adjust);
        let end = new Date(date.getTime() + ONEDAY_AS_MILLISECONDS);
        let data = null;
        // console.log('Start', adjust);
        if ((playerName === undefined) || (playerName === '')) {
            data = await getPlayerWealthOverview(moment(start).format('yyyy-MM-DD'), moment(end).format('yyyy-MM-DD'));
            //console.log('Player Wealth Overview', data);
            if (data.result) {
                setPlayerWealthOverview(data.data);
            }
        } else {
            data = await getPlayerWealth(playerName, moment(start).format('yyyy-MM-DD'), moment(end).format('yyyy-MM-DD'));
            //console.log('Player Wealth', data);
            if (data.result) {
                setPlayerWealth(data.data);
            }
        }
    }

    useEffect(() => {
        console.log(dateRange);
        if (playerWealth === undefined)
            getPlayerWealthLocal();
    }, [playerWealth, dateRange]);

    return (
        <div className="container-eom" id="divMainContent">
            <Row className={"flex-nowrap"}>

                <ButtonGroup toggle className="px-2">
                    {radios.map((radio, idx) => (
                        <Col xs ={2} key={idx}>
                            <ToggleButton
                                key={idx}
                                type="radio"
                                variant="themed-dark"
                                name="radio"
                                value={radio.value}
                                checked={dateRange === radio.value}
                                onClick={(e) => setDateRange(e.currentTarget.value)
                                    

                                }
                            >
                                {radio.name}
                            </ToggleButton>
                        </Col>

                    ))}
                </ButtonGroup>
            </Row>
            <Row>
                <div className="input-group mb-3" onSubmit={getPlayerWealthLocal}>
                    <div className="input-group-prepend" >
                        <button className="btn btn-themed-dark" type="submit" onClick={getPlayerWealthLocal}>Get Economic Data</button>
                    </div>
                    <input type="text" className="form-control" defaultValue={playerName} onKeyUp={onKeyUpEvent} onChange={(e) => {
                        setPlayerName(e.currentTarget.value);
                        clearData();
                    }} />
                </div>
            </Row>
            {playerWealth !== undefined
                ? <PlayerWealth playerWealth={playerWealth} dateRange={dateRange} />
                : null}
            {playerWealthOverview !== undefined
                ? <PlayerWealthOverview playerWealthOverview={playerWealthOverview} dateRange={dateRange} />
                : null}
        </div>
    );
}

export default AdminWealthPortal;