import React, { useEffect } from 'react'

const ThemeContext = React.createContext();
const ThemeDispatchContext = React.createContext();

let theme = localStorage.getItem("theme");
//console.log('Loading theme', theme);
const initialState = {
    theme: theme || 'light'
};

function useThemeDispatch() {
    const context = React.useContext(ThemeDispatchContext);
    if (context === undefined) {
        throw new Error("useThemeDispatch must be used within an ThemeProvider");
    }
    return context;
}

const themeReducer = (initialState, action) => {
    switch (action.type) {
        case "TOGGLE_THEME":
            var newTheme;
            if (initialState.theme == 'light')
                newTheme = 'dark';
            else
                newTheme = 'light';
            localStorage.setItem('theme', newTheme);
            return {
                ...initialState,
                theme: newTheme
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

function ThemeProvider({ children }) {
    const [state, dispatch] = React.useReducer(themeReducer, initialState);

    return <ThemeContext.Provider value={state}>
        <ThemeDispatchContext.Provider value={dispatch}>
            {children}
        </ThemeDispatchContext.Provider>
    </ThemeContext.Provider>
}

function useThemeBuilder() {
    const context = React.useContext(ThemeContext)
    if (context === undefined) {
        throw new Error('useThemeBuilder must be used within a ThemeProvider')
    }
    return context
}

export { ThemeProvider, useThemeBuilder, useThemeDispatch }
