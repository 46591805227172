import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StandardRoom from '../map/StandardRoom';
import SimpleRoom from '../map/SimpleRoom';
import SimpleExit from '../map/SimpleExit';
import StandardExit from '../map/StandardExit';

import { Stage, Layer, Line, Text, Group, Circle } from 'react-konva';

function EoMMap(props) {

    const selectRoom = props.selectRoom;
    const currentRoom = props.currentRoom;
    const editing = props.editing;

    let simpleMode = props.simpleMode;// true;
    var mapwidth = 600;
    var mapheight = 600;
    if (props.mapwidth !== undefined)
        mapwidth = props.mapwidth;
    if (props.mapheight !== undefined)
        mapheight = props.mapheight;

    var showElevaton = false;
    if (props.showElevaton !== undefined) {
        showElevaton = props.showElevaton;
        if(showElevaton) {
            mapheight -= 50; 
        }
    }

    if(!simpleMode) {
        mapheight -= 50;  
    }

    const roomRef = useRef();
    const stageRef = useRef();

    const [stageScale, setStageScale] = useState(1);
    const [stageX, setStageX] = useState(0);
    const [stageY, setStageY] = useState(0);

    const [roomList, setRoomList] = useState([]);
    const [exitsList, setExitsList] = useState([]);
    const [level, setLevel] = useState(0);
    const [submap, setSubmap] = useState(0);
    const [oldAreaId, setOldAreaId] = useState();

    const [currentAreaData, setCurrentAreaData] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (currentAreaData !== undefined)
            setOldAreaId(currentAreaData.id);
        else
            setOldAreaId();
    }, [currentAreaData]);

    const uplevel = () => {
        if (level < currentAreaData.map.submaps[submap].maxz)
            setLevel(level + 1);
    }
    const downlevel = () => {
        if (level > currentAreaData.map.submaps[submap].minz)
            setLevel(level - 1);
    }

    const upSubmap = () => {
        if (submap < (currentAreaData.map.submaps.length - 1))
            setSubmap(submap + 1);
    }
    const downSubmap = () => {
        if (submap > 0)
            setSubmap(submap - 1);
    }

    const handleDragStart = (e) => {
        //console.log('dragStart');
        setIsDragging(true);
    };

    const handleDragEnd = e => {
        //console.log('dragEnd');
        setIsDragging(false);
    }

    const clickHandler = (roomid) => {
        //console.log('Selected Room', e.target.attrs.id);
        if (editing)
            return;
        if (selectRoom === undefined)
            return;
        //setCurrentRoomId(e.target.attrs.id);
        selectRoom(roomid);
    };

    const handleWheel = e => {
        e.evt.preventDefault();

        const scaleBy = 1.02;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };

        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

        setStageScale(newScale);
        setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale);
        setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale);
    };


    let offsetX = mapwidth / 2 * -1;
    let offsetY = mapheight / 2 * -1;

    const resetMap = () => {
        if ((currentAreaData !== null) && (currentAreaData.map !== undefined)) {
            // console.log(currentAreaData.map.submaps[submap]);
            let xd = currentAreaData.map.submaps[submap].minx - currentAreaData.map.submaps[submap].maxx;
            // console.log('xd:', currentAreaData.map.submaps[submap].minx, currentAreaData.map.submaps[submap].maxx, xd);
            xd = xd / 2;
            offsetX = -150 + (xd * 10);

            let xy = currentAreaData.map.submaps[submap].miny - currentAreaData.map.submaps[submap].maxy;
            //console.log('xd:', currentAreaData.map.miny, currentAreaData.map.maxy, xy);
            xy = xy / 2;
            offsetY = -150 + (xy * 10);
        }
    }

    if ((props.currentAreaData === null) && (currentAreaData !== null)) {
        //Reset the map
        // console.log('Resetting the map');
        setCurrentAreaData(null);
    } else if ((props.currentAreaData !== null) && (currentAreaData === null)) {
        //Set the map regardless
        setCurrentAreaData(props.currentAreaData);
        setLevel(0);
        setSubmap(0);
    } else if (props.currentAreaData !== null) {
        if (currentAreaData.id !== props.currentAreaData.id) {
            setCurrentAreaData(props.currentAreaData);
            setLevel(0);
            setSubmap(0);
        } else if (currentAreaData.location !== props.currentAreaData.location) {
            setCurrentAreaData(props.currentAreaData);
            setLevel(0);
            setSubmap(0);
        }
    }

    useEffect(() => {
        //console.log("Redrawing Map");
        resetMap();
    }, [roomList, exitsList]);

    useEffect(() => {
        if ((currentAreaData !== null) && (currentAreaData.map !== undefined)) {
            //console.log("Updating Rooms and Exits");
            setRoomList(currentAreaData.map.submaps[submap].rooms);
            setExitsList(currentAreaData.map.submaps[submap].exits);
        } else {
            //console.log("Clearing Rooms and Exits");
            setRoomList([]);
            setExitsList([]);
            setLevel(0);
            setSubmap(0);
        }
    }, [currentAreaData, submap]);

    useEffect(() => {
        //console.log('map', currentAreaData.map);  
        if ((currentRoom != null) && (currentAreaData.map != undefined)) {
            //console.log('map2', currentAreaData.map);
            //find the submap
            for (var i = 0; i < currentAreaData.map.submaps.length; i++) {
                let index = currentAreaData.map.submaps[i].rooms.findIndex((room) => {
                    return (room.roomid === currentRoom.id);
                });
                if (index > -1) {
                    //console.log(currentAreaData.map.submaps[i].rooms[index]);
                    setSubmap(i);
                    setLevel(currentAreaData.map.submaps[i].rooms[index].z);
                }

            }
        }
    }, [currentRoom]);

    if (currentAreaData === null)
        return null;

    if (currentAreaData === undefined)
        return null;

    if (currentAreaData.map === undefined)
        return null;


    const drawRoom = (index, room) => {
        if (simpleMode)
            return (<SimpleRoom key={index} room={room} activeroom={currentRoom} clickHandler={clickHandler} />);
        else
            return (<StandardRoom key={index} room={room} activeroom={currentRoom} clickHandler={clickHandler} />);

    }

    const drawExit = (index, exit) => {
        if (simpleMode)
            return (<SimpleExit key={index} index={index} exit={exit} />);
        else
            return (<StandardExit key={index} index={index} exit={exit} />);

    }

    return (
        <>
            {showElevaton ?
                <Row className={"mb-1 px-2"}>
                    <Col>
                        <span> <font color={"white"}>Elevation {level} (Max: {currentAreaData.map.submaps[submap].minz}/ Min:{currentAreaData.map.submaps[submap].maxz})
                        </font></span>
                    </Col>
                    <Col>
                        <div className={"px-2"}>
                            <FontAwesomeIcon onClick={uplevel} className="fa fa-lg fa-eom m-2" icon={"arrow-up"} />
                            <FontAwesomeIcon onClick={downlevel} className="fa fa-lg fa-eom m-2" icon={"arrow-down"} />
                        </div>

                    </Col>
                </Row>
                : null}
            {currentAreaData.map.submaps.length > 1 ?
                <Row className={"mb-2 px-2"}>
                    <Col>
                        <span><font color={"white"}>Submap {submap} (0 to Max: {currentAreaData.map.submaps.length - 1})</font></span>
                    </Col>
                    <Col>
                        <div className={"px-2"}>
                            <FontAwesomeIcon onClick={upSubmap} className="fa fa-lg fa-eom m-2" icon={"arrow-up"} />
                            <FontAwesomeIcon onClick={downSubmap} className="fa fa-lg fa-eom m-2" icon={"arrow-down"} />
                        </div>
                    </Col>
                </Row>
                : null}
            <Row className={"map-container"}>
                <div className={"map-container"}>
                    <Stage ref={stageRef}
                        width={mapwidth} height={mapheight}

                        offset={{ x: offsetX, y: offsetY }}
                        scaleX={stageScale}
                        scaleY={stageScale}
                        x={stageX}
                        y={stageY}
                        draggable={true}
                        onDragMove={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onWheel={handleWheel}

                    >

                        <Layer ref={roomRef}>
                            {exitsList.filter(room => room.z1 == level).map(((exit, index) =>
                                drawExit(index, exit)
                            ))}

                            {roomList.filter(room => room.z == level).map((room, index) =>
                                drawRoom(index, room)
                            )}
                        </Layer>


                    </Stage>
                </div>
            </Row>
        </>)
}

export default EoMMap;