import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Container, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EomMarkdown from './EomMarkdown';


const MarkdownEditor = ({ editing = false, documentToEdit, saveDocument = null, defaultHeight = 25, noCodeBlocks = true, autoUpdate = null }) => {
    const modes = ['View', 'Edit', 'Side By Side'];
    const max_history = 5;
    const topOfDocRef = useRef(null);

    const [document, setDocument] = useState(undefined);
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [mode, setMode] = useState(0);

    const storyRef = useRef();

    useEffect(() => {
        setDocument(documentToEdit);
    }, [documentToEdit]);

    useEffect(() => {
        setHistory(prev => ([...prev, document]));
        if (autoUpdate != null)
            autoUpdate(document)
    }, [document]);

    useEffect(() => {
        if (!editing) {
            setMode(0);
        } else {
            setMode(1);
        }
    }, [editing])

    useEffect(() => {
        //const delayDebounceFn = setTimeout(() => {
        //     console.log('Update History');
        let oldIndex = historyIndex;
        var newIndex = oldIndex;
        if (history.length > max_history) {
            setHistory(prev => prev.slice(1));
            if (historyIndex > 0) {
                //    console.log('Index less more then zero, trmming back');
                newIndex = oldIndex - 1;
            }
        }

        // if (historyIndex >= history.length - 1)
        //     newIndex = history.length - 1;
        if (historyIndex < 0) {
            //console.log('Index less than one, resetting to max');
            newIndex = history.length - 1;
        }
        if (newIndex >= max_history) {
            newIndex = max_history - 1;
        }
        setHistoryIndex(newIndex);
        // }, 2000)

        // return () => clearTimeout(delayDebounceFn)

    }, [history]);


    const undo = () => {
        if (historyIndex >= 0) {
            console.log('Undo - set from', historyIndex);
            setDocument(history[historyIndex]);
            setHistoryIndex(prev => (prev - 1));
        }
    }

    const redo = () => {
        if (historyIndex < max_history - 1) {
            setDocument(history[historyIndex + 1]);
            setHistoryIndex(prev => (prev + 1));
        }
    }

    const changeSelection = (characters) => {
        const w = characters.length;
        const divElement = storyRef.current;
        let cursorStart = divElement.selectionStart;
        let cursorEnd = divElement.selectionEnd;

        if (document.charAt(cursorEnd - 1) == ' ') {
            //console.log('Space!');
            cursorEnd--;
        }

        var before = document.slice(cursorStart - w, cursorStart);
        //console.log('Before', before);

        var after = document.slice(cursorEnd, cursorEnd + w);
        //console.log('After', after);

        if (before == characters && after == characters) {
            //console.log('Already bold');
            var remove = document.split('');
            remove.splice(cursorEnd, w);
            remove.splice(cursorStart - w, w);
            //console.log('Already bold', remove.join(''));
            setDocument(remove.join(''));

        } else {

            var output = [document.slice(0, cursorStart), characters, document.slice(cursorStart, cursorEnd), characters, document.slice(cursorEnd)].join('');
            setDocument(output);
        }
        storyRef.current.focus();
        divElement.selectionStart = cursorStart;
        divElement.selectionEnd = cursorEnd;
    }

    const italiciseSelection = () => {
        changeSelection('*');
    }

    const boldSelection = (e) => {
        changeSelection('**');
    }

    const setHeader = (header) => {
        const divElement = storyRef.current;
        console.log(divElement.value);

        let cursorStart = divElement.selectionStart;
        let cursorEnd = divElement.selectionEnd;

        console.log(document, cursorStart);

        var headerPos = document.lastIndexOf("\n", cursorStart);
        headerPos += 1;

        var output = [document.slice(0, headerPos), header, document.slice(headerPos)].join('');
        setDocument(output);
    }


    const showMenu = () => {
        if (!editing) {
            return null;
        }
        return (

            <div className={"markdown-editor-header"}>

                <FontAwesomeIcon onClick={undo} className={"fa fa-lg m-2 fa-eom" + (historyIndex < 1 ? "-disabled" : "")} icon={"undo"} />

                <FontAwesomeIcon onClick={redo} className={"fa fa-lg m-2 fa-eom" + ((historyIndex >= max_history - 1) ? "-disabled" : "")} icon={"redo"} />

                <FontAwesomeIcon onClick={boldSelection} className="fa fa-lg fa-eom m-2" icon={"bold"} />

                <FontAwesomeIcon onClick={italiciseSelection} className="fa fa-lg fa-eom m-2" icon={"italic"} />

                <Dropdown disabled={true} className={"btn-menu"}>
                    <Dropdown.Toggle variant="tool" id="dropdown-basic" size="sm">
                        Select Text Size
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setHeader('')}>Normal</Dropdown.Item>
                        <Dropdown.Item onClick={() => setHeader('# ')}>Header 1</Dropdown.Item>
                        <Dropdown.Item onClick={() => setHeader('## ')}>Header 2</Dropdown.Item>
                        <Dropdown.Item onClick={() => setHeader('### ')}>Header 3</Dropdown.Item>
                        <Dropdown.Item onClick={() => setHeader('#### ')}>Header 4</Dropdown.Item>
                        <Dropdown.Item onClick={() => setHeader('##### ')}>Header 5</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>


                <Dropdown disabled={true} className={"btn-menu"}>
                    <Dropdown.Toggle variant="tool" id="dropdown-basic" size="sm">
                        {modes[mode]} Mode
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {modes.map((mode, index) =>
                            <Dropdown.Item key={index} onClick={() => setMode(index)}>{mode}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                {saveDocument !== null ?
                    <Button className={"btn-menu btn-right"} onClick={() => saveDocument(document)}>Save Document</Button>
                    : null
                }
            </div>

        )
    }

    const showEditText = () => {
        return (<>
            <Row>
                <textarea rows={defaultHeight}
                    ref={storyRef}
                    name="textValue"
                    height="100%"
                    width="100%"
                    className="form-control form-story"
                    value={document}
                    onChange={(e) => {
                        setHistoryIndex(-1);
                        setDocument(e.target.value)
                    }
                    }
                />

            </Row>
            {autoUpdate ? null :
                <Row>
                    <Button onClick={() => {
                        topOfDocRef.current.scrollIntoView({ behavior: 'auto' });

                    }}>Scroll to the top</Button>
                </Row>}
        </>)
    }

    if (document === undefined) {
        return null;
    }


    const getEditor = () => {
        switch (mode) {
            case 1:
            case 2:
                return (<Col>{showEditText()}</Col>);
            default:
                return null;
        }
    }

    const getDisplay = () => {
        switch (mode) {
            case 0:
            case 2:
                return (
                    <Col>

<EomMarkdown markdown={noCodeBlocks ? document.replace('`', '\\`') : document}/>

                    </Col>
                );

            default:
                return null;
        }
    }

    return (
        <Container ref={topOfDocRef}>
            <Row className={"markdown-editor-header"} xs={1} md={6} lg={8}>
                {showMenu()}
            </Row>
            <Row>
                {getEditor()}
                {getDisplay()}
            </Row>
        </Container>
    )
}

export default MarkdownEditor;