import React from 'react';

import { Line, Text, Group } from 'react-konva';
import getLocaleColour from './MapColouring'

const width = 40;
const roomWidth = width / 2;

function StandardRoom(props) {

    let room = props.room;
    let piX = room.x;
    let piY = room.y;
    let roomid = room.roomid;
    let locale = room.locale;
    let mobcount = room.mobcount;
    let activeroom = props.activeroom;
    let clickHandler = props.clickHandler;

    piY = piY * -1;

    let roomColor = getLocaleColour(locale);
    let borderColour = 'silver';
    let textColour = 'red'
    let borderWidth = 1;


    if (activeroom != undefined) {
        if (activeroom.id === room.roomid) {
            borderWidth = 3;
            borderColour = 'gold';
        }
    }

    const w = roomWidth / 2;
    const x = piX * width;
    const y = piY * width;

    return (<Group key={roomid}><Line key={roomid} id={roomid} closed points={[
        x - w, y - w,
        x + w, y - w,
        x + w, y + w,
        x - w, y + w]} fill={roomColor} stroke={borderColour} strokeWidth={borderWidth} onClick={()=>clickHandler(roomid)} />
        {mobcount > 0 ?
            <Text x={x - 8} y={y - 8}
                fontSize={10}
                text={mobcount}
                stroke={textColour}
                strokeWidth={1}
                align="center"
                onClick={()=>clickHandler(roomid)}
            />
            : null}
    </Group>)
}

export default StandardRoom;