const getLocaleColour = (locale) => {
    let roomColour = 'White';
    switch (locale) {
        case 'Road':
        case 'CityStreet':
            roomColour = 'grey';
            break;
        case 'GardenRoom':
        case 'Woods':
            roomColour = 'green';
            break;
        case 'Plains':
            roomColour = 'Tan';
            break;
        case 'WoodRoom':
            roomColour = 'SaddleBrown';
            break;
        case 'StoneRoom':
            roomColour = 'SlateGray';
            break;
        default:
            if (locale.includes('Water')) {
                roomColour = 'LightSkyBlue';
            }
    }
    return roomColour;
}

export default getLocaleColour;