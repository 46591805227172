import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { listAreas, getAreaData } from '../components/';
import { removeColourCodes } from '../utils/ColourMap'

function RealmAreas() {

  const [areas, setAreas] = useState([]);
  const [currentArea, setCurrentArea] = useState('');
  const [currentAreaData, setCurrentAreaData] = useState('');
  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getAreaList = async () => {
    // console.log('Getting area list');
    const data = await listAreas();
    if (data.result) {
      // console.log('Got area list');
      setAreas(data.areas);
    } else {
      //   console.log('Failed area list');
      setAreas([]);
    }
  }

  const loadArea = async e => {
    const newAreaName = e.target.getAttribute('name');

    //console.log('Loading ' + newAreaName);
    setCurrentArea(newAreaName);

    const data = await getAreaData(newAreaName);
    //console.log('Loaded ' + data);
    if (data.result) {

      setCurrentAreaData(data);
      handleShow();
    } else {
      setCurrentAreaData({});
    }
  }

  useEffect(() => {
    //console.log('Use Effect');
    getAreaList();
  }, []);

  return (
    <div className="container" id="divMainContent">
      <br />
      <h1>Areas of the Realm</h1>
      <br />
      <div className="d-flex align-content-start flex-wrap bd-highlight mb-3" >
        {areas.map(area =>
          <div className="p-2 m-2" onClick={loadArea} name={area.name} key={area.name}>
            {removeColourCodes(area.display)}
          </div>

        )}
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{currentArea}</Modal.Title>
          <div className="alignright">
            <small className="alignright" id="area_status">Status {currentAreaData.status}</small>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>{currentAreaData.description}</p>
          <ul>
            <li id="area_author">Author: {currentAreaData.author}</li>
            <li id="area_region">Region: {currentAreaData.region}</li>
            <li id="area_population">Population: {currentAreaData.population}</li>
            <li id="area_level">Level Range: {currentAreaData.level}</li>
          </ul>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RealmAreas;