import React, { useState, useEffect } from 'react';

import {listClans} from './../components';

function RealmClans() {
  const [clanList, setClanList] = useState([]);

  const getClanList = async () => {
    const data = await listClans();
    console.log(data);
    if(data.result) {
      setClanList(data.clans);
    } else {
      setClanList([]);
    }
  }

  useEffect(() => {
    getClanList();
  }, []);

  return (
    <div className="container" id="divMainContent">
      <br />
      <div className='row justify-content-between'>
        <h1>Clans of the Realm</h1>
      </div>
      <br />
      <ul>
        {clanList.map(clan =>
        <div key = {clan.name} className="jumbotron deity-female">
          <h2 className="display-4">{clan.name}</h2>
          <>
          {
            clan.premise.split(/\r\n|\r|\n/).map((info, index) => 
              <p className="lead" key={index}>
                {info}
              </p>
            )
          }
          </>
          <hr className="my-4"/>
          <strong>Premise </strong>  <p>{clan.type}</p>
          <strong>Leaders </strong>  <p>{clan.leaders}</p>
          <strong>Officers </strong>  <p>{clan.officers}</p>
        </div>

        )}
      </ul>
    </div>
  );
}

export default RealmClans;