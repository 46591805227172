import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { updateTemplate, deleteTemplate } from '../../components/ProceduralGenerationRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip as ReactTooltip, Tooltip } from 'react-tooltip'
import EditableField from './../EditableField';

function ProcGenTemplate(props) {
    var editable = props.editable;
    if (editable === undefined)
        editable = true;

    const [expanded, setExpanded] = useState(false);
    const [editing, setEditing] = useState(false);
    const [feature, setFeature] = useState(null);
    const [originalFeature, setOriginalFeature] = useState(null);

    useEffect(() => {
        console.log('useEffect', feature);
        var featurecopy = JSON.parse(JSON.stringify(props.feature))
        setFeature(featurecopy);
        setOriginalFeature(props.feature);

    }, [props]);

    // useEffect(() => {
    //     var featurecopy = JSON.parse(JSON.stringify(props.feature))
    //     setFeature(featurecopy);
    //     setOriginalFeature(props.feature);
    // }, []);

    const callDispatcher = (fieldName, newValue) => {
        console.log('Dispatching', fieldName, newValue);
        feature[fieldName] = newValue;
        setFeature({ ...feature });
    }

    const deleteEntry = () => {
        deleteTemplate(feature.id).then(
            result => {
                if (result.result) {
                    setExpanded(false);
                    props.deleteCallback(feature.id);
                }
            }
        );
    }

    const cancelChanges = () => {
        var featurecopy = JSON.parse(JSON.stringify(originalFeature))
        setFeature(featurecopy);
        setEditing(false);
    }


    const revertChanges = () => {
        var featurecopy = JSON.parse(JSON.stringify(originalFeature))
        setFeature(featurecopy);
    }

    const cloneFeature = () => {
        var featurecopy = JSON.parse(JSON.stringify(feature));
        featurecopy.id = -1;
        updateTemplate(featurecopy).then(result => {
            console.log(result);
            setFeature(result.data);

            setEditing(false);
            setExpanded(false);
            props.refreshCallback(result.data);
        }

        ).catch(error => {
            console.log(error);
        }
        );
    }

    const saveChanges = () => {
        updateTemplate(feature).then(result => {
            console.log(result);
            setFeature(result.data);

            setEditing(false);
            setExpanded(false);
        }

        ).catch(error => {
            console.log(error);
        }
        );
    }

    if (feature == null) {
        return <div>Loading...</div>
    }
    if (expanded) {
        return (
            <Card className={"m-2 score-card-eom-wide"} >

                <Card.Header className={"going-on-header" + (editing ? "-editing" : "")}>
                    <Card.Title >
                        <div className="d-flex">
                            <Col xs={2} onClick={() => { setExpanded(prev => !prev) }}>
                                <strong>{(editing ? "Editing " : "")}Id:</strong>
                                {originalFeature.id}
                            </Col>
                            <Col xs={6} onClick={() => { setExpanded(prev => !prev) }}>
                                <strong>Value:</strong>
                                {originalFeature.value}
                            </Col>
                            <div className="ml-auto">
                                {
                                    editable ?
                                        editing
                                            ?
                                            <>

                                                <ReactTooltip id="ttSaveFeature" place="top" effect="solid">
                                                    Save Entry
                                                </ReactTooltip>
                                                <ReactTooltip id="ttCancelChanges" place="top" effect="solid">
                                                    Cancel Changes
                                                </ReactTooltip>
                                                <ReactTooltip id="ttUndoChanges" place="top" effect="solid">
                                                    Undo Changes
                                                </ReactTooltip>

                                                <FontAwesomeIcon data-tip data-tooltip-id="ttSaveFeature" className="mx-1 fa fa-trash fa-lg fa-eom" icon={"check-circle"} onClick={saveChanges} />
                                                <FontAwesomeIcon data-tip data-tooltip-id="ttUndoChanges" className="mx-1 fa fa-trash fa-lg fa-eom" icon={"undo"} onClick={revertChanges} />
                                                <FontAwesomeIcon data-tip data-tooltip-id="ttCancelChanges" className="mx-1 fa fa-trash fa-lg fa-eom" icon={"times-circle"} onClick={cancelChanges} />
                                            </>
                                            :
                                            <>
                                                <ReactTooltip id="ttEditFeature" place="top" effect="solid">
                                                    Edit Entry
                                                </ReactTooltip>
                                                <ReactTooltip id="ttDeleteFeature" place="top" effect="solid">
                                                    Delete Entry
                                                </ReactTooltip>
                                                <ReactTooltip id="ttCopyFeature" place="top" effect="solid">
                                                    Clone Entry
                                                </ReactTooltip>
                                                <ReactTooltip id="ttUndoChanges" place="top" effect="solid">
                                                    Undo Changes
                                                </ReactTooltip>
                                                <FontAwesomeIcon data-tip data-tooltip-id="ttEditFeature" className="mx-1 fa fa-trash fa-lg fa-eom" icon={"edit"} onClick={() => setEditing(prev => !prev)} />
                                                <FontAwesomeIcon data-tip data-tooltip-id="ttDeleteFeature" className="mx-1 fa fa-trash fa-lg fa-eom" icon={"trash-alt"} onClick={deleteEntry} />
                                                <FontAwesomeIcon data-tip data-tooltip-id="ttCopyFeature" className="mx-1 fa fa-trash fa-lg fa-eom" icon={"clone"} onClick={cloneFeature} />
                                                <FontAwesomeIcon data-tip data-tooltip-id="ttUndoChanges" className="mx-1 fa fa-trash fa-lg fa-eom" icon={"undo"} onClick={revertChanges} />
                                            </>
                                        : null
                                }
                            </div>
                        </div>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={1}>
                            <strong>Key:</strong>
                        </Col>
                        <Col xs={1}>
                            {feature.key}
                        </Col>
                    </Row>
                    <EditableField editing={editing}
                        field={"mask"}
                        value={feature.mask}
                        callDispatcher={callDispatcher}
                        description="Feature Mask"
                        fieldType={10} />

                    <EditableField editing={editing}
                        field={"value"}
                        value={feature.value}
                        callDispatcher={callDispatcher}
                        description="Field Value"
                        fieldType={2} />

                </Card.Body>
            </Card>
        );
    } else {
        return (
            <Row onClick={() => { setExpanded(prev => !prev) }}>
                <Col xs={1}>
                    {feature.key}
                </Col>
                <Col xs={3}>
                    {feature.mask}
                </Col>
                <Col xs={6}>
                    {feature.value}
                </Col>
            </Row>
        );
    }
}

export default ProcGenTemplate;

