import { getProductList ,getSchematics, updateProduct, deleteProductFromServer } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useProfessionDispatch, ProfessionProvider } from './ProfessionProvider';
import ProductEditor from './ProductEditor';
import ProductLineCreator from './ProductLineCreator';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { useParams } from 'react-router-dom';

function BuilderProductEditorMain({match, props}) {
    return (<ProfessionProvider><ProductEditorMain params={match} /></ProfessionProvider>);
}

function ProductEditorMain() {
    let params = useParams();

    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [creatingProduct, setCreatingProduct] = useState(false);
    const [deletingProduct, setDeletingProduct] = useState(null);
    
    const providerDispatch = useProfessionDispatch();

    const getListOfProducts = async () => {
        let data = await getProductList();
        if (data.result) {
            setProducts(data.products);

            if(params.product_id!==undefined){
                const i = data.products.findIndex(p => p.product_id === params.product_id);
                if(i>=0) {
                    selectProduct(data.products[i]);
                }
            }

        } else {
            setProducts([]);
        }
    }

    const selectProduct = async (product) => {
        setProduct(product);
        providerDispatch({ type: "SET_PROFESSION", payload: null });
        providerDispatch({ type: "SET_PRODUCT", payload: product });
    }

    useEffect(() => {
        getListOfProducts();
    }, []);

    useEffect(() => {
        if(product !== null) {
            getSchematicList('xxx', product.product_id);
        }
    }, [product]);


    const getSchematicList = async (profession, productLine) => {
        let data = await getSchematics(profession, productLine);
        //console.log('Schematic Data', data);
        if (data.result) {
            //console.log('Schematic Data', data);
            providerDispatch({ type: "SET_SCHEMATIC_LIST", payload : data.schematics }); 
        } else {
            providerDispatch({ type: "SET_SCHEMATIC_LIST", payload : [] }); 
        }
    }

    const createProductLine = () => {
        setCreatingProduct(true);
    }

    const choseProduct = async (product_id, product_name, product_description) => {
        setCreatingProduct(false);
        //console.log(product_id, product_name);
        let product = {}
        product.product_id = product_id;
        product.product_name = product_name;
        product.product_description = product_description;
        updateProduct(product_id, product, []).then(data => {
            //console.log('Saved', data.product);
            getListOfProducts();
        });

    }

    const deleteProduct = (product_id) => {
        setDeletingProduct(product_id);
    //    console.log('We want to delete', product_id);
    }

    const confirmDeleteProduct = () => {
        setDeletingProduct(null);
     //   console.log('We are deleting', deletingProduct);
        deleteProductFromServer(deletingProduct).then(data => {
            if(!data.result) {
                console.log(data);
                console.log('There was an error deleting this record!');
            }
            selectProduct(null);
            getListOfProducts();       
        });
    }

    return (
        <div className="container-eom" id="divMainContent">
            <ConfirmationDialog title={`Delete Product${deletingProduct}?`} show={deletingProduct!==null} cancelModal={()=>setDeletingProduct(false)} 
                confirmModal={() => { confirmDeleteProduct() }} />
            
             <ProductLineCreator title={"Create Product"} show={creatingProduct} cancelModal={() => { choseProduct(null,0) }} 
                confirmModal={(productId, productName, productDescription) => { choseProduct(productId, productName, productDescription) }} />
         
            <Row>
                <Col xs={3}>
                    <Row className={"professionheader"}>
                         <h4>Products</h4>
                    </Row>
                    <Row>
                        <Button className={"m-1"} onClick={createProductLine}>Create New</Button>
                    </Row>
                    {products.map((product, index) =>
                        <Row key={index} onClick={() => selectProduct(product)}>
                            {product.product_id}
                        </Row>
                    )}
                </Col>
                <Col xs={6}>
                    <ProductEditor deleteProduct={deleteProduct}/>
                </Col>
            </Row>
        </div>
    );
}

export default BuilderProductEditorMain;