import React, { useState, useEffect } from 'react';
import { useBuilder, useBuilderDispatch } from '../../components/index';
import { Row, Col } from 'react-bootstrap';
import { setBuilderMobData, getBuilderMobItemData } from '../../components/builderRequests';
import { removeColourCodes } from '../../utils/ColourMap';
import ColouredText from '../../components/ColouredText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AffectEditor from '../AffectEditor';
import { getMobTypes } from '../BuilderUtils';
import MobGeneric from './MobGeneric';
import { useMobDispatch, useMobBuilder } from './MobProvider';

function MobEditor(props) {

    const refreshroom = props.refreshroom;

    const { currentMobData, error } = useMobBuilder();
    const mobDispatch = useMobDispatch();

    const { currentmob, currentroom, editing } = useBuilder();

    const dispatch = useBuilderDispatch();

    const unloadMob = async () => {
        dispatch({ type: 'SELECT_MOB', payload: null });
    }

    useEffect(() => {
        if (currentmob != null) {
            var mobcopy = JSON.parse(JSON.stringify(currentmob));
            //console.log(mobcopy);
            mobDispatch({ type: 'SELECT_CURRENTMOB', payload: mobcopy });
        }
    }, [currentmob]);


    let active = props.active;

    let width = 3;
    if (active) { width = 6 }

    const cancelMob = async (e) => {
        dispatch({ type: 'EDITING_DISABLED' });
        dispatch({ type: 'SELECT_MOB', payload: null });
        refreshroom();
    }

    const handleEditing = () => {
        if (editing) {
            dispatch({ type: 'EDITING_DISABLED' });
        } else {
            dispatch({ type: 'EDITING_ENABLED' });
        }
    }

    const editingDone = () => dispatch({ type: 'EDITING_DISABLED' });

    const handleRevert = () => {
        console.log('Reverting MOB', currentmob);
        var mobcopy = JSON.parse(JSON.stringify(currentmob))
        mobDispatch({ type: 'SELECT_CURRENTMOB', payload: mobcopy });
    }


    const saveChanges = async () => {
        currentMobData.sourceRoomId = currentroom.id;
        let data = await setBuilderMobData(currentMobData);

        if (data.result) {
            editingDone();
            dispatch({ type: 'SELECT_MOB', payload: data.data });    
            mobDispatch({ type: 'SELECT_ERROR', payload: "" });
        } else {
            mobDispatch({ type: 'SELECT_ERROR', payload: data.reason });
        }
    }

    const selectExitType = (type) => {
        mobDispatch({ type: "SET_FIELD", field: 'type', payload: type });
    }

    const getMobComponent = () => {
        switch (currentMobData.type) {
            case 'GenMob':
            case 'GenShopkeeper':
                return (<MobGeneric editing={editing} currentMobData={currentMobData} />);
            default:
                return null;

        }
    }

    if (currentMobData == null) return null;

    return (
        <>
            <Row>
                <Col xs={1}>
                    <FontAwesomeIcon onClick={cancelMob} className="fa fa-lg fa-eom m-2" icon={"times-circle"} />
                </Col>
                <Col>
                    <h4> <strong>{editing ? "Editing Mob:" : "Viewing Mob:"}  </strong> {removeColourCodes(currentMobData.name)} </h4>
                </Col>
            </Row>
            <FontAwesomeIcon onClick={handleEditing} className="fa fa-lg fa-eom m-2" icon={"edit"} />

            {editing ? <>
                <FontAwesomeIcon onClick={saveChanges} className="fa fa-lg fa-eom m-2" icon={"check-circle"} />
                <FontAwesomeIcon onClick={handleRevert} className="fa fa-lg fa-eom m-2" icon={"undo"} />
            </> : null}
            <span className="error"><font color="red">{error}</font></span>
            <Row>
                <strong>{editing ? <span>Editing </span> : null}Mob Type:</strong>
                {editing
                    ? getMobTypes(currentMobData.type, selectExitType)
                    : <span>{currentMobData.type}</span>}

            </Row>
            {getMobComponent()}
        </>
    )
}

export default MobEditor;