import React from 'react';

import { ClientProvider } from './ClientProvider';
import AnsibleMessenger from './AnsibleMessenger';
import AnsibleConnection from './AnsibleConnection';

function AnsibleMessengerClient() {
    return (
        <ClientProvider>
            <AnsibleConnection />
            <AnsibleMessenger />
        </ClientProvider>
    );

}

export default AnsibleMessengerClient;