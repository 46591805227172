import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown, ButtonGroup, Tab, Tabs } from 'react-bootstrap';

import DisplayMessage from './DisplayMessage';
import StatGuage from './StatGauge';
import { decodeMessage } from './messageutils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ColouredText from './../pages/components/ColouredText';
import ResourcePool from './ResourcePool';
import Indicator from './Indicator';
import MapContainer from '../pages/map/MapContainer';
import { useClientContext } from './ClientProvider';
import FatigueIndicator from './FatigueIndicator';

function AnsibleLeftPanel(props) {

    const { enableswipe, currentCharacter, currentAreaData, callback, inhabitants, items, vitals } = useClientContext();

    const examineInahitant = (inhabitant) => {

        let messageObject = {};
        messageObject.type = 'message';
        messageObject.data = 'QLOOK "' + inhabitant + '"';

        callback(messageObject);
    }

    if (currentAreaData !== undefined) {
        const data = {
            id: currentAreaData.location
        };

        return (
            <Col className={"px-3 d-md-block d-sm-none eomRightBorders" + (enableswipe ? "" : " limitWidth")}>
                <Row className={"eomCharacter mx-2"}>
                    <h1>{currentAreaData.id}</h1>
                </Row>
                <Row className={"py-2 eomIntervalBorderTop"}>
                </Row>
                <Row className={"pb-2 eomCharacter"}>
                    <Col className={"eomCharacterLeft"}>
                        <span>{currentCharacter}</span>
                    </Col>
                    <Col className={"eomCharacterRight"}>
                        <span>{vitals.characterclass} Level {vitals.level} </span>
                    </Col>
                </Row>

                <Row className={"pb-2 eomIntervalBorderTop"}>
                    <StatGuage min={0} max={vitals.max_experience-vitals.min_experience} value={vitals.experience-vitals.min_experience} tip="next level" colour="Gold" />
                </Row>
                <Row className={"p-4"}>
                    <MapContainer currentAreaData={currentAreaData} currentRoom={data} simpleMode={true} />
                </Row>
                <Row className={"eomEditor py-2"}>
                    <Col>
                        <ColouredText overrideClassName={"eomViewer"} ColouredText={currentAreaData.title} />
                    </Col>
                    <Col>
                        <span><FontAwesomeIcon className="a fa-lg" icon="portrait" color="white" /> {inhabitants.length} </span>
                        <span><FontAwesomeIcon className="a fa-lg" icon="box-open" color="white" /> {items.length} </span>
                    </Col>

                </Row>
                <Row className={"eomClientTabs py-2"}>
                    <div className={"eomClientTabs"}>
                        <Tabs defaultActiveKey="stats" id="uncontrolled-tab-example">

                            <Tab eventKey="stats" title="Stats">
                                <Row className={"eomResourcePoolColumn py-2"}>
                                    <ResourcePool min={0} max={vitals.max_hp} value={vitals.hp} tip="Hit Points" colour="Green" pool="Health" />
                                    <ResourcePool min={0} max={vitals.max_mana} value={vitals.mana} tip="Mana" colour="Cyan" pool="Mana" />
                                    <ResourcePool min={0} max={vitals.max_move} value={vitals.move} tip="Movement" colour="Orange" pool="Move" />

                                </Row>
                                <br />
                                <Row className={"eomIndicatorRow"}>
                                    <Indicator value={vitals.hunger} title="Hunger" iconname="hamburger" />
                                    <Indicator value={vitals.thirst} title="Thirst" iconname="glass-whiskey" />
                                    <FatigueIndicator value={vitals.fatigue} title="Fatigue" iconname="bed" />
                                    
                                </Row>

                            </Tab>

                            <Tab eventKey="location" title="Location">
                                <Row className={"eomEditor"}>
                                    <ColouredText overrideClassName={"eomViewer"} ColouredText={currentAreaData.title} />
                                    <br />
                                    <ColouredText overrideClassName={"eomViewer px-2"} ColouredText={currentAreaData.description} />
                                </Row>

                                <Row className={"eomEditor pt-2"}>
                                    Room Inhabitants
                                </Row>
                                <Row className={"eom-box"}>
                                    {inhabitants.map((inhabitant, idx) => (
                                        <ColouredText overrideClassName={"eomViewer px-2"} key={idx} ColouredText={inhabitant.display} onClick={() => examineInahitant(inhabitant.name)} />
                                    ))}
                                </Row>
                            </Tab>

                        </Tabs>
                    </div>

                </Row>
            </Col>);
    }
    else
        return (<Col className={"px-4 mx-2 d-md-block d-sm-none"+ (enableswipe ? "" : " limitWidth")}></Col>);


}

export default AnsibleLeftPanel;