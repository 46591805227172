import Config from '../../../config/Config';
import { getAuthenticatedHeader } from '../utils/FetchHeaders';
import { genericAuthenticatedFetch, genericAuthenticatedPut } from './deityRequests';

export async function getPlayerStoryList(playername) {
    var escapedplayername = "all";
    if (playername !== undefined)
        escapedplayername = encodeURIComponent(playername);

    return genericAuthenticatedFetch(`story/list/${escapedplayername}`);
}

export async function getWebContentList(category) {
    var escapedcategory = "all";
    if (category !== undefined)
        escapedcategory = encodeURIComponent(category);

    return genericAuthenticatedFetch(`content/list/${escapedcategory}`);
}

export const getPlayerStory = async (storyToLoad) => {
    try {
        const headers = getAuthenticatedHeader();
        let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/story/get/` + storyToLoad, { mode: 'cors', headers: headers })
        //console.log(response);
        let data = await response.json();
        // console.log('Awaited Data', data);
        return data;
    } catch (error) {
        //console.log(error);
        return {
            'result': false,
            'reason': 'Error Sending Request'
        };
    }
}

export const getWebContent = async (contentToLoad) => {
    try {
        const headers = getAuthenticatedHeader();
        let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/content/get/` + contentToLoad, { mode: 'cors', headers: headers })
        //console.log(response);
        let data = await response.json();
        // console.log('Awaited Data', data);
        return data;
    } catch (error) {
        //console.log(error);
        return {
            'result': false,
            'reason': 'Error Sending Request'
        };
    }
}

export const savePlayerStory = async (metadata, story) => {
    let updateData = JSON.parse(JSON.stringify(metadata));
    updateData.story_text = story;
    //console.log('Updating Story', updateData);
    return genericAuthenticatedPut(`story/update/${updateData.story_id}`, JSON.stringify(updateData));
}

export const saveWebContent = async (metadata, story) => {
    let updateData = JSON.parse(JSON.stringify(metadata));
    updateData.content_text = story;
    //console.log('Updating Story', updateData);
    return genericAuthenticatedPut(`content/update/${updateData.content_id}`, JSON.stringify(updateData));
}

export const getWebContentTypes = () => {
    let data = [];
    data = [...data, {id : "builderguide", title : "Builder Guides"}];
    data = [...data, {id : "releasenotes", title : "Release Notes"}];
    data = [...data, {id : "devblog", title : "Developer Blog"}];
    return data;
}