import * as React from 'react'

const ProfessionContext = React.createContext();
const ProfessionDispatchContext = React.createContext();

const initialState = {
    profession: null,
    product : null,
    schematics : [],
    original : null,
    error: ''
};

function useProfessionDispatch() {
    const context = React.useContext(ProfessionDispatchContext);
    if (context === undefined) {
        throw new Error("useProfessionDispatch must be used within an ProfessionProvider");
    }
    return context;
}

const professionReducer = (initialState, action) => {
    //console.log('Carrying out action', action);
    switch (action.type) {
        case "SET_PROFESSION":
            //console.log('Payload was', action.payload);
            return {
                ...initialState,
                profession: action.payload
            }
        case "SET_PRODUCT":
           // console.log('Payload was', action.payload);
            return {
                ...initialState,
                product: action.payload
            }
        case "SET_SCHEMATIC_LIST":
            //console.log('Payload was', action.payload);
            return {
                ...initialState,
                schematics: action.payload
            }
        case "SELECT_ORIGINAL":
            return {
                ...initialState,
                original : action.payload
            }
        case "SELECT_ERROR":
            return {
                ...initialState,
                error: action.payload
            }
        case "SET_PRODUCT_LEVEL": {
            if(action.fieldname=='level'){
                let updatedprofession = { ...initialState.profession };
                const i = updatedprofession.products.findIndex(p => p.product_id === action.id); 
                updatedprofession.products[i].product_level = action.value;
                
                return {
                    ...initialState,
                    profession : updatedprofession
                }
            } else {
                let updatedprofession = { ...initialState.profession };
                const i = updatedprofession.products.findIndex(p => p.product_id === action.id); 
                console.log(updatedprofession.products[i]);
                updatedprofession.products[i].product_id = action.value;
                
                return {
                    ...initialState,
                    profession : updatedprofession
                }          
            }
        }

        case "SET_SCHEMATIC_LEVEL": {
            
            let updatedschematics = [...initialState.schematics ];
            console.log(updatedschematics);
            const i = updatedschematics.findIndex(p => p.schematic_id === action.product); 
            console.log(updatedschematics[i]);
            updatedschematics[i].schematic_level = action.value;
            
            return {
                ...initialState,
                schematics : updatedschematics
            }          
            
        }
        case "SORT_PRODUCTS" : {
            if(initialState.profession===null) {
                return initialState;
            }
            let updatedprofession = { ...initialState.profession };
            updatedprofession.sortedProducts = updatedprofession.products.sort((a, b) => {
                return a.product_level - b.product_level;
    
            });
            return {
                ...initialState,
                profession : updatedprofession
            }
        }

        case "SORT_SCHEMATICS" : {
            let schematicsu = [...initialState.schematics];
            //console.log('Sorting', schematicsu)
            schematicsu.sort((a, b) => {
                 return a.schematic_level - b.schematic_level;
             });
            return {
                ...initialState,
                schematics : schematicsu
            }
        }
        case "ADD_PRODUCT_TO_PROFESSION" : {
            //console.log("Adding Product");
            if(initialState.profession===null) {
                return initialState;
            }
            let newProduct = {
                    product_id : action.product.product_id, 
                    product_level : action.level, 
                    product_description : action.product.product_description, 
                    product_name : action.product.product_description};
            //console.log("Adding Product", newProduct);
            let updatedprofession = { ...initialState.profession };
            let newproductlist = [...updatedprofession.products, newProduct] ;

            updatedprofession.products = newproductlist;

            return {
                ...initialState,
                profession : updatedprofession
            }         
        }
        case "DELETE_PRODUCT" : {
            if(initialState.profession===null) {
                return initialState;
            }
            let updatedprofession = { ...initialState.profession };
            let newproductlist = updatedprofession.products.filter(x => x.product_id !== action.payload);

            updatedprofession.products = newproductlist;

            return {
                ...initialState,
                profession : updatedprofession
            }
        }
        case "DELETE_SCHEMATIC_FROM_PRODUCT" : {
            console.log(action);
            let schematicsu = initialState.schematics.filter(x => x.schematic_id !== action.schematic_id);
            return {
                ...initialState,
                schematics : schematicsu
            }
        }
        case "ADD_SCHEMATIC_TO_PRODUCT" : {
            console.log(action);
            let schematicsu = [{"schematic_id": action.id, "schematic_name" : action.name, "schematic_level" : 1},...initialState.schematics];
            return {
                ...initialState,
                schematics : schematicsu
            }     
        }

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

const sortedSchematics = () => {
    let sortedSchematics = initialState.schematics.sort((a, b) => {
        return a.schematic_level - b.schematic_level;

    });

    return sortedSchematics;
}

function ProfessionProvider({ children }) {
    const [state, dispatch] = React.useReducer(professionReducer, initialState);
    
    return <ProfessionContext.Provider value={state}>
        <ProfessionDispatchContext.Provider value={dispatch}>
            {children}
        </ProfessionDispatchContext.Provider>
    </ProfessionContext.Provider>
}

function useProfessionBuilder() {
    const context = React.useContext(ProfessionContext)
    if (context === undefined) {
        throw new Error('useProfessionBuilder must be used within a ProfessionProvider')
    }
    return context
}

export { ProfessionProvider, useProfessionBuilder, useProfessionDispatch }
