import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useMobDispatch, useMobBuilder } from './MobProvider';
import { useBuilder, useBuilderDispatch } from './../../components/index';
import StockItem from './StockItem';
import CreateItemPrompt from '../items/CreateItemPrompt';

function ShopInventory(props) {
    let inventory = props.inventory;

    const [show, setShow] = useState(false);
    const { editing } = useBuilder();
    const mobDispatch = useMobDispatch();

    const createItem = (newItem) => {
        newItem.uniqueid = "";
        console.log(newItem);
        mobDispatch({type : "ADD_STOCK", payload : newItem});
        setShow(false);
    }

    const closeCreate = () => {
        setShow(false);
    }

    if (inventory === undefined) {
        if(editing)
            return <Button onClick={()=>setShow(true)} >Create Item</Button>;
        else 
            return null;
    }

    return (
        <>
            <CreateItemPrompt show={show} closeModal={closeCreate} createItem={createItem}/>
            <Row className={"floatingdiv my-2 "}>
                <strong> Shop Stock</strong>
            </Row>
            <Row className="floatingdiv" >

                <Row className="floatingdiv">
                    <Col md={6}>
                        <strong>Name</strong>
                    </Col>
                    <Col>
                        <strong>Price</strong>
                    </Col>
                    <Col>
                        <strong>Quantity</strong>
                    </Col>

                </Row>
                {inventory.map((stock, index) =>
                    <StockItem key={index} stock={stock} index={index} />
                )}
                {editing? <Button onClick={()=>setShow(true)} >Create Item</Button>:null}

            </Row>
        </>
    );
}

export default ShopInventory;