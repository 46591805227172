import React from 'react';
import { Buffer } from 'buffer';
import Config from '../../../config/Config';
import { getStandardHeader, getAuthenticatedHeader } from '../utils/FetchHeaders'

import CryptoJS from 'crypto-js';

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });

    let hashedpassword = getHashedPassword(loginPayload.password);

    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/authenticate/login/` + loginPayload.username + '/' + hashedpassword, { mode: 'cors', headers: getStandardHeader() });
    if (response.ok) {
      let data = await response.json();
      if (data.result) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: data });
        localStorage.setItem('token', data.auth_token);
        localStorage.setItem('currentUser', data.username);
        localStorage.setItem('showPlayerMenu', false);
        localStorage.setItem('newMailCount', parseInt(data.newmessages));
        localStorage.setItem('basicinfo', JSON.stringify(data.playerbasic));
        localStorage.setItem('avatar', data.playerbasic.avatar);
        localStorage.setItem('roles', data.roles);

        //console.log(data);
        // console.log( data.playerbasic);

        return data;
      }
      else {
        dispatch({ type: 'LOGIN_ERROR', error: 'data.errors[0]' });
        return data;
      }
    } else if (response.status === 404) {
      dispatch({ type: 'LOGIN_ERROR', error: 'Error Sending Request' });
      return {
        'result': false,
        'reason': 'Error Sending Request'
      };
    } else {
      dispatch({ type: 'LOGIN_ERROR', error: 'Error Sending Request' });
      return {
        'result': false,
        'reason': 'Error Sending Request'
      };
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: 'LOGIN_ERROR', error: error });
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

const getHashedPassword = (valueToHash) => {
  const salt = Config.mud.salt;

  let hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA512, salt);
  hmac.update(valueToHash);
  let hash = hmac.finalize();
  let value = hash.toString(CryptoJS.enc.Hex)
  return value;
}

export async function changePassword(dispatch, currentPassword, newPassword) {
  try {
    const token = localStorage.getItem('token');
    const currentUser = localStorage.getItem('currentUser');

    let currentPasswordHash = getHashedPassword(currentPassword);
    let newPasswordHash = getHashedPassword(newPassword);

    const headers = {
      'Content-Type': 'application/json',
      'auth-token': token,
      'username': currentUser,
    };
    console.log('Sending Password Change Request');

    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/password/` + currentPasswordHash + '/' + newPasswordHash, { mode: 'cors', headers: getAuthenticatedHeader() });
    if (response.ok) {
      let data = await response.json();
      console.log(data);
      return data
    } else if (response.status === 404) {
      return {
        'result': false,
        'reason': 'Error Sending Request'
      };
    } else {
      return {
        'result': false,
        'reason': 'Error Sending Request'
      };
    }

  } catch (error) {
    console.log(error);
    const errorjson = {
      'result': false,
      'reason': 'Error Sending Request'
    };
    dispatch({ type: 'PASS_ERROR', error: error });
    return errorjson;
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
  localStorage.removeItem('showPlayerMenu');
  localStorage.removeItem('avatar');
  localStorage.removeItem('basicinfo');
  localStorage.removeItem('roles');
  localStorage.removeItem('newMailCount');
}

export async function toggleMenu(dispatch) {
  dispatch({ type: 'TOGGLE_MENU' });
  return localStorage.getItem('showPlayerMenu');
}