import React, { Component, useState } from 'react';
import { Button, Form, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { useAuthState } from './../components';
import PlayerNavBar from './PlayerNavBar';
import AdminNavBar from './AdminNavBar';
import BuilderNavBar from './BuilderNavBar';
import Config from '../../../config/Config';
import WithRouter from '../Routes/WithRouter';
import { Link } from 'react-router-dom';
import InlineUserLoggedIn from './InlineUserLoggedIn';
import InlineUserLoggedOut from './InlineUserLoggedOut';


function TopNavBar(props) {
  const { token, roles } = useAuthState();
  //console.log('Top Nav Bar',props.location.pathname);
  if (window.location.pathname === "/client/") {
    return false;
  }

  if (window.location.pathname === "/client") {
    return false;
  }

  //console.log('Authstate', roles);

  let checkroles = roles;

  if (checkroles == null) {
    checkroles = [];
  }

  const tokenNotEmpty = () => {
    return (token !== undefined) && (token !== null) && (token.length > 0);
  }



  return (
    <Navbar expand="lg" className="bg-body-tertiary bg-eom navbar-dark px-2">
      <Navbar.Brand as={Link} to="/">
        <span className="divSiteTitle eom1">{Config.name1}</span>
        <span className="divSiteTitle eom2">{Config.name2}</span>
        <br />
        <span style={{ marginRight: '1.25em' }}>  </span>
        <span className="divSiteTitle eom1" >{Config.name3}</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto container-fluid d-flex align-items-center">

          <Nav.Link as={Link} className="nav-link nav-item" to="/online">Who's Online</Nav.Link>
          <Nav.Link as={Link} to="/news">Latest News</Nav.Link>
          <NavDropdown title="Introduction" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/intro/muds">New to Muds</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/loggingin">Logging In</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/webclient">Web Client</NavDropdown.Item>
            <NavDropdown.Divider className="divider2" />
            <NavDropdown.Item as={Link} to="/intro/charcreation">Character Creation</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/gettingsetup">Getting Setup</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/screenreader">Screen Readers</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/roleplaysetup">Preparing for Roleplay</NavDropdown.Item>
            <NavDropdown.Divider className="divider2" />
            <NavDropdown.Item as={Link} to="/intro/exploring">Exploration</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/communication">Communication</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/roleplay">Roleplaying</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/events">Events</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/scenes">Scenes</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/rules">Rules of The Game</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/intro/gettinghelp">Getting Help</NavDropdown.Item>
            <NavDropdown.Divider className="divider2" />
            <NavDropdown.Item as={Link} to="/intro/releases">Release Notes</NavDropdown.Item>

          </NavDropdown>
          <NavDropdown title="Realm" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/realm/cities">Major Cities</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/realm/areas">Areas</NavDropdown.Item>

            <NavDropdown.Item as={Link} to="/realm/maps">Maps of the Realm</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/realm/deities">Deities of the Realm</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/realm/clans">Clans of the Realm</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/realm/societies">Societies</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/realm/wildlife">Flora & Fauna</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Gameplay" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/gameplay/races">Playable Races</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/classes">Playable Classes</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/combat">Combat</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/housing">Housing</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/shops">Shopping</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/crafting">Crafting</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/help">Help</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/remorts">Remorting</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/parties">Adventuring Parties</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gameplay/screenreader">Screen Reader Support</NavDropdown.Item>
          </NavDropdown>


          {tokenNotEmpty() ? <PlayerNavBar /> : null}
          {checkroles.indexOf('builder') > -1 ? <BuilderNavBar /> : null}
          {checkroles.indexOf('webadmin') > -1 ? <AdminNavBar /> : null}
        </Nav>
        {
          tokenNotEmpty()?<InlineUserLoggedIn/>:<InlineUserLoggedOut/>
        }

      </Navbar.Collapse>
    </Navbar>

  );
}

export default WithRouter(TopNavBar);