import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const StatGuage = ({ min, max, value, colour, tip }) => {

    let start = parseInt(min);
    let end = parseInt(max);
    let completion = parseInt(value);
    let percentage = 100;

    if (min !== max) {
        percentage = completion / (end - start) * 100;
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {(Math.round(percentage * 100) / 100) +"% " + tip + " " + (start+value) + "/" + end}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <div
                style={
                    {
                        backgroundColor: "silver",
                        width: "100%",
                        height: "10px",
                        borderRadius: "25px"
                    }
                }
            >
                <div
                    style={
                        {
                            backgroundColor: colour,
                            width: percentage + "%",
                            height: "10px",
                            borderRadius: "25px"

                        }
                    }
                >
                </div>

            </div>
        </OverlayTrigger>
    )
}

export default StatGuage