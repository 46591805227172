import React from 'react';

const DisplayMessage = ({ message, displayType }) => {

    if (message.type !== displayType) {
        return null;
    }
    return (
        <p className={"pl-0"}>
            {message.decoded.map(fragment => (
                fragment
            ))}
        </p>
    )
}

export default DisplayMessage