
import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';

function NewsArticle(params) {
    let article = params.article;
    article = decodeURIComponent(article);
    let lines = article.split(/\r\n|\\r|\r|\n/);

    return (
        <div>
        {lines.map((line, index)=>
        <p key={index}>
            <Linkify tagName="p" className="text-left">{line}</Linkify>
            </p>
        )}

        </div>
    )
}

export default NewsArticle;