import React, { useState, useEffect } from 'react';
import { useBuilder, useBuilderDispatch, BuilderProvider } from './../components/index';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Row, Col, Button, Alert, Dropdown, ToggleButton, ButtonGroup } from 'react-bootstrap';
import { listAreas } from '../components/';
import {
    getBuilderAreaData, getBuilderRoomData, getBuilderMobData,
    setAreaLockdown, createRoomForArea, createRoomFromSource,
    deleteRoomData, unlinkExitInDirection, linkRoomsInDirection
} from '../components/builderRequests';
import { removeColourCodes } from '../utils/ColourMap';
import AreaEditor from './AreaEditor';
import RoomEditor from './rooms/RoomEditor';
import MobEditor from './mobs/MobEditor';
import ItemEditor from './items/ItemEditor';
import { ItemProvider } from './items/ItemProvider';
import ExitEditor from './exits/ExitEditor';
import AreaSummary from './area/AreaSummary';
import RoomSummary from './area/RoomSummary';
import MobSummary from './area/MobSummary';
import SelectRoom from './SelectRoom';
import { ExitProvider } from './exits/ExitProvider';
import { MobProvider } from './mobs/MobProvider';

import { defaultArea } from './config/DefaultObjects';
import MapContainer from '../map/MapContainer';
import { populateConfig } from './BuilderUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmationDialog from './common/ConfirmationDialog';
import { useParams } from 'react-router-dom';

const editorWidthsWide = "col-xs-12 col-sm-12 col-md-12 col-lg-9";
const editorWidthsNarrow = "col-xs-12 col-sm-12 col-md-6 col-lg-5";

function BuilderAreaEditor({ match, extraParams }) {
    let parms = useParams();
    return (<BuilderProvider><BuilderAreaEditorInternal search={parms} /></BuilderProvider>);
}

function BuilderAreaEditorInternal({ match, props, search }) {
    let params = new URLSearchParams(search);
    let roomid = params.get('room_id');

    const [areas, setAreas] = useState([]);
    const [filter, setFilter] = useState('');
    const [warning, setWarning] = useState('');
    const [ignoreWarning, setIgnoreWarning] = useState(false);
    const [showModal, setShow] = useState(false);
    const [exploring, setExploring] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [statusFilter, setStatusFilter] = useState('');
    const [showRoomDelete, setShowRoomDelete] = useState(false);
    const [actionableId, setActionableId] = useState();
    const [editorWidth, setEditorWidth] = useState(editorWidthsNarrow);

    const { currentarea, currentitem, currentroom, currentmob, currentexit, editing, direction } = useBuilder();
    const dispatch = useBuilderDispatch();

    const getAreaList = async () => {
        //console.log('Getting area list');
        const data = await listAreas(true);
        if (data.result) {
            //console.log('Got area list', data.areas);
            setAreas(data.areas);
        } else {
            //console.log('Failed area list');
            setAreas([]);
        }
    }

    const lockdownArea = async e => {
        //console.log('Lockdown Area');
        const data = await setAreaLockdown(currentarea.id, true);
        if (data.result) {
            //    console.log('Lockdown Area Successful', currentarea.id);
            loadAreaByName(currentarea.id);
        } else {
            //    console.log('Lockdown Area Failed', currentarea.id);
        }
    }

    const loadArea = async e => {
        const newAreaName = e.target.getAttribute('name');
        loadAreaByName(newAreaName);
    }

    const createArea = async () => {
        let newArea = defaultArea;
        dispatch({ type: 'SELECT_AREA', payload: newArea });
        dispatch({ type: 'EDITING_ENABLED' });
    }

    const loadAreaByName = async (areaname) => {
        if (editing) {
            return;
        }
        //console.log('Reselect Area 1');
        setWarning('');
        setIgnoreWarning(false);
        const data = await getBuilderAreaData(areaname);
        dispatch({ type: 'SELECT_AREA', payload: null });
        if (data.result) {
            //console.log('Reselect Area 2');
            var copyofarea = JSON.parse(JSON.stringify(data))
            dispatch({ type: 'SELECT_AREA', payload: copyofarea });
            let filteredEffects = copyofarea.effects.filter(effect => effect.id === 'Prop_BuilderLockdown');
            //console.table(filteredEffects);
            if (filteredEffects.length < 1) {
                setWarning('Area is not locked down, wandering mobs may effects changes you make.');
            }

        } else {
            console.log('Wwwww')
            dispatch({ type: 'SELECT_AREA', payload: null });
        }
    }

    const reloadAreaByName = async (areaname) => {
        const data = await getBuilderAreaData(areaname);
        //dispatch({ type: 'UPDATE_AREA', payload: null });
        if (data.result) {
            var copyofarea = JSON.parse(JSON.stringify(data))
            dispatch({ type: 'UPDATE_AREA', payload: copyofarea });
        }
    }

    const loadRoom = async e => {
        const newRoomName = e.target.getAttribute('name');
        //    console.log('Load Room by Id', newRoomName);
        loadRoomById(newRoomName);
    }

    const refreshroom = () => {
        dispatch({ type: 'SELECT_ROOM', payload: null });
        //console.log('Refreshing Room', currentroom);
        loadRoomById(currentroom.id);
    }

    const createRoom = async e => {
        //console.log('Create Room for ', currentarea.id);
        createRoomForArea(currentarea.id).then(data => {
            //console.log(data);
            if (data.result) {
                dispatch({ type: 'SELECT_ROOM', payload: data.room });
                dispatch({ type: 'EDITING_ENABLED' });
            }
        });
    }

    const loadExit = async (roomid, direction) => {
        //console.log('Load Exit', roomid, direction);
        const newExit = currentroom.exits.find(e => e.direction === direction);
        //console.log('Load Exit', newExit);
        dispatch({ type: 'SELECT_EXIT', payload: newExit });
    }

    const createRoomInDirection = (sourceRoomId, direction) => {
        //console.log(`Create room ${direction} off of ${sourceRoomId}`);
        createRoomFromSource(sourceRoomId, direction).then(data => {
            //console.log(data);
            if (data.result) {
                addRoomToAreaRooms(data.room.id.toUpperCase());
                dispatch({ type: 'SELECT_ROOM', payload: data.room });
                dispatch({ type: 'EDITING_ENABLED' });
            }
        });
    }

    const addRoomToAreaRooms = (roomid) => {
        //console.log('Adding room', roomid)
        currentarea.roomids.push(roomid);
    }

    const loadRoomById = async (newRoomName) => {
        if (editing) {
            return;
        }
        const data = await getBuilderRoomData(newRoomName);
        //console.log('Loaded ', data);
        if (data.result) {
            dispatch({ type: 'SELECT_ROOM', payload: data });
        } else {
            dispatch({ type: 'SELECT_AREA', payload: null });
        }
    }

    const loadMob = async (index) => {
        if (editing)
            return;

        let i = parseInt(index);

        if (index > -1) {
            var payload = {};
            payload['roomid'] = currentroom.id;
            payload['databaseid'] = currentroom.mobs[i].databaseid;
            payload['mobindex'] = index;
            payload['mobname'] = currentroom.mobs[i].name;
            //console.log('Loading Mob Payload', payload);
            const data = await getBuilderMobData(currentroom.id, currentroom.mobs[i].name, currentroom.mobs[i].databaseid);
            //console.log('Loaded ', data);
            if (data.result) {
                data.data.index = i;
                dispatch({ type: 'SELECT_MOB', payload: data.data });
            } else {
                dispatch({ type: 'SELECT_ITEM', payload: null });
            }
        } else {
            dispatch({ type: 'SELECT_ITEM', payload: null });
        }
    }

    const unloadArea = async e => {
        //console.log('Unloading Area');
        setWarning('');
        dispatch({ type: 'SELECT_AREA', payload: null });
    }

    const unloadRoom = async e => {
        dispatch({ type: 'SELECT_ROOM', payload: null });
    }

    const deleteRoom = async (roomid) => {
        //console.log('Delete Room', roomid);
        setActionableId(roomid);
        setShowRoomDelete(true);
    }

    const deleteRoomAction = async (roomid) => {

        //       console.log('Deleting Room', roomid);
        const data = await deleteRoomData(roomid);
        if (data.result) {
            //           console.log('Deleted Room', roomid);
            dispatch({ type: 'EDITING_DISABLED' });
            dispatch({ type: 'SELECT_ROOM', payload: null });

            loadAreaByName(currentarea.id);
            setShowRoomDelete(false);

        } else {
            //           console.log('Failed to Delete Room', roomid);
            //           console.log('Failed to Delete Room', data);

            //set up an error
        }
        return true;
    }

    const unlinkExit = async (id, direction) => {
        //console.log('Unlinking Room', id, direction);
        const data = await unlinkExitInDirection(id, direction);
        if (data.result) {
            //   console.log('Unlinked exit Room', id, direction);
            dispatch({ type: 'EDITING_DISABLED' });
            dispatch({ type: 'SELECT_ROOM', payload: null });

            loadAreaByName(currentarea.id);
        } else {
            //    console.log('Failed to Unlink Exit', id, direction, data);
        }
    }

    const requestLinkRooms = (newDirection) => {
        //console.log('Request to link rooms in direction', newDirection)
        dispatch({ type: 'SELECT_DIRECTION', payload: newDirection });
        setShow(true);
    }

    const linkRooms = async (startroomid, endroomid, direction) => {
        //console.log('Linking Rooms', startroomid, endroomid, direction);
        const data = await linkRoomsInDirection(startroomid, endroomid, direction);
        if (data.result) {
            //console.log('Linked exit Room', startroomid, endroomid, direction);
            dispatch({ type: 'EDITING_DISABLED' });
            dispatch({ type: 'SELECT_ROOM', payload: null });

            loadAreaByName(currentarea.id);
            setShow(false);

        } else {
            //console.log('Failed to Link Rooms', startroomid, endroomid, direction, data);
            setShow(false);
        }
    }

    const unloadMob = async e => {
        dispatch({ type: 'SELECT_MOB', payload: null });
    }

    useEffect(() => {
        populateConfig();
        getAreaList();
    }, []);

    useEffect(() => {
        if (showMap) {
            setEditorWidth(editorWidthsNarrow);
        } else {
            setEditorWidth(editorWidthsWide);
        }
    }, [showMap]);

    useEffect(() => {
        if ((areas.length > 0) && (roomid != null)) {
            let area = roomid.substr(0, roomid.lastIndexOf('#'));
            console.log(roomid, area);
            loadAreaByName(area).then(() => {
                loadRoomById(roomid);
                roomid = null;
            }
            )
        }
    }, [areas]);

    useEffect(() => {
        //console.log('The Room Changed');
        //We have changed the room, perhaps created it so background refresh the area
        if (currentarea != null)
            reloadAreaByName(currentarea.id);
    }, [currentroom]);

    // useEffect(() => {
    //     console.log('The Area Changed');
    //     getAreaList();
    // }, [currentarea]);

    const filterMatch = (area) => {
        return (area.display.toLowerCase().indexOf(filter.toLowerCase()) > -1) && (area.status.toLowerCase().indexOf(statusFilter.toLowerCase()) > -1);
    }

    let summaries = [];
    var activeSection = '';
    if (currentarea !== null) {
        activeSection = 'area';
    }
    if (currentroom !== null) {
        summaries.push('area');
        activeSection = 'room';
    }
    if (currentmob !== null) {
        summaries.push('room');
        activeSection = 'mob';
    }

    if ((currentitem !== null) && (currentmob == null)) {
        summaries.push('room');
        activeSection = 'item';
    } else if ((currentitem !== null) && (currentmob !== null)) {
        summaries.push('mob');
        activeSection = 'item';
    }

    if (currentexit !== null) {
        summaries.push('room');
        activeSection = 'exit';
    }

    const getSummarySection = (section) => {
        switch (section) {
            case 'area':
                return (<AreaSummary unloadArea={unloadArea} loadRoom={loadRoom} />);
            case 'room':
                return (<RoomSummary unloadRoom={unloadRoom} />);
            case 'mob':
                return (<MobSummary unloadMob={unloadMob} />);
        }
    }

    const getSummarySections = () => {
        return (
            <>
                {summaries.map((summary, index) =>
                    <div key={index} >
                        {getSummarySection(summary)}
                    </div>
                )}
            </>)
    }


    let showWarning = () => {
        return (warning.length > 0) && (!ignoreWarning);
    };

    const selectRoomToLink = (roomid) => {
        console.log("Link to roomid");
    }

    const cancelRoomDelete = async () => {
        setShowRoomDelete(false);
    }
    return (
        <div className="container-eom" id="divMainContent">
            <SelectRoom showModal={showModal} hideModal={() => setShow(false)} selectRoom={linkRooms} />
            <ConfirmationDialog title={"Delete Room?"} show={showRoomDelete} cancelModal={cancelRoomDelete} confirmModal={() => { deleteRoomAction(actionableId) }} />
            {showWarning()
                ?
                <Row className="lockdown">
                    <ReactTooltip id="tooltipLockdown" place="bottom" effect="solid">
                        Ignore Warning
                    </ReactTooltip>

                    <ReactTooltip id="tooltipLockdown2" place="bottom" effect="solid">
                        Lockdown Area
                    </ReactTooltip>

                    <Alert variant={"warning"} className="lockdown">{warning}
                        <Button data-tip data-tooltip-id="tooltipLockdown2" className={"btn-themed-dark"} onClick={lockdownArea}>Lockdown!</Button>

                        <FontAwesomeIcon data-tip data-tooltip-id="tooltipLockdown" className="fa fa-lg fa-eom" icon={"times"} color="maroon" onClick={() => setIgnoreWarning(true)} />

                    </Alert>
                </Row>

                : null}
            <Row >
                <Col xs={6} md={4} lg={2} className={"mx-2 output-container-area"}>
                    {summaries.length > 0 ?

                        <>{getSummarySections()}</>

                        :
                        <>
                            <Col>
                                <ButtonGroup toggle>
                                    <ToggleButton

                                        type="radio"
                                        variant="danger"
                                        name="radio"
                                        value={false}
                                        checked={showMap}
                                        onClick={(e) => setShowMap(false)}
                                    >
                                        {"Hide Map"}
                                    </ToggleButton>
                                    <ToggleButton
                                        type="radio"
                                        variant="danger"
                                        name="radio"
                                        value={true}
                                        checked={!showMap}
                                        onClick={(e) => setShowMap(true)}
                                    >
                                        {"Show Map"}
                                    </ToggleButton>
                                </ButtonGroup>
                            </Col>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend" >
                                    <button className="btn btn-themed-dark" type="submit">Filter</button>
                                </div>
                                <input type="text" value={filter} className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                                <div className="input-group-append" >
                                    <Dropdown >
                                        <Dropdown.Toggle variant="tool" id="dropdown-basic">
                                            {statusFilter == '' ? "All" : statusFilter}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setStatusFilter('')}>All</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Open')}>Open</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Closed')}>Closed</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Private')}>Private</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('System')}>System</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Building')}>Building</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Testing')}>Testing</Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div>
                                {areas.filter(area => filterMatch(area)).map(area =>
                                    <div className="p-2 m-2" name={area.name} key={area.name} onClick={loadArea}>
                                        {removeColourCodes(area.display)}
                                    </div>
                                )}
                            </div>
                            <Button className={"btn-themed-dark"} onClick={createArea}>Create New Area</Button>
                        </>}
                </Col>

                {activeSection === 'area'
                    ? <Col className={editorWidth}>
                        <AreaEditor active={activeSection === 'area'} loadRoom={loadRoom} unloadArea={unloadArea} createRoom={createRoom} deleteRoom={deleteRoom} />

                    </Col>
                    : null}
                {activeSection === 'room'
                    ?
                    <Col className={editorWidth}>
                        <RoomEditor active={activeSection === 'room'}
                            loadRoom={loadRoomById}
                            loadMob={loadMob}
                            unloadRoom={unloadRoom}
                            loadExit={loadExit}
                            deleteRoom={deleteRoom}
                            unlinkExit={unlinkExit}
                            linkRooms={requestLinkRooms}
                            refreshroom={refreshroom} />
                    </Col>
                    : null}
                {activeSection === 'mob'
                    ?
                    <Col className={editorWidth}>
                        <MobProvider><MobEditor active={activeSection === 'mob'} unloadMob={unloadMob} refreshroom={refreshroom} /></MobProvider>
                    </Col>
                    : null}
                {activeSection === 'item'
                    ? <Col className={editorWidth}>
                        <ItemProvider><ItemEditor active={activeSection === 'item'} /></ItemProvider>  </Col>
                    : null}
                {activeSection === 'exit'
                    ? <Col className={editorWidth}><ExitProvider><ExitEditor active={activeSection === 'exit'} /></ExitProvider>  </Col>
                    : null}

                {(currentarea != null) && (currentmob === null) && showMap ?
                    <Col xs={12} md={4}>
                        <ReactTooltip id="tooltipMap" place="bottom" effect="solid">
                            Toggle between Room Layout and Explorer Mode
                        </ReactTooltip>
                        <Row className="m-2 fluid align-content-center" onClick={() => setExploring(!exploring)}>
                            <FontAwesomeIcon className="fa fa-trash fa-lg fa-eom mr-2" icon={exploring ? "map-marked" : "map-marked-alt"} color="maroon"
                                data-tip data-tooltip-id="tooltipMap" />
                            {exploring ? "Exploring" : "Building"} {currentroom === null ? "" : currentroom.id}
                        </Row>
                        <Row>
                            {<MapContainer currentAreaData={currentarea} currentRoom={currentroom} selectRoom={loadRoomById} createRoom={createRoomInDirection}
                                editing={editing} showelevation={true} simpleMode={false} linkRooms={requestLinkRooms} exploring={exploring} />}
                        </Row>
                    </Col>
                    : null}

            </Row >
        </div>

    )
}

export default BuilderAreaEditor;