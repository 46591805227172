import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';
import Config from '../../config/Config';
import {getStandardHeader, getAuthenticatedHeader} from './utils/FetchHeaders';
import {timeConverter} from './utils/NiceStrings';
import { removeColourCodes} from './utils/ColourMap';
import NewsArticle from './components/NewsArticle';



function News() {
  const [news, setNews] = useState([]);
  
  // Retrieves the list of items from the Express app
  const getList = () => {
    const headers = getStandardHeader();
    fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/news/recent/10`, {headers : headers})
    .then(res =>res.json())


    .then(list => { 
      if(list.result)
        setNews(list.data)
    }
    )
  }

  useEffect(() => {
    getList();
  }, []);
  
    return (
      <div className="container">
        <br/>
        {/* Check to see if any items are found*/}
        {news!=null ? (
          <div className="coloured-box-aligned">
            {news.map((data, key) => {
              return (
              <div key={key}>
                <div className="card" >
                  <div className="card-header">
                    <h5>                    
                      <div className="text-left">{timeConverter(data.cmdate)} - {removeColourCodes(data.cmsubj)}</div>
                    </h5>
                  </div>
                  <NewsArticle article={data.cmmsgt}/>
                  <br />
                </div>
                <br />
                
              </div>
              );
           })}
          </div>
        ) : (
          <div>
            <h4>No News Articles Found</h4>
          </div>
        )
      }
      </div>
    );
  
}

export default News;
