import React, { useState, useEffect } from 'react';
import { Row, Button } from 'react-bootstrap';
import { useExitBuilder, useExitDispatch } from './ExitProvider';
import AffectEditor from './../AffectEditor';
import EditableField from './../EditableField';

const exitfieldsMap = [
    { field: 'destination', description: 'Destination', type: 4 },
    { field: 'name', description: 'Name', type: 0 },
    { field: 'display', description: 'Display', type: 0 },
    { field: 'description', description: 'Description', type: 0 },
    { field: 'hasdoor', description: 'Has A Door', type: 1 },
    { field: 'isopen', description: 'Is Open', type: 1 },
    { field: 'defaultsclosed', description: 'Defaults Closed', type: 1 },
    { field: 'defaultslocked', description: 'Defaults Locked', type: 1 },
    { field: 'openticks', description: 'Open Ticks', type: 2 },
    { field: 'closedtext', description: 'Closed Ticks', type: 2 },
    { field: 'closeword', description: 'Close Word', type: 2 },
    { field: 'openword', description: 'Open Word', type: 2 },
    { field: 'keyname', description: 'Key Name', type: 2 },
    { field: 'doorname', description: 'Door Name', type: 2 },
];


function ExitGenDoor(props) {
    let editing = props.editing;

    const { currentExitData } = useExitBuilder();
    const exitDispatch = useExitDispatch();

    const deleteAffect = (affectField, affectIndex) => {
        console.log('Deleting Behaviour from Exit', affectIndex);
        if (affectIndex >= 0) {
            exitDispatch({ type: "DELETE_AFFECT", field: affectField, payload: affectIndex });
        }
    }

    const updateAffect = (affectField, affectIndex, affectId, affectParameters) => {
        if (affectIndex >= 0) {
            let updateAffect = {};
            updateAffect.index = affectIndex;
            updateAffect.id = affectId;
            updateAffect.params = affectParameters;

            exitDispatch({ type: "SET_AFFECT", field: affectField, payload: updateAffect });
        }
    }

    const createAffect = (field) => {
        console.log('Creating ' + field + ' for Exit');
        let newBehaviour = {};
        newBehaviour.id = '';
        newBehaviour.params = '';
        //currentExitData.behaviours.push(newBehaviour);
        exitDispatch({ type: "ADD_AFFECT", field: field, payload: newBehaviour });
        //setCurrentRoomData(prevstate => ({ ...prevstate }));
    }

    const callDispatcher = (fieldName, newValue) => {
        console.log('Dispatching', fieldName, newValue);
        exitDispatch({ type: "SET_FIELD", field: fieldName, payload: newValue });
    }

    return (
        <>
            {exitfieldsMap.map((fieldMap, index) =>
                <EditableField 
                    key={index}    
                    editing={editing}
                    field={fieldMap.field}
                    value={currentExitData[fieldMap.field]}
                    callDispatcher={callDispatcher}
                    description={fieldMap.description}
                    fieldType={fieldMap.type} />
            )}

            <Row className={"my-2"}>
                <strong> Effects:</strong>
            </Row>
            <Row>
                {currentExitData.effects.length === 0 ?
                    <span>No effects applied to Exit</span>
                    : null}
                <div className="container">
                    {currentExitData.effects.map((effect, index) =>
                        <div key={index} name={effect.id}>
                            <AffectEditor field={'effects'} effectIndex={index} effectId={effect.id} effectParameters={effect.params} editing={editing}
                                updateAffect={updateAffect}
                                deleteAffect={deleteAffect} />
                        </div>)}
                    {editing
                        ? <Button className={"btn-themed-dark m-2"} onClick={() => createAffect('effects')}>Add Effect</Button>
                        : null
                    }
                </div>

            </Row>

            <Row className={"my-2"}>
                <strong> Behaviours</strong>
            </Row>
            <Row>
                {currentExitData.behaviours.length === 0 ?
                    <span>No behaviours applied to Exit</span>
                    : null}
                <div className="container">

                    {currentExitData.behaviours.map((behaviour, index) =>
                        <div key={index} name={behaviour.id}>
                            <AffectEditor field={'behaviours'} effectIndex={index} effectId={behaviour.id} effectParameters={behaviour.params} editing={editing}
                                updateAffect={updateAffect}
                                deleteAffect={deleteAffect} />
                        </div>)}
                    {editing
                        ? <Button className={"btn-themed-dark m-2"} onClick={() => createAffect('behaviours')}>Add Behaviour</Button>
                        : null
                    }
                </div>

            </Row>
        </>);
}

export default ExitGenDoor;