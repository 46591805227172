import React, { Component } from 'react';

class CharacterCreation extends Component {
  // Initialize the state
  constructor(props){
    super(props);
    this.state = {
      list: null
    }
  }

  render() {
    const { list } = this.state;

    return (
      <div className="container" id="divMainContent">
        <br/>
          <h1>Character Creation</h1>  
        <br/>
        <p> Before you start the character creation process I strongly suggest that you take a few minutes to look though the races and classes available to find a combination that will fit the way you want your character to be</p>
        <h3>Choosing A Name</h3>   
        <p>Now that you have your character concept you need to name your character, this needs to be a unique original name consisting of a first name only. You will be allowed to add a surname later.</p>
        <p>This name should be appropriate to your character and the world, special characters such as ç and é are not allowed nor are numbers or punctuation.</p>
        <p>There are lots of name generators out there if you are struggling for a name, for <a href="https://www.name-generator.org.uk/fantasy/" target="_blank" rel="noreferrer">example this name generator</a> provides a number of options for various races.</p>
        
        <h3>Choosing A Password</h3> 
        <p>After your name you will be asked to create a password. This needs to be something that is unique to you and that you will be able to remember .</p>
        <h3>Entering your e-mail address</h3> 
        <p>After you set your password the game will ask for an email address, This is so the game can warn you of an impending auto purge which we will cover later in this guide or notify you of any private messages you may have been sent.</p>
        <p>You will not be contacted for any other reason unless you request us to, and we do not share user information with other online services</p>
        <h3>Colour</h3>
        <p>At this point the MUD will offer you a chance to activate ANSI color, most mud clients are compatible with these colors to make the game more enjoyable to play. If you select not to use ANSI color at this time the mud will only display in your clients default text color.</p>
        <ul>
            <li><strong>ANSI</strong> (A) - Simple 16 colour palette</li>
            <li><strong>XTERM</strong> (X) - A wider colour palette based on the 256 colour xterm palette</li>
            <li><strong>No Colour</strong>(N) - Disable colour</li>    
        </ul>
        <p>You can turn this on or off at anytime during play though the COLOR and NOCOLOR commands.</p>   
              
        <h3>Select Race</h3>
        <p>You will now be asked to select your race. Recall that each race has its own strengths and weakness. Some classes are also restricted to certain races so it is a good idea to read up on the race you wish to play.</p>
        <p><a href="races.php">The player races can be found here.</a></p>
        <div className="alert alert-primary">                          
          <h4>Advanced Races</h4>              
          <p>Dryads and Dragons have some advanced options to select at creation - please ensure you are familiar with the consequences of your choices before selecting them.</p>          
        </div>

        <h3>Gender</h3>
        <p>After selecting your race it is time to select weather you are a boy or a girl. Though it is important to recall that some races have default genders with the game will set you as regardless of your selection. However if your race gets changed by a polymorph spell you will revert to the gender you selected</p>
        <ul>
          <li><strong>Male</strong> (M) - Your standard male choice!</li>
          <li><strong>Female</strong> (F) - Your standard female choice!</li>
          <li><strong>Neuter</strong> (N) - No gender</li>
          <li><strong>Futanari</strong> (I) - A futanari, a female that has, or can manifest male genitalia.</li>
          <li><strong>Hermaphrodite</strong> (H) - An hermaphrodite, a character that has both male and female genitalia.</li>
        </ul>

        <h3>Stats</h3>
        <p>At this point you will be shown a list of six stats including Strength, Dexterity, Constitution, Wisdom, intelligence, and Charisma. As a general rule the higher your stats the better. However each class is keyed off a separate stat so you will wish to read the class files for each of the bass classes before conforming your stats. If you wish to reroll your stats you can do so at this point by selection Y for yes or conform them with N. An explanation of each stat is provided when creating your character.</p>

        <h3>Class</h3>
        <p>At this point in the creation process you will be asked to select your class from a list of up to seven base classes. The list will differ depending on your race and abilities selections as not everyone qualifies for each class. The base classes are, Apprentice, Bard, Cleric, Druid, Fighter, Mage and, Thief.</p>

        <h3>Alignment</h3>
        <p>The Edge of Midnight uses three primary alignments. Good, Neutral and, Evil. At this point you will be asked to select with of the three you wish to start out play as. Your alignment with affect the skills that you will be able to use. Advanced classes you may qualify for and the experience you get for hunting various enemies in the game. You alignment will change as you play depending on how you play your character.</p>
        <p>At this point you have completed the basic creation of your character. After reading a short bit you are ready to enter the MUD world.</p>
      </div>
    );
  }
}

export default CharacterCreation;