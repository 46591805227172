import React, { Component } from 'react';

class IntroToMuds extends Component {
  // Initialize the state
  constructor(props){
    super(props);
    this.state = {
      list: null
    }
  }


  render() {
    const { list } = this.state;

    return (
      <div className="container" id="divMainContent">
        <br/>
        <h1>Mudding 101</h1>
        <br/>
        <p>A MUD or a <strong>multi-user dungeon</strong> is an online game where many people can come and adventure together. Unlike an MMORPG a MUD is entirely textual where the graphics are replaced with rich descriptions and every players action is entered through a command.</p>
        <p>The themes of the various muds cover many aspects of fantasy, science fiction and contemporary life drawing on a rich vein of fiction and reality to give people an opportunity to go on grand quests and slay dangerous monsters or engage in machiavellian plots!</p>
        <p>The <strong>Edge of Midnight</strong> takes elements of many genres and brings them all into the virtual space of their creators mind.</p>
        <blockquote>
          <h4 className="text-primary">“We encouraged people to write their own MUDs,” he says. “We made MUD because the real world sucked.."</h4>
          <small>Richard Bartle <cite title="Source Title">Guardian Interview 2014</cite></small>
        </blockquote>
        <p>More importantly it seeks to bring players into an environment away from reality where their characters can live 
            lives and explore themes and experience things that are out of reach in reality.</p>
            
        <h2>OK, so what is CoffeeMud?</h2>
        <blockquote>
          <small>Bo Zimmerman<cite title="Source Title"> Coffeemud Creator</cite></small>
        </blockquote>
        <p><strong>Well, CoffeeMud is, most importantly, a MUD Engine.</strong></p>
        <p>That means that CoffeeMud is a very large computer program that provides a foundation upon which one can most efficiently build a text based MUD of their very own. When you download the CoffeeMud software, you are dedicating yourself to weeks, months, even years of meticulous work using it to build a new world for others to log into and play.</p>
        <p>A MUD Engine, also called a MUD Codebase, provides the gathering place for players all over the internet to congregate for the purpose of adventuring. The person who downloads, installs, and runs the CoffeeMud server is providing his computer as a host to the fantasy world in which the players reside and pursue their adventures. This means that the host must take the time to provide such a world for the players to wander around in. CoffeeMud contains software which provides both the foundation and the tools with which the host can create such a world.</p>
        <p>So, as you can now see, those who wish to experience or play in MUDs do not need to download CoffeeMud in order to do so. They should skip most of the CoffeeMud page and download one of the MUD clients like GMud or ZMud. With those clients they can connect to one of the hundreds of existing mud engines which are running on the internet and play to their hearts content.</p>
        <p>It is the TRULY adventurous among you, who wish to host and create your own text based worlds who might be interested in our humble codebase.</p>
      </div>
    );
  }
}

export default IntroToMuds;