import { getSchematics, updateProduct } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useProfessionBuilder, useProfessionDispatch } from './ProfessionProvider';
import SchematicEntry from './SchematicEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SchematicSelector from './SchematicSelector';

function ProductEditor(props) {

    const deleteProduct = props.deleteProduct;
    const { schematics, error, product } = useProfessionBuilder();
    const [ editing, setEditing] = useState(false);
    const [ selectingSchematic, setSelectingSchematic] = useState(false);
    const [ original, setOriginal] = useState();

    const providerDispatch = useProfessionDispatch();

    useEffect(() => {
        setEditing(false);
        setOriginal(product)
    }, [product]);

    const callDispatcher = (product, fieldname, value) => {
       providerDispatch({ type: "SET_SCHEMATIC_LEVEL", product, value });
    }

    const selectSchematic = (schematic) => {
    //    console.log('Selected', schematic);
        //     getSchematicList(profession, product);
    }

    const deleteSchematic = (schematic_id) => {
        console.log('Deleting ', schematic_id, 'from', product.product_id);
        providerDispatch({ type: "DELETE_SCHEMATIC_FROM_PRODUCT", product, schematic_id });
    }

    const toggleEditing = () => {
        setEditing(true);
    }

    const undoChanges = async () => {
        let data = await getSchematics('xxx', original.product_id);
        console.log('Schematic Data', data);
        if (data.result) {
            console.log('Schematic Data', data);
            providerDispatch({ type: "SET_SCHEMATIC_LIST", payload : data.schematics }); 
        } else {
            providerDispatch({ type: "SET_SCHEMATIC_LIST", payload : [] }); 
        }
        setEditing(false);
    }

    const saveChanges = async () => {
        providerDispatch({ type: "SORT_SCHEMATICS" });
        updateProduct(original.product_id, product, schematics);
        setEditing(false);
    }

    const newSchematicEntry = () => {
//        console.log('Add Schematic Entry');
        setSelectingSchematic(true);
//        providerDispatch({ type: "ADD_SCHEMATIC_TO_PRODUCT" });
    }

    const choseSchematic = (schematic) => {
  //      console.log("Selected Schematic", schematic);
        setSelectingSchematic(false);
        if(schematic!==null)
            providerDispatch({ type: "ADD_SCHEMATIC_TO_PRODUCT", id : schematic.SCHEMAID, name : schematic.NAME });
    }



    return (
        <>
            <SchematicSelector title={"Select Schematic"} show={selectingSchematic} cancelModal={() => { choseSchematic(null) }} confirmModal={(schematic_id) => { choseSchematic(schematic_id) }} />
            
            {(product !== undefined)&&(product!==null)
                ?
                <>
                    <Row>
                        <Col>
                            <FontAwesomeIcon onClick={toggleEditing} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"+(editing?" xyz":"")} icon={"edit"} />
                            <FontAwesomeIcon onClick={saveChanges} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"+(editing?"":" xyz")} icon={"check-circle"} />
                            <FontAwesomeIcon onClick={undoChanges} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"+(editing?"":" xyz")} icon={"undo"} />
                            <FontAwesomeIcon onClick={()=>deleteProduct(product.product_id)} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"+(product.product_generic?"":" xyz")} icon={"trash-alt"} />
                        </Col>
                    </Row>
                    <Row>
                        <span>
                            <strong>Product Id: </strong>
                            {product.product_id}
                        </span>
                    </Row>
                    <Row>
                        <span>
                            <strong>Product Name: </strong>
                            {product.product_name}
                        </span>
                    </Row>
                    <Row>
                        <span>
                            <strong>Description: </strong>
                            {product.product_description}
                        </span>
                    </Row>
                    <Row className={"professionheader"}>
                <strong>Product Schematics:</strong>
            </Row>
            {editing?<Row><Button onClick={newSchematicEntry}>Add Schematic</Button></Row>:null}
            {schematics.map((schematic, index) =>
                <Row key={index}>
                    <SchematicEntry editing={editing}
                        field={"level"}
                        value={schematic.schematic_level}
                        callDispatcher={callDispatcher}
                        onSelect={selectSchematic}
                        onDelete={deleteSchematic}
                        data={schematic}
                        description={schematic.schematic_name}
                        fieldType={2} />
                </Row> )}
                </>
                : null

            }


           
        </>);
}

export default ProductEditor;