import React, { useState, useEffect,useRef } from 'react';
import { Modal, Dropdown, Row, Col, Accordion, Card, Button, Form, InputGroup, DropdownButton, FormControl } from 'react-bootstrap';
import { findSchematic, getProductList } from '../../components/professionRequests';

function ProductLineCreator(props) {

    let show = props.show;
    let cancelModal = props.cancelModal;
    let confirmModal = props.confirmModal;
    let title = props.title;
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    
    const [products, setProducts] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [error, setError] = useState('');
    const [validated, setValidated] = useState(false);
    const focusRef = useRef(null);
    useEffect(() => {
        if(show){
            setProductId('');
            setProductName('');
            setProductDescription('');
            searchForProduct();
            focusRef.current.focus();
        } else
            setProducts([]);
    }, [show]);

    useEffect(() => {
        validateProduct(productId, productName);
    }, [productId, productName]);

    const searchForProduct = () => {
        setError('');

      getProductList().then(data => {
            if (data.result) {
                setProducts(data.products);
            } else {
                //error
                setProducts([]);
                setError(`Failed to find products for ${searchCriteria}.`);
            }
        }).catch(
            error => {
                setError(`Could not find products for ${searchCriteria}.`);
                console.log(error);
            }
        );
    }

    const validateProduct = (product_id, productName) => {
        let ok = true;
        setError('');
        let matchingProducts = products.filter(p => (p.product_id.toLowerCase()==product_id.toLowerCase())); 
        if(products.length==0){
            ok=false;
            setError('');
        }
        else if(matchingProducts.length>0){
            setError("Product already exists");
            ok = false;
        } else if (product_id.length<5){
            setError("Product Id too short");
            ok = false;
        }

        matchingProducts = products.filter(p => (p.product_name.toLowerCase()==productName.toLowerCase())); 
        if(products.length==0){
            ok=false;
            setError('');
        }
        else if(matchingProducts.length>0){
            setError("Product Name already in use");
            ok = false;
        } else if (productName.length===0){
            setError("");
            ok = false;
        } else if (productName.length<5){
            setError("Product Name too short");
            ok = false;
        }
        setValidated(ok);
    }

    const notSaveable = () => {
        return !validated;
    }

    return (

        <Modal centered show={show} onHide={cancelModal}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <span>Product Id</span>
                 <input ref={focusRef} type="text" className="form-control" onChange={(e) => setProductId(e.currentTarget.value)} />
                 <span>Product Name</span>
                 <input type="text" value={productName} className="form-control" onChange={(e) => setProductName(e.currentTarget.value)} />
                 <span>Product Description</span>
                 <input type="text" value={productDescription} className="form-control" onChange={(e) => setProductDescription(e.currentTarget.value)} />
            </Modal.Body>
            <Modal.Footer>
                <font color="red">{error}</font>
                <Button variant="secondary" onClick={cancelModal}>
                    Cancel
                </Button>

                <Button variant="secondary" onClick={()=>confirmModal(productId,productName, productDescription)} disabled={notSaveable()}>
                    Confirm
                </Button>

            </Modal.Footer>
        </Modal>
    );
}

export default ProductLineCreator;