import React from 'react';
import {transformText} from './../utils/ColourMap';

function ColouredText(props) {
    let covertedtext = transformText(props.ColouredText);
    let clickText = props.onClick;
    let classNameToUse = "eomEditor my-2 p-2 eom-box";
    if(props.overrideClassName!==undefined)
    classNameToUse = props.overrideClassName;
    return (
        <div className={classNameToUse} rows="3" dangerouslySetInnerHTML={{__html: covertedtext}} onClick={clickText}/>
    );
  }

  export default ColouredText;