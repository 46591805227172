import React, { useState, useEffect } from 'react';
import { getListOfMaps, ImageComponent } from './../components';

function RealmMaps() {
  const [mapList, setMapList] = useState([]);

  const populateListOfMaps = async () => {
    const data = await getListOfMaps();
    
    if (data.result) {
      setMapList(data.data);
    } else {
      setMapList([]);
    }
  }
  
  useEffect(() => {
    populateListOfMaps();
  }, []);

  return (
    <div className="container" id="divMainContent">
      
      <ul>
        {mapList.map((map, index)=>
          <div key={index} className="border border-dark p-3 m-2">
            <h4>{map.cmarea}</h4><small>{map.description.trim()}</small>
            <ImageComponent image={map.weblink} />
            <br/>
          </div>)}
      </ul>

    </div>
  );
}


export default RealmMaps;