import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import EditableField from '../../EditableField';
import { getFieldMappingsForList } from './SchematicFieldManager';

function CraftingListEditor(props) {

    let listId = props.list;
    const fieldsForList = getFieldMappingsForList(listId);
    let index = props.index;
    const [entry, setEntry] = useState(props.effect);
    const [headerFields, setHeaderFields] = useState([]);
    const [header, setHeader] = useState(true);
    const [editing, setEditing] = useState(true);

    useEffect(() => {
        setEntry(props.entry);
    }, [props.entry]);

    useEffect(() => {
        setEditing(props.editing);
    }, [props.editing]);

    useEffect(() => {
        setHeaderFields(getFieldMappingsForList(listId, true));
    }, [entry]);

    const callDispatcher = (field, value) => {
        console.log(listId, index, field, value);
        props.updateEntry(listId, index, field, value);
    }

    const displayHeader = () => {
        return (<Row className={"ingredient-header row-centered"}>
            {headerFields.map((field, index) =>
                <Col className={"row-centered"} xs={2} key={index} onClick={() => setHeader(!header)}>
                    {index == 2 ? "(" : null}
                    {entry[field.name]}
                    {index == 2 ? ")" : null}
                </Col>

            )}
            <Col xs={2} sm={8} md={8} className={"right-col"}>
                <FontAwesomeIcon onClick={() => props.deleteEntry(index)} data-tip data-tooltip-id="ttDeleteProcess"
                    className={"fa fa-lg fa-eom-inverse m-1" + (editing ? "" : " xyz")} icon={"times-circle"} />
                {props.promoteEntry !== undefined
                    ? <FontAwesomeIcon onClick={() => props.promoteEntry(props.index)} data-tip data-tooltip-id="ttPromoteProcess"
                        className={"fa fa-lg fa-eom-inverse m-1" + (props.editing ? "" : " xyz")} icon={"arrow-up"} />
                    : null}
                {props.demoteEntry !== undefined
                ?<FontAwesomeIcon onClick={() => props.demoteEntry(props.index)} data-tip data-tooltip-id="ttDemoteProcess"
                    className={"fa fa-lg fa-eom-inverse m-1" + (props.editing ? "" : " xyz")} icon={"arrow-down"} />
                    :null}
            </Col>

        </Row >)
}

const displayDetail = () => {
    return <>
        {displayHeader()}
        {fieldsForList.map((field, index) =>
        (field.split ? <br key={index} /> :
            <EditableField key={index}
                editing={editing}
                field={field.name}
                value={entry[field.name]}
                callDispatcher={callDispatcher}
                description={field.name}
                fieldType={field.type}
                choices={field.choices}
                addRow={true} />)
        )}
    </>;
}
return (
    <div className={"ingredient-border my-1"}>
        {
            header ? displayHeader() : displayDetail()}
    </div>)
}

export default CraftingListEditor;