import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import EomMarkdown from '../components/EomMarkdown';

const IntroScenes = () => {

    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch('/guides/scene-guide.md')
            .then((r) => r.text())
            .then(text => {
                setMarkdown(text);
            })
    }, []);


    return (
        <Row className="container centered-row" id="divMainContent">
            <Col xs={12} md={10} lg={8}>
                <EomMarkdown markdown={markdown} />
            </Col>
        </Row>
    );
}

export default IntroScenes;