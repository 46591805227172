import React, { Component } from 'react';

class IntroExploring extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">
			<br/>
			<h1>Exploring</h1>
			<br/>
			<p>Getting around the realm is an important part of the game and these commands and abilities will help you navigate your character through the world.</p>
			<p>Many of the abilities that move your character around the map will consume <strong>MOVEMENT</strong> points and you may need to stop and allow your character to rest from time to time to recover.</p>
			<h3>North</h3>
			<p>This compass direction will attempt to move your character in the direction specified.</p>
			<h3>Northeast</h3>
			<p>This compass direction will attempt to move your character in the direction specified.</p>
			<h3>East</h3>
			<p>This compass direction will attempt to move your character in the direction specified.</p>
			<h3>Southeast</h3>
			<p>This compass direction will attempt to move your character in the direction specified.</p>
			<h3>South</h3>
			<p>This compass direction will attempt to move your character in the direction specified.</p>
			<h3>Southwest</h3>
			<p>This compass direction will attempt to move your character in the direction specified.</p>
			<h3>West</h3>
			<p>This compass direction will attempt to move your character in the direction specified.</p>
			<h3>Northeast</h3>
			<p>This compass direction will attempt to move your character in the direction specified.</p>
			<h3>Up</h3>
			<p>This directional command will attempt to move your character in the direction specified. If there is no exit up and the room is open to the sky your character will attempt to flt.</p>
			<h3>Down</h3>
			<p>This directional command will attempt to move your character in the direction specified. If you are in the air you will begin to descend toward the ground.</p>
			<h3>Enter</h3>
			<p>This command can be used to enter portals or other entrances.</p>
			<h3>Follow</h3>
			<p>Your character will begin to follow the target, to stop following someone enter <strong>FOLLOW SELF</strong> to stop someone from following you enter <strong>NOFOLLOW [TARGET]</strong></p>
			<h3>Group</h3>
			<p>As well as being part of the target's party your character will also begin to follow the target, to stop grouping with someone enter <strong>GROUP SELF</strong> to stop someone from following you enter <strong>NOGROUP [TARGET]</strong></p>
			<h3>Mount</h3>
			<p>Your character attempts to mount a horse, or other rideable creature, carriage, boat, or item.</p>
			<p>Once mounted, the creature can be navigated around by use of the normal N,S,E,W navigation commands.  Some rideables may also be mounted on others, such as a cart or wagon that must be pulled.</p>
			<h3>Dismount</h3>
			<p>If your character is presently riding a horse, this command will cause you to dismount from the horse. </p>
			<h3>Flying</h3>
			<p>There are a number of abilities that can grant your character the ability to fly, but if they have wings the <strong>flap</strong> command will cause them to start and stop flying.</p>
			<p>If the target of the mount or board commands has the ability to fly, your character will fly with the target object or creature.</p>
			<h3>Sneak</h3>
			<p>Your character will attempt to sneak in the indicated direction. If successful, they will not be seen when entering the room.</p>
			<p>A sneak attempt will automatically invokes a hide attempt.</p>
      </div>
    );
  }
}

export default IntroExploring;