import React from 'react';
import Config from '../../../config/Config';
import { getStandardHeader, getAuthenticatedHeader } from '../utils/FetchHeaders';

export async function listAreas(authenticated) {
  try {
    var headers = getStandardHeader();
    if (authenticated === true)
      headers = getAuthenticatedHeader();

    // console.table(headers);
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/area/list`, { headers: headers });
    let data = await response.json();
    // console.log(data); 
    return data
  } catch (error) {
    console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function getAreaData(areaname) {
  try {
    const headers = getStandardHeader();
    //console.log(areaname);
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/area/get/${areaname}`, { headers: headers });
    let data = await response.json();

    //console.log(data);

    if (data.result) {
      //console.log(data);
      return data
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}