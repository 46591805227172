import React, { useState } from 'react';
import {  Accordion } from 'react-bootstrap';
import { changePassword, useAuthState, useAuthDispatch } from './index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToast from '../toast/useToasts';

function PasswordChange() {
    const { loading  } = useAuthState();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState('');
    const [inputType, setInputType] = useState('password');
    const { addToastMessage } = useToast(false);

    const dispatch = useAuthDispatch();

    const handlePasswordExpand = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setPasswordError('');
        setInputType('password');
    }

    const handleValidation = () => {
        if (newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return false;
        }
        else if (newPassword.length > 20) {
            setPasswordError('Passwords must not exceed 20 characters.');
            return false;
        }
        return true;
    }
    
    const handlePasswordChange = async (e) => {
        e.preventDefault();

        let allOk = handleValidation();

        if (allOk) {
            let response = await changePassword(dispatch, oldPassword, newPassword);
            if (response.result) {
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setPasswordError('');
                setInputType('password');

                addToastMessage('Password Change Success', 'You have changed your password');
                
                setPasswordSuccess(response.reason);
            } else {
                setPasswordError(response.reason);
            }
        }
    }

    const handleVisibilityChange = (e) => {
        setInputType('password' === inputType ? "text" : "password");
    }

    const handleChange = async (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        switch (name) {
            case 'oldPassword':
                setOldPassword(value);
                break;
            case 'newPassword':
                setNewPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
        }
        setPasswordError('');
    }

    return (
        <Accordion className={"navbar-dark footer-card menu-accordian"} defaultActiveKey={[]} alwaysOpen>
            <Accordion.Item eventKey={'0'} className={"accordion-body"}>
                <Accordion.Header onClick={handlePasswordExpand} >
                   <span className={"largeFont"}>Change Password</span> 
                </Accordion.Header>
                <Accordion.Body>
                    <form >
                        <input className="form-control m-2" type={inputType} placeholder="Current Password" name="oldPassword" autoComplete="off" value={oldPassword} onChange={handleChange} />
                        <input className="form-control m-2" type={inputType} placeholder="New Password" name="newPassword" autoComplete="off" value={newPassword} onChange={handleChange} />
                        <input className="form-control m-2" type={inputType} placeholder="Confirm Password" name="confirmPassword" autoComplete="off" value={confirmPassword} onChange={handleChange} />
                        <div className="d-flex m-2">
                            <button className="btn btn-themed-dark btn-small my-2 my-lg-0" onClick={handlePasswordChange} disabled={loading}>Change Password</button>
                            <span className="text-white p-2 icon rounded-right" onClick={handleVisibilityChange}>
                                <FontAwesomeIcon className="fa fa-trash fa-lg" icon={(inputType === 'text') ? "eye-slash" : "eye"} color="white" />
                            </span>
                        </div>
                        <span className="error"><font color="red">{passwordError}</font></span>
                        <span className="error"><font color="green">{passwordSuccess}</font></span>
                    </form>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    );
}

export default PasswordChange;