import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { Row, Col } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { removeColourCodes } from '../../utils/ColourMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AffectEditor from '../AffectEditor';
import { useBuilder, useBuilderDispatch } from '../../components/index';
import { useItemBuilder, useItemDispatch } from './ItemProvider';
import EditableField from './../EditableField';
import { getCraftedItemFields, getFieldsMap, showAffects } from './ItemFields';
import { getBuilderItemTpsReport } from '../../components/builderRequests';

let emptyReport = {}
emptyReport.tps = "n/a";
emptyReport.tps = "0";

function ItemEditor(props) {
    const { currentItemData, error } = useItemBuilder();
    const itemDispatch = useItemDispatch();

    const [fieldsMap, setFieldsMap] = useState([]);
    const [report, setReport] = useState(emptyReport);

    const { currentitem, currentroom, editing, saveItem } = useBuilder();
    const dispatch = useBuilderDispatch();

    const unloadItem = async () => {
        dispatch({ type: 'SELECT_ITEM', payload: null });
    }

    const updateTpsReport = async (itemData) => {
        console.log('Making Request');
        let data = await getBuilderItemTpsReport(itemData);
        if (data.result) {
            console.log(data);
            setReport(data.report);
        } else {
            console.log(data);
            setReport(emptyReport);
        }
    }

    useEffect(() => {
        if ((currentItemData !== undefined)&&(currentItemData !== null)) {
            updateTpsReport(currentItemData);
        }

    }, [currentItemData]);

    useEffect(() => {
        var itemcopy = JSON.parse(JSON.stringify(currentitem))
        itemDispatch({ type: 'SELECT_CURRENTITEM', payload: itemcopy });
        let newFieldsMap = getFieldsMap(itemcopy);
        console.log('New Fields Map', itemcopy);
        setFieldsMap(newFieldsMap);
        if (currentitem !== undefined) {
            updateTpsReport(currentitem);
        }
    }, [currentitem]);


    let active = props.active;

    let width = 3;
    if (active) { width = 6 }

    const cancelItem = async (e) => {
        editingDone();
        dispatch({ type: 'SELECT_ITEM', payload: null });
    }

    const handleEditing = () => {
        if (editing) {
            dispatch({ type: 'EDITING_DISABLED' });
            handleRevert();
        }
        else
            dispatch({ type: 'EDITING_ENABLED' });
    }

    const editingDone = () => dispatch({ type: 'EDITING_DISABLED' });

    const handleRevert = () => {
        console.log('Reverting Exit', currentitem);
        var itemcopy = JSON.parse(JSON.stringify(currentitem))
        itemDispatch({ type: 'SELECT_CURRENTITEM', payload: itemcopy });
    }

    if (currentItemData === undefined) {
        return null;
    }

    const saveChanges = async () => {
        console.log('Item Editor saving', currentItemData);
        saveItem(currentItemData);
    }

    const deleteAffect = (affectField, affectIndex) => {
        console.log('Deleting Behaviour from Mob', affectIndex);
        if (affectIndex >= 0) {
            itemDispatch({ type: "DELETE_AFFECT", field: affectField, payload: affectIndex });
        }
    }

    const updateAffect = (affectField, affectIndex, affectId, affectParameters) => {
        if (affectIndex >= 0) {
            let updateAffect = {};
            updateAffect.field = affectField;
            updateAffect.index = affectIndex;
            updateAffect.id = affectId;
            updateAffect.params = affectParameters;

            itemDispatch({ type: "SET_AFFECT", field: affectField, payload: updateAffect });
        }
    }

    const createAffect = (field) => {
        console.log('Creating ' + field + ' for Mob');
        let newBehaviour = {};
        newBehaviour.id = '';
        newBehaviour.params = '';
        //currentMobData.behaviours.push(newBehaviour);
        itemDispatch({ type: "ADD_AFFECT", field: field, payload: newBehaviour });
        //setCurrentRoomData(prevstate => ({ ...prevstate }));
    }

    if (currentItemData == null) {
        return null;
    }

    const callDispatcher = (fieldName, newValue) => {
        console.log('Dispatching', fieldName, newValue);
        itemDispatch({ type: "SET_FIELD", field: fieldName, payload: newValue });
    }
    const callCraftedDispatcher = (fieldName, newValue) => {
        console.log('Dispatching', fieldName, newValue);
        itemDispatch({ type: "SET_CRAFTEDFIELD", field: fieldName, payload: newValue });
    }
    

    let itemfieldsMap = [];
    const headers = Object.keys(fieldsMap);

    headers.map(header => {
        let field = fieldsMap[header];
        //console.log('Filtering', header, field);
        if (field !== undefined) {
            itemfieldsMap = [...itemfieldsMap, field];
        }
    });

    let craftedItemFields = getCraftedItemFields();
    let craftedItemFieldsMap = [];
    const craftedHeaders = Object.keys(craftedItemFields);

    craftedHeaders.map(header => {
        let field = craftedItemFields[header];
        if (field !== undefined) {
            craftedItemFieldsMap = [...craftedItemFieldsMap, field];
        }
    });

    const displayTpsInfo = () => {
        if ((report.tps == 0) && (report.op == 0)) {
            return <span><strong>TPS/OP:</strong>Roleplay Item (0 Power)</span>
        } else {
            return <span><strong>TPS/OP:</strong> {report.tps} points, {report.op}% of OP</span>
        }
    }

    return (
        <>
            <ReactTooltip id="tooltipUndoChanges" place="bottom" effect="solid">
                Undo Changes to Item
            </ReactTooltip>
            <ReactTooltip id="tooltipSaveChanges" place="bottom" effect="solid">
                Save Changes to Item
            </ReactTooltip>
            <ReactTooltip id="tooltipDelete" place="bottom" effect="solid">
                Delete Item
            </ReactTooltip>
            <ReactTooltip id="tooltipEdit" place="bottom" effect="solid">
                Toggle Editing of Item
            </ReactTooltip>
            <ReactTooltip id="tooltipClose" place="bottom" effect="solid">
                Close Item
            </ReactTooltip>

            <div >
                <Row>
                    <Col xs={1}>
                        <FontAwesomeIcon onClick={cancelItem} className="fa fa-lg fa-eom m-2" icon={"times-circle"} data-tip data-tooltip-id="tooltipClose" />
                    </Col>
                    <Col><h4> {editing ? "Editing" : "Viewing"} {removeColourCodes(currentItemData.name)}</h4>
                    </Col>
                    <span><strong>Unique Id:</strong> {currentItemData.uniqueid}</span>
                    <br />
                    {displayTpsInfo()}

                </Row>
                <FontAwesomeIcon onClick={handleEditing} className="fa fa-lg fa-eom m-2" icon={"edit"} data-tip data-tooltip-id="tooltipEdit" />

                {editing ? <>
                    <FontAwesomeIcon onClick={saveChanges} className="fa fa-lg fa-eom m-2" icon={"check-circle"} data-tip data-tooltip-id="tooltipSaveChanges" />
                    <FontAwesomeIcon onClick={handleRevert} className="fa fa-lg fa-eom m-2" icon={"undo"} data-tip data-tooltip-id="tooltipUndoChanges" />
                </> : null}
                <span className="error"><font color="red">{error}</font></span>

                {itemfieldsMap.map((fieldMap, index) =>
                    <EditableField key={index} editing={editing}
                        field={fieldMap.field}
                        value={currentItemData[fieldMap.field]}
                        callDispatcher={callDispatcher}
                        description={fieldMap.description}
                        fieldType={fieldMap.type}
                        conditional={currentItemData[fieldMap.conditional]}
                        choices={fieldMap.choices} />
                )}

                {currentItemData.crafted === undefined ? null :
                    <>
                        {craftedItemFieldsMap.map((fieldMap, index) =>
                            <EditableField key={index} editing={editing}
                                field={fieldMap.field}
                                value={currentItemData.crafted[fieldMap.field]}
                                callDispatcher={callCraftedDispatcher}
                                description={fieldMap.description}
                                fieldType={fieldMap.type}
                                conditional={currentItemData.crafted[fieldMap.conditional]}
                                choices={fieldMap.choices} />
                        )}
                    </>
                }

                {showAffects(currentItemData.type)
                    ?
                    <>
                        <Row className={"my-2"}>
                            <strong> Effects:</strong>
                        </Row>
                        <Row>
                            {currentItemData.effects.length === 0 ?
                                <span>No effects applied to Item</span>
                                : null}
                            <div className="container">

                                {currentItemData.effects.map((effect, index) =>
                                    <div key={effect.id} name={effect.id}>
                                        <AffectEditor field={'effects'} effectIndex={index} effectId={effect.id} effectParameters={effect.params} editing={editing}
                                            deleteAffect={deleteAffect}
                                            updateAffect={updateAffect} />
                                    </div>)}
                            </div>
                            {editing
                                ? <Button className={"btn-themed-dark m-2"} onClick={() => createAffect('effects')}>Add Effect</Button>
                                : null
                            }
                        </Row>
                    </>
                    : null}

                <Row className={"my-2"}>
                    <strong> Contents:</strong>
                </Row>
                <Row>
                    {currentItemData.contents.length === 0 ?
                        <span>Nothing in or on this Item</span>
                        : null}
                    <div className="container">
                        {currentItemData.contents.map(item =>
                            <div key={item.index} name={item.index}>
                                <span>{removeColourCodes(item.name)} </span>
                            </div>)}
                    </div>
                </Row>
            </div>
        </>
    )
}

export default ItemEditor;