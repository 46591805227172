import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Row, Col, Accordion, Card, Button, Form, InputGroup, DropdownButton, FormControl } from 'react-bootstrap';
import { findSchematic } from '../../components/professionRequests';

function ProductSelector(props) {

    let show = props.show;
    let cancelModal = props.cancelModal;
    let confirmModal = props.confirmModal;
    let title = props.title;
    let validProducts = props.validProducts;

    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [error, setError] = useState('');
    
    useEffect(() => {
        if(!show) {
            setProducts([]);
        }
        else if (searchCriteria === '') {
            setProducts([]);
        } else {
            const delayDebounceFn = setTimeout(() => {
                // console.log(searchString)
                searchForSchematic();
                // Send Axios request here
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchCriteria]);

    const searchForSchematic = () => {
        setError('');
        console.log("Searching for", searchCriteria);
        if (searchCriteria.length < 2) {
            setProducts([]);
        } else {
            let prods = validProducts.filter(product => product.product_id.toLowerCase().indexOf(searchCriteria.toLowerCase())>-1);
            console.log(prods, validProducts);
            setProducts(prods);
        }
    }

    const getProductList = (products, currentProduct, setProduct) => {   
        return (
            <InputGroup className="mb-3">
                <DropdownButton
                    variant="outline-secondary"
                    title={currentProduct==null?"Select":currentProduct}
                    id="input-group-dropdown-1"
                    disabled={products.length===0}
                >
                    {products.map(product =>
                        <Dropdown.Item key={product.product_id} name={product.product_id} onClick={() => setProduct(product.product_id)}>{product.product_id}</Dropdown.Item>
                    )}
    
                </DropdownButton>
                <p>{currentProduct==null?"":currentProduct}</p>
            </InputGroup>
        )
    }

    return (

        <Modal centered show={show} onHide={cancelModal}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <input type="text" className="form-control" onChange={(e) => setSearchCriteria(e.currentTarget.value)} />
                {getProductList(products, product, setProduct)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelModal}>
                    Cancel
                </Button>

                <Button variant="secondary" onClick={()=>confirmModal(product)} disabled={product==null}>
                    Confirm
                </Button>

            </Modal.Footer>
        </Modal>
    );
}

export default ProductSelector;