import React from 'react';
import StatGuage from './StatGauge';
import { Row, Col, Button, Dropdown, ButtonGroup } from 'react-bootstrap';

const ResourcePool = ({ min, max, value, colour, tip, pool }) => {

    return (
        <div>
            <Row className={"eomResourcePool "}>
                <Col>
                    <span>{pool}</span>
                </Col>
                <Col>
                    <span>{value}</span>
                </Col>
            </Row>
            <Row className={"p-1 eomResourcePool px-3 "}>
                <StatGuage min={min} max={max} value={value} tip={tip} colour={colour} />
            </Row>
        </div>
    )
}

export default ResourcePool;