import React, { Component, useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Config from '../../config/Config';
import { getStandardHeader } from './utils/FetchHeaders';
import PlayerAvatar from './components/PlayerAvatar';
import { useAuthState } from './components';

const Online = () => {
  const { mudStatus, currentUser } = useAuthState();

  const [mudRunning, setMudRunning] = useState(undefined);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const intervalStatus = setInterval(() => {
      setMudRunning((running) => {
        if (running)
          getList();
        else
          setUserList([]);
        
        return running;
      })
    }, 10000);

    return () => {
      clearInterval(intervalStatus);
    }
  }, []);

  useEffect(() => {
    if (mudStatus != null) {
      setMudRunning(mudStatus.status === 'OK');
      getList();
    }
    else {
      setMudRunning(false);
    }
  }, [mudStatus]);

  // Retrieves the list of items from the Express app
  const getList = () => {
    const headers = getStandardHeader();
    fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/online`, { headers: headers })
      .then(res => res.json())
      .then(list => {
        setUserList(list.users)
      })
  }

  const getUsername = (data) => {
    if (currentUser !== null) {
      return (
        <a className="dryLink p-2" href={`/player/rpsheet/${data.userName}`}>
          {data.admin == "true"
            ? <strong>{data.longName}</strong>
            : data.longName
          }
        </a>
      );

    } else {
      return (
        <>{
          data.admin == "true"
            ? <strong>{data.longName}</strong>
            : data.longName
        }
        </>);
    }

  }

  return (
    <div className="container">
      {mudRunning ? (
        <div key="staticb">
          <br key="break.0" />
          <div key="static" className='alert alert-success'>{mudStatus.name} version {mudStatus.version} status is online.</div>

          {/* Check to see if any items are found*/}
          {(userList != null) && (userList.length > 0) ? (
            <Row className="container d-flex justify-content-center">
              {/* Render the list of items */}
              {userList.map((data, index) => {
                return (
                  <Row key={index} className="d-flex align-items-center justify-content-center ">
                    <Col xs={6} md={4} lg={1}>
                      <PlayerAvatar avatarlocation={data.avatar.avatar} size={50} />
                    </Col>
                    <Col xs={8} md={4} lg={4}>
                      {getUsername(data)}

                    </Col>
                  </Row>
                );
              })}

            </Row>

          ) : (
            <div key="deadtome">
              <br />
              <div>There are currently no users online.</div>
              <br />
            </div>
          )
          }
          <br />

          <div className='alert alert-success'>There {userList.length == 1 ? 'is' : 'are'} currently <strong>{userList.length} user{userList.length == 1 ? '' : 's'} </strong>online.</div>

          <div>
            <a href='/client'
              target="_blank"
              style={{
                justifyContent: 'center',
                alignSelf: 'center'
              }}>
              <Row>
                <Button className={"btn-themed-dark"}>Play Now!</Button>
              </Row>
            </a>
          </div>
          <br />
        </div>) : (<div><br />
          <div className='alert alert-primary'>Edge of Midnight is offline.</div>
          <br />
        </div>)}

    </div>
  );
}

export default Online;
