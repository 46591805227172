import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { makeNiceString, timeConverter, makeNiceRpsheetLinks } from './../utils/NiceStrings'
import { getPlayersOnline, getRumours, getTopRpList, getEventList, } from './../components';
import { listScenes } from '../components/sceneRequests';

function WhatsGoingOn() {
    const [whosOnline, setWhosOnline] = useState([]);
    const [roleplayActivity, setRoleplayActivity] = useState([]);
    const [realmEvents, setRealmEvents] = useState([]);
    const [futureEvents, setFutureEvents] = useState([]);
    const [rumourMill, setRumourMill] = useState([]);
    const [scenes, setScenes] = useState([]);

    const getRumourMillCard = () => {

        return (
            //let oocplayers = [...new Set(whosOnline.filter(who => who.isooc === true).map(who => who.userName))];
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>The Midnight Rumour Mill</Card.Title>
                </Card.Header>
                <Card.Body>
                    {rumourMill.map((rumour, index) =>
                        <div key={index} className="divtext">
                            <h4>{rumour.subject}</h4><small>{timeConverter(rumour.cmdate)}</small>
                            <p>{rumour.rumour}</p>
                            <br></br>
                        </div>
                    )}


                </Card.Body>
            </Card>

        );
    }

    const getSceneCard = () => {
        console.log(scenes);
        return (
            //let oocplayers = [...new Set(whosOnline.filter(who => who.isooc === true).map(who => who.userName))];
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Plots & Scenes</Card.Title>
                </Card.Header>
                <Card.Body>
                    {scenes.map((scene, index) =>
                        <div key={index} className="divtext">
                            <a key={scene.scene_id} className="dryLink p-2" href={`/player/scene/${scene.scene_id}`}>
                                <h4>{scene.scene_title}</h4>
                                <small>{scene.scene_status} - Updated {scene.scene_updated}</small>
                            </a>


                        </div>
                    )}


                </Card.Body>
            </Card>

        );
    }

    const getMudEventsCard = (title, mudEvents) => {
        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>{title} Events</Card.Title>
                </Card.Header>
                <Card.Body>

                    {(mudEvents === undefined) || (mudEvents.length === 0)
                        ? <p>There are currently <strong>no</strong> active events in the realm - why not create one! see <a href="/intro/events">here</a> for details!</p>
                        : null}
                    {mudEvents.map((event, index) =>
                        <div key={index}>
                            <strong>{event.organiser}</strong> organised the following event in {event.location}
                            <p>{event.event}</p>
                            <p>{event.expires}</p>
                        </div>)}

                </Card.Body>
            </Card>
        );
    }

    const getTopRpCard = () => {
        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Busiest Roleplayers of the Day</Card.Title>
                </Card.Header>
                <Card.Body>
                    {roleplayActivity.map((rper, index) =>
                        <Row key={index}>
                            <Col>{rper.userName}</Col>
                            <Col>{rper.rpxp}</Col>

                        </Row>)}

                </Card.Body>
            </Card>
        );
    }

    const getRoleplayStatusCard = () => {
        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Roleplay Status of Active Players</Card.Title>
                </Card.Header>
                <Card.Body>
                    {whosOnline.filter(who => who.rpstatus.length > 0).map(who =>
                        <p key={who.userName}>
                            <a key={who.userName} className="dryLink p-2" href={`/player/rpsheet/${who.userName}`}>
                               <strong>{who.userName}</strong> - {who.rpstatus}
                            </a>
                        </p>
                    )}
                </Card.Body>
            </Card>
        );
    }

    const getSimpleWhoCard = () => {
        let players = [...new Set(whosOnline.map(who => who.userName))];
        let oocplayers = [...new Set(whosOnline.filter(who => who.isooc === true).map(who => who.userName))];
        let idleplayers = [...new Set(whosOnline.filter(who => who.idle.length > 0).map(who => who.userName))];

        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Who's Online?</Card.Title>
                </Card.Header>
                <Card.Body>
                    <p>{makeNiceRpsheetLinks(players)} {players.length > 1 ? " are " : " is "} <strong>online</strong></p>
                    <p>{makeNiceRpsheetLinks(oocplayers)} {oocplayers.length > 1 ? " are " : " is "} <strong>out of character</strong></p>
                    <p>{makeNiceRpsheetLinks(idleplayers)} {idleplayers.length > 1 ? " are " : " is "} <strong>idle</strong></p>

                </Card.Body>
            </Card>
        );
    }

    // Retrieves the list of items from the Express app
    const getWhoList = async () => {
        let data = await getPlayersOnline();
        //console.log(data);
        //if (data.result) {
        setWhosOnline(data.users);
        //} else {
        //    setWhosOnline([]);
        //}
    }

    const getLatestRumours = async () => {
        let data = await getRumours();
        console.log(data.data);
        if (data.data === undefined)
            setRumourMill([]);
        else
            setRumourMill(data.data);
    }

    const getScenesFromServer = async e => {
        const data = await listScenes();
        if (data.result) {
            setScenes(data.scenes);
        } else {
            setScenes([]);
        }
    }

    const getTopRpData = async () => {
        let data = await getTopRpList();
        //console.log('Top RP' ,data.rpdata);
        if (data.result)
            setRoleplayActivity(data.rpdata);
        else {
            setRoleplayActivity([]);
        }
    }

    const getEventData = async () => {
        let data = await getEventList();
        //console.log('Event' ,data.currentevents);
        if (data.result) {
            setRealmEvents(data.currentevents);
            setFutureEvents(data.futureevents);
        } else {
            setRealmEvents([]);
            setFutureEvents([]);
        }
    }

    useEffect(() => {
        getWhoList();
        getLatestRumours();
        getTopRpData();
        getEventData();
        getScenesFromServer();

        const intervalRefreshFast = setInterval(() => {
            getWhoList();
        }, 60000);

        const intervalRefresh = setInterval(() => {
            //console.log('Refresh page')
            getWhoList();
            getLatestRumours();
            getTopRpData();
            getEventData();
        }, 300000);

        const intervalRefreshSlow = setInterval(() => {
            getScenesFromServer();
        }, 600000);

        return () => {
            clearInterval(intervalRefresh);
            clearInterval(intervalRefreshSlow);
            clearInterval(intervalRefreshFast);
        }

    }, []);


    return (
        <div className="container container-eom" id="divMainContent">
            <div style={{
                display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignItems: "flex-start",
                justifyContent: "flex-start"
            }}>
                <Row>
                    <Col>
                        {getSimpleWhoCard()}
                        {getRoleplayStatusCard()}
                        {getMudEventsCard('Active', realmEvents)}
                        {getMudEventsCard('Upcoming', futureEvents)}
                        {getTopRpCard()}
                    </Col>
                    <Col>
                        {getSceneCard()}
                        {getRumourMillCard()}
                    </Col>
                </Row>


            </div>
        </div>
    )
}

export default WhatsGoingOn;