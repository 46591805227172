import React, { useRef, useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import UserInput from './UserInput'
import DisplayMessage from './DisplayMessage';
import { useClientContext } from './ClientProvider';

function AnsibleCentralView(props) {

    let submitMessage = props.submitMessage;
    let clearInput = props.clearInput;

    const messagesEndRef = useRef(null);
    const { messages, autoScroll, fontSize, fontFamily, renderLineLimit } = useClientContext();

    const [renderedMessages, setRenderedMessages] = useState([]);

    let onTouchStart = props.onTouchStart;
    let onTouchEnd = props.onTouchEnd;
    let onTouchMove = props.onTouchMove;
    let hideInput = props.hideInput;

    let divStyle = {};
    divStyle.fontSize = fontSize + "px";
    divStyle.fontFamily = fontFamily;
    divStyle.textAlign = 'left';
    divStyle.color = 'white';

    const delay = ms => new Promise(res => setTimeout(res, ms));

    useEffect(() => {
        scrollToBottom(false, 0);
    }, []);


    useEffect(() => {
        const handleResize = () => {
            scrollToBottom(false, 100);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        setRenderedMessages(messagesToView());
        scrollToBottom(false, 0);
    }, [messages, autoScroll]);

    useEffect(() => {
        scrollToBottom(false, 0);
    }, [hideInput])

    const scrollToBottom = async (forcedScroll, waitTime) => {
        //console.log('Scrolling', autoScroll, forcedScroll)
        if (autoScroll || forcedScroll) {
            if (messagesEndRef.current != null) {
                if (waitTime !== undefined)
                    await delay(waitTime);
                document.getElementById("books").scrollIntoView();
            }
        }
    }

    const messagesToView = () => {
        //const startTime = performance.now();
        let counter = 0;
        let lines = 0;
        for (let message of [...messages].reverse()) {
            lines += message.lineCount;
            counter++;
            if (lines > renderLineLimit) {
                break;
            }
        }

        let messagesToRender = [];
        if (counter >= messages.length) {
            messagesToRender = messages;
        } else {
            counter *= -1;
            messagesToRender = [...messages].slice(counter);
        }


        if (!autoScroll) {
            messagesToRender = messages;
        }

        let output = messagesToRender.map((message, index) =>
            <DisplayMessage
                key={index}
                message={message}
                name="eom"
                displayType={"output"}
            />
        );
        return output;
    }

    return (
        <>
            <Row className={"output-container" + (hideInput ? "max" : "") + " py-2 eom-output"}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                onTouchMove={onTouchMove}
            >
                <div id="myInput" style={divStyle}>
                    {renderedMessages}
                    <div className={'py-2'} id="books" key={"end"} ref={messagesEndRef} />
                </div>

            </Row>
            {hideInput ? null :
                <Row className={"input-container"}>
                    <UserInput onSubmitMessage={submitMessage} clearInput={clearInput} layout={""} />
                </Row>}
        </>
    );
}

export default AnsibleCentralView;