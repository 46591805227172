import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Row, Col, Accordion, Card, Button, Form } from 'react-bootstrap';

function ConfirmationDialog(props) {

    let show = props.show;
    let cancelModal = props.cancelModal;
    let confirmModal = props.confirmModal;
    let title = props.title;

    return (

        <Modal centered show={show} onHide={cancelModal}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="secondary" onClick={cancelModal}>
                    Cancel
                </Button>

                <Button variant="secondary" onClick={confirmModal}>
                    Confirm
                </Button>

            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationDialog;