import React, { Component } from 'react';

function GameplayHousing() {

    return (
        <div className="container" id="divMainContent">
        <h1>Introduction to Housing</h1>

        <p>Instanced housing is aimed at providing an unlimited amount of basic housing to the whole playerbase that in the long term is easier for builders to track and develop as well as requiring less manual intervention.</p>
        
        <h2>Purchasing a Deed</h2>
        
        <p>
        There are various vendors around the realm that sell deeds to different properties and you will see something for sale such as below.</p>
        
        <pre>A deed to 1 room in A Ramshackle Terrace</pre>
        
        <p>Once purchased the entrance will be in vicinity of the Vendor and once located you will able to &#39;enter&#39; the entrance and be taken to your home.</p>
        
        <h2>Modifying your home</h2>
        <p>You can modify your home more easily with the instanced properties. Entering the command &lsquo;property construct&rsquo; will take you to a screen where you can modify your rooms.</p>
        
        <pre>{`
>property construct

o----------------------------------------------------------------------------o
| 1) A studio flat in Suki's house                                           |
| 2) A room in Suki's House - Bathroom                                       |
o----------------------------------------------------------------------------o
Select Room (1 - 2) or (RETURN)
        `}</pre>
        
        
        <p>Select the room you wish to work, Selecting 1 will allow us work on the studio flat</p>
        <pre>{`
>1

o-----Title------------------------------------------------------------------o
| A studio flat in Suki&#39;s house                                          |
o-----Description------------------------------------------------------------o
| This is the description of the studio flat                                 |
o-----Exits------------------------------------------------------------------o
| I) a wooden door to Waygate Square                                         |
| N) a walkway to A room in Suki&#39;s House - Bathroom                      |
o----------------------------------------------------------------------------o

Help(?) T)itle D)escription B)uild Room DemO)lish Room or (Return to finish)
        `}</pre>
        <p>From here I have a number of options to take against the room.</p>
        <pre>{`
Help (Option ?)
T) Title: This will edit the title (short) for the room. 
D) Description: This will edit the description for the room. 
You can use the default (single line) or specify the editor by entering DE. 
L) Link Rooms: This will create exits to and from specific rooms. 
U) Unlink Rooms: This will remove exits to and from specific rooms. 
E) This will set the entrance to the property to the selected room. 
B) This will build a new room. 
R) This will change the type of room. 

(RETURN to Continue) 
         `}</pre>
        <p>The help option lists all available options that can be carried out with a little more description about what that entails.</p>
        <p>Cost (Option C) With construction/masonry skills</p>
        <pre>{`
o----------------------------------------------------------------------------o
| Build      1 plushie cthulhu, 5 yarn balls(15000)                          |
| Link       125 Stone                                                       |
| Unlink     100 Stone                                                       |
| Title      Free                                                            |
| Entrance   300 Stone                                                       |
| Description Free                                                           |
| Demolish   Free                                                            |
| Room Type  Free                                                            |
o----------------------------------------------------------------------------o
(RETURN to Continue)
Coins
o----------------------------------------------------------------------------o
| Build      1 plushie cthulhu, 5 yarn balls(15000)                          |
| Link       5 plushie cthulhus(50000)                                       |
| Unlink     5 plushie cthulhus(50000)                                       |
| Title      2 yarn balls, 1 feather(2500)                                   |
| Entrance   7 yarn balls, 1 feather(7500)                                   |
| Description 5 yarn balls(5000)                                             |
| Demolish   Free                                                            |
| Room Type  1 plushie cthulhu(10000)                                        |
o----------------------------------------------------------------------------o
`}</pre>

         
        <p>Taking option &lsquo;C&rsquo; will show how much each operation will cost to carry out, if you have construction options this will be presented as material needs, otherwise the user will be presented with a cash fee (As if you were employing someone else to do the work for you!).</p>
        
        <h2>Setting the Title (Option T)</h2>
        
        <p><strong>Option Key:</strong> T</p>
        
        <p><strong>Gold Cost:</strong> 2500</p>
        
        <p><strong>Material Cost:</strong> Free</p>
        
        <p><strong>Maximum Characters:</strong> 50/100</p>
        <br/>
        
        <p>
        The title for the from (the short description if you will) is taken with option &lsquo;T&rsquo;.</p>
        
        <pre>{`
>T        
Current: 'A studio flat in Suki's house'. 
Enter new Value: 
`}</pre>
        
        <p>You will be prompted for a new value, this should be no more than 50 characters wide when shown to a user and up to 100 characters.</p>
        
        <p>Setting the Description (Option D)</p>
        
        <p><strong>Option Key:</strong> D</p>
        
        <p><strong>Gold Cost:</strong> 5000</p>
        
        <p><strong>Material Cost:</strong> Free</p>
        
        <p><strong>Maximum Characters:</strong> 5000</p>
        
        <p>
        The title for the from (the short description if you will) is taken with option &lsquo;T&rsquo;.</p>

        <pre>{`>D 
Current: 'This is the description of the studio flat'. 
Enter new Value:`}</pre>
       
        <p>
        You will be prompted for a new value, this should be no more than 5000 characters.&nbsp;</p>
        
        <p>Editor Usage
        If preferred you can use the inline editor to build the description by taking the option &lsquo;DS&rsquo;. This will take you into the editor, with the current description previewed and starting at line 1.</p>
                
        <pre>{`Preview of Description
1  ) This is the description of a wondrously open room, wow!
Editing Description.
CoffeeMud Message Maker
Menu (?/A/D/L/I/E/R/S/Q):
`}</pre>

        
        <h5>Setting the Entrance Room (E)</h5>
        <p><strong>Option Key:</strong> E</p>
        
        <p><strong>Gold Cost: </strong>7500</p>
        
        <p><strong>Material Cost:</strong> 300 (Material based on type of room)</p>
        
        <p>This will set the selected room to be the entrance to your home, so you can move this about to wherever you please and it will create a doorway to the location of the instance&rsquo;s portal/doorway.</p>
        
        <h5>Change the Room Type (R)</h5>
        <p><strong>Option Key:</strong> R</p>
        
        <p><strong>Gold Cost:</strong> 10000</p>
        
        <p><strong>Material Cost:</strong> 300 (Material based on type of room)</p>
        
        <p>This option can be used to change the underlying type of room, most rooms in a typical housing instance will be predominantly StoneRoom or WoodRoom. Some instances will allow you to select a different type of room depending on the configuration of the room.</p>
        
        <p>
        Taking option &lsquo;R&rsquo;&nbsp;</p>
        
        <pre>{`
>R
Select Locale -WoodRoom and StoneRoom
>WoodRoom
`}</pre>
        
        <h5>Unlink Rooms (U)</h5>
        <p><strong>Option Key</strong>: U</p>
        
        <p><strong>Gold Cost</strong>: 50000</p>
        
        <p><strong>Material Cost</strong>: 100 (Material based on type of room)</p>
        
        <p>It is possible to change the configuration of your homes layout by linking and unlinking rooms, unlinking a room will remove the exits between two selected rooms.</p>
        
        <pre>{`A wondrously open room 
This is the description of a wondrously open room, wow! 
A massive 700lbs bundle of stone is here. 
( 2) A huge 400lbs bundle of stone is here. 
Obvious exits: 
Leave: a closed door 
North: A room in Suki&#39;s House - Bathroom 
`}</pre>

        
        <p>In the above example the bathroom is located north of the main room, if I wanted to remove this link I could take option &lsquo;U&rsquo;.</p>
        
        <pre>{`A wondrously open room 
>U 
 This will cost 100 lumps of stone. do you want to continue? Yes/No? 
 >Y 
 o----------------------------------------------------------------------------o 
 | 1) A wondrously open room                                              | 
 | 2) A room in Suki&#39;s House - Bathroom                                   | 
 o----------------------------------------------------------------------------o 
 Select Start Room (1 - 2) or (RETURN) 
 >1 
 Select End Room (1 - 2) or (RETURN) 
 >2 
 `}</pre><p>...</p><pre>{`
 A wondrously open room 
 This is the description of a wondrously open room, wow! 
	A massive 700lbs bundle of stone is here. 
	A huge 400lbs bundle of stone is here. 
	A huge 200lbs bundle of stone is here. 
 Obvious exits: 
 Leave: a closed door 
`}</pre>

        
        <p>It is important to remember this will leave orphaned rooms which have no entrance/exit although this does have implications around accessing them (See Linking Rooms) it can be used to create an oubliette or such for RP purposes, use a portal key for the room and you can still get to it!</p>
        
        <h5>Linking Rooms (L)</h5>
        <p><strong>Option Key:</strong> L</p>
        
        <p><strong>Gold Cost:</strong> 50000</p>
        
        <p><strong>Material Cost:</strong> 125 (Material based on type of room)</p>
        
        <p>Closely related to the unlinking option, this will create a new link between rooms.</p>
        
        <p>This will cost 125 lumps of stone. do you want to continue? Yes/No?</p>
        <pre>{`A wondrously open room 
>L       
o----------------------------------------------------------------------------o 
| 1) A wondrously open room                                              | 
| 2) A room in Suki&#39;s House - Bathroom                                   | 
o----------------------------------------------------------------------------o 
Select Start Room (1 - 2) or (RETURN) 
>1 
Select End Room (1 - 2) or (RETURN) 
>2 
Select a direction or (RETURN) 
>east 
o-----Title------------------------------------------------------------------o 
| A wondrously open room                                                 | 
o-----Description------------------------------------------------------------o 
| This is the description of a wondrously open room, wow!                | 
o-----Exits------------------------------------------------------------------o 
| I) a wooden door to Waygate Square                                     | 
| E) a walkway to A room in Suki&#39;s House - Bathroom                      | 
o----------------------------------------------------------------------------o 
Help(?) T)itle D)escription B)uild Room DemO)lish Room or (Return to finish) 
>RETURN 
o----------------------------------------------------------------------------o 
| 1) A wondrously open room                                              | 
| 2) A room in Suki&#39;s House - Bathroom                                   | 
o----------------------------------------------------------------------------o 
Select Room (1 - 2) or (RETURN) 
>RETURN 
>look 
A wondrously open room 
This is the description of a wondrously open room, wow! 
A huge 200lbs bundle of stone is here. 
A huge 400lbs bundle of stone is here. 
A massive 575lbs bundle of stone is here. 
Obvious exits: 
Leave: a closed door 
East : A room in Suki&#39;s House - Bathroom 
>east 
A room in Suki&#39;s House - Bathroom 
This is the bathroom in Suki&#39;s house 

Obvious exits: 
West : A wondrously open room 
`}</pre>

        
        <p>This will create the link in the above example I wanted an east-west link between the main room and the bathroom and so selected the main room as my start point, the bathroom as my end point and east as the direction and the exits were created.</p>
        
        <p>If there was an existing link between these rooms that would be destroyed before the new one was created.</p>
        
        <h5>Demolish (O)</h5>
        <p><strong>Option Key:</strong> O</p>
        
        <p><strong>Gold Cost:</strong> Free</p>
        
        <p><strong>Material Cost:</strong> Free</p>
        
        <p>This is a very risky option, please do not take it lightly. This will destroy the chosen room and all its contents. If this is your only room in the instance and it is destroyed, you will need to put the deed in the trash and purchase a NEW home.</p>
        
        <p>It is only permissible to destroy the entrance room when there are no others room left.</p>
        
        <h5>Building a new Room (B)</h5>
        <p><strong>Option Key:</strong> B</p>
        
        <p><strong>Gold Cost:</strong> Based on cost of a room in the instance.</p>
        
        <p><strong>Material Cost:</strong> n/a</p>
        
        <pre>{`  o----------------------------------------------------------------------------o 
| 1) A room in Suki&#39;s House - Studio                                     | 
o----------------------------------------------------------------------------o 
>B 
Select Start Room (1 - 1) or (RETURN) 
>1 
Select Locale -WoodRoom and StoneRoom 
>WoodRoom 
Select a direction or (RETURN) 
>East 
o-----Title------------------------------------------------------------------o 
| A room in Suki&#39;s House - Studio                                        | 
o-----Description------------------------------------------------------------o 
| This is a lovely little sitting room with a roaring fire.              | 
o-----Exits------------------------------------------------------------------o 
| I) a wooden door to Waygate Square                                     | 
| U) a walkway to A room in Suki&#39;s House                                 | 
o----------------------------------------------------------------------------o 
Help(?) T)itle D)escription B)uild Room DemO)lish Room or (Return to finish) 
>RETURN 
o----------------------------------------------------------------------------o 
| 1) A room in Suki&#39;s House - Studio                                     | 
| 2) A room in Suki&#39;s House                                              | 
O----------------------------------------------------------------------------o 
>RETURN 
`}</pre>

        
        <p>Taking option B will build a new room from the selected room in the specified direction ( Basic ten directions are valid, n,e,s,w, ne, nw, se, sw, up and down.</p>
        
        <p>&nbsp;</p>
        </div>
        );
    }
    
export default GameplayHousing;