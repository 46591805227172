import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { setBuilderAreaData } from '../components/builderRequests';
import ColouredText from '../components/ColouredText';
import AffectEditor from './AffectEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBuilder, useBuilderDispatch } from './../components/index';
import EditableField from './EditableField';



function AreaEditor(props) {
    const [originalArea, setOriginalArea] = useState(null);
    const [currentAreaData, setCurrentAreaData] = useState(null);
    const [error, setError] = useState('');
    const [filter, setFilter] = useState('');
    const [fieldsMap, setFieldsMap] = useState([]);


    let creatingArea = false;
    if (originalArea != null) {
        creatingArea = originalArea.creating;
    }
    //console.log('Creating Area', creatingArea);

    const dispatch = useBuilderDispatch();
    const { currentarea, editing, currentroom } = useBuilder();

    //console.log(currentAreaData);

    let loadRoom = props.loadRoom;
    let unloadArea = props.unloadArea;
    let active = props.active;
    let createRoom = props.createRoom;
    let deleteRoom = props.deleteRoom;

    const loadRoomLocal = async e => {
        if (!editing) {
            loadRoom(e);
            setError('');
        } else {
            setError('Cannot edit a room whilst editing area.');
        }
    }

    const createRoomLocal = async => {
        if (!editing) {
            createRoom();
            setError('');
        } else {
            setError('Cannot edit a room whilst editing area.');
        }
    }

    const getStatusCodes = () => {
        return ['Open', 'Closed', 'Private', 'System', 'Building', 'Testing'];
    }

    const fullFieldsMap = {
        'id': { description: 'Id', type: 2 },
        'type': { description: 'Type', type: 2 },
        'status': { description: 'Status', type: 5, choices: getStatusCodes },
        'name': { description: 'Name', type: 3 },
        'description': { description: 'Description', type: 0 },
        'author': { description: 'Author', type: 2 },
        'currency': { description: 'Currency', type: 2 },
        'region': { description: 'Region', type: 2 },
        'continent': { description: 'Contintent', type: 2 },
        'world': { description: 'World', type: 2 },
        'staff': { description: 'Staff', type: 2 },
        'parents': { description: 'Parent Areas', type: 2 },
        'children': { description: 'Child Areas', type: 2 },
    }

    const getAreaFields = (area) => {
        let areafieldsMap = {};

        const areaHeaders = Object.keys(area);

        const objectHeaders = Object.keys(fullFieldsMap);
        //console.log('Fields to edit', areaHeaders);
        objectHeaders.map(header => {
            if (areaHeaders.indexOf(header) > -1) {
                //console.log('Found', header);
                let field = fullFieldsMap[header];
                if (field !== undefined) {
                    field.field = header;
                    areafieldsMap[header] = field;
                }
            }
        });
        return areafieldsMap;
    }

    useEffect(() => {
        //console.log('Effect Triggered', currentarea);
        if (currentarea !== null) {
            if ((currentAreaData === null) || (currentarea.id !== currentAreaData.id)) {
                //console.log('Switching area', currentarea.id);
                setCurrentAreaData(currentarea);
                setOriginalArea(JSON.parse(JSON.stringify(currentarea)));
            }
        } else {
            //console.log('Resetting Area', currentarea);
            setCurrentAreaData(null);
            setOriginalArea(null);
        }

        let newFieldsMap = getAreaFields(currentarea);
        //console.log('New Fields Map', currentarea);
        setFieldsMap(newFieldsMap);

    }, [currentarea]);

    const cancelArea = () => {
        dispatch({ type: 'EDITING_DISABLED' });

        unloadArea();
    }

    const saveChanges = async () => {
        currentAreaData.originalId = originalArea.id;
        let data = await setBuilderAreaData(JSON.stringify(currentAreaData));

        if (data.result) {
            editingDone();
            setCurrentAreaData(currentAreaData);
            setOriginalArea(JSON.parse(JSON.stringify(currentAreaData)));
            dispatch({ type: 'SELECT_AREA', payload: currentAreaData });
            setError('');
        } else {
            setError('Area could not be saved, ', data.reason);
            console.log(data.reason);
        }
    }

    const handleEditing = () => {
        if (editing)
            dispatch({ type: 'EDITING_DISABLED' });
        else
            dispatch({ type: 'EDITING_ENABLED' });
    };

    const editingDone = () => dispatch({ type: 'EDITING_DISABLED' });
    const handleRevert = () => {
        var copyofarea = JSON.parse(JSON.stringify(originalArea))
        setCurrentAreaData(copyofarea);
    }

    const filterMatch = (room) => {
        return (room.room_id.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
        (room.room_name.toLowerCase().indexOf(filter.toLowerCase()) > -1) ;
    }

    const updateAffect = (field, effectIndex, effectId, effectParameters) => {
        if (effectIndex >= 0) {
            currentAreaData[field][effectIndex].id = effectId;
            currentAreaData[field][effectIndex].params = effectParameters;
        }
    }

    const createEffect = () => {
        let newEffect = {};
        newEffect.id = '';
        newEffect.params = '';
        currentAreaData.effects.push(newEffect)
        setCurrentAreaData(prevstate => ({ ...prevstate }));
    }

    const deleteAffect = (field, index) => {
        console.log('Deleting ' + field + ' from Area', index);
        if (index >= 0) {

            let newBehviours = currentAreaData[field].splice(index, 1);
            console.table(currentAreaData[field]);
            console.table(newBehviours);
            setCurrentAreaData(prevstate => ({ ...prevstate }));
        }
    }

    const createBehaviour = () => {
        let newBehaviour = {};
        newBehaviour.id = '';
        newBehaviour.params = '';
        currentAreaData.behaviours.push(newBehaviour)
        setCurrentAreaData(prevstate => ({ ...prevstate }));
    }

    const callDispatcher = (fieldName, newValue) => {
        currentAreaData[fieldName] = newValue;
        setCurrentAreaData(prevstate => ({ ...prevstate }));
    }

    if (currentAreaData == null) {
        return <Col md={3} className={"mx-2"} />
    }

    let itemfieldsMap = [];

    const headers = Object.keys(fieldsMap);

    headers.map(header => {
        let field = fieldsMap[header];
        //console.log('Filtering', header, field);
        if (field !== undefined) {
            itemfieldsMap = [...itemfieldsMap, field];
        }
    });


    let width = 2;
    if (active) { width = 8 }
    return (
        <>
            <ReactTooltip id="tooltipUndoChanges" place="top" effect="solid">
                Undo Changes to Area
            </ReactTooltip>
            <ReactTooltip id="tooltipEditArea" place="top" effect="solid">
                Edit Area
            </ReactTooltip>
            <ReactTooltip id="tooltipSaveArea" place="top" effect="solid">
                Save Changes to Area
            </ReactTooltip>
            <Row>
                <Col xs={1}>
                    <FontAwesomeIcon onClick={cancelArea} className="fa fa-lg fa-eom m-2" icon={"times-circle"} />
                </Col>
                <Col>
                    <h4> <strong>{editing ? "Editing" : "Viewing"} Area:</strong> {currentAreaData.id} </h4>
                </Col>
            </Row>
            <Row>
                <Col xs={1}>
                    <FontAwesomeIcon onClick={handleEditing} data-tip data-tooltip-id="tooltipEditArea" className="fa fa-lg fa-eom m-2" icon={"edit"} />

                    {editing ? <>
                        <FontAwesomeIcon onClick={saveChanges} data-tip data-tooltip-id="tooltipSaveArea" className="fa fa-lg fa-eom m-2" icon={"check-circle"} />
                        <FontAwesomeIcon onClick={handleRevert} data-tip data-tooltip-id="tooltipUndoChanges" className="fa fa-lg fa-eom m-2" icon={"undo"} />
                    </> : null}
                </Col>
                <span className="error"><font color="red">{error}</font></span>
            </Row>
            {itemfieldsMap.map((fieldMap, index) =>
                <EditableField key={index} editing={editing}
                    field={fieldMap.field}
                    value={currentAreaData[fieldMap.field]}
                    callDispatcher={callDispatcher}
                    description={fieldMap.description}
                    fieldType={fieldMap.type}
                    conditional={currentAreaData[fieldMap.conditional]}
                    choices={fieldMap.choices} />
            )}

            <Row className={"my-2"}>
                <strong> Effects:</strong>
            </Row>
            <Row>
                <div>
                    {currentAreaData.effects.length === 0 ?
                        <span>No effects applied to Area</span>
                        : null}
                    {currentAreaData.effects.map((effect, index) =>
                        <div key={effect.id} name={effect.id}>
                            <AffectEditor effectIndex={index} field={'effects'} effectId={effect.id} effectParameters={effect.params} editing={editing}
                                deleteAffect={deleteAffect} updateAffect={updateAffect} />

                        </div>)}
                    {editing
                        ? <Button className={"btn-themed-dark px-2"} onClick={createEffect}>Add Effect</Button>
                        : null
                    }
                </div>
            </Row>
            <Row className={"my-2"}>
                <strong> Behaviours:</strong>
            </Row>
            <Row>
                <div>
                    {currentAreaData.behaviours.length === 0 ?
                        <span>No behaviours applied to Area</span>
                        : null}
                    {currentAreaData.behaviours.map((behaviour, index) =>
                        <div key={behaviour.id} name={behaviour.id}>
                            <AffectEditor effectIndex={index} field={'behaviours'} effectId={behaviour.id} effectParameters={behaviour.params} editing={editing}
                                deleteAffect={deleteAffect} updateAffect={updateAffect} />
                        </div>)}
                    {editing
                        ? <Button className={"btn-themed-dark mx-2"} onClick={createBehaviour}>Add Behaviour</Button>
                        : null
                    }
                </div>
            </Row>



            {editing ? null :
                <>
                    <Row className={"my-2"}>
                        <h5> Rooms In Area </h5>
                        <input type="text" className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                    </Row>
                    <Row className={"output-container-room"}>
                        <div>
                            {currentAreaData.roomids.filter(room => filterMatch(room)).map(room =>
                                <Row className={"pb-1"} key={room}  >
                                    <Col xs={1}>
                                        <FontAwesomeIcon onClick={() => deleteRoom(room)} className="mr-2 fa fa-lg fa-eom" icon={"trash-alt"} />
                                    </Col>
                                    <Col xs={3}>
                                        <span name={room.room_id} onClick={loadRoomLocal}>
                                            {room.room_id}
                                        </span>

                                    </Col>
                                    <Col xs={8}>
                                        <span name={room.room_id}>
                                            {room.room_name}
                                        </span>
                                    </Col>
                                </Row>)}
                        </div>

                    </Row>
                    <Button className={"my-2"} onClick={createRoomLocal}>Create Room</Button>
                </>}
        </>
    )
}

export default AreaEditor;