import React from 'react';
import { getBuilderConfig } from '../components/builderRequests';
import { InputGroup, FormControl, Dropdown, ButtonGroup, Button, DropdownButton } from 'react-bootstrap';

export async function populateConfig() {
    console.log('Populate Config');
    const data = await getBuilderConfig();
    if (data.result) {
        localStorage.setItem('builder-exits', data.exits);
        localStorage.setItem('builder-races', data.races);
        localStorage.setItem('builder-locales', data.locales);
        localStorage.setItem('builder-mobtiers', data.mobtiers);
        localStorage.setItem('builder-mobtypes', data.mobtypes);
        localStorage.setItem('builder-itemtypes', data.itemtypes);
        localStorage.setItem('builder-materials', data.materials);
        localStorage.setItem('builder-componenttypes', data.componenttypes);
        localStorage.setItem('builder-ammotypes', data.ammotypes);
        localStorage.setItem('builder-craftingtools', data.craftingtools);
        localStorage.setItem('builder-craftingtables', data.craftingtables);
        localStorage.setItem('builder-craftingprocesses', data.craftingprocesses);
        localStorage.setItem('builder-craftingingredients', data.craftingingredients);

        localStorage.setItem('builder-weapontypes', JSON.stringify(data.weapontypes));
        localStorage.setItem('builder-armourtypes', JSON.stringify(data.armourtypes));
        localStorage.setItem('builder-wearlocations', JSON.stringify(data.wearlocations));
        localStorage.setItem('builder-bodies', JSON.stringify(data.bodies));
        localStorage.setItem('builder-layers', (data.layers));
        localStorage.setItem('builder-genders', 'FEMALE,MALE,FUTANARI,NEUTER,HERMAPHRODITE');

        return true;
    }
}

export async function getExitTypes() {
    if (localStorage.getItem('builder-exits') == null) {
        await populateConfig();
    }
    return localStorage.getItem('builder-exits');
}

export const getGenders = () => {

    if (localStorage.getItem('builder-genders') == null) {
        populateConfig().then(() => {
            return localStorage.getItem('builder-genders').split(',');
        }
        );
    }
    return localStorage.getItem('builder-genders').split(',');
}
export async function getLocales() {
    if (localStorage.getItem('builder-locales') == null) {
        await populateConfig();
    }
    return localStorage.getItem('builder-locales');
}

export async function getArmourTypes() {
    if (localStorage.getItem('builder-armourtypes') == null) {
        await populateConfig();
    }
    return JSON.parse(localStorage.getItem('builder-armourtypes'));
}

export function getComponentTypes(filter) {
    if (localStorage.getItem('builder-componenttypes') == null) {
        populateConfig().then(() => {
            return localStorage.getItem('builder-componenttypes').split(',');
        });
    }
    return localStorage.getItem('builder-componenttypes').split(',');
}


export function getEmbellishmentTypes(filter) {
    return ["TYPE_ANYTHING"];
}

export function getCraftingProcesses(filter) {
    if (localStorage.getItem('builder-craftingprocesses') == null) {
        populateConfig().then(() => {
            return localStorage.getItem('builder-craftingprocesses').split(',');
        });
    }
    return localStorage.getItem('builder-craftingprocesses').split(',');
}

export function getCraftingIngredientTypes(filter) {
    if (localStorage.getItem('builder-craftingingredients') == null) {
        populateConfig().then(() => {
            return localStorage.getItem('builder-craftingingredients').split(',');
        });
    }
    return localStorage.getItem('builder-craftingingredients').split(',');
}

export function getCraftingTools(filter) {
    if (localStorage.getItem('builder-craftingtools') == null) {
        populateConfig().then(() => {
            return localStorage.getItem('builder-craftingtools').split(',');
        });
    }
    return localStorage.getItem('builder-craftingtools').split(',');
}

export function getCraftingTables(filter) {
    if (localStorage.getItem('builder-craftingtables') == null) {
        populateConfig().then(() => {
            return localStorage.getItem('builder-craftingtables').split(',');
        });
    }
    return localStorage.getItem('builder-craftingtables').split(',');
}

export const getFilteredArmourTypes = (layer) => {
    let armourtypes = JSON.parse(localStorage.getItem('builder-armourtypes'));
    armourtypes.sort((a, b) => a.description.localeCompare(b.description));
    if (layer !== undefined) {
        //console.log(layer);
        return armourtypes.filter(armour => armour.layer == layer);
    }
    return armourtypes;
}

export const getWornLayers = (filter) => {
    if (localStorage.getItem('builder-layers') == null) {
        populateConfig().then(() => {
            return localStorage.getItem('builder-layers').split(',');
        });
    }
    return localStorage.getItem('builder-layers').split(',');
}

export const getMaterials = (filter) => {
    if (localStorage.getItem('builder-materials') == null) {
        populateConfig().then(res => {
            return getFilteredMaterials(filter);
        }
        );
    } else {
        return getFilteredMaterials(filter);
    }
}

export const getWearSlots = (filter) => {
    if (localStorage.getItem('builder-wearlocations') == null) {
        populateConfig().then(res => {
            return getFilteredWearSlots(filter);
        }
        );
    } else {
        return getFilteredWearSlots(filter);
    }
}

export const getAmmoTypes = (filter) => {
    if (localStorage.getItem('builder-ammotypes') == null) {
        populateConfig().then(res => {
            return localStorage.getItem('builder-ammotypes').split(',');
        }
        );
    } else {
        return localStorage.getItem('builder-ammotypes').split(',');
    }
}

const getFilteredWearSlots = (filter) => {
    let wearlocations = JSON.parse(localStorage.getItem('builder-wearlocations'));
    return wearlocations;
}

const getFilteredMaterials = (filter) => {
    let materials = localStorage.getItem('builder-materials').split(',');
    return materials;
}

export function getWeaponTypes(field) {
    if (localStorage.getItem('builder-weapontypes') == null) {
        populateConfig().then(() => {
            return JSON.parse(localStorage.getItem('builder-weapontypes'));
    });
    }
    let wt = JSON.parse(localStorage.getItem('builder-weapontypes'));
    if(field!==undefined){
        const idArray = wt.map((w) => {return w[field]});
        return idArray;
    }
    return wt;
}

export const getRoomList = (roomids, currentRoomId, loadRoom) => {
    return (
        <InputGroup className="mb-3">
            <DropdownButton
                variant="outline-secondary"
                title="Select"
                id="input-group-dropdown-1"

            >

                {roomids.map(roomid =>
                    <Dropdown.Item key={roomid} name={roomid} onClick={() => loadRoom(roomid)}>{roomid}</Dropdown.Item>
                )}

            </DropdownButton>
            <FormControl aria-label="Text input with dropdown button"
                value={currentRoomId}
                onChange={(e) => loadRoom(e.target.value)}
            />
        </InputGroup>
    )
}

export const getRoomListSimple = (roomids, currentRoomId, loadRoom) => {
    //console.log('current room ', currentRoomId);
    return (
        <Dropdown>
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentRoomId !== null ? currentRoomId : "Select Room"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {roomids.map(roomid =>
                    <Dropdown.Item key={roomid} name={roomid} onClick={loadRoom}>{roomid}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const getExitList = (currentType, selectExit) => {
    let exits = localStorage.getItem('builder-exits').split(',');
    //console.log(exits);
    return (
        <Dropdown>
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentType !== null ? currentType : "Select Exit"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {exits.map(exit =>
                    <Dropdown.Item key={exit} name={exit} onClick={() => selectExit(exit)}>{exit}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const getMobTypes = (currentType, selectType) => {
    let mobtypes = localStorage.getItem('builder-mobtypes').split(',');
    //console.log(mobtypes);
    return (
        <Dropdown>
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentType !== null ? currentType : "Select Mob Type"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {mobtypes.map(mobtype =>
                    <Dropdown.Item key={mobtype} name={mobtype} onClick={() => selectType(mobtype)}>{mobtype}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const chooseArmourTypes = (currentType, selectArmourType, layer) => {
    let armourtypes = getFilteredArmourTypes(layer);
    //console.log(armourtypes);
    return (
        <Dropdown>
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentType !== null ? currentType : "Select Armour Type"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {armourtypes.map((armourtype, index) =>
                    <Dropdown.Item key={index} name={armourtype.id} onClick={() => selectArmourType(armourtype)}>{armourtype.description}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const chooseArmourLayers = (currentLayer, selectArmourLayer) => {
    let layers = getWornLayers();
    //console.log(layers);
    return (
        <Dropdown>
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentLayer !== null ? currentLayer : "Select Armour Layer"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {layers.map((layer, index) =>
                    <Dropdown.Item key={index} name={index} onClick={() => selectArmourLayer(layer)}>{layer}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const getRoomLocales = (currentType, selectType) => {
    let locales = localStorage.getItem('builder-locales').split(',');
    //console.log(locales);
    return (
        <Dropdown >
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentType !== null ? currentType : "Select Locale Type"}
            </Dropdown.Toggle>

            <Dropdown.Menu className={"dropdown-menu-editor"}>
                {locales.map(locale =>
                    <Dropdown.Item key={locale} name={locale} onClick={() => selectType(locale)}>{locale}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const raceSelector = (currentRace, selectRace) => {
    let races = localStorage.getItem('builder-races').split(',');
    //console.log(locales);
    return (
        <Dropdown >
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentRace !== null ? currentRace : "Select Race"}
            </Dropdown.Toggle>

            <Dropdown.Menu className={"dropdown-menu-editor"}>
                {races.map(race =>
                    <Dropdown.Item key={race} name={race} onClick={() => selectRace(race)}>{race}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const genderSelector = (currentGender, selectGender) => {
    let genders = getGenders();
    console.log(genders);
    return (
        <Dropdown className={"my-2"}>
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentGender !== null ? currentGender : "Select gender"}
            </Dropdown.Toggle>

            <Dropdown.Menu className={"dropdown-menu-editor"}>
                {genders.map(gender =>
                    <Dropdown.Item key={gender} name={gender} onClick={() => selectGender(gender)}>{gender}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const roleSelector = (currentRole, selectRole) => {
    let roles = ['','NORMAL', 'GUARD', 'SLAVE', 'MAID'];
    return (
        <Dropdown className={"my-2"}>
            <Dropdown.Toggle variant="themed-dark" id="dropdown-basic">
                {currentRole !== null ? currentRole : "Select Role"}
            </Dropdown.Toggle>

            <Dropdown.Menu className={"dropdown-menu-editor"}>
                {roles.map(role =>
                    <Dropdown.Item key={role} name={role} onClick={() => selectRole(role)}>{role}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}