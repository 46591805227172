import { genericAuthenticatedDelete, genericAuthenticatedFetch, genericAuthenticatedPost, genericAuthenticatedPut } from '.';

export async function listScenes() {
    return genericAuthenticatedFetch(`scene/list`);
}

export async function getScene(scene_id) {
    return genericAuthenticatedFetch(`scene/get/${scene_id}`);
}

export async function lockScene(scene_id, lock) {
    return genericAuthenticatedPut(`scene/update/${scene_id}/lock/${lock}`);
}

export async function getSceneActivity(scene_id, activity_index) {
    return genericAuthenticatedFetch(`scene/get/${scene_id}/activity/${activity_index}`);
}

export async function updateSceneActivity(scene_id, activity_index, activity) {
    return genericAuthenticatedPost(`scene/update/${scene_id}/activity/${activity_index}`, JSON.stringify(activity));
}

export async function deleteSceneActivity(scene_id, activity_index) {
    return genericAuthenticatedDelete(`scene/delete/${scene_id}/activity/${activity_index}`);
}


export async function updateSceneBackground(scene_id, background) {
    return genericAuthenticatedPost(`scene/update/${scene_id}/background`, JSON.stringify(background));
}

export async function getSceneBackground(scene_id) {
    return genericAuthenticatedFetch(`scene/get/${scene_id}/background`);
}

export async function updateScene(scene) {
    return genericAuthenticatedPut(`scene/update/${scene.scene_id}`, JSON.stringify(scene));
}

export async function deleteScene(scene_id) {
    return genericAuthenticatedFetch(`scene/delete/${scene_id}/`);
}
