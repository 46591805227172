import React, { useEffect } from 'react';

import { useAuthState, useAuthDispatch, getUserStatus } from './components';

import NewsHeadlines from './NewsHeadlines';
import { Row, Col, Badge } from 'react-bootstrap';
import Config from '../../config/Config';
import { getStandardHeader } from './utils/FetchHeaders';
import WithRouter from './Routes/WithRouter';
import { useParams } from 'react-router-dom';


function Footer() {

	const dispatch = useAuthDispatch();
	const { mudStatus, currentUser } = useAuthState();


	const showFooter = () => {
		if (window.location.pathname.startsWith("/client")) {
			return false;
			
		} 

		if (window.location.pathname.startsWith("/builder")) {
			return false;
		}

		if (window.location.pathname.startsWith("/admin")) {
			return false;
		}

		return true;
	}

	useEffect(() => {
		if (showFooter()) {
			getStatus();

			const intervalStatus = setInterval(() => {
				getStatus();
			}, 30000);

			const intervalMessages = setInterval(() => {
				getUserStatusLocal();
			}, 120000);

			return () => {
				clearInterval(intervalStatus);
				clearInterval(intervalMessages);
			}
		}
	}, []);

	if (window.location.pathname === "/client/") {
		return null;
	}

	if (window.location.pathname === "/client") {
		return null;
	}

	const getStatus = () => {
		//console.log('Getting Status');
		const headers = getStandardHeader();
		fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/status`, { headers: headers })
			.then(res => res.json())
			.then(json => {
				dispatch({ type: 'STATUS_CHANGE', payload: json });
			}
			).catch({

			});
	}

	const getUserStatusLocal = () => {
		if (currentUser !== undefined) {
			//console.log('Updating user status');
			getUserStatus()
				.then(json => {
					//console.log("status=>", json);
					if (json === undefined) {
						//?
					} else if (json.status == "OFFLINE") {
						//?
					} else if (json.newmessages !== undefined)
						dispatch({ type: 'NEW_MESSAGES', payload: json });
				});
		}
	}

	if(!showFooter()){
		return null;
	}

	return (
		<div className="footer">
			<Row className="row fluid align-content-center">
				<Col sm>
					<div className="card h-100 footer-card" style={{ width: "18rem" }}>
						<div className="card-header footer-card-header">
							<h5>{Config.name}</h5>
						</div>
						<div className="px-2">
							<p>The Edge of Midnight is a MUD that has been running since January 1998 and has built upon several codebase before it's current one, a heavily modified Coffeemud server.</p>
							<p>
								<a className="eomLink" href="/intro/rules" title="Rules of the Game">Rules of the Game</a>
							</p>
						</div>
					</div>
				</Col>
				<Col sm>
					<div className="card footer-card h-100" >
						<div className="card-header footer-card-header">
							<h5>Recent News</h5>
						</div>
						<div className="px-2">
							<NewsHeadlines />
						</div>
					</div>
				</Col>

				<Col sm>
					<div className="card footer-card h-100" style={{ width: "18rem" }}>
						<div className="card-header footer-card-header">
							<Row>
								<Col>
									<h5 className="px-3">Connect  </h5>
								</Col>
								<Col>
									<div>	{(mudStatus !== null) && (mudStatus.status === 'OK')
										? <span className={"mx-1 px-2 badge text-bg-success"}>Online</span>
										: <span className={"mx-1 px-2 badge text-bg-danger"}>Offline</span>}
									</div>
								</Col>

							</Row>
						</div>
						<ul id="contact-info">
							<li>
								<span className="fi-chat"></span>
								<span className="field">Web:</span> <br />
								<a className="eomLink" href={"https://" + Config.middleware.host + "/client"} title="Web Client">Web Client</a>
							</li>
							<li>
								<span className="fi-laptop"></span>
								<span className="field">Telnet:</span>
								<br />
								<a className="eomLink" href={"telnet:" + Config.middleware.host + ":" + Config.mud.port + ""} title="Live Server">{Config.middleware.host + " port " + Config.mud.port}</a>
							</li>
							<li>
								<span className="fi-laptop"></span>
								<span className="field">Secure Socket:</span>
								<br />
								<a className="eomLink" href={"telnet:" + Config.middleware.host + ":" + Config.mud.port + ""} title="Live Server">{Config.middleware.host + " port " + Config.mud.secure.port}</a>
							</li>
							<li>
								<img width="15" height="15" src="https://www.freepnglogos.com/uploads/discord-logo-png/discord-logo-logodownload-download-logotipos-1.png" />
								<span className="field"> Discord Server:</span> <br />
								<a className="eomLink" href={"https://" + Config.mud.discord} title="Discord">{Config.mud.discord}</a>
							</li>

						</ul>
					</div>
				</Col>
			</Row>
			<Row>
				<p className="copyright">Copyright © 2022 The Edge of Midnight. All Rights Reserved.</p>
			</Row>
		</div>
	);
}


export default WithRouter(Footer);