import React from 'react';
import { Row, FormCheck, Dropdown, Col } from 'react-bootstrap';
import ColouredText from '../components/ColouredText';
import WearEditor from './items/WearEditor';
import BitmapField from './items/BitmapField';
import { timeConverter } from '../utils/NiceStrings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerAvatar from '../components/PlayerAvatar';

function EditableField(props) {
    //console.log(props);
    let editing = props.editing;
    let value = props.value;
    let field = props.field;
    let callDispatcher = props.callDispatcher;
    let fieldType = props.fieldType;
    let description = props.description;
    let choices = props.choices;
    let conditional = props.conditional;
    let addRow = true;

    const titleWidthsWide = "col-xs-6 col-sm-4 col-md-3 col-lg-2";
    const valueWidthsWide = "col-xs-12 col-sm-12 col-md-9 col-lg-8";
    const titleWidthsSmall = "col-xs-6 col-sm-3 col-md-1 col-lg-1";
    const valueWidthsSmall = "col-xs-12 col-sm-8 col-md-6 col-lg-2";

    let titleWidths = titleWidthsWide;
    let valueWidths = valueWidthsWide;
    if (props.addRow !== undefined) {
        addRow = props.addRow;
    }

    if (!addRow) {
        titleWidths = titleWidthsSmall;
        valueWidths = valueWidthsSmall;
    }



    const wearSlots = () => {
        return (
            <WearEditor
                editing={editing}
                value={value}
                field={field}
                transparent={'transparent'}
                callDispatcher={callDispatcher}
                description={description}
            />
        );
    }

    const bitmaps = () => {

        return (
            <BitmapField
                editing={editing}
                value={value}
                field={field}
                transparent={'transparent'}
                callDispatcher={callDispatcher}
                description={description}
                choices={choices}
            />
        );
    }

    const colourfulText = () => {
        return (<>
            <Col className={titleWidths}>
                <strong>{description}:</strong>
            </Col>
            <Col className={valueWidths}>
                {editing ?
                    <textarea rows="5" className="form-control"
                        value={value}
                        onChange={(e) => callDispatcher(field, e.currentTarget.value)} />
                    : null}
                <ColouredText ColouredText={value} />
            </Col>
        </>)
    }

    const colourfulShortText = () => {
        return (
            <>
                <Col className={titleWidths}>
                    <strong>{description}:</strong>
                </Col>
                <Col className={valueWidths}>
                    {editing ?
                        <textarea rows="1" className="form-control"
                            value={value}
                            onChange={(e) => callDispatcher(field, e.currentTarget.value)} />
                        : null}
                    <ColouredText ColouredText={value} />
                </Col>

            </>)
    }

    const basicShortText = () => {
        return (
            <>
                <Col className={titleWidths}>
                    <strong>{description}:</strong>
                </Col>
                <Col className={valueWidths}>
                    {editing ?
                        <input type="text" className="form-control"
                            value={value}
                            onChange={(e) => callDispatcher(field, e.currentTarget.value)} />
                        : value}
                </Col>

            </>)
    }

    const numberEditor = () => {
        return (
            <>
                <Col className={titleWidths}>
                    <strong>{description}:</strong>
                </Col>
                <Col className={valueWidths}>
                    {editing ?
                        <input type="number" className="form-control"
                            value={value}
                            onChange={(e) => callDispatcher(field, e.currentTarget.value)} />
                        : value}
                </Col>

            </>)
    }

    const avatarEditor = () => {
        return (
            <>
                <Col className={titleWidths}>
                    <strong>{description}:</strong>
                </Col>
                <Col className={valueWidths}>
                    <PlayerAvatar avatarlocation={value} size={50} />
                </Col>
            </>)
    }

    const maskEditor = () => {
        return (
            <>
                <strong>{editing ? <span>Editing </span> : null}{description}:</strong>
                {editing ?
                    <>
                        <FontAwesomeIcon className="mx-1 fa fa-trash fa-lg fa-eom" icon={"mars"} onClick={() => maleMask(value)} />
                        <FontAwesomeIcon className="mx-1 fa fa-trash fa-lg fa-eom" icon={"venus"} onClick={() => femaleMask(value)} />
                        <FontAwesomeIcon className="mx-1 fa fa-trash fa-lg fa-eom" icon={"transgender"} onClick={() => futaMask(value)} />
                        <FontAwesomeIcon className="mx-1 fa fa-trash fa-lg fa-eom" icon={"mercury"} onClick={() => feminineMask(value)} />
                        <input type="text" className="form-control"
                            value={value}
                            onChange={(e) => callDispatcher(field, e.currentTarget.value)} />
                    </>
                    : value}

            </>)
    }

    const maleMask = (mask) => {
        addToMask(mask, '+GENDER', 'MALE');
    }

    const femaleMask = (mask) => {
        addToMask(mask, '+GENDER', 'FEMALE');
    }

    const futaMask = (mask) => {
        addToMask(mask, '+GENDER', 'FUTANARI');
    }

    const feminineMask = (mask) => {
        addToMask(mask, '+GENDER', 'FEMALE,FUTANARI');
    }

    const addToMask = (mask, section, value) => {
        console.log('Add to mask', mask, section, value);
        if (mask.indexOf(section) >= 0) {
            let idx = mask.indexOf(section) + section.length + 1;
            let idx2 = mask.indexOf(' ', idx);
            if (idx2 < 0)
                idx2 = mask.length;
            let current = mask.substring(idx, idx2);
            // mask = mask.slice(idx,idx2);
            let part1 = mask.slice(0, idx);
            let part2 = mask.slice(idx2);

            mask = part1 + value + part2;
            console.log('Amending Mask', part1 + value + part2);

        } else {
            mask += " " + section + " " + value;
            console.log('Added to mask', mask);
        }
        callDispatcher('mask', mask.trim());
    }

    const readOnlyText = () => {
        return (
            <>
                <Col xs={2}>
                    <strong>{description}:</strong>
                </Col>
                <Col>
                    {value}
                </Col>
            </>)
    }

    const booleanField = () => {
        return (
            <>
                <Col className={titleWidths}>
                    <strong>{description}:</strong>
                </Col>
                <Col className={valueWidths}>
                    {editing ?
                        <FormCheck
                            id={field}
                            type="switch"
                            checked={value}
                            onChange={(e) => callDispatcher(field, e.currentTarget.checked)}
                            label=""
                        />
                        : <span>{value ? "True" : "False"}</span>}
                </Col>
            </>
        )
    }

    const timestampField = () => {
        return (
            <>
                <strong>{editing ? <span>Editing </span> : null}{description}: </strong> {editing ? timeConverter(value) : null}
                {editing ?
                    <input type="text" className="form-control"
                        value={value}
                        onChange={(e) => callDispatcher(field, e.currentTarget.value)} />
                    : timeConverter(value)}
            </>
        )
    }

    const sortedChoices = (choices) => {
        return choices.sort((a, b) => a.localeCompare(b));
    }

    const dropDownList = () => {
        let filteredchoices = choices(value);
        filteredchoices = sortedChoices(filteredchoices);
        //console.log(filteredchoices);
        return (
            <>
                <Col className={titleWidths}>
                    <strong>{description}:</strong>
                </Col>
                <Col className={valueWidths}>
                    {editing ?
                        <Dropdown className={"my-1"}>
                            <Dropdown.Toggle variant="editor" id="dropdown-basic">
                                {value}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={"dropdown-menu-short"}>
                                {filteredchoices.map((choice, index) =>
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => callDispatcher(field, choice)}>
                                        {choice}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        : value
                    }
                </Col>
            </>
        )
    }

    const typeAheadList = () => {
        let filteredchoices = choices(value);
        return (
            <>
                <Col className={titleWidths}>
                    <strong>{description}:</strong>
                </Col>
                <Col className={valueWidths}>

                    {editing ?
                        <Dropdown >
                            <Dropdown.Toggle variant="tool" id="dropdown-basic">
                                {editing ?
                                    <input type="text" className="form-control"
                                        value={value}
                                        onChange={(e) => callDispatcher(field, e.currentTarget.value)} />
                                    : value}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {filteredchoices.map((choice, index) =>
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => callDispatcher(field, choice)}>
                                        {choice}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        : value
                    }
                </Col>
            </>
        )
    }

    const showEditor = () => {
        switch (fieldType) {
            case 0:
                return colourfulText();
            case 1:
                return booleanField();
            case 2:
                return basicShortText();
            case 3:
                return colourfulShortText();
            case 4:
                return readOnlyText();
            case 5:
                return dropDownList();
            case 6:
                return typeAheadList();
            case 7:
                return wearSlots();
            case 8:
                return bitmaps();
            case 9:
                return timestampField();
            case 10:
                return maskEditor();
            case 11:
                return numberEditor();
            case 12:
                return avatarEditor();
            default:
                return colourfulText();
        }
    }

    if (conditional !== undefined) {
        if (conditional === false) {
            return null;
        }
    }

    if (addRow) {
        return <Row className={"py-1 row-centered"}>{showEditor()}</Row>
    } else {
        return showEditor();
    }
}



export default EditableField;