import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card, FormCheck } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import KinkEntry from './KinkEntry';

function KinkFilter(props) {

    const [filterTitle, setFilterTitle] = useState('');
    const [filterKeywords, setFilterKeywords] = useState('');
    const [filterCurrent, setFilterCurrent] = useState(true);
    const [playerKinks, setPlayerKinks] = useState();

    const [filteredKinks, setFilteredKinks] = useState([]);


    useEffect(() => {
        if (playerKinks === undefined) {
            return;
        }
        let keywords = filterKeywords.split(','); 
        let filteredKinks = props.kinks.filter(kink => {
            let nameMatched = kink.title.toLowerCase().indexOf(filterTitle) > -1;
            
            
            let activeMatched = !filterCurrent;
            if (filterCurrent) { 
                activeMatched = getRecord(kink) !== undefined; 
            }
            //If we are not filtering on keywords, this can just pass.
            let keywordMatch = keywords.length===0;
            if(nameMatched&&(keywords.length>0)) {
                //console.log("Comparing", kink.keywords, keywords);
                 //for each filter keyword, see if it is a match.
                 keywordMatch = true;
                 keywords.forEach(word =>{
                    let found = false;
                    kink.keywords.forEach(kinkKeyWord => {
                        //If it's blank, ignore it.
                        if(kinkKeyWord.length>0) {
                            if( kinkKeyWord.toLowerCase().indexOf(word) > -1)
                                found = true;
                        }
                    });
                    keywordMatch &=found;
                });
                //console.log("Comparing", kink.keywords, keywords, keywordMatch);
            }
            let matched = nameMatched & activeMatched & keywordMatch;

            return matched;
        });
        filteredKinks.sort((a, b) => a.title.localeCompare(b.title));
        setFilteredKinks(filteredKinks);
    }, [props.kinks, playerKinks, filterTitle, filterCurrent, filterKeywords]);


    useEffect(() => {
        const hashMap = props.playerKinks.reduce((result, kink) => {
            return { ...result, [kink.kink_id]: kink };
        }, {});
        setPlayerKinks(hashMap);
        //console.log(hashMap);
    }, [props.playerKinks]);

    const getRecord = (kink) => {
        let record = playerKinks[kink.id];
        return record;
    }

    return <>
        <div className={"stock-row mb-2 row-centered"}>
            <Row my={1} className={"p-1 row-centered"}>
                <Col xs={1}>
                    Filter
                </Col>
                <Col xs={3}>
                    <input value={filterTitle} type="text" className="form-control" onChange={(e) => setFilterTitle(e.currentTarget.value)} />
                </Col>
            </Row>
            <Row my={1} className={"p-1 row-centered"}>
                <Col xs={1}>
                    Keywords
                </Col>
                <Col xs={3}>
                    <input value={filterKeywords} type="text" className="form-control" onChange={(e) => setFilterKeywords(e.currentTarget.value)} />
                </Col>
            </Row>
            <Row my={1} className={"p-1 row-centered"}>
                <Col xs={1}>
                    Current
                </Col>
                <Col>
                    <FormCheck
                        id={"current"}
                        type="switch"
                        checked={filterCurrent}
                        onChange={(e) => setFilterCurrent(e.currentTarget.checked)}
                        label=""
                    />
                </Col>
            </Row>
        </div>
        <Row>
            <Col xs={4} m={2} l={1}><strong>Kink</strong></Col>
            <Col xs={4} m={3} l={2}><strong>Giving</strong></Col>
            <Col xs={4} m={3} l={2}><strong>Receiving</strong></Col>
        </Row>

        {filteredKinks.map((kink, index) => {

            let record = getRecord(kink);
            return (
                <KinkEntry key={index} kink={kink} record={record} updateKink={props.updateKink} />
            );
        })}

    </>

}

export default KinkFilter;