import * as React from 'react'

const ClientContext = React.createContext();
const ClientDispatchContext = React.createContext();

const defaultFontSize = 20;
const defaultRenderLineLimit = 200;
const defaultFontFamily = 'Inconsolata';
const defaultTitle = 'Ansible Messenger';
const defaultHide = true;
const defaultRpMode = false;

const getDefaultValue = (field, defaultValue) => {
    var stickyValue = localStorage.getItem(field);
    if (stickyValue === null) {
        stickyValue = defaultValue;
    }
    else if (stickyValue % 1 === 0) {
        //        console.log('defaulting local number ' + field, stickyValue);
        stickyValue = parseInt(stickyValue);
    } else if (stickyValue === 'true') {
        return true;
    } else if (stickyValue === 'false') {
        return false;
    }

    // else {
    //      console.log('defaulting local string ' + field, stickyValue);    
    // }
    return stickyValue;
}
getDefaultValue('fontSize', defaultFontSize);
getDefaultValue('renderLineLimit', defaultRenderLineLimit);

const defaultVitals = {
    health: 0,
    maxHealth: 0,
    mana: 0,
    maxMana: 0,
    move: 0,
    maxMove: 0,
    hunger: 0,
    thirst: 0,
    experience: 0,
    max_experience: 0,
    tnl_experience: 0,
    level: 1,
    characterclass: '',
}

const initialState = {
    fontSize: getDefaultValue('fontSize', defaultFontSize),
    fontFamily: getDefaultValue('fontFamily', defaultFontFamily),
    title: 'Ansible Messenger',
    hide: true,
    autoScroll: true,
    area: null,
    callback: null,
    currentCharacter: null,
    currentAreaData: undefined,
    vitals: defaultVitals,
    inhabitants: [],
    items: [],
    messages: [],
    channelMessages: [],
    enableswipe: false,
    rpmode: getDefaultValue('rpmode', defaultRpMode),
    renderLineLimit: getDefaultValue('renderLineLimit', defaultRenderLineLimit),
    message: ''
};

function useClientDispatch() {
    const context = React.useContext(ClientDispatchContext);
    if (context === undefined) {
        throw new Error("useClientDispatch must be used within a ClientProvider");
    }
    return context;
}

const clientReducer = (initialState, action) => {

    switch (action.type) {
        case "SET_RPMODE": {
            return {
                ...initialState,
                rpmode: action.payload
            }
        }
        case "SET_MESSAGE": {
            return {
                ...initialState,
                message: action.payload
            }
        }
        case "ADD_MESSAGE": {
            return {
                ...initialState,
                messages: [...initialState.messages.slice(-300), action.payload]
            }
        }
        case "ADD_CHANNELMESSAGE": {


            return {
                ...initialState,
                channelMessages: [...initialState.channelMessages.slice(-50), action.payload]
            }
        }

        case "CLEAR_MESSAGES": {
            return {
                ...initialState,
                messages: [],
                channelMessages: []
            }
        }

        case "SET_FONT":
            return {
                ...initialState,
                fontFamily: action.payload
            }

        case "SET_FONTSIZE":
            return {
                ...initialState,
                fontSize: action.payload
            }
        case "SET_RENDERLINELIMIT":
            return {
                ...initialState,
                renderLineLimit : action.payload
            }
        case "SET_TITLE":
            return {
                ...initialState,
                title: action.payload
            }

        case "SET_AREADATA":
            return {
                ...initialState,
                currentAreaData: action.payload
            }
        case "SET_ROOMINHABITANTS":
            return {
                ...initialState,
                inhabitants: action.payload
            }
        case "SET_ROOMITEMS":
            return {
                ...initialState,
                items: action.payload
            }
        case "SET_CHARACTER":
            return {
                ...initialState,
                currentCharacter: action.payload
            }
        case "SET_CALLBACK":
            return {
                ...initialState,
                callback: action.payload
            }
        case "SET_VITALS":
            return {
                ...initialState,
                vitals: action.payload
            }
        case "SET_SWIPE":
            return {
                ...initialState,
                enableswipe: action.payload
            }
        case "SET_AUTOSCROLL":
            return {
                ...initialState,
                autoScroll: action.payload
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

function ClientProvider({ children }) {
    const [state, dispatch] = React.useReducer(clientReducer, initialState);
    return <ClientContext.Provider value={state}>
        <ClientDispatchContext.Provider value={dispatch}>
            {children}
        </ClientDispatchContext.Provider>
    </ClientContext.Provider>
}

function useClientContext() {
    const context = React.useContext(ClientContext)
    if (context === undefined) {
        throw new Error('useClientContext must be used within a ItemProvider')
    }
    return context
}

export { ClientProvider, useClientContext, useClientDispatch }
