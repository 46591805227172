import React from 'react';
import {genericAuthenticatedFetch, genericAuthenticatedPut, genericAuthenticatedPost} from './index';

export async function getBuilderAreaData(areaName) {
  return genericAuthenticatedFetch('builder/area/get/' +  encodeURIComponent(areaName));
}

export async function setAreaLockdown(areaName, status) {
  return genericAuthenticatedFetch('builder/area/lockdown/' + encodeURIComponent(areaName) + '/' + status);
}

export async function setBuilderAreaData(areaData) {
  //console.log('setBuilderAreaData', areaData);
  return genericAuthenticatedPost('builder/area/update' , areaData);
}

export async function setBuilderMobData(mobData) {
  //console.log('setBuilderMobData', mobData);
  let roomid = encodeURIComponent(mobData.sourceRoomId);
  return genericAuthenticatedPost(`builder/room/mob/update/${roomid}` , JSON.stringify(mobData));
}

export async function setBuilderRoomData(roomData) {
  //console.log('setBuilderRoomData', roomData);
  let escapedRoomId = encodeURIComponent(roomData.id);
  return genericAuthenticatedPost(`builder/room/update/${escapedRoomId}` , JSON.stringify(roomData));
}

export async function setBuilderItemData(itemData) {
  //console.log('setBuilderItemData',itemData);
  return genericAuthenticatedPost('builder/item/update' , JSON.stringify( itemData));
}

export async function setBuilderRoomExit(exitData) {
  //console.log('setBuilderExitData', exitData);
  return genericAuthenticatedPut('builder/exit/update' , JSON.stringify(exitData));
}

export async function deleteBuilderMobData(mobData) {
  //console.log('setBuilderMobData');
  return genericAuthenticatedPost('builder/mob/delete' ,JSON.stringify( mobData));
}

export async function deleteBuilderItemData(itemData) {
  //console.log('setBuilderMobData');
  return genericAuthenticatedPost('builder/item/delete' , JSON.stringify(itemData));
}

export async function getBuilderRoomData(roomName) {
  let escapedRoomId = encodeURIComponent(roomName);
  return genericAuthenticatedFetch(`builder/room/get/${escapedRoomId}`, );
}

export async function getBuilderItemTpsReport(itemData) {
  return genericAuthenticatedPost(`builder/item/get/tps`,  JSON.stringify(itemData));
}

export async function deleteRoomData(roomName) {
  let escapedRoomId = encodeURIComponent(roomName);
  return genericAuthenticatedFetch(`builder/room/delete/${escapedRoomId}`, );
}

export async function unlinkExitInDirection(roomid, direction) {
  let escapedRoomId = encodeURIComponent(roomid);
  return genericAuthenticatedFetch(`builder/exit/unlink/${escapedRoomId}/${direction}`, );  
}

export async function linkRoomsInDirection(startroomid, endroomid, direction) {
  let escapedStartRoomId = encodeURIComponent(startroomid);
  let escapedEndRoomId = encodeURIComponent(endroomid);
  return genericAuthenticatedFetch(`builder/exit/link/${escapedStartRoomId}/${escapedEndRoomId}/${direction}`, );  
}

export async function createRoomForArea(areaName) {
  let escapedAreaName = encodeURIComponent(areaName);
  return genericAuthenticatedPost(`builder/room/create/${escapedAreaName}`);
}

export async function createItem(itemType) {
  let escapeditemType = encodeURIComponent(itemType);
  return genericAuthenticatedFetch(`builder/item/create/${escapeditemType}`, );
}

export async function createRoomFromSource(roomid, direction) {
  let escapedroomid = encodeURIComponent(roomid);

  return genericAuthenticatedPost(`builder/room/create/${escapedroomid}/${direction}` );
}



export async function getBuilderConfig() {  
  return genericAuthenticatedFetch('builder/config' );
}

export async function getScriptList() {  
  return genericAuthenticatedFetch('builder/scripts/list' );
}

export async function getBuilderMobData(roomname, mobname, databaseid) {
  let escapedRoomId = encodeURIComponent(roomname);
  let escapedMobId = encodeURIComponent(mobname);
  let escapedDatabaseId = encodeURIComponent(databaseid);
  
  return genericAuthenticatedFetch(`builder/room/mob?roomid=${escapedRoomId}&mobname=${escapedMobId}&dbid=${escapedDatabaseId}` );
}

export async function getBuilderRoomItemData(roomname, itemid) {
  let escapedRoomId = encodeURIComponent(roomname);
  let escapedItemId = encodeURIComponent(itemid);
  
  return genericAuthenticatedFetch(`builder/room/item?roomid=${escapedRoomId}&itemid=${escapedItemId}` );
}

export async function getBuilderMobItemData(roomname, mobname, databaseid, itemid) {
  let escapedRoomId = encodeURIComponent(roomname);
  let escapedMobId = encodeURIComponent(mobname);
  let escapedItemId = encodeURIComponent(itemid);
  let escapedDatabaseId = encodeURIComponent(databaseid);
  
  return genericAuthenticatedFetch(`builder/room/mob/item?roomid=${escapedRoomId}&mobname=${escapedMobId}&dbid=${escapedDatabaseId}&itemid=${escapedItemId}` );
}

export async function getScript(path, name) {
  let escapedPath = encodeURIComponent(path);
  let escapedName = encodeURIComponent(name);
  
  return genericAuthenticatedFetch(`builder/scripts/get?path=${escapedPath}&name=${escapedName}` );
}

export async function deleteScript(path, name) {
  let escapedPath = encodeURIComponent(path);
  let escapedName = encodeURIComponent(name);
  
  return genericAuthenticatedFetch(`builder/scripts/delete?path=${escapedPath}&name=${escapedName}` );
}

export async function setScript(scriptData) {
  console.log('setScript');
  return genericAuthenticatedPut('builder/script/put' , scriptData);
}

