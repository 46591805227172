import React from "react";


export default class ImageComponent extends React.Component {
    state = { isOpen: false };

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        return (
            <div>
                <img
                    className="map_image"
                    src={this.props.image}
                    onClick={this.handleShowDialog}
                    alt="no image"
                />
                {this.state.isOpen && (
                    <dialog
                        className="dialog-map"
                        open
                        onClick={this.handleShowDialog}
                    >
                        <img
                            className="map_image_big"
                            src={this.props.image}
                            onClick={this.handleShowDialog}
                            alt="no image"
                        />
                    </dialog>
                )}
            </div>
        );
    }
}
