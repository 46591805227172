import React, { Component } from 'react';

class IntroRules extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">
        <br/>
        <h1>General Rules <small>Rules to live by</small></h1>
        <br/>

	
    		<p><strong>Common Courtesy</strong> Do not allow in-character conflicts to taint out-of-chracter relations. Any instances of OOC threats/insults/racism or otherwise derogatory comments will not be treated lightly and will most likely result in a permanent ban from the game. See HELP OOC and HELP IC. </p>
			
			<p><strong>RPXP</strong> Do not attempt to abuse the RPXP functionality of EOM. You may gain experience for interaction/communication, but there is an automatic control to detect misuse. </p>

			<p><strong>Sexual Harassment</strong> The penalties are severe. If you are role-playing a non-consensual situation, make sure all parties involved are perfectly aware of this and have given their consent (Implicit consent can be given in your roleplay sheet).</p> 

			<p><strong>Spamming</strong> Please do not interrupt the atmosphere of the game via spam of commands, dropping or picking up inventory, or excessive spell usage. This is especially important in high traffic areas. </p>

			<p><strong>Playerkilling</strong> AFK characters are not to be stolen from or killed. This is taking advantage of an OOC situation for IC gain. Keep PK to a minimum to prevent grief, and be sure that you have a very good reason for it. Nobles may ask you for your reasons at any time. </p>

			<p><strong>Fun</strong> Everyone is here to have fun. Maliciously destroying a role-playing situation ruins the fun of others, so don't do it.</p>

			<p><strong>Short Descriptions</strong> Everyone must set their short description reasonably soon after creating a character. Nobles and players will remind you to set your short description. Ask for help if the helpfile on SHORT is not sufficient.</p> 

			<p><strong>Multiplay</strong> You may have multiple characters logged in at once. However, they may not help each other in any way. We do log. You will be discovered.</p>
            <p>If you wish to roleplay a scene with your own characters must turn <strong>Roleplaying experience (RPXP)</strong> off on all but one of your characters.</p> 

			<p><strong>Summoning</strong> Do not summon or drag mobs into towns, inns, or other central locations for role-playing. Do not open portals to high-level areas without removing them afterward. 

			</p><p><strong>Community</strong> We like to promote a community, instead of a random assortment of players. This means you should do your utmost to get along with each other on the public and private communication channels available in EOM. This also means that honesty is key. It is better to remain mysterious than to give a false answer to an OOC question. 

		</p><p><strong>Staff</strong> They will not do you favours, so do not ask. Disrespect will be tolerated in any format, so remember to be polite when paging a member of staff. You may contact them privately over any concerns that you may have and they will assist you as they see fit. </p>

		<p><strong>Botting/Triggers</strong> You may not for any circumstance set up a series of commands and triggers to play your character for you. You may create the equivalent of 'buttons' for common commands, but you MUST be able to respond to a member of staff if they send a tell to you in a reasonable amount of time. If your character is performing actions autonomously, you will be disconnected. On the third offense, you shall be banned.</p> 
		
        <p><strong>Fairplay</strong> This mud is a fairplay mud that means killstealing and or player corpse stealing is frowned upon and person by person punishments will be given out. </p>

		<p><strong>Shopkeepers &amp; Judges</strong> Do not NOT kill judges or shopkeepers - the mud engine dislikes it intensely and what the mud engine dislikes so too do the staff and Pyros.</p>

		<h1 id="policies">Policies <small>keeping the realm running smoothly</small></h1>
        <p><strong>Sexual roleplay</strong> is an integral part of the mud's fabric and a driving reason why many of the players are here - it is a safe haven to explore fantasies and ideas and act out desires that you may not for whatever reason be able to do in the real world.</p>
             
        <p><strong>Consent</strong> There are no specific rules around IC consent - players can play out whatever scenes they wish but it is down to the players involved to make sure that everyone involved is happy with the RP and the direction it is taking and that you are prepared for your character to face any IC consequences for their actions. </p>
             
 		<p>IC Age however has some more stringent requirements around it where a character is deemed anything less than a young-adult it is not permissible to have public kinks, or sexual content in your roleplaying sheet or character descriptions and SRP is absolutely forbidden.</p>

		<p><strong>Requests</strong> Do not ask immortals for spellups, restores or hotreboots, these are totally at immortal discretion.</p> 

		<p><strong>Autocracy</strong> This mud or no other that comes to mind is a democracy, all control rests with the immortals, if ANY immortal, Pyros, Vella or any other immortal we add to the game, says no to something or tells you a certain way things are, you do not talk back or argue about it on open channel, you are allowed to tell that immortal politely of your concerns in tells.</p>

		<p><strong>Fairness</strong> This is a very fair mud and we look after our players, but I have noticed some players taking liberties with certain gods/immortals and I have noticed people going to another immortal if they don't like the answer from one, if I ever see it again I will deal with them severely. </p>
        
        <p><strong>Punishment</strong> Please be aware that breaking any or all of these rules will leave you answerable to the staff.</p>
        <p>Punishments include, but are not limited to</p><p>
        </p><ul>
            <li>a warning</li>
            <li>revoking your abilities to use channels.</li>
            <li>sending you to an inescapable room where you cannot use channels</li> 
            <li>banning you</li> 
        </ul>
        <p>Your presence here is a privilege and not a right, therefore the staff reserve the right to keep the overall peace</p>
	
      </div>
    );
  }
}

export default IntroRules;