import React from 'react';
import { Navigate, Route, redirect } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const tokenNotEmpty = () => {
    let localToken = localStorage.getItem('token');
    return (localToken !== undefined) && (localToken !== null);
  }
  
  return tokenNotEmpty() ? children : <Navigate to="/unauthorised" />
};

export default PrivateRoute;