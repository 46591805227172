import React, { useState, useEffect, useRef } from 'react';
import { removeColourCodes } from '../utils/ColourMap';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Stage, Layer, Line, Text, Group, Canvas } from 'react-konva';
import { createRoomForArea } from '../components/builderRequests';

const directions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const getDirection = (code) => {
    switch (code) {
        case 0: //North
            return "N";
        case 1: //South
            return "S";
        case 2: //East
            return "E";
        case 3: //West
            return "W";
        case 4: //Up
            return "U";
        case 5: //Down
            return "D";
        case 6: //Down
            return "G";
        case 7: //Northeast
            return "NE";
        case 8: //Northwest
            return "NW";
        case 9: //Southeast
            return "SE";
        case 10: //Souhwest
            return "SW";
        case 11: //Enter
            return "E";
        case 12: //Leave
            return "L";

        default:
            return "Z";
    }
}

function RoomLayout(props) {

    const stageRef = useRef();
    const editing = props.editing;
    let selectRoom = props.selectRoom;
    let createRoom = props.createRoom;
    let linkRooms = props.linkRooms;

    const [stageScale, setStageScale] = useState(1.0);
    const [stageX, setStageX] = useState(0);
    const [stageY, setStageY] = useState(0);
    const [oldRoomId, setOldRoomId] = useState();
    const [currentRoom, setCurrentRoom] = useState();

    var mapwidth = 600;
    var mapheight = 600;

    if (props.mapwidth !== undefined)
        mapwidth = props.mapwidth;
    if (props.mapheight !== undefined)
        mapheight = props.mapheight;

    const w = 70;
    const w2 = 20;
    const linkedRoomWidth = 45;

    const mainRoomPositionX = 225;
    const mainRoomPositionY = mapheight / 2.6;

    let offsetX = 0;
    let offsetY = 0;
    let roomColor = 'white';
    let borderColour = 'silver';
    let emptyRoomColour = 'Gray';
    let textColour = 'white';
    let exitColour = 'DarkCyan';
    let usedExitColour = 'Cyan';
    let emptyRoomBackground = '#1e1e1e';

    let borderWidth = 1;

    const hasExit = (direction) => {
        let matching = currentRoom.exits.filter(exit => exit.code === direction);
        return matching.length > 0;
    }

    const getExit = (direction) => {
        let matching = currentRoom.exits.find(exit => exit.code === direction);
        return matching;
    }

    useEffect(() => {
        setCurrentRoom(props.currentRoom);
    }, [props.currentRoom]);

    useEffect(() => {
        if (currentRoom !== undefined)
            setOldRoomId(currentRoom.id);
    }, [currentRoom]);

    const showRoom = (x, y, mobcount) => {

        return (
            <Group key={currentRoom.id}>
                <Line id={currentRoom.id} points={[
                    x - w, y - w,
                    x + w, y - w,
                    x + w, y + w,
                    x - w, y + w,
                    x - w, y - w
                ]} fill={roomColor} stroke={roomColor} strokeWidth={borderWidth * 4} />
                <Text x={x - w + 8} y={y - w + 8}
                    key="title"
                    fontSize={18}
                    wrap="word"
                    width={w * 2}
                    text={removeColourCodes(currentRoom.display)}
                    stroke={textColour}
                    strokeWidth={0}
                    align="left"
                    fontStyle="normal"
                    fontFamily='Calibri'
                    fill={textColour}
                    strokeScaleEnabled={false}
                />

                <Text x={x - w - 4} y={y + (w / 2)}
                    key="id"
                    fontSize={16}
                    wrap="word"
                    width={w * 2}
                    text={removeColourCodes(currentRoom.id)}
                    stroke={textColour}
                    strokeWidth={0}
                    align="right"
                    fontStyle="normal"
                    fontFamily='Calibri'
                    fill={textColour}
                    strokeScaleEnabled={false}
                />
            </Group>)
    }

    const showExit = (x, y, direction) => {
        //console.log(`Showing exit ${x}/${y} in ${direction} for ${w2}`)
        let location = calculateRelativeCoordinate(x, y, direction, w2, 0);
        //console.log(`Showing exit at `, location);
        let exitUsed = hasExit(direction);
        let exitBorderColour = exitColour;
        let exitBorderWidth = borderWidth;
        if (exitUsed) {
            exitBorderColour = usedExitColour;
            exitBorderWidth = borderWidth * 2;
        }

        return (<Group key={"exit-" + direction}><Line id={currentRoom.id} points={[
            location.x - w2, location.y - w2,
            location.x + w2, location.y - w2,
            location.x + w2, location.y + w2,
            location.x - w2, location.y + w2,
            location.x - w2, location.y - w2
        ]}
            dash={hasExit(direction) ? [] : [5, 5]} fill={exitBorderColour}
            closed onClick={() => linkRooms(direction)}
            stroke={exitBorderColour} strokeWidth={exitBorderWidth} />

            <Text key={direction} x={location.x - w2} y={location.y}
                fontSize={14}
                wrap="word"
                width={w2 * 2}
                text={getDirection(direction)}
                stroke={textColour}
                strokeWidth={0}
                align="center"
                fontStyle="normal"
                fontFamily='Calibri'
                fill={textColour}
                strokeScaleEnabled={false}
            />
        </Group>)
    }

    const clickHandler = (e) => {

        if (!editing) {
            if (e.target.attrs.id !== undefined) {
                //console.log('Navigate to Room', e.target.attrs.id);
                selectRoom(e.target.attrs.id);
            } else {
                if (window.confirm('Are you sure you wish to create a room?')){
                    let dir = parseInt(e.target.attrs.direction);
                    //console.log('Create Room from ', dir);
                    createRoom(currentRoom.id, dir);
                }
            }
        }
    };

    const showLinkedRoom = (x, y, direction) => {
        //console.log(`Showing Linked Room ${x}/${y} in ${direction} for ${linkedRoomWidth}`)
        let location = calculateRelativeCoordinate(x, y, direction, linkedRoomWidth, w2 * 2);
        //console.log(location);

        let foundExit = getExit(direction);
        // console.log('Exit is', foundExit);
        let roomId = undefined;
        let exitUsed = foundExit !== undefined;
        let linkedBorderColour = emptyRoomColour;
        let linkedEmptyRoomBackground = "black";
        if (exitUsed) {
            linkedBorderColour = roomColor;
            roomId = foundExit.destination;
            linkedEmptyRoomBackground = emptyRoomBackground;
        }

        return (
            <Group key={'room-' + direction} >
                <Line
                    id={roomId}
                    points={[
                        location.x - linkedRoomWidth, location.y - linkedRoomWidth,
                        location.x + linkedRoomWidth, location.y - linkedRoomWidth,
                        location.x + linkedRoomWidth, location.y + linkedRoomWidth,
                        location.x - linkedRoomWidth, location.y + linkedRoomWidth,
                        location.x - linkedRoomWidth, location.y - linkedRoomWidth
                    ]}
                    closed
                    dash={exitUsed ? [] : [5, 5]} fill={linkedEmptyRoomBackground} stroke={linkedBorderColour} strokeWidth={borderWidth} onClick={clickHandler} direction={direction} />

                {exitUsed ? <>
                    <Text x={location.x - linkedRoomWidth + 4} y={location.y - linkedRoomWidth + 8}
                        key="title"
                        fontSize={12}
                        wrap="word"
                        width={linkedRoomWidth * 1.9}
                        text={removeColourCodes(foundExit.destinationdisplay)}
                        stroke={textColour}
                        strokeWidth={0}
                        align="left"
                        fontStyle="normal"
                        fontFamily='Calibri'
                        fill={textColour}
                        strokeScaleEnabled={false}
                    />

                    <Text x={location.x - linkedRoomWidth - 4} y={location.y + (linkedRoomWidth / 4)}
                        key="id"
                        id={foundExit.destination}
                        fontSize={12}
                        wrap="word"
                        width={linkedRoomWidth * 2}
                        text={removeColourCodes(foundExit.destination)}
                        stroke={textColour}
                        strokeWidth={0}
                        align="right"
                        fontStyle="normal"
                        fontFamily='Calibri'
                        fill={textColour}
                        strokeScaleEnabled={false}
                        onClick={clickHandler}
                    />
                </>
                    : null}
            </Group>)
    }

    const calculateRelativeCoordinate = (x, y, direction, size, offset) => {
        let point = {};
        point.x = x;
        point.y = y;

        switch (direction) {
            case 0: //North
                point.y = point.y - w - size - offset;
                break;
            case 1: //South
                point.y = point.y + w + size + offset;
                break;
            case 2: //East
                point.x = point.x + w + size + offset;
                break;
            case 3: //West
                point.x = point.x - w - size - offset;
                break;
            case 4: //Up
                point.x = point.x + (w * 3) + w2 + size + offset;
                point.y = point.y - (w) - size - offset;
                break;

            case 5: //Down
                point.x = point.x + (w * 3) + w2 + size + offset;
                point.y = point.y + (w) + size + offset;
                break;

            case 6: //Gate
                point.x = point.x + (w * 3) + w2 + size + offset;
                break;

            case 7: //Northeast
                point.x = point.x + w + size + offset;
                point.y = point.y - w - size - offset;
                break;
            case 8: //Northwest
                point.x = point.x - w - size - offset;
                point.y = point.y - w - size - offset;
                break;
            case 9: //Southeast
                point.x = point.x + w + size + offset;
                point.y = point.y + w + size + offset;
                break;
            case 10: //Souhwest
                point.x = point.x - w - size - offset;
                point.y = point.y + w + size + offset;
                break;
            case 11: //Enter
                point.x = point.x - w - size - offset;
                point.y = point.y + (w * 3) + size + offset;
                break;
            case 12: //Leave
                point.x = point.x + w + size + offset;
                point.y = point.y + (w * 3) + size + offset;
                break;

            default:
                break;
        }

        return point;
    }

    if (currentRoom === undefined) {
        return null;
    }

    //console.log('calculateRelativeCoordinate', calculateRelativeCoordinate(mainRoomPosition, mainRoomPosition, 0, w2));
    //console.log('calculateRelativeCoordinate', calculateRelativeCoordinate(mainRoomPosition, mainRoomPosition, 1, w2));
    //console.log(currentRoom);
    return (
        <div className={"map-container"}>
            <Stage
                width={mapwidth} height={mapheight} offset={{ x: offsetX, y: offsetY }}
                scaleX={stageScale}
                scaleY={stageScale}
                x={stageX}
                y={stageY}
                
            >
                <Layer>
                    {directions.map((direction) =>
                        showExit(mainRoomPositionX, mainRoomPositionY, direction)

                    )}
                </Layer>
                <Layer>
                    {directions.map((direction) =>
                        showLinkedRoom(mainRoomPositionX, mainRoomPositionY, direction)
                    )}
                </Layer>
                <Layer ref={stageRef}>

                    {showRoom(mainRoomPositionX, mainRoomPositionY, currentRoom.mobcount)}
                </Layer>


            </Stage>
        </div>

    )
}

export default RoomLayout;