import { getProfessions, getProducts, getSchematics, getSchematicData, updateProfessionProducts } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import ProfessionEditor from './ProfessionEditor';
import { useProfessionDispatch, ProfessionProvider } from './ProfessionProvider';

function BuilderProfessionEditorMain() {
    return (<ProfessionProvider><ProfessionEditorMain /></ProfessionProvider>);
}

function ProfessionEditorMain() {
    const [profession, setProfession] = useState(null);
    const [professions, setProfessions] = useState([]);
    
    const providerDispatch = useProfessionDispatch();

    const callDispatcher = (profession => {
         console.log('Dispatching', profession);
         providerDispatch({ type: "SET_PROFESSION", payload: profession });
         providerDispatch({ type: "SORT_PRODUCTS", payload: profession });
         providerDispatch({ type: "SET_SCHEMATIC_LIST", payload: [] });
    });

    const getProfessionList = async () => {
        let data = await getProfessions();
        //console.log('Profession Data', data);
        if (data.result) {
            //console.log('Profession Data', data.professions);

            setProfessions(data.professions);
        } else {
            setProfessions([]);
        }
    }

    const getProductList = async (product_id) => {
        let data = await getProducts(product_id);
        //console.log('Product Data', data);
        if (data.result) {
            console.log('Product Data', data);
            callDispatcher(data);
        } else {
            callDispatcher(null);
        }
    }

    const undoChanges = (product_id) => {
        console.log('Undoing changes', product_id);
        getProductList(product_id);
    }

    const selectProfession = async (profession_id) => {
        setProfession(profession_id);
    }

    useEffect(() => {
        getProfessionList();
    }, []);

    useEffect(() => {
        console.log('Selected', profession);
        getProductList(profession);
    }, [profession]);



    return (
        <div className="container-eom" id="divMainContent">
            <Row>
                <Col xs={2}>
                    <Row className={"professionheader"}>
                         <strong>Professsions</strong>
                    </Row>

                    {professions.map((profession, index) =>
                        <Row key={index} onClick={() => selectProfession(profession.profession_id)}>
                            {profession.profession_name}
                        </Row>
                    )}
                </Col>
                <Col xs={7}>
                  <ProfessionEditor undoChanges={undoChanges}/>                    
                </Col>
            </Row>
        </div>
    );
}

export default BuilderProfessionEditorMain;