import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getWebContentList, getWebContent, saveWebContent, getWebContentTypes } from '../components/webContentRequests';
import EditableField from '../builder/EditableField';
import MarkdownEditor from '../components/MarkdownEditor';


function WebContentSubmission() {
    const [contentData, setContentData] = useState(undefined);
    const [contentList, setContentList] = useState([]);
    const [metadata, setMetadata] = useState({});
    const [editing, setEditing] = useState(false);
    const [currentCategory, setCurrentCategory] = useState('');


    useEffect(() => {
        if (contentList.length === 0)
            getStoryList();
        setMetadata({});
        setContentData();
        setEditing(false);
    }, [currentCategory]);

    const loadContentFromId = (id) => {
        getWebContent(id).then(data => {
            //console.log('Received', data);
            setMetadata(data);
            setContentData(data.content_text);
        })
    }

    const getStoryList = async e => {
        const data = await getWebContentList(currentCategory);
        if (data.result) {
            data.content.sort((a, b) => b.content_publishdate - a.content_publishdate);
            setContentList(data.content);
        } else {
            //setContentList([]);
        }
    }

    useEffect(() => {
        getStoryList();
    }, [currentCategory])



    const saveStory = (content) => {
        saveWebContent(metadata, content).then(data => {
            //console.log('Saved' ,data); 
            if (data.result) {
                setEditing(false);
                getStoryList();
                setMetadata(data.data);
            } else {
                window.alert('Could not save contentData.');
            }
        }).catch(error => {
            console.log(error);
            window.alert('Could not save contentData.');
        });
    }

    const markdownSave = (content) => {
        setContentData(content);
        saveStory(content);
    }

    const createStory = () => {
        var newmetadata = {
            content_owner: localStorage.getItem('currentUser'),
            content_title: '',
            content_category: currentCategory,
            content_id: -1,
            content_text: '',
            content_status: 'DRAFT',
            content_publishdate: Date.now()
        };

        setMetadata(newmetadata);
        setContentData('');
        setEditing(true);
    }

    const callDispatcher = (fieldName, newValue) => {
        metadata[fieldName] = newValue;
        setMetadata({ ...metadata });
    }

    const canAdminStories = () => {
        let roles = localStorage.getItem('roles');
        if (roles === null)
            roles = [];

        return roles.indexOf('webadmin') > -1;
    }

    const canChangeStatus = () => {
        if (canAdminStories()) {
            return true;
        } else {
            return metadata.content_status !== 'APPROVED';
        }
    }

    const getValidStatuses = () => {
        if (canAdminStories())
            return ['DRAFT', 'PENDING', 'SUBMITTED', 'APPROVED', 'REJECTED', 'DELETED'];
        else
            return ['DRAFT', 'PENDING', 'SUBMITTED', 'DELETED'];
    }

    const getCurrentPlayer = () => {

        if (canAdminStories()) {
            return (<>
                <Dropdown >
                    <Dropdown.Toggle variant="tool" id="dropdown-basic" size="sm">
                        Select Content Type
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {getWebContentTypes().map((contentType, index) => {
                            return <Dropdown.Item key={index} onClick={() => setCurrentCategory(contentType.id)}>{contentType.title}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>

                <input type="text" className="form-control"
                    value={currentCategory}
                    onChange={(e) => {
                        setCurrentCategory(e.currentTarget.value)
                    }
                    } />
                <FontAwesomeIcon onClick={() => getStoryList()} className={"fa fa-lg m-2 fa-eom"} icon={"undo"} />
            </>
            )
        } else {
            return <h3>{currentCategory} Stories</h3>
        }

    }
    return (
        <Container fluid>

            <Row className="container-eom" id="divMainContent">

                <Col xs="2">
                    <Row>
                        {getCurrentPlayer()}

                    </Row>
                    {contentList.map(contentData =>
                        <Row key={contentData.content_id} name={contentData.content_id} className={"py-2"} onClick={() => loadContentFromId(contentData.content_id)}>
                            {contentData.content_category} - {contentData.content_title}
                        </Row>

                    )}
                    <Row>
                        <Button onClick={createStory}>Create New Story</Button>
                    </Row>
                </Col>
                {contentData === undefined ? null :
                    <Col>
                        <Row>
                            <h2>{metadata.content_owner} - {metadata.content_title}</h2>
                            <FontAwesomeIcon onClick={() => setEditing(prev => !prev)} className={"ml-5 fa fa-lg m-2 fa-eom"} icon={"edit"} />
                        </Row>
                        {canChangeStatus()
                            ?
                            <EditableField editing={editing}
                                field={"content_status"}
                                value={metadata.content_status}
                                callDispatcher={callDispatcher}
                                description="Status"
                                fieldType={5}
                                choices={getValidStatuses} />
                            : <Row>
                                <strong>Status:</strong>
                                <span>{metadata.content_status}</span>
                            </Row>
                        }

                        <Row >
                            <strong>Story Id:</strong>
                            <span>{metadata.content_id}</span>
                        </Row>

                        <EditableField editing={editing}
                            field={"content_owner"}
                            value={metadata.content_owner}
                            callDispatcher={callDispatcher}
                            description="Owner"
                            fieldType={2} />

                        <EditableField editing={editing}
                            field={"content_title"}
                            value={metadata.content_title}
                            callDispatcher={callDispatcher}
                            description="Title"
                            fieldType={2} />

                        <EditableField editing={editing}
                            field={"content_category"}
                            value={metadata.content_category}
                            callDispatcher={callDispatcher}
                            description="Category"
                            fieldType={2} />

                        <EditableField editing={editing}
                            field={"content_publishdate"}
                            value={metadata.content_publishdate}
                            callDispatcher={callDispatcher}
                            description="Date Published"
                            fieldType={9} />
                        <Row>
                            <MarkdownEditor documentToEdit={contentData} editing={editing} saveDocument={markdownSave} noCodeBlocks={false}/>
                        </Row>
                    </Col>}
            </Row>

        </Container>
    )
}

export default WebContentSubmission;