import React from 'react';
import Config from '../../../config/Config';
import {getStandardHeader, getAuthenticatedHeader} from '../utils/FetchHeaders';
 
export async function listChannels(dispatch, loginPayload) {
  try {
    
    const headers = getAuthenticatedHeader();

    //console.log(headers);
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/channels/list`, { headers: headers });
    let data = await response.json();
    //console.log(data);
 
    if (data.result) { 
      return data
    }
  } catch (error) {
    return {}
  }
}

export async function getChannelMessages(channelName, page, pagesize) {
    try {
      
      const headers = getAuthenticatedHeader();
  
      //console.log(headers);
      let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/channels/backlog/` + channelName +'/' + page + '/' + pagesize, { headers: headers });
      let data = await response.json();
     // console.log(data);
      return data;
    } catch (error) {
     // console.log(error);
    }
}