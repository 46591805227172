import * as React from 'react'

const ItemContext = React.createContext();
const ItemDispatchContext = React.createContext();

const initialState = {
    currentItemData: null,
    originalItem: null,
    error: ''
};

function useItemDispatch() {
    const context = React.useContext(ItemDispatchContext);
    if (context === undefined) {
        throw new Error("useItemDispatch must be used within an ItemProvider");
    }
    return context;
}

const exitReducer = (initialState, action) => {
    let newItemData = { ...initialState.currentItemData };
    switch (action.type) {
        case "SELECT_CURRENTITEM":
            return {
                ...initialState,
                currentItemData: action.payload
            }
        case "SET_FIELD":
            //console.log(action);
            newItemData[action.field] = action.payload;
            return {
                ...initialState,
                currentItemData: newItemData
            }
        case "SET_CRAFTEDFIELD":
            newItemData.crafted[action.field] = action.payload;
            return {
                ...initialState,
                currentItemData: newItemData
            }       
        case "SET_ARMOURFIELD":
            console.log(action);
            newItemData.armour[action.field] = action.payload;
            return {
                ...initialState,
                currentItemData: newItemData
            }
        case 'SET_AFFECT': {

            let affects = newItemData[action.field];
            console.log('data-2', affects);
            console.log('action', action.payload);
            affects[action.payload.index].id = action.payload.id;
            affects[action.payload.index].params = action.payload.params;
            newItemData[action.field] = affects;
            // currentItemData.effects[effectIndex].id = effectId;
            // currentItemData.effects[effectIndex].params = effectParameters;

            //exitDispatch({ type: "SET_AFFECT", field: affectField, payload: updateAffect });

            return {
                ...initialState,
                currentItemData: newItemData
            }
        }
        case "SELECT_ORIGINALITEM":
            return {
                ...initialState,
                originalItem: action.payload
            }
        case "SELECT_ERROR":
            return {
                ...initialState,
                error: action.payload
            }
        case 'DELETE_AFFECT': {
            console.log('data-1', newItemData);
            //currentItemData.behaviours = currentItemData.behaviours.splice(effectIndex, 1);
            let behaviours = newItemData[action.field];
            console.log('data-2', behaviours);
            behaviours.splice(action.payload, 1);

            newItemData[action.field] = behaviours;//newItemData[action.field].splice(action.payload, 1);
            console.log('data-3', newItemData);
            return {
                ...initialState,
                currentItemData: newItemData
            }
        }
        case 'ADD_AFFECT': {
            //currentItemData.behaviours = currentItemData.behaviours.splice(effectIndex, 1);
            console.log('action', action);
            console.log('payload', action.payload);
            console.log('field', newItemData[action.field]);
            let addEfffects = newItemData[action.field];
            addEfffects.push(action.payload);

            newItemData[action.field] = addEfffects;// newItemData[action.field].push(action.payload);
            console.log('data', newItemData);
            return {
                ...initialState
            }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

function ItemProvider({ children }) {
    const [state, dispatch] = React.useReducer(exitReducer, initialState);
    return <ItemContext.Provider value={state}>
        <ItemDispatchContext.Provider value={dispatch}>
            {children}
        </ItemDispatchContext.Provider>
    </ItemContext.Provider>
}

function useItemBuilder() {
    const context = React.useContext(ItemContext)
    if (context === undefined) {
        throw new Error('useItemBuilder must be used within a ItemProvider')
    }
    return context
}

export { ItemProvider, useItemBuilder, useItemDispatch }
