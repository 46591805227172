import React, { Component, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import navigation from './NavigationConfiguration';
import { Link } from 'react-router-dom';

class BuilderNavBar extends Component {
  // Initialize the state
  constructor(props) {
    super(props);
    this.state = {
      list: []
    }
  }

  sortedNavigationItems() {
    return navigation.filter(target => target.role === 'builder').sort((a, b) => a.title.localeCompare(b.title));
  }


  render() {
    const { list } = this.state;

    return (
      <NavDropdown title="Builder" id="basic-nav-dropdown">

        {this.sortedNavigationItems().map((destination, index) => {
          let navigation = destination.path;
          if ((navigation!==undefined)&&navigation.includes(':')) {
            navigation = navigation.substr(0, navigation.indexOf(':') - 1);
          }

          return (
            destination.divider === true
              ? <NavDropdown.Divider key={index} className="divider2" />
              : <NavDropdown.Item key={index} as={Link} to={navigation}>{destination.title}</NavDropdown.Item>
          )
        }
        )}
      </NavDropdown>
    );
  }
}

export default BuilderNavBar;