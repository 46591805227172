import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { makeNiceString, timeConverter, makeNiceRpsheetLinks } from '../../utils/NiceStrings'
import { getPlayersOnlineAdmin, getRumours, getTopRpList, getEventList, } from '../../components';
import { getPlayerList } from '../../components/deityRequests';
import PlayerRow from './PlayerRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AdminPlayerManager() {
    const [playerList, setPlayerList] = useState([]);
    const [sortBy, setSortBy] = useState(0);
    const [textFilter, setTextFilter] = useState('');

    useEffect(() => {
        getPlayerList().then(data => {
            if (data.result)
                setPlayerList(data.players);
            else
                setPlayerList([]);
            //console.log(data)
        }).catch(error => {
            //console.log(error);
            setPlayerList([]);
        });
    }, []);

    const sortedAndFilteredData = () => {
        let filteredList = playerList.filter(player =>
            player.name.toLowerCase().includes(textFilter.toLowerCase()) ||
            player.race.toLowerCase().includes(textFilter.toLowerCase()) ||
            player.charclass.toLowerCase().includes(textFilter.toLowerCase()) ||
            player.ip.toLowerCase().includes(textFilter.toLowerCase())
        );

        let sortedList = filteredList.sort((a, b) => {
            switch (sortBy) {
                case 0:
                    return a.name.localeCompare(b.name);
                case 1:
                    return a.race.localeCompare(b.race);
                case 2:
                    return a.level - b.level;
                case 3:
                    return b.remort - a.remort;
                case 4:
                    return a.ip.localeCompare(b.ip);
                case 5:
                    return b.laston - a.laston;
                default:
                    return b.name - a.name;

            }
        });

        return sortedList;
    }
    const data = sortedAndFilteredData();
    return (
        <div className="container-eom" id="divMainContent">
            <Row >
                <Col md={2} className={"mx-2 output-container-area"}>
                    <Row>
                        <span>Players: </span><strong>{playerList.length}</strong>
                    </Row>
                    <Row>
                        <span>Filtered Players: </span><strong>{data.length}</strong>
                    </Row>
                </Col>

                <Col>

                    <Row>
                        <Col onClick={() => setSortBy(0)}>
                            <Row>
                                <strong>Name</strong>

                            </Row>
                            <Row>
                                <Col >
                                    <input type="text" value={textFilter} className="form-control" onChange={(e) => setTextFilter(e.currentTarget.value)} />
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={2} onClick={() => setSortBy(1)}>
                            <strong>Race</strong>
                        </Col>
                        <Col xs={2} onClick={() => setSortBy(1)}>
                            <strong>Class</strong>
                        </Col>
                        <Col onClick={() => setSortBy(2)}>
                            <strong>Level</strong>
                        </Col>
                        <Col onClick={() => setSortBy(3)}>
                            <strong>Remort</strong>
                        </Col>
                        <Col onClick={() => setSortBy(4)}>
                            <strong>IP</strong>
                        </Col>
                        <Col xs={3} onClick={() => setSortBy(5)}>
                            <strong>Laston</strong>
                        </Col>
                    </Row>


                    {data.map((player, index) =>
                        <PlayerRow key={player.name} player={player} index={index} />
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default AdminPlayerManager;