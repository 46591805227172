import {genericAuthenticatedFetch, genericAuthenticatedPut} from './index';

export async function getQuestList() {  
  return genericAuthenticatedFetch('quests/list' );
}

export async function setQuestControl(name, action) { 
  let escapedname = encodeURIComponent(name);
  let escapedaction = encodeURIComponent(action);   
  return genericAuthenticatedFetch(`quests/control/${escapedname}/${escapedaction}` );
}

export async function deleteQuest(name) { 
  let escapedname = encodeURIComponent(name);
  return genericAuthenticatedFetch(`quests/delete/${escapedname}` );
}

export async function updateQuest(name, data) { 
  let escapedname = encodeURIComponent(name);
  let json = JSON.stringify(data);
  return genericAuthenticatedPut(`quests/update/${escapedname}` , json );
}