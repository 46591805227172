import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormCheck } from 'react-bootstrap';
import MarkdownEditor from '../../components/MarkdownEditor';

const SceneActivityEditor = ({ activity, saveSceneActivity, show = false, handleClose }) => {

    const [sceneActivity, setSceneActivity] = useState(undefined);
    const [notifyDiscord, setNotifyDiscord] = useState(false);
    const [error, setError] = useState(undefined);

    const handleSave = () => {
        sceneActivity['activity_discord'] = notifyDiscord;
        saveSceneActivity(sceneActivity);
    }

    useEffect(() => {
        setSceneActivity(activity);
        if(activity!==undefined)
            setNotifyDiscord(activity.activity_index<0);
        else
            setNotifyDiscord(false);
        
    }, [activity]);

    const updateText = (text) => {
        sceneActivity.activity_text = text;
    }

    if (sceneActivity === undefined) {
        return null;
    }

    const getDiscordNotification = () => {
        if(sceneActivity.activity_index >= -1){
            return null;
        }
        return <FormCheck
            className={"m-2"}
            type="switch"
            checked={notifyDiscord}
            onChange={(e) => {
                setNotifyDiscord((prev) => !prev);
            }
            }
            label="Notify discord"
        />
    }

    return (
        <Modal size="lg" centered show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Editing Scene Activity: {sceneActivity.activity_title}</Modal.Title>
                <div className="alignright">
                    <small className="alignright" >id {sceneActivity.activity_index}</small>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Control type="text" placeholder="Kink Title" value={sceneActivity.activity_title} onChange={(e) => {
                        let newValue = e.currentTarget.value;

                        setSceneActivity(prevstate => ({
                            ...prevstate,
                            activity_title: newValue
                        }));
                    }} />
                    {getDiscordNotification()}
                    <br />
                    <MarkdownEditor editing={true} documentToEdit={sceneActivity.activity_text} autoUpdate={updateText} />

                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <span className="error"><font color="red">{error}</font></span>
                <Button variant="secondary" onClick={handleSave}>
                    Save
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>);
}

export default SceneActivityEditor;