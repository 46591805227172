import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import EditableField from '../EditableField';
import ResourceStylingEntryAction from './ResourceStylingEntryAction';

export const getAdverbOrders = () => {
    return ['', 'Opinion', 'Size', 'Shape', 'Condition', 'Age', 'Colour', 'Pattern', 'Origin', 'Material', 'Purpose'];
}

function ResourceStylingEntry(props) {
    const [editing, setEditing] = useState(true);

    let entry = props.entry;
    let callDispatcher = props.dispatcher;
    let deleteEntry = props.deleteEntry;
    let addAction = props.addAction;
    let deleteAction = props.deleteAction;

    const localDispatcher = (fieldName, newValue) => {
        callDispatcher(fieldName, newValue, props.entryId);
    }

    const actionDispatcher = (index, fieldName, newValue) => {
        callDispatcher(fieldName, newValue, props.entryId, index);
    }

    return (
        <Card className={"m-2 score-card-eom"} >
            <Card.Header className={"going-on-header"}>
                <Card.Title>Dictionary Entry for <strong>{entry.word}</strong> </Card.Title>
                <Button className={"mx-1"} onClick={() => deleteEntry(props.entryId)}>Delete</Button>
                <Button className={"mx-1"} onClick={addAction}>Add Action</Button>
            </Card.Header>
            <Card.Body>
                <EditableField editing={editing}
                    field={"word"}
                    value={entry.word}
                    callDispatcher={localDispatcher}
                    description="Word"
                    fieldType={2} />

                <EditableField editing={editing}
                    field={"level"}
                    value={entry.level}
                    callDispatcher={localDispatcher}
                    description="Level"
                    fieldType={2} />
                <EditableField editing={editing}
                    field={"type"}
                    value={entry.type}
                    callDispatcher={localDispatcher}
                    description="Type"
                    fieldType={2} />
                <EditableField editing={editing}
                    field={"order"}
                    value={entry.order}
                    callDispatcher={localDispatcher}
                    description="Adjective Order"
                    fieldType={5}
                    choices={getAdverbOrders}
                />
                <EditableField editing={editing}
                    field={"cost"}
                    value={entry.cost}
                    callDispatcher={localDispatcher}
                    description="Cost"
                    fieldType={2} />

                {entry.actions.map((action, index) =>
                    <ResourceStylingEntryAction dispatcher={actionDispatcher} action={action} index={index} key={index} deleteAction={deleteAction} />
                )}
            </Card.Body>
        </Card>

    );
}



export default ResourceStylingEntry;
