import { genericAuthenticatedFetch, genericAuthenticatedPost } from './deityRequests';

export async function getCharacterData(playername, page) {
    let safe_playername = encodeURIComponent(playername);
    let safe_page = encodeURIComponent(page);
    return genericAuthenticatedFetch(`admin/character/get/${safe_playername}/${safe_page}`);
}

export async function setCharacterSecurityData(playername, key, value) {
    let safe_playername = encodeURIComponent(playername);
    return genericAuthenticatedPost(`admin/character/set/${safe_playername}/security/flag/${key}/${value}`);
}