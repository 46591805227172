import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import { getRoleplaySheetData, listScenes } from './../components/sceneRequests';
import Config from './../../../config/Config';
import SceneHeader from './scene/SceneHeader';

function PlayerScenes() {

    const [scenes, setScenes] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const getScenesFromServer = async e => {
        const data = await listScenes();
        if (data.result) {
            setScenes(data.scenes);
            setLoaded(true);
        } else {
            setScenes([]);
            setLoaded(true);
        }
    }

    const getSceneStatusInformation = () => {
        if (loaded) {
            if (scenes.length == 0) {
                return (
                    <>
                        <br/>
                        <h5>There no scenes to display.</h5>
                        <p>
                            <span>See <strong>help scene</strong> in game for more information or navigate to </span>
                            <a  href={`/into/scenes`}>
                                Introduction to Scenes
                            </a>
                        
                            <span> and create your own scene and invite people to join in!</span>
                        </p>
                    </>
                );
            }
        } else {
            return <small>loading...</small>;

        }
        return null;

    }

    useEffect(() => {
        setLoaded(false);
        getScenesFromServer();
    }, []);

    return (
        <div className="container">
            <h2>
                Active Plots and Scenes
            </h2>
            {scenes.sort((a, b) => a.scene_index > b.scene_index ? 1 : -1).map((scene, index) => {
                return (

                    <SceneHeader key={index} scene={scene} />

                );
            })}
            {getSceneStatusInformation()}
        </div>
    )
}

export default PlayerScenes;