import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Alert, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBuilder } from '../../components/index';


function RoomSummary(props) {

    let unloadRoom = props.unloadRoom;

    const { currentarea, currentitem, currentroom, currentmob, currentexit, editing } = useBuilder();


    return (

        <Row key={currentroom.id} className="row fluid align-content-center py-2">
            <Col xs={2}>
                <FontAwesomeIcon onClick={unloadRoom} className="fa fa-lg fa-eom m-2" icon={"times-circle"} />
            </Col>

            <Col xs={10}>
                <h4> Viewing {currentroom.id} </h4>
            </Col>
            <span><strong>Mobs: </strong>{currentroom.mobs.length}</span> <br />
            <span><strong>Items: </strong>{currentroom.items.length}</span><br />
            <span><strong>Effects: </strong>{currentroom.effects.length}</span><br />
        </Row>);

}

export default RoomSummary;