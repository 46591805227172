import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import QuestData from './QuestData';
import { getQuestList } from '../../components/QuestRequests';

function QuestManager() {
    const [quests, setQuests] = useState([]);

    useEffect(() => {
        getQuestList().then(result => {
            let array = result.quests;
            //console.log(array);
            array = array.sort((a, b) => a.name.localeCompare( b.name.toUpperCase()))
            //console.log(array);
            setQuests(array);
        }).catch(error => {
            setQuests([]);
        });
    }, []);

    const forceRefresh = (index) => {
        setQuests([]);
        getQuestList().then(result => {
            let array = result.quests;
            array = array.sort((a, b) => a.name.localeCompare( b.name.toUpperCase()))
            setQuests(array);
        }).catch(error => {
            setQuests([]);
        });   
    }

    return (
        <div className="container-eom" id="divMainContent">
            <h2>Quest Management</h2>

            {quests.map((quest, index) =>
                <QuestData quest={quest} key={index} index={index} forceRefresh={forceRefresh} />
            )}
        </div>
    );
}

export default QuestManager;
