import React, { useState, useEffect } from 'react';
import { BuilderProvider } from '../components/index';
import { Row, Col } from 'react-bootstrap';
import { getWeaponTypes } from './BuilderUtils';

function BuilderWeaponTypeView() {
    return (<BuilderProvider><BuilderWeaponTypeViewInternal /></BuilderProvider>);
}

function BuilderWeaponTypeViewInternal() {
    const [weaponTypes, setWeaponTypes] = useState([]);
    const [filter, setFilter] = useState('');
    const [filterBase, setFilterBase] = useState('');

    const populateWeaponTypes = async () => {
        let data = await getWeaponTypes();
        setWeaponTypes(data);
    }
    useEffect(() => {
        populateWeaponTypes();
    }, []);

    const filterMatch = (at) => {
        return ((at.id.toLowerCase().indexOf(filter.toLowerCase()) > -1) && (at.basetype.toLowerCase().indexOf(filterBase.toLowerCase())>-1));
    }

    return (
        <div className="container-eom" id="divMainContent">
            <Row >
            <Col xs={2} className={"mx-2"}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend" >
                            <button className="btn btn-themed-dark" type="submit">Base Class</button>
                        </div>
                        <input type="text" value={filterBase} className="form-control" onChange={(e) => setFilterBase(e.currentTarget.value)} />
                    </div>
                </Col>

                <Col xs={2} className={"mx-2"}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend" >
                            <button className="btn btn-themed-dark" type="submit">Weapon Class</button>
                        </div>
                        <input type="text" value={filter} className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                    </div>
                </Col>


            </Row>
            <Row >

                <Col xs={2} className={"mx-2"}>
                    <strong>Base Class</strong>
                </Col>
                <Col xs={2} className={"mx-2"}>
                    <strong>Weapon Class</strong>
                </Col>
                <Col xs={1} className={"mx-2"}>
                    <strong>Reach</strong>
                </Col>
                <Col xs={1} className={"mx-2"}>
                    <strong>Handed</strong>
                </Col>
                <Col xs={1}>
                    <strong>Damage Type</strong>
                </Col>
                <Col xs={1}>
                    <strong>Attack/Damage/Armour Balance</strong>
                </Col>
            </Row>

            {weaponTypes.filter(wt => filterMatch(wt)).map((wt, index) =>
                <Row key={wt.id}>
                    <Col xs={2} className={"mx-2"}>
                        {wt.basetype}
                    </Col>

                    <Col xs={2} className={"mx-2"}>
                        {wt.id}
                    </Col>
                    <Col xs={1} className={"mx-2"}>
                        {wt.reach}

                    </Col>
                    <Col xs={1} className={"mx-2"}>
                        {wt.handed}
                    </Col>
                    <Col xs={1}>
                        {wt.weapontype}
                    </Col>
                    <Col xs={1}>
                        {wt.attack}/{wt.damage}/{wt.armour}
                    </Col>
                </Row>

            )}
        </div>
    )
}

export default BuilderWeaponTypeView;