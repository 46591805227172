import React, { useState, useEffect } from 'react';
import { Row, Col, FormCheck, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SchematicEntry(props) {

    let editing = props.editing;
    let value = props.value;
    let field = props.field;
    let callDispatcher = props.callDispatcher;
    let onSelect = props.onSelect;
    let data = props.data;
    let description = props.description;
    let deleteProduct = props.onDelete;
    const displayEntry = () => {
        if (editing) {
            return (
                <>
                    <Col xs={2} className={"numbercolumn"}>
                        <input className={"numberedit"} type="number"
                            value={value}
                            onChange={(e) => callDispatcher(data.schematic_id, field, e.currentTarget.value)} />
                    </Col>
                    <Col xs={4} >

                            <span>{data.schematic_id}</span>
                    </Col>
                    <Col >
                        <span>{description}</span>
                    </Col>
                    <Col xs={1} >
                        <FontAwesomeIcon onClick={() => deleteProduct(data.schematic_id)} data-tip data-tooltip-id="tooltipEditArea" className="fa fa-lg fa-eom m-2" icon={"trash-alt"} />
                    </Col>
                </>
            );
        } else {
            return (
                <>
                    <Col xs={2}>
                        {value}
                    </Col>
                    <Col>
                         <a className="dryLink" href={`/builder/schematics/${data.schematic_id}`}>
                            {description}
                        </a>
                    </Col>
                </>
            );
        }
    }

    return (displayEntry());

}

export default SchematicEntry;