import React, { Component } from 'react';

class IntroRpSetup extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">
        <div className="row fluid">
          <div className="col-md-2">
            <br/>
            <br/>
            <ul className="list-eom">
              <li><a className="eomLink" href="#shortdesc">Short Description</a></li>
              <li><a className="eomLink" href="#longdesc">Long Description</a></li>
              <li><a className="eomLink" href="#bodydesc">Body Description</a></li>
              <li><a className="eomLink" href="#rpsheet">Roleplay Sheet</a></li>
              <li><a className="eomLink" href="#rpwizard">Roleplay Wizard</a></li>
            </ul>
				    </div>
        	<div className="col-md-10">
      <br/>
            <h1>Getting your character setup for Roleplay</h1>
			<br/>
            <p>You've gone to all the effort of creating a character, but that's just the start <strong>roleplaying</strong> is a vital part of the edge of midnight. Whether you are an evil overload looking to take over 
            the realm, a holy paladin looking to save everyone or something more nuanced - or even if you are here for a little kinky fun. When you are out in the realm you are considered <strong>in character</strong> and so people need to 
            know at least a little about you.</p>
            <p>A lot of this information will be the sort of thing that people would pick up if they were to look at, to listen to ,or to hear about your character and more of it goes beyond that - what we might call <strong>metadata</strong> 
            that in a character's biography it mentions that their fear of heights stems from being picked up by a giant as a toddler - <strong>your</strong> character will <strong>not</strong> know this until they discover it for themselves 
            though interacting with the character. Often it is there to help shape roleplay, provide an interesting backstory and help as an aide de memoire for those that roleplay with you.</p>
            <blockquote>
					    <h3 className="text-error">“If your character hasn't learned it for themselves, they don't know it!"</h3>
					    <small>Metagaming <cite title="Source Title">don't do it!</cite></small>
					  </blockquote>
					<h2 id="shortdesc">Short Descriptions</h2>
					<p>Your short description is what everyone sees when they enter a room, look around or your character is included in an emote or pose. 
					It is intended to give everyone a flavour of what they are looking, creating that first impression.</p>
					<p>What should be in your short?  At the very least your name; even if your character is trying to go incognito  put your char's real name in brackets so 
					we can see who we are dealing with -  the name is considered <strong>out of character</strong> information.</p>
					<p>What else? well this is much more flexible anything from a brief physical description ('A lithe, raven-haired elven girl' 
					for example tells us a great deal about the characters appearance) or something more about their demeanour 
					('The Cabbit Knight' for example tells us that this person has a certain  bearing and you get a sense of how they might hold themselves). </p>
					<p>There are currently only two rules about short descriptions:</p>
					<ol>
						<li>You must have one.</li>
						<li>You must include your name in it.</li>
					</ol>
	                <p>There is no hard fast rule about you go about this, but these should be built around the defining physical characteristics - Imagine you are walking into a room and you see your character for the first time - what is it about them that first strikes you?</p>
	                <p>Is it their hair? Their height? Their build, the way they dress? There are many permutations to this and it is down to you.</p>
	                <h3>Some Examples</h3>
	                <ul>
	                	<li>A broad shouldered Orc (Crishnaw)</li>
	                	<li>A tall half-elven male (Ehjir)</li>
	                	<li>A buxom bunny girl (Felicity)</li>
	                </ul>
	                <h3>Things to avoid</h3>
	                <p><strong>Just using your name</strong> for example 'Jonas Derford' this doesn't really give us very much about the character, only their name which is really meta information until your character actually finds out!</p>
	                <p><strong>Hiding your name</strong> for example the character River 'Scriver, a skinny elf' or Autumn, 'An elf with hair the colour of autumn leaves' These are avoiding the policy on displaying your name, short term exemptions can be made but this should be avoided in general use.</p>
	                <p><strong>Too much colour</strong> Although we support a wide range of colours, please do not make other players eyes bleed.</p>
	                <h3>How to set your short description</h3>
	                <h4>The quick method</h4>
	                <p>Enter the command <strong>SHORT</strong> followed by the description you wish to use, include an asterisk where you want your name and you are done!</p>
	                <p>For example 'short a lithe bunny-girl (*)'</p>
	                <pre>{`	Prepended normalising colour code to short description
	Appended normalising colour code to short description
	Short Description changed
	You are now using 'a lithe bunny-girl (Felicity)' as a short description.
	`}</pre>
	                
	                <h4>The longer method</h4>
	                <p>A second way to do this is to go through the menu system, entering the command <strong>SHORT</strong> without any parameters will bring up a menu option, you can follow through the menu
	                and add a number of different short descriptions for different situations.</p>
	                <pre> {`	BLACK          :a buxum white-hair..
	BUNNY          :a buxum white-hair..
	NORMAL         :B1a buxum white-hair..
	QUICK          :a lithe bunny-girl (Felicity)
	
	Select: (S)ave (A)dd (M)odify (U)se (D)elete [Name] (F)ull or e(X)it:`}</pre>
	
	<p>You can pick from the list of existing short descriptions, add, modify or delete an entry from this menu.</p>
	<p>It's useful to note that the <strong>QUICK</strong> entry is the one that is used when you use the first method for setting your short and 
	<strong>WIZARD</strong> if you have set one using the <strong>RP WIZARD</strong>.</p>
	
	<pre>{`	Select: (S)ave (A)dd (M)odify (U)se (D)elete [Name] (F)ull or e(X)it:
	Save short description as : 
	>EXAMPLE
	
	Enter a Description for 'EXAMPLE',  Use * to place name.
	>A long-eared bunny-girl (*)
	Prepended normalising colour code to short description
	Appended normalising colour code to short description
	Short Description changed
	BLACK          :a buxum white-hair..
	BUNNY          :a buxum white-hair..
	NORMAL         :B1a buxum white-hair..
	QUICK          :a lithe bunny-girl (Felicity)
	EXAMPLE        :A long-eared bunny-girl (Felicity)
	
	Select: (S)ave (A)dd (M)odify (U)se (D)elete [Name] (F)ull or e(X)it:
	>X
	>short example
	You are now using 'A long-eared bunny-girl (Felicity)' as a short description.
	`}</pre>
	
	                <h2 id="longdesc">Long Descriptions</h2>
	            	<p>A long description allows you to describe your character's appearance in overall more detail than your short or your body parts.</p>
	            	<p>It is much more difficult than writing a short! With a short you can just think what does my  character look like... hmm she is a slender half-elven girl .. oh I am done for the moment.</p>
	            	<p>Of course it might take a little while to find the perfect one or to get the colouring just right - but a decent basic short is relatively easy to  write. 
	            	A long description however can be much more troublesome</p>
	            	<p>We (The staff and the players) are not particularly strict about peoples descriptions but there is one hard and fast rule. 
	            	<strong>Do not force the readers perceptions or actions</strong>.</p>    
	            	<p>For example: Looking at this beautiful coder you feel an overwhelming desire to masturbate.</p>
	            	<p>Of course you do., perhaps this is a bad example everyone feels that way when examining the character River, but persevering with the example regardless. River IS beautiful 
	            	and she HAS got some abilities (Through RP) to engender that sort of response but not everyone is susceptible or of the persuasion that her race or gender 
	            	is at all attractive and forcing them to feel a certain way,  to react in a certain way is simply wrong and we WILL ask you to change it.</p>
	            	<p>Please don't get upset with us! You are effectively power gaming the description. We know that there is a slightly fuzzy line and you might be able to talk it 
	            	through and justify it with the staff - but if they say change it,  then that is what needs to happen - do ask them for alternative ways to  phrase it or for 
	            	help if you want it though.</p>     
	
					<h4>The use of 'you'</h4>
					<p>The use of you in descriptions is not prohibited, used effectively it can be very useful, but as stated above using it to force someones reaction or perception of your character should not be done.</p>
					<p>Obviously starting out with 'You see a tall slender elf...'  seems entirely reasonable.</p>
	
					<p>What it is better to do if possible is to put suggestions in for something  rather than outright statements.</p>
					<p>'They are the most evilest, smelliest  bastard you ever saw.' really? are they? What makes them appear, or smell  that way? The scent of rotting flesh from 
					the bodies of their victims that they carry around with them might be a delicious aphrodisiac to one and utterly revolting to another.</p>
					<p>Instead you can put something like. 'As you get closer you can catch the scent of rotting meat' and allow someone to interpret that how they will.</p>
	
					<p>What makes them look evil? Is it the bags under their eyes? the vicious scars? the way their eyes shift and dart about the room? 
					People will see  you for what you and your characters actions will do more to prove their evil ways in the eyes of others.</p>
					<p>Other ways of avoiding the use 'you'  'Before you stands a diminutive little...' &lt;==&lgt; 'This diminutive little...'  'You smell rotting flesh...' &lt;==> 'The smell of rotting flesh hangs  around...' </p>   
	
					<h4>Use the present tense</h4>
					<p>We are not reading a history of the character (use RPSheet and Bio for that!)  we are looking at them in the now!</p>    
	
					<h4>Clothing &amp; Armour</h4>
					<p>If you are going to put this in your description, by all means do so - but  with the caveat that you should be wearing the items you are describing.
					(Use aliases to switch between appearances and outfits).</p>
					
					<h3>How to set your long description</h3>
	                <h4>The quick method</h4>
	                <p>Much like the short command you set your long description from a single line, using the command <strong>DESCRIPTION</strong> followed by the description you want, you will be 
	                prompted to check that this is what you intended and then the description will be set for your character.</p>
	            	
	            	<h4>Using the text editor</h4>
	                <p>Entering the command <strong>DESCRIPTION EDIT</strong> will drop you into the standard in-game text editor.</p>
		<pre>{`	Editing main description.
	
	
	CoffeeMud Message Maker
	Menu (?/A/D/L/I/E/R/S/Q): CoffeeMud Message Maker Options:
	>?
	A)dd new lines (go into ADD mode)
	D)elete one or more lines
	L)ist the entire text
	I)nsert a line
	E)dit a line
	R)eplace text in the message
	S)ave the text
	Q)uit without saving
	Menu (?/A/D/L/I/E/R/S/Q):	
	>S
	Save and exit, are you sure (N/y)?  
	>Y
	`}</pre>
		
		<pre>{`	DEFAULT        :With a couple of rather telling features altered, this tall ...
	OLD            :This sable is a little more human looking than many, though ...
	
	Select: (S)ave (A)dd (M)odify (U)se (D)elete [Name] (F)ull or e(X)it:
	`}</pre>
	
		<p>Follow the editor through to set your description, it is useful to note that a single period (.)on a line will bring you out of the editor.</p>
		<h2 id="bodydesc">Body Descriptions</h2>
		<p>It is possible for a player to add a short description (64 characters each) individual body parts for their character. This is done through the <strong>bodydesc</strong> command.</p>
		<h3>Simple Approach</h3>
		<p>To simply describe a single part you include the command, the body part and the description e.g.  bodydesc "upper legs" thick powerful thighs.</p>
		<p>This can be used to build up a description of the characters body parts that are then visible when the player is examined (and they are not covered by a non-transparent layer of clothing!</p>
		<h3>Body Description Sets</h3>
		<p>It is also possible to save and group together sets of body descriptions so that they can be used quickly and easily when switching between forms.</p>
		<p>To do this enter the command <strong>BODYDESC</strong> </p>
		<pre>{`		PIRATE         :a left finger...
		Select: (S)ave (A)dd (U)se (D)elete [Name] (F)ull or e(X)it:
		`}</pre>
		<p>From there you can save your current body descriptions to a set for future use, add a new set or update/delete an existing set. 
		Opting to save te current set you will be prompted to enter a name, this should be a short, single word that will be used to activate the set when needed.</p>
		<pre>{`Save body description as :`}</pre>
		<p>Entering the name, this will be saved e.g. saving this as 'Example' you can use the command <strong>BODYDESC EXAMPLE</strong> and you will use the body description set example.</p>
	
		<pre>{`		<p>You can also create a set within the command by taking the <strong>A</strong>dd option this will prompt you for a name and then ask for 
		body parts, for each part it will ask for a description and when you are done hit enter when prompted for the next body part.</p>
Select: (S)ave (A)dd (U)se (D)elete [Name] (F)ull or e(X)it:
PIRATE         :a left finger...
EXAMPLE        :a left finger...

Select: (S)ave (A)dd (U)se (D)elete [Name] (F)ull or e(X)it:
>A
Save body description as : 
>Example2
Select Body Part: 
>Lower Legs
Enter a Description for 'Lower Legs'
Lower Legs: >slender calves
Select Body Part: 
>upper arms
Enter a Description for 'Upper Arms'
Upper Arms: >sleekly muscled arms 
Select Body Part: 
>[RETURN]
PIRATE         :a left finger...
EXAMPLE        :a left finger...
EXAMPLE2       :Sleekly muscled...
Select: (S)ave (A)dd (U)se (D)elete [Name] (F)ull or e(X)it:`}</pre>
		<br/>
				<h2>Managing Description Sets</h2>
		<p>Managing sets of the above descriptions is covered <a href="managedescs.php">here</a>.</p>

	            	<h2 id="rpsheet">Roleplay Sheet</h2>
	            	<p>The Roleplay sheet is the central hub of all the information about your character, from their full name, age, race vital statistics to preferences about consent and their biography.</p>
	            	<p>To view your roleplays sheet you enter the command <strong>RPSHEET</strong>, adding in another characters name will bring up their sheet e.g. <strong>rpsheet felicity</strong>.</p>
	            	
	                <p>The roleplay sheet can be accessed through the command RPSHEET</p>
	                <pre>{`o------------------------Roleplay Sheet for Felicity-------------------------o
| Felicity                                                                   |
| Height: 5' 11" tall.    Weight: 103 pounds.                                |
| Age: 24 years (Young adult)                                                |
| Birthday: Lenas, the 8th day of Chaos, Adamantium 58                       |
| Gender: futanari but identifies as female                                  |
| Orientation: Bisexual - Male Preference.                                   |
| Kinks: Felicity is filthy dirty with very few inhibitions.                 |
| Scent: smoky heat.                                                         |
| Figure: 34E-24-34.                                                         |
o----------------------------------------------------------------------------o
| Personal Traits:                                                           |
|  1) Loves her magic                                                        |
|  2) Cheerful, outgoing and friendly                                        |
|  3) Is a sucker for a fun dare                                             |
| Physical Traits:                                                           |
|  1) Is physically very fit and athletic                                    |
|  2) Believes even mages need to train their bodies.                        |
|  3) She has tall ears, that add almost another foot to her                 |
|     height, which when combined with a killer pair of six inch             |
|     heels puts her at nearly 7 and a half feet tall from toe to            |
|     ear tip!                                                               |
|  4) During the course of her first remort she aided a hentai               |
|     creature who in thanks altered her body a little, granting             |
|     her some of it's own gifts, this has manifested after being            |
|     raped by someone infused with an aphrodisiac in her body               |
|     trying to adapt and produce it herself.                                |
|  5) OOCThere is no need to gain consent from the player for                |
|     Humiliation and Rape however rough - but anything longer               |
|     term - please do speak to the player!                                  |
o----------------------------------------------------------------------------o
| Consent is given for specified kinks                                       |
o----------------------------------------------------------------------------o	`}                
	                </pre>
	                <h3>Configuring your RP Sheet</h3>
	                <p>To configure your roleplay sheet enter the command <strong>RPSHEET CONFIG</strong> and this will bring you into a menu where you can edit each of the values.</p>
	                <div className="row fluid">
		                <div className="col-md-2"><strong>Item</strong></div>
	        			<div className="col-md-10">
	        				<p><strong>Description</strong></p>
	        			</div>
	        		</div>

					<div className="row fluid">
		                <div className="col-md-2">Surname</div>
	        			<div className="col-md-10">
	        				<p>Set the characters surname, this only shows in score sheet and roleplay sheet.</p>
	        			</div>
	        		</div>

	        		<div className="row fluid">
		                <div className="col-md-2">Eyes</div>
	        			<div className="col-md-10">
	        				<p>Set the characters eye shape and colour.</p>
	        			</div>
	        		</div>
	        		
	        		<div className="row fluid">
		                <div className="col-md-2">Hair</div>
	        			<div className="col-md-10">
	        				<p>Set the characters hair length and style.</p>
	        			</div>
	        		</div>

	        		<div className="row fluid">
		                <div className="col-md-2">Bodyshape</div>
	        			<div className="col-md-10">
	        				<p>Set the characters bodyshape. e.g. Lithe, brawny.</p>
	        			</div>
	        		</div>
	        		
	        		<div className="row fluid">
		                <div className="col-md-2">Physical and Personal traits</div>
	        			<div className="col-md-10">
	        				<p>This will take you into the text editor where you can add a list of physical or personal traits.</p>
	        			</div>
	        		</div>
	        		<div className="row fluid">
		                <div className="col-md-2">Biography</div>
	        			<div className="col-md-10">
	        				<p>This will take you into the text editor where you can add the characters biography, other characters biographies are access using <strong>BIO <em>playername</em></strong>.</p>
	        			</div>
	        		</div>
	        		
	        		<div className="row fluid">
		                <div className="col-md-2">Age</div>
	        			<div className="col-md-10">
	        				<p>This is the age of your character, different races come of age at different times and next to the age the category of the age bracket will be displayed next to it and only 
	        				characters of <strong>young adult</strong> or older will have sexual details displayed to other players.</p>
	        				<a href="rules.php#policies"><strong>See the policy for information about SRP</strong></a>
	        			</div>
	        		</div>
	        		<div className="row fluid">
		                <div className="col-md-2">Height and Weight</div>
	        			<div className="col-md-10">
	        			<p>The height and weight of your character can be set here, there is an advanced feature here where you can store the height and weight for any forms that your character 
	        			shape shifts into.</p>
	        			</div>
	        		</div>

	        		<div className="row fluid">
		                <div className="col-md-2">Vital Statistics</div>
	        			<div className="col-md-10">
	        			<p>The intimate details of your character.</p>
	        			<p>This will vary from gender to gender - but it allows you to specify the shape, length and girth of your characters cock, their measurements, bust size and so on.</p>
						<p>This information can be very useful for other players who are interacting with your character in sexual roleplay, knowing how to respond appropriately to anything you try to do or expose them to!</p>
	        			<p>This information can be stored for different forms.</p>
	        			</div>
	        		</div>

	        		<div className="row fluid">
		                <div className="col-md-2">Scent</div>
	        			<div className="col-md-10">
	        			<p>What does your character smell like.</p>
	        			</div>
	        		</div>
	        		
	        		<div className="row fluid">
		                <div className="col-md-2">In/Out Message</div>
	        			<div className="col-md-10">
	        			<p>Do you amble around or stomp about? Set the message that people see when you enter or leave a room</p>
	        			</div>
	        		</div>

					<div className="row fluid">
		                <div className="col-md-2">Roleplay Style, Posting Style and Frequency and any Grammar preferences</div>
	        			<div className="col-md-10">
	        			<p>What sort of interactions do you prefer?</p>
	        			</div>
	        		</div>

					<div className="row fluid">
		                <div className="col-md-2">Character Accent</div>
	        			<div className="col-md-10">
	        			<p>Freetext description of your characters accent. This will appear in the roleplay sheet and when the <strong>SAY</strong> command is used/</p>
	        			</div>
	        		</div>

					<div className="row fluid">
		                <div className="col-md-2">Roleplay Status</div>
	        			<div className="col-md-10">
	        			<p>When enterting the <strong>RPSTATUS </strong> command you will see a list of others players statuses so you can see if any are interested in similar RP.</p>
	        			<p>This will also be displayed to players as they log in to the game.</p>
	        			</div>
	        		</div>

	        		<div className="row fluid">
		                <div className="col-md-2">Teleport In/Out Message</div>
	        			<div className="col-md-10">
	        			<p>When being transported through magical means - these are the messages that will be displayed to the room.</p>
	        			</div>
	        		</div>
						
	        		<div className="row fluid">
		                <div className="col-md-2">Gender Mode of Address</div>
	        			<div className="col-md-10">
	        			<p>Although your character may be a male, they may identify as another gender - this will affect the gender pronouns used with your characters actions.</p>
	        			</div>
	        		</div>

	        		<div className="row fluid">
		                <div className="col-md-2">Consent Option</div>
	        			<div className="col-md-10">
	        			<p>Some interactions require consent, if you have a default answer to any requests you can set the level of consent here.</p>
	        			</div>
	        		</div>

	        		<div className="row fluid">
		                <div className="col-md-2">Sexual Preference</div>
	        			<div className="col-md-10">
	        			<p>If desired, set your characters sexual preference here.</p>
	        			</div>
	        		</div>

	                <h2 id="rpwizard">Roleplay Wizard</h2>
	                <p>The roleplay wizard will run you through setting up the basics for your Roleplay Sheet. To begin this wizard enter the command <strong>RPWIZARD</strong>.</p>
	                <p>If at any time you wish to exit the wizard enter *ABORT at the prompt and you will be returned to the game. Alternatively if there is a value that you don't wish to enter at this point, enter *SKIP at the prompt and the setting will be skipped!
Your short description is what everyone sees when they enter a room, when you emote, say or tell and it sets the tone and flavour for your character. </p>
<p>Any short or long descriptions will be stored against the <strong>RPWIZARD</strong> code and will not override any values have already stored.</p>
	            	</div>
            	</div> 	
		</div>
    );
  }
}

export default IntroRpSetup;