import React, { useState, useEffect } from 'react';
import { BuilderProvider } from '../components/index';
import { Row, Col } from 'react-bootstrap';
import { removeColourCodes } from '../utils/ColourMap';
import { getArmourTypes } from './BuilderUtils';

function BuilderArmourTypeView() {
    return (<BuilderProvider><BuilderArmourTypeViewInternal /></BuilderProvider>);
}

function BuilderArmourTypeViewInternal() {
    const [armourTypes, setArmourTypes] = useState([]);
    const [filter, setFilter] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterDescription, setFilterDescription] = useState('');

    const populateArmourTypes = async () => {
        let data = await getArmourTypes();
        setArmourTypes(data);
    }
    useEffect(() => {
        populateArmourTypes();
    }, []);
    
    const filterMatch = (at) => {
        return (at.layer.toLowerCase().indexOf(filter.toLowerCase()) > -1)
        &&
        (at.id.toLowerCase().indexOf(filterName.toLowerCase()) > -1)
        &&
        (at.description.toLowerCase().indexOf(filterDescription.toLowerCase()) > -1);
    }

    return (
        <div className="container-eom" id="divMainContent">
            <Row >
                <Col xs={2} className={"mx-2"}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend" >
                            <button className="btn btn-themed-dark" type="submit">Layer</button>
                        </div>
                        <input type="text" value={filter} className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                    </div>
                </Col>
                <Col xs={2} className={"mx-2"}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend" >
                            <button className="btn btn-themed-dark" type="submit">ID</button>
                        </div>
                        <input type="text" value={filterName} className="form-control" onChange={(e) => setFilterName(e.currentTarget.value)} />
                    </div>
                </Col>
                <Col xs={2} className={"mx-2"}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend" >
                            <button className="btn btn-themed-dark" type="submit">Description</button>
                        </div>
                        <input type="text" value={filterDescription} className="form-control" onChange={(e) => setFilterDescription(e.currentTarget.value)} />
                    </div>
                </Col>
            </Row>
            {armourTypes.filter(at => filterMatch(at)).map((at, index) =>
                <Row key={at.id}>
                    <Col xs={2} className={"mx-2"}>
                        {at.layer}

                    </Col>
                    <Col xs={2} className={"mx-2"}>
                        {at.id}
                    </Col>
                    <Col xs={2} className={"mx-2"}>
                        {at.description}
                    </Col>
                    <Col>
                        {removeColourCodes(at.wornon)}
                    </Col>
                </Row>

            )}
        </div>
    )
}

export default BuilderArmourTypeView;