import React, { useState, useEffect } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { Row, Col } from 'react-bootstrap';
import AdminWealthActivity from './AdminWealthActivity';
import { getWealthActivity } from '../components';

function PlayerWealth(props) {
    console.log(props);

    const [playerWealth, setPlayerWealth] = useState();
    const [data, setData] = useState();
    const [wotdata, setWoTData] = useState();
    const [wotOptions, setWoTOptions] = useState();
    const [activity, setActivity] = useState([]);
    const [dateRange, setDateRange] = useState(props.dateRange);

    const buildData = (wealthData) => {
        console.log('Building Wealth Data', wealthData);
        const numbers = [];
        const labels = [];
        labels.push('Cash');
        numbers.push(wealthData.cash);

        wealthData.banks.map(bank => {
            if (bank.balance > 0) {
                labels.push(bank.banktitle);
                numbers.push(bank.balance);
            }
            if (bank.collateral > 0) {
                labels.push(bank.banktitle + '-Collateral');
                numbers.push(bank.collateral);
            }
        });

        console.log('Building data');

        // const timestamp = Date.now();
        // const intervals = getTimeDataPoints(timestamp - oneDay, timestamp, fifteenMinutes * 4);
        // console.log(intervals);

        setData({
            labels: labels,
            datasets: [
                {
                    label: '# of Votes',
                    data: numbers,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                    hoverBorderWidth: 2,
                    borderAlign: 'inner',

                },
            ],
        });

        let historicbank = [];
        let historiccash = [];
        let historictotal = [];

        wealthData.history.map(dp => {
            let bankdata = {};
            bankdata['x'] = dp.timestamp;
            bankdata['y'] = dp.bankvalue;
            historicbank.push(bankdata);

            let cashdata = {};
            cashdata['x'] = dp.timestamp;
            cashdata['y'] = dp.cashvalue;
            historiccash.push(cashdata);

            let totaldata = {};
            totaldata['x'] = dp.timestamp;
            totaldata['y'] = dp.cashvalue + dp.bankvalue + dp.collateral;
            historictotal.push(totaldata);
        });

        //console.log('Cash', historiccash);


        setWoTData({
            //labels: intervals,
            datasets: [
                {
                    label: 'Bank Balance',
                    data: historicbank,
                    borderColor: "ForestGreen",
                    backgroundColor: "SpringGreen",
                    borderWidth: 2

                },
                {
                    label: 'Cash',
                    data: historiccash,
                    borderColor: "DarkOrange",
                    backgroundColor: "Moccasin",
                    borderWidth: 2
                },
                {
                    label: 'Total',
                    data: historictotal,
                    borderColor: "CornflowerBlue",
                    backgroundColor: "LightSkyBlue",
                    borderWidth: 2
                }
            ]
        });

        setWoTOptions({

            scales: {
                x: {
                    type: 'time',
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 10
                    },
                    time: {
                        parser: 'YYYY-MM-DD HH:mm:ss',
                        unit: 'minute',
                        unitStepSize: 15,
                        displayFormats: {
                            'minute': 'YYYY-MM-DD HH:mm:ss',
                            'hour': 'YYYY-MM-DD HH:mm:ss'
                        },
                    },
                },
                y: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
            }
        })
    }


    console.log(playerWealth);
    console.log(data);

    const loadActivityDetail = async (location, timestamp) => {
        let data = await getWealthActivity(location, timestamp);
        if (data.result) {
            // console.log(data);
            setActivity(data.activity);
        } else {
            setActivity([]);
        }
    }

    const displayWealth = () => {

        if (playerWealth === undefined)
            return null;
        // console.log('Display stuff');
        // console.table(playerWealth.activity);
        return (
            <div>
                <br />
                <h2>Recent Activity</h2>
                {playerWealth.activity.map((activity, index) => {
                    return (
                        <Row key={index}>
                            <Col>
                                <span>{moment(activity.timestamp, 'YYYY.MM.DD.HH.mm.SS.T').format('Do MMM HH:mm')}</span>

                            </Col>
                            <Col onClick={() => {
                                loadActivityDetail(activity.location, activity.timestamp);
                            }}>
                                <span>{activity.location}</span>
                            </Col>
                            <Col style={{
                                textAlign: "right"
                            }}>
                                <font color={activity.value > 0 ? "blue" : "red"}>
                                    {activity.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </font>
                            </Col>
                            <Col>
                                {activity.event}
                            </Col>
                        </Row>)
                })}
            </div>)
    }
    //console.log(data);

    const clearDetail = async () => {
        setActivity([]);
    }

    const dnutoptions = {
        cutoutPercentage: 60,
    }
    let width = 5;
    if (activity.length > 0) {
        width = 3;
    }



    console.log('Input - playerWealth', playerWealth);
    console.log('Input - props.playerWealth', props.playerWealth);

    if ((props.playerWealth === undefined) && (playerWealth !== undefined)) {
        setPlayerWealth();
    } else if ((props.playerWealth !== undefined) && (playerWealth === undefined)) {
        setPlayerWealth(props.playerWealth);
    }

    useEffect(() => {
        console.log('use Effect => Player Wealth', playerWealth);
        let d2 = buildData(playerWealth);
        console.log('d2', d2);
        //        setPlayerWealth(props.playerWealth);
    }, [playerWealth]);

    return (
        <div className="container-eom" id="divMainContent">

            <Row className={"d-flex justify-content-centre"}>
                {data !== undefined ?
                    <Col md={width} className="mx-2">
                        <Row >
                            <h1>Overall Wealth</h1>
                        </Row>
                        <Row className={"py-3"}>
                            <div className="chartContainer">
                                <Doughnut data={data} options={dnutoptions} height={400} width={400} />
                                <div className="chartInner">
                                    <div className="chartStatus">Achieved</div>
                                    <div className="chartValue">$100,000</div>
                                    <div className="chartTarget">Target: $120,000</div>
                                    <div className="chartDaysRemaining">120</div>
                                    <div className="chartDaysLabel">Days left</div>
                                </div>

                            </div>
                        </Row>
                        <Row>
                            <h1>Wealth Over Time</h1>


                            <Line data={wotdata} options={wotOptions} />
                        </Row>
                    </Col>
                    : null}
                <Col md={width}>
                    {displayWealth()}
                </Col>
                <AdminWealthActivity activity={activity} clearDetail={clearDetail} />
            </Row>
        </div>
    )
}

export default PlayerWealth;