import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card, Tabs, Tab } from 'react-bootstrap';
import { getSceneActivity, updateSceneActivity } from './../components/sceneRequests';
import MarkdownEditor from '../components/MarkdownEditor';
import { useAuthState } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PlayerSceneActivity({ sceneId, activity, editActivity, deleteActivity }) {
    const { roles, currentUser } = useAuthState();

    const [editing, setEditing] = useState(false);
    const [sceneActivity, setSceneActivity] = useState(activity);
    const [text, setText] = useState(undefined);

    useEffect(() => {
        setText(text);
    }, [text]);

    const selectActivity = async (index, forceUpdate = false) => {
        if (forceUpdate || (text=== undefined)) {
            if (forceUpdate)
                setText(text + ' ...reverting...');

            const data = await getSceneActivity(sceneId, index);
            if (data.result) {
                sceneActivity.activity_text = data.activity.activity_text;
                setSceneActivity(data.activity);
                setText(data.activity.activity_text);
            }
        }
    }

    const canEditActivity = () => {
        let valid = (roles !== undefined) && (roles.indexOf('webadmin') > -1);
        return (sceneActivity.activity_author === currentUser) || (valid);
    }

    return (

        <Accordion.Item eventKey={"'" + activity.activity_index + "'"} className={"my-1 accordion-body"}>
            <Accordion.Header onClick={() => selectActivity(activity.activity_index, false)}>
                <span >
                    <strong>{activity.activity_author} - </strong>{activity.activity_title}
                </span>

            </Accordion.Header>
            <Accordion.Body >
                {canEditActivity()
                    ?
                    <div>
                        <FontAwesomeIcon className="fa fa-lg m-2 fa-eom" icon={"edit"} onClick={() => editActivity( activity.activity_index )} />
                        <FontAwesomeIcon className="fa fa-lg m-2 fa-eom" icon={"trash-alt"} onClick={() => deleteActivity( activity.activity_index )}/>
                    </div>
                    : null}
                {text === undefined
                    ? <h5>loading...</h5>
                    :
                    <MarkdownEditor editing={false} documentToEdit={text} />
                }
            </Accordion.Body >
        </Accordion.Item>
    );

}

export default PlayerSceneActivity;