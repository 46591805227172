import {genericAuthenticatedFetch, genericAuthenticatedPut} from './index';

export async function getTemplateList() {  
  return genericAuthenticatedFetch('procgen/template/list' );
}

export async function getFeatureList() {  
  return genericAuthenticatedFetch('procgen/feature/list' );
}

export async function getMutatorList() {  
  return genericAuthenticatedFetch('procgen/mutator/list' );
}

export async function getTemplate(template) {
  let escapedTemplate = encodeURIComponent(template); 
  return genericAuthenticatedFetch(`procgen/template?templateid=${escapedTemplate}` );
}

export async function updateTemplate(template) {
  return genericAuthenticatedPut('procgen/template/update/-1' , JSON.stringify( template));
}

export async function updateFeature(feature) {
  return genericAuthenticatedPut('procgen/feature/update/-1' , JSON.stringify( feature));
}

export async function deleteFeature(id) {
  return genericAuthenticatedFetch(`procgen/feature/delete/${id}`);
}


export async function updateMutator(feature) {
  return genericAuthenticatedPut('procgen/mutator/update/-1' , JSON.stringify( feature));
}

export async function deleteMutator(id) {
  return genericAuthenticatedFetch(`procgen/mutator/delete/${id}`);
}


export async function deleteTemplate(id) {
  return genericAuthenticatedFetch(`procgen/template/delete/${id}`);
}

export async function generateParameters(data) {
  let json = JSON.stringify(data);
  return genericAuthenticatedPut('procgen/parameters/generate', json );
}