import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { getAuthenticatedBinaryHeader } from '../utils/FetchHeaders';
import Config from './../../../config/Config';
import { useAuthDispatch } from './index';

export default function ManageAvatar2(props) {
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 50, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const dispatch = useAuthDispatch();

    const handleClose = () => {
        props.hideManager();

        //setPreview(null);
        setUpImg(null);
        setCompletedCrop(null);
        previewCanvasRef.current = null;
        imgRef.current = null;
    }

    const handleSubmit = async (files, allFiles) => {

        if (!crop || !previewCanvasRef.current) {
            return;
        }

        await previewCanvasRef.current.toBlob(blob => {
            console.log('Preview File');
            const data = new FormData();

            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                data.append('filename', 'bah.png');
                data.append('file', base64data);

                const headers = getAuthenticatedBinaryHeader();

                fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/avatar/upload/`, { method: 'POST', body: data, headers: headers }).then(
                    response => {
                        //console.log('response', response);
                        return response.json();
                    }
                ).then(
                    json => {
                        console.log('json', json);
                        if (json.result) {
                            localStorage.setItem('avatar', json.newavatar);
                            dispatch({ type: 'NEW_AVATAR', payload: { "avatar": json.newavatar } });
                            handleClose();
                        }
                    }
                );
            }
        });
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setUpImg(reader.result)
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        console.log('Loaded');
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current) {
            return;
        }
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    return (
        <Modal size="xl" centered show={props.showManager} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title >Manage Avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body >

                <div className="content">

                    <div>
                        <input type="file" accept="image/*" onChange={onSelectFile} />
                    </div>
                    <Row>
                        <Col md={5}>
                            <ReactCrop 
                               aspect = {1}
                                crop={crop} 
                                ruleOfThirds={true}
                                onChange={c => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                                onImageLoad = {(e)=> onLoad(e)}>    
                                <img src={upImg} ref={imgRef}/>
                            </ReactCrop>
                        </Col>
                        <Col md={5}>
                   
                                <canvas className={"rounded-circle content-image"}
                                    ref={previewCanvasRef}
                                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                    style={{
                                        width: Math.round(completedCrop?.width ?? 0),
                                        height: Math.round(completedCrop?.height ?? 0)
                                    }}
                                />
    
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"btn-themed-dark"} onClick={handleSubmit} disabled={!completedCrop?.width || !completedCrop?.height}>
                    Submit
                </Button>

                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}