import React, { useState, useEffect } from 'react';
import { Row, Button } from 'react-bootstrap';
import { useMobBuilder, useMobDispatch } from './MobProvider';
import AffectEditor from './../AffectEditor';
import EditableField from './../EditableField';
import ShopInventory from './ShopInventory';
import MobInventory from './MobInventory'

const mobsfieldsMap = [
    { field: 'name', description: 'Name', type: 3 },
    { field: 'display', description: 'Display', type: 3 },
    { field: 'description', description: 'Description', type: 0 },
    { field: 'race', description: 'Race', type: 2 },
    { field: 'gender', description: 'Gender', type: 2 },
    { field: 'money', description: 'Money', type: 2 },
    { field: 'level', description: 'Level', type: 2 },
    { field: 'tier', description: 'Mob Tier', type: 2 }
];


function MobGeneric(props) {
    let editing = props.editing;

    const { currentMobData } = useMobBuilder();
    const mobDispatch = useMobDispatch();

    const deleteAffect = (affectField, affectIndex) => {
        console.log('Deleting Behaviour from Mob', affectIndex);
        if (affectIndex >= 0) {
            mobDispatch({ type: "DELETE_AFFECT", field: affectField, payload: affectIndex });
        }
    }

    const updateAffect = (affectField, affectIndex, affectId, affectParameters) => {
        if (affectIndex >= 0) {
            let updateAffect = {};
            updateAffect.index = affectIndex;
            updateAffect.id = affectId;
            updateAffect.params = affectParameters;

            mobDispatch({ type: "SET_AFFECT", field: affectField, payload: updateAffect });
        }
    }

    const createAffect = (field) => {
        console.log('Creating ' + field + ' for Mob');
        let newBehaviour = {};
        newBehaviour.id = '';
        newBehaviour.params = '';
        //currentMobData.behaviours.push(newBehaviour);
        mobDispatch({ type: "ADD_AFFECT", field: field, payload: newBehaviour });
        //setCurrentRoomData(prevstate => ({ ...prevstate }));
    }

    const callDispatcher = (fieldName, newValue) => {
        mobDispatch({ type: "SET_FIELD", field: fieldName, payload: newValue });
    }

    if (currentMobData == null) return null;
    return (
        <>
            {mobsfieldsMap.map((fieldMap, index) =>
                <EditableField key={index} editing={editing}
                    field={fieldMap.field}
                    value={currentMobData[fieldMap.field]}
                    callDispatcher={callDispatcher}
                    description={fieldMap.description}
                    fieldType={fieldMap.type} />
            )}

            <Row className={"my-2"}>
                <strong> Effects:</strong>
            </Row>
            <Row>
                {currentMobData.effects.length === 0 ?
                    <span>No effects applied to Mob</span>
                    : null}
                <div className="container">
                    {currentMobData.effects.map((effect, index) =>
                        <div key={index} name={effect.id}>
                            <AffectEditor field={'effects'} effectIndex={index} effectId={effect.id} effectParameters={effect.params} editing={editing}
                                updateAffect={updateAffect}
                                deleteAffect={deleteAffect} />
                        </div>)}
                    {editing
                        ? <Button className={"btn-themed-dark m-2"} onClick={() => createAffect('effects')}>Add Effect</Button>
                        : null
                    }
                </div>

            </Row>
            <MobInventory inventory={currentMobData.items} editing={editing}/>
            <ShopInventory inventory={currentMobData.stock}/>

            
            <Row className={"my-2"}>
                <strong> Behaviours</strong>
            </Row>
            <Row>
                {currentMobData.behaviours.length === 0 ?
                    <span>No behaviours applied to Mob</span>
                    : null}
                <div className="container">

                    {currentMobData.behaviours.map((behaviour, index) =>
                        <div key={index} name={behaviour.id}>
                            <AffectEditor field={'behaviours'} effectIndex={index} effectId={behaviour.id} effectParameters={behaviour.params} editing={editing}
                                updateAffect={updateAffect}
                                deleteAffect={deleteAffect} />
                        </div>)}
                    {editing
                        ? <Button className={"btn-themed-dark m-2"} onClick={() => createAffect('behaviours')}>Add Behaviour</Button>
                        : null
                    }
                </div>

            </Row>
        </>);
}

export default MobGeneric;