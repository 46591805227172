import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import { getKinkDefinitions, getRoleplaySheetData } from './../components';
import Config from './../../../config/Config';
import { listPlayerKinks, updatePlayerKink } from '../components/kinksApi';
import KinkFilter from './kinks/KinkFilter';
import { useParams } from 'react-router-dom';

function PlayerKinks(props) {
  let match = useParams();
  console.log('match', match);
  console.log('props', props);

  //console.log(match);
  let defaultPlayer = localStorage.getItem('currentUser');
  if (match.player_id !== undefined) {
    defaultPlayer = match.player_id;
  }

  const [playerName, setPlayerName] = useState(defaultPlayer);
  const [kinkList, setKinkList] = useState([]);
  const [playerKinks, setPlayerKinks] = useState([]);

  const getKinkList = async () => {
    let data = await getKinkDefinitions();
    if (data.result) {
      data.kinks.forEach((kink, index) => {
        data.kinks[index].keywords = kink.keywords.split(',');
      });
      setKinkList(data.kinks);

    } else {
      setKinkList([]);
    }
  }

  const getPlayerKinks = async () => {
    if (playerName === undefined)
      setPlayerKinks([]);
    else {
      let data = await listPlayerKinks(playerName);
      //console.log(data);
      setPlayerKinks(data.kinks);
    }
  }

  useEffect(() => {
    getKinkList();
  }, []);

  useEffect(() => {
    getPlayerKinks();
  }, [playerName]);

  const updateKinkRecord = (newRecord) => {
    //console.log(newRecord);

    let index = playerKinks.findIndex((record) => {
      return (record.kink_id === newRecord.kink_id)
    });

    if (index < 0) {
      setPlayerKinks([...playerKinks, newRecord])
    } else {
      playerKinks[index] = newRecord;
      setPlayerKinks([...playerKinks])
    }
    updatePlayerKink(playerName, newRecord.kink_id, newRecord);
  }

  return (
    <div className="container container-eom" id="divMainContent">
      <h3>Kinks for {playerName}</h3>
      <KinkFilter kinks={kinkList} playerKinks={playerKinks} updateKink={updateKinkRecord} />
    </div>
  )
}

export default PlayerKinks;