import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchSchematics } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';

function SchemaSearch(props) {
    let products = props.products;
    let professions = props.professions;
    const [schematics, setSchematics] = useState([]);
    const [filterByName, setFilterByName] = useState('');
    const [filterByType, setFilterByType] = useState('');
    const [filterByProduct, setFilterByProduct] = useState('');
    const [filterByProfession, setFilterByProfession] = useState('');

    const templates = ['GenArmorTemplate',
        'GenBookDesign',
        'GenPaperDesign',
        'GenCageDesign',
        'GenCageRideableDesign',
        'GenClothingDesign',
        'GenComponentDesign',
        'GenConsumableRecipe',
        'GenContainerDesign',
        'GenEmbellishDesign',
        'GenFabricRecipe',
        'GenFountainDesign',
        'GenHeelDesign',
        'GenInstrumentDesign',
        'GenItemDesign',
        'GenLimbDesign',
        'GenRideableDesign',
        'GenScrollDesign',
        'GenSoleDesign',
        'GenTubDesign',
        'GenWeaponTemplate'];

    const getSchematicListFromServer = async () => {
        let data = await searchSchematics(filterByName, filterByType, filterByProduct, filterByProfession);
        if (data.result) {
            setSchematics(sortedSchematics(data.schematics));
        } else {
            setSchematics([]);
        }
    }

    useEffect(() => {
        if(props.params.schematic_id!==undefined)
            setFilterByName(props.params.schematic_id);

    },[props.params.schematic_id]);

    useEffect(() => {

        const delayDebounceFn = setTimeout(() => {
            // console.log(searchString)
            getSchematicListFromServer();
            // Send Axios request here
        }, 500)

        return () => clearTimeout(delayDebounceFn)

    }, [filterByName, filterByProduct, filterByProfession, filterByType]);


    const sortedSchematics = (schematicList) => {
        let sortedSchematics = schematicList.sort((a, b) => {
            return a.schematic_name.toUpperCase().localeCompare(b.schematic_name.toUpperCase());
        });

        return sortedSchematics;
    }

    const showFilter = () => {
        return <>
            <Row className={"row-centered py-1"}>
                <Col xs={3}>
                    <strong>Text Match:</strong>
                </Col>
                <Col>
                    <input value={filterByName} type="text" className="form-control" onChange={(e) => setFilterByName(e.currentTarget.value)} />
                </Col>
            </Row>
            <Row className={"row-centered py-1"}>
                <Col xs={3}>
                    <strong>Type:</strong>
                </Col>
                <Col xs={6}>
                    <DropdownButton
                        variant="outline-secondary"
                        title={filterByType == '' ? "Select Type" : filterByType}
                        id="input-group-dropdown-1"
                        disabled={products.length === 0}
                    >
                        {templates.map(template =>
                            <Dropdown.Item key={template} name={template} onClick={() => setFilterByType(template)}>{template}</Dropdown.Item>
                        )}
                    </DropdownButton>
                </Col>
                <Col>
                    <FontAwesomeIcon onClick={() => setFilterByType('')} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"} icon={"undo"} />
                </Col>
            </Row>
            <Row className={"row-centered py-1"}>
                <Col xs={3}>
                    <strong>Product:</strong>
                </Col>
                <Col xs={6}>
                    <DropdownButton className={"dropdown-menu-scroll"}
                        variant="outline-secondary"
                        title={filterByProduct == '' ? "Select Product" : filterByProduct}
                        id="input-group-dropdown-1"
                        disabled={products.length === 0}
                    >
                        {products.map(product =>
                            <Dropdown.Item key={product.product_id} name={product.product_id} onClick={() => setFilterByProduct(product.product_id)}>{product.product_id}</Dropdown.Item>
                        )}
                    </DropdownButton>
                </Col>
                <Col xs={1}>
                    <FontAwesomeIcon onClick={() => setFilterByProduct('')} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"} icon={"undo"} />
                </Col>
            </Row>
            <Row className={"row-centered py-1"}>
                <Col xs={3}>
                    <strong>Profession:</strong>
                </Col>
                <Col xs={6}>
                    <DropdownButton
                        variant="outline-secondary"
                        title={filterByProfession == '' ? "Select Profession" : filterByProfession}
                        id="input-group-dropdown-1"
                        disabled={products.length === 0}
                    >
                        {professions.map(profession =>
                            <Dropdown.Item key={profession.profession_id} name={profession.profession_id} onClick={() => setFilterByProfession(profession.profession_id)}>{profession.profession_id}</Dropdown.Item>
                        )}
                    </DropdownButton>
                </Col>
                <Col>
                    <FontAwesomeIcon onClick={() => setFilterByProfession('')} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"} icon={"undo"} />
                </Col>
            </Row>
        </>
    }

    return (
        <>
            <Row>
                <h4>Schematics</h4>
            </Row>
            {showFilter()}
            {schematics.map((schematic, index) =>
                <Row className={"my-1"} key={index} onClick={() => props.selectSchematic(schematic.schematic_id)}>
                    <Col xs={5}>
                        {schematic.schematic_name}
                    </Col>
                    <Col>
                        {schematic.schematic_id}
                    </Col>
                </Row>
            )}
        </>
    );
}

export default SchemaSearch;