import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import Home from './pages/Home';
import Online from './pages/Online';
import News from './pages/News';
import {
  IntroToMuds, IntroLoggingIn, CharacterCreation, IntroSetup, IntroRpSetup, IntroCommunication,
  IntroExploring, IntroEvents, IntroRoleplay, IntroRules, IntroGettingHelp
} from './pages/introscreens';
import { RealmMajorCities, RealmAreas, RealmWildlife, RealmSocieties, RealmDeities, RealmClans, RealmMaps } from './pages/realmscreens';
import {
  GameplayHelp, GameplayClasses, GameplayCombat,
  GameplayShopping, GameplayCrafting, GameplayRaces, GameplayScreenReader,
  GameplayRemorting, GameplayParties, GameplayHousing
} from './pages/gameplayscreens';

import Footer from './pages/Footer';
import TopNavBar from './pages/navigation/TopNavBar';
import PrivateRoute from './pages/Routes/PrivateRoute';
import AdminRoute from './pages/Routes/AdminRoute';
import AnsibleMessengerClient from './client/AnsibleMessengerClient';
import ContentViewer from './pages/player/ContentViewer';
import EoMEditor from './pages/EoMEditor';
import { RoleplaySheet, Channels, PlayerMessages, ScoreSheet, PlayerAccount, PlayerStories, PlayerScenes, WhatsGoingOn, PlayerEditor } from './pages/player/';
import { library } from '@fortawesome/fontawesome-svg-core';
import PlayerStorySubmission from './pages/player/PlayerStorySubmission';
import {
  faCheckSquare, faCoffee, faEye, faEyeSlash, faUser, faUndo, faUserPlus, faMapMarked, faMapMarkedAlt, faPaintRoller,
  faEnvelope, faBowlingBall, faTimesCircle, faCheckCircle, faTimes, faPlus,
  faChevronRight, faTrashAlt, faCog, faGlassWhiskey, faHamburger,
  faChevronDown, faSyncAlt, faEdit, faArrowUp, faArrowDown, faBoxOpen, faPortrait, faBackspace,
  faSortNumericUpAlt, faSortNumericDownAlt, faSortNumericUp, faSortNumericDown, faClone,
  faSortAmountUpAlt, faSortAmountDownAlt, faSortAmountUp, faSortAmountDown, faUnlink, faUserMinus,
  faAngleDoubleDown, faAngleDoubleRight, faItalic, faBold, faRedo, faVenus, faMars, faTransgender, faMercury, faBed, faFileExport, faFileImport

} from '@fortawesome/free-solid-svg-icons';

import navigation from './pages/navigation/NavigationConfiguration';
import Unauthorised from './pages/Unauthorised';
import IntroWebClient from './pages/introscreens/IntroWebClient';
import PlayerKinks from './pages/player/PlayerKinks';
import { useThemeBuilder } from './theming/ThemeProvider';
import PlayerScene from './pages/player/PlayerScene';
import ToastMaker from './pages/toast/ToastMaker';
import IntroScenes from './pages/introscreens/IntroScenes';

library.add(faCheckSquare, faTrashAlt, faCoffee, faEye, faEyeSlash, faUser, faEnvelope, faBowlingBall, faChevronRight, faChevronDown, faSyncAlt, faEdit, faTimesCircle, faUndo, faRedo,
  faCheckCircle, faArrowUp, faArrowDown, faCog, faHamburger, faGlassWhiskey, faPortrait, faBoxOpen, faBackspace, faMapMarked, faMapMarkedAlt, faClone, faBold, faItalic);

library.add(faAngleDoubleRight, faAngleDoubleDown, faSortNumericUpAlt, faSortNumericDownAlt, faSortNumericUpAlt, faSortNumericDownAlt, faSortAmountUpAlt, faSortAmountDownAlt, faSortAmountUp, faSortAmountDown, faSortNumericUp,
  faSortNumericDown, faUserPlus, faUserMinus, faTimes, faUnlink, faVenus, faMars, faTransgender, faMercury, faPlus, faBed, faFileExport, faFileImport, faPaintRoller)

function App() {
  const { theme } = useThemeBuilder();
  const adminNavigation = () => {
    return navigation.filter(target => (!target.divider));
  }


  const getApp = () => {
    let routes = adminNavigation();
    return (
      <div id="appviewport container-wide">
        <ToastMaker />
        <div id="wrap" data-menu-option="navhome">
          <TopNavBar />
          <div id="main" className="coloured-box px-0">
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/unauthorised' element={<Unauthorised />} />
              <Route path='/online' element={<Online />} />
              <Route path='/news' element={<News />} />

              <Route path='/intro/muds' element={<IntroToMuds />} />
              <Route path='/intro/loggingin' element={<IntroLoggingIn />} />
              <Route path='/intro/charcreation' element={<CharacterCreation />} />
              <Route path='/intro/gettingsetup' element={<IntroSetup />} />
              <Route path='/intro/webclient' element={<IntroWebClient />} />
              <Route path='/intro/roleplaysetup' element={<IntroRpSetup />} />
              <Route path='/intro/exploring' element={<IntroExploring />} />
              <Route path='/intro/communication' element={<IntroCommunication />} />
              <Route path='/intro/roleplay' element={<IntroRoleplay />} />
              <Route path='/intro/events' element={<IntroEvents />} />
              <Route path='/intro/scenes' element={<IntroScenes />} />
              <Route path='/intro/rules' element={<IntroRules />} />
              <Route path='/intro/gettinghelp' element={<IntroGettingHelp />} />
              <Route path='/intro/releases/:release?' element={<ContentViewer extraParams={{ type: 'release', content: "releasenotes" }} />} />
              <Route path='/client' element={<AnsibleMessengerClient />} />

              <Route path='/realm/cities' element={<RealmMajorCities />} />
              <Route path='/realm/areas' element={<RealmAreas />} />
              <Route path='/realm/wildlife' element={<RealmWildlife />} />
              <Route path='/realm/societies' element={<RealmSocieties />} />
              <Route path='/realm/maps' element={<RealmMaps />} />
              <Route path='/realm/deities' element={<RealmDeities />} />
              <Route path='/realm/clans' element={<RealmClans />} />

              <Route path='/gameplay/races' element={<GameplayRaces />} />
              <Route path='/gameplay/classes' element={<GameplayClasses />} />
              <Route path='/gameplay/combat' element={<GameplayCombat />} />
              <Route path='/gameplay/shops' element={<GameplayShopping />} />
              <Route path='/gameplay/housing' element={<GameplayHousing />} />
              <Route path='/gameplay/screenreader' element={<GameplayScreenReader />} />
              <Route path='/gameplay/crafting/:page?' element={<GameplayCrafting />} />
              <Route path='/gameplay/help' element={<GameplayHelp />} />
              <Route path='/gameplay/remorts' element={<GameplayRemorting />} />
              <Route path='/gameplay/parties' element={<GameplayParties />} />
              <Route path='/editor' element={<EoMEditor />} />

              <Route path='/player/rpsheet/:playername?' element={<PrivateRoute><RoleplaySheet /></PrivateRoute>} />
              <Route path='/player/channels' element={<PrivateRoute><Channels /></PrivateRoute>} />
              <Route path='/player/messages' element={<PrivateRoute><PlayerMessages /></PrivateRoute>} />
              <Route path='/player/scoresheet' element={<PrivateRoute><ScoreSheet /></PrivateRoute>} />
              <Route path='/player/account' element={<PrivateRoute><PlayerAccount /></PrivateRoute>} />
              <Route path='/player/stories/:storyid?' element={<PrivateRoute><PlayerStories /></PrivateRoute>} />
              <Route path='/player/scene/:scene_id?' element={<PrivateRoute><PlayerScene /></PrivateRoute>} />
              <Route path='/player/scenes' element={<PrivateRoute><PlayerScenes /></PrivateRoute>} />
              <Route path='/player/storysubmission' element={<PrivateRoute><PlayerStorySubmission /></PrivateRoute>} />
              <Route path='/player/goingon' element={<PrivateRoute><WhatsGoingOn /></PrivateRoute>} />
              <Route path='/player/editor' element={<PrivateRoute><PlayerEditor /></PrivateRoute>} />
              <Route path='/player/kinks/:player_id?' element={<PrivateRoute><PlayerKinks /></PrivateRoute>} />

              <Route path='/player/devblog/:blog?' element={
                <PrivateRoute>
                  <ContentViewer extraParams={{ type: 'blog', content: "devblog" }} />
                </PrivateRoute>
              } />
              {routes.map((destination, index) =>
                <Route
                  key={index}
                  path={destination.path}
                  element={
                    <AdminRoute role={destination.role}>
                      {<destination.component extraParams={destination.extraParams} />}
                    </AdminRoute>
                  }
                />
              )}

            </Routes>

          </div>
        </div>

        <Footer />

      </div>
    );
  }
  let themeToUse = theme;
  if (themeToUse === undefined) {
    themeToUse = 'light';
  }
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href={`/${themeToUse}.lazy.css`} />
      </Helmet>
      {getApp()}

    </>
  );
}

export default App;