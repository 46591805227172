import React from 'react';
import Config from './../../../config/Config';

const PlayerAvatar = ({ avatarlocation, size }) => {
    var imagelocation = null;
    if ((avatarlocation !== undefined) && (avatarlocation != null) && (avatarlocation.trim() != "")) {

        imagelocation = `https://${Config.middleware.host}:${Config.middleware.port}/` + avatarlocation;

    } else {
        imagelocation = `https://${Config.middleware.host}:${Config.middleware.port}/default.png`;
    }
    if (imagelocation == null) {
        return null;
    }
    return <img width={size} height={size} src={imagelocation} className="rounded-circle p-1" alt="avatar"></img>;
}

export default PlayerAvatar;