import React, { Component } from 'react';

class RealmWildlife extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">

        <h2>Flora, Fauna and Geology</h2>
				<p>In the realm of midnight, different types of material can be harvested from the land using various skills.</p>
				<p>Many of these abilities are looking for these materials in different parts of the soil and this is broken down into what are known as <strong>strata</strong>.</p>
				<p>There are a number of what are known as <strong>Gathering</strong> skills that can be utilised to extract the raw materials that are to be used for crafting.</p>
				<br/>
				<h3>The Air</h3>
				<p>This primarily concerns all things floating around in the atmosphere, different gasses and the like.</p>
				<p><strong>Related skills</strong> N/A</p>
                
			
      	<h3>Fauna</h3>
				<p>Things that walk, crawl and flitter about the land</p>
				<p><strong>Example</strong> Deer, Rabbits</p>
				<p><strong>Related skills</strong> Hunting, Butchering</p>
                
				<h3>Flora</h3>			
				<p>Things that can be found amongst the plants.</p>
				<p><strong>Example Trees</strong>, flowers and herbs.</p>
				<p><strong>Related skills</strong> Chopping, Herbology, Farming</p>
             
				<h3>Ground</h3>
				<p>Things that can be found on the ground.</p>
				<p><strong>Example </strong>Mushrooms</p>
				<p><strong>Related skills</strong> Foraging</p>
                
				<h3>Earth</h3>
				<p>The soil, all things that are found in the soil that need not be dug too deep.</p>
				<p><strong>Example </strong> Root Vegetables</p>
				<p><strong>Related skills</strong> Foraging, Farming</p>                
				<h3>Underground</h3>
				<p>Going deep beneath the soil, precious materials can be found</p>
				<p><strong>Example</strong> Oil, Gems</p>
				<p><strong>Related skills</strong> Mining</p>
                <h3>Underwater</h3>
				<p>Searching where things that lurk beneath the waves.</p>
				<p><strong>Example</strong> Fish</p>
				<p><strong>Related skills</strong> Fishing</p>
			
      </div>
    );
  }
}

export default RealmWildlife;