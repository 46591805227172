import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function AffectEditor(props) {

    const [editing, setEditing] = useState(false);
    const [effectIndex, setEffectIndex] = useState(-1);
    const [affectId, setAffectId] = useState('');
    const [affectParameters, setAffectParameters] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setEditing(props.editing);
        setEffectIndex(props.effectIndex);
        setAffectId(props.effectId);
        setAffectParameters(props.effectParameters);
    }, [props]);

    let updateAffect = props.updateAffect;
    let deleteAffect = props.deleteAffect;
    let field = props.field;

    //console.table('Effect Props = ', props);

    const updateAffectId = async (newValue) => {
        setAffectId(newValue);
        updateAffect(field, effectIndex, newValue, affectParameters);
    }

    const updateAffectParameters = async (newValue) => {
        setAffectParameters(newValue);
        updateAffect(field, effectIndex, affectId, newValue);
    }

    const deleteEffectFromParent = async (e) => {
        console.log('Deleting Affect', field, effectIndex);
        if (deleteAffect !== undefined)
            deleteAffect(field, effectIndex);
    }

    return (
        <>
            {editing
                ? (
                    <>
                        <Row>
                            <Col md={2}>
                                <ReactTooltip id="tooltipDelete" place="top" effect="solid">
                                    Delete Entry
                                </ReactTooltip>
                                <FontAwesomeIcon onClick={deleteEffectFromParent} data-tip data-tooltip-id="tooltipDelete" className="fa fa-lg fa-eom" icon={"trash-alt"} />
                                <span>
                                    Affect:
                                </span>
                            </Col>
                            <Col md={8}>
                                <input type="text" className="form-control" value={affectId} onChange={(e) => updateAffectId(e.currentTarget.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <span>
                                    Params:
                                </span>
                            </Col>
                            <Col md={8}>
                                <input type="text" className="form-control" value={affectParameters} onChange={(e) => updateAffectParameters(e.currentTarget.value)} />
                            </Col>
                        </Row>
                    </>
                ) : (<span>{affectId} ({affectParameters})</span>)}
        </>
    )
}

export default AffectEditor;