import React, { useState, useEffect } from 'react';
import { Doughnut, PolarArea } from 'react-chartjs-2';
import { Row, Col } from 'react-bootstrap';
import { getBankingOverview, getBankTransactions, getBankAccount } from '../components';
import { timeConverter } from '../utils/NiceStrings';

import { ArcElement, CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto';
import "chart.js/auto";
Chart.register(ArcElement);
Chart.register(CategoryScale);

function AdminBankingPortal() {

    const clearData = () => {
        setBankingOverview();
    }

    const initialCrumbs = [{
        name: 'Overview',
        onClick: () => {
            setActiveView('overview');
            setBankingOverview();
            setBankAccount();
            setPlayerName();
            setError('');
            setCrumbs(initialCrumbs);
        }
    }];

    const [activeView, setActiveView] = useState('overview');
    const [bankingOverview, setBankingOverview] = useState();
    const [bankDonoughtData, setBankDonoughtData] = useState();
    const [bankingDetail, setBankingDetail] = useState();
    const [bankDetailData, setBankDetailData] = useState();
    const [bankAccount, setBankAccount] = useState();
    const [playerName, setPlayerName] = useState();
    const [error, setError] = useState();

    const [crumbs, setCrumbs] = useState(initialCrumbs);

    const breadcrumbs = (breadcrumbs) => (
        < >
            {breadcrumbs.map((breadcrumb, index) => (
                <span key={index} className="px-2" id={breadcrumb.bankid} onClick={breadClick}>
                    {breadcrumb.name}
                </span>
            ))}
        </>
    );

    const getBankingOverviewLocal = async () => {
        let data = await getBankingOverview();
        // console.log('Banking Data', data);
        if (data.result) {
            setBankingOverview(data);
            buildData(data);
        } else {
            setBankingOverview();
        }
    }


    useEffect(() => {
        console.log('Changing view')
        if (bankingOverview === undefined)
            getBankingOverviewLocal();

    }, [bankingOverview, bankingDetail, bankAccount]);


    const buildData = (bankingOverview) => {
        //console.log('Wealth Data', wealthData);
        const numbers = [];
        const labels = [];
        const accounts = [];

        bankingOverview.banks.map(bank => {
            //console.table(bank);
            labels.push(bank.banktitle);
            numbers.push(bank.bankaum);
            accounts.push(bank.bankaccounts);
        });

        setBankDonoughtData({
            labels: labels,
            datasets: [
                {
                    data: numbers,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 4,
                    hoverBorderWidth: 2,
                    borderAlign: 'inner',

                },
                {
                    data: accounts,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 4,
                    hoverBorderWidth: 2,
                    borderAlign: 'inner',

                },
            ],
        });
    }

    const buildBankDetailData = (bankData) => {
        //console.log('Wealth Data', wealthData);
        const numbers = [];
        const labels = ['Clan Money', 'Player Money'];
        const accounts = [];

        numbers.push(bankData.clanaum);
        numbers.push(bankData.playeraum);


        setBankDetailData({
            labels: labels,
            datasets: [
                {
                    data: numbers,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 4,
                    hoverBorderWidth: 2,
                    borderAlign: 'inner',

                }
            ],
        });
    }

    const getBankingDetailLocal = async (bankid) => {
        let data = await getBankingOverview(bankid);
        // console.log('Banking Details', data);
        if (data.result) {
            setBankingDetail(data);
            buildBankDetailData(data);
        } else {
            setBankingDetail();
        }
    };

    const getAccountDataLocal = async (bankid, accountid) => {
        let data = await getBankTransactions(bankid, accountid);
        // console.log('Banking Details', data);
        if (data.result) {
            setBankAccount(data);
        } else {
            setBankAccount();
        }
    }

    const breadClick = async (e) => {
        // console.log(e.target.getAttribute('id'));
        let bankid = e.target.getAttribute('id');
        // console.log(bankid);

        setActiveView('bank');

        // console.table(crumbs);
        let reducedCrumbs = crumbs.slice(0, 2);
        setCrumbs(reducedCrumbs);
        setBankDetailData();
        getBankingDetailLocal(bankid);
        setBankAccount();
        setPlayerName();
        setError('');

    };

    const getPlayerNameFromAccount = (accountid) => {
        if (accountid === null)
            return;
        // console.log('looking', accountid);
        const id = parseInt(accountid);
        const found = bankingDetail.topaccounts.filter((account) => {
            return account.accountid === id;
        });

        // console.log('found', found[0].customerid);
        return found[0].customerid;
    }

    const accountClick = async (e) => {
        // console.log(e.target);
        let accountid = e.target.getAttribute('id');
        // console.log('Account', accountid);

        setActiveView('account');

        let playername = getPlayerNameFromAccount(accountid);
        // console.log('found', playername);

        let newCrumbs = crumbs.slice(0, 2);
        newCrumbs.push({
            name: playername,
            accountid: accountid,
        });
        // console.table(newCrumbs);
        setCrumbs(newCrumbs);
        getAccountDataLocal(bankingDetail.bankid, accountid);
    };

    const doBankDetailOptions = {
        onClick: function (evt, element) {
            if (element.length > 0) {
                let newCrumbs = crumbs.slice(0, 2);
                // newCrumbs.push({
                //     name: bankingOverview.banks[element[0].index].banktitle,
                //     bankid: bankingOverview.banks[element[0].index].bankid,
                // });
                // console.table(newCrumbs);
                setCrumbs(newCrumbs);

                setBankAccount();
                setPlayerName();
                setError('');


            }
        }
        ,
        zoomOutPercentage: 55, // makes chart 40% smaller (50% by default, if the preoprty is undefined)


    };

    const donoughtOptions = {
        onClick: function (evt, element) {
            if (element.length > 0) {
                let newCrumbs = crumbs.slice(0, 1);
                newCrumbs.push({
                    name: bankingOverview.banks[element[0].index].banktitle,
                    bankid: bankingOverview.banks[element[0].index].bankid,
                });
                // console.table(newCrumbs);
                setCrumbs(newCrumbs);
                setBankDetailData();
                setBankAccount();
                setPlayerName();
                setError('');
                getBankingDetailLocal(bankingOverview.banks[element[0].index].bankid);
            }
        }
        ,
        zoomOutPercentage: 55, // makes chart 40% smaller (50% by default, if the preoprty is undefined)


    };

    const getBankDetail = () => {
        if (bankingDetail === undefined) {
            return null;
        }

        if(bankDetailData===undefined) {
            return (<>Loading...</>);
        }

        return (
            <Col md={width}>
                <Row>
                    <h2>
                        {bankingDetail.banktitle}
                    </h2>
                </Row>
                <Row>
                    <p className={"px-2"}>Total AUM </p> <span>{bankingDetail.totalaum.toLocaleString()}</span>
                </Row>
                <Row>
                    <p className={"px-2"}>Clan AUM </p> <span>{bankingDetail.clanaum.toLocaleString()}</span>
                </Row>
                <Row>
                    <p className={"px-2"}>Player AUM </p> <span>{bankingDetail.playeraum.toLocaleString()}</span>
                </Row>
                <Row>
                    <div height={400} width={400}>
                        <Doughnut data={bankDetailData} options={doBankDetailOptions} height={400} width={400} />
                    </div>
                </Row>
                <Row>
                    <h2>Top Accounts</h2>
                </Row>
                <Row>

                    <div>
                        {bankingDetail.topaccounts.map((account, index) =>
                            <Row key={index} id={account.accountid} onClick={accountClick}>
                                <Col xs={1} >{index + 1}</Col>
                                <Col xs={6} id={account.accountid}>{account.customerid}</Col>
                                <Col style={{ textAlign: "right" }}>
                                    <font color={account.balance >= 0 ? "blue" : "red"}>
                                        {account.balance.toLocaleString()}
                                    </font>
                                </Col>
                            </Row>)
                        }
                    </div>
                </Row>
            </Col>
        )
    }

    const getBankAccountIdLocal = async () => {
        // console.log('Banking Details', bankingDetail.bankid);
        // console.log('Banking Details', playerName);

        let data = await getBankAccount(bankingDetail.bankid, playerName);
        // console.log('Banking Details', data);
        if (data.result) {
            let newCrumbs = crumbs.slice(0, 2);
            newCrumbs.push({
                name: playerName,
                accountid: data.accountid,
            });
            // console.table(newCrumbs);
            setCrumbs(newCrumbs);

            getAccountDataLocal(bankingDetail.bankid, data.accountid);
        } else {
            setError(data.reason);
        }
    }

    const onKeyUpEvent = async e => {
        if (e.key === 'Enter') {
            getBankAccountIdLocal();
        }
    }

    const getBankAccountDetail = () => {
        if (bankingDetail === undefined) {
            return null;
        }
        if (bankAccount === undefined) {
            return (
                <div>
                    <Row>
                        <div className="input-group mb-3" onSubmit={getBankAccountIdLocal}>
                            <div className="input-group-prepend" >
                                <button className="btn btn-themed-dark" onClick={getBankAccountIdLocal} type="submit">Get Bank Account</button>
                            </div>
                            <input type="text" className="form-control" defaultValue={playerName} onKeyUp={onKeyUpEvent} onChange={(e) => {
                                setPlayerName(e.currentTarget.value);
                                setBankAccount();
                            }} />
                        </div>
                        <h3>
                            <font color={"red"}>
                                {error}
                            </font>
                        </h3>
                    </Row>
                </div>

            );
        }
        return (
            <Col>
                <Row>
                    <h2>Account: {bankAccount.owner}</h2>
                    <br />
                    <small> ({bankAccount.accountid})</small>
                </Row>
                <Row>
                    <p>Balance  {bankAccount.balance.toLocaleString()}</p>
                </Row>
                <Row>
                    <div>
                        {bankAccount.transactions.map(transaction =>
                            <Row key={transaction.transactionid}>
                                <Col>
                                    {timeConverter(transaction.timestamp)}
                                </Col>
                                <Col style={{ textAlign: "right" }}>
                                    <font color={transaction.value >= 0 ? "blue" : "red"}>
                                        {transaction.value.toLocaleString()}
                                    </font>
                                </Col>
                                <Col>
                                    {transaction.actor}
                                </Col>
                                <Col>
                                    {transaction.reason}
                                </Col>
                            </Row>

                        )}
                    </div>
                    <Col>

                    </Col>
                </Row>

            </Col>
        );
    }
    let width = 10;
    if(bankingDetail!==undefined){
        width=4;
    }

    console.log(bankDonoughtData);

    if(bankDonoughtData===undefined) {
        return <span>Loading...</span>
    }
    return (
        <div className="container-eom" id="divMainContent">
            {breadcrumbs(crumbs)}
            <Row>
                <Col md = {width} className="mx-2">
                    <Row >
                        <h1>Bank Overview</h1>
                    </Row>
                    <Row>
                        <div>
                            <Doughnut data={bankDonoughtData}  options={donoughtOptions} height={400} width={400} />  

                        </div>

                    </Row>
                </Col>
                {getBankDetail()}
                {getBankAccountDetail()}

            </Row>
        </div >
    )
}

export default AdminBankingPortal;