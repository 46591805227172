import React, { useState } from 'react';
import { Row, Col, Button, Accordion, Dropdown, InputGroup } from 'react-bootstrap';
import { timeConverter } from '../utils/NiceStrings';
import { useAuthState } from '../components';
import TextLabels from './TextLabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const transistions = {};
transistions.Reopened = ['UpNext', 'InProgress', 'Backlog', 'Rejected', 'CantFix', 'WontFix', 'WaitingOnPlayer'];
transistions.New = ['UpNext', 'InProgress', 'Backlog', 'Rejected', 'CantFix', 'WontFix', 'WaitingOnPlayer'];
transistions.Backlog = ['UpNext', 'Todo', 'Backlog', 'Rejected', 'InProgress', 'WaitingOnPlayer', 'Closed', 'WorkingAsIntended'];
transistions.Todo = ['UpNext', 'InProgress', 'Backlog', 'Rejected', 'WaitingOnPlayer'];
transistions.InProgress = ['UpNext', 'Backlog', 'Todo', 'Done', 'WaitingOnPlayer', 'Closed', 'WorkingAsIntended'];
transistions.Done = ['InProgress', 'Reopened', 'Released'];
transistions.Released = ['Reopened'];
transistions.Rejected = ['Reopened'];
transistions.Duplicate = ['Reopened'];
transistions.CantFix = ['Reopened', 'Backlog'];
transistions.WontFix = ['Reopened', 'Backlog'];
transistions.WaitingOnPlayer = ['UpNext', 'InProgress', 'Backlog', 'Rejected', 'CantFix', 'WontFix', 'Closed', 'WorkingAsIntended'];
transistions.PlayerResponded = ['UpNext', 'InProgress', 'Backlog', 'Rejected', 'CantFix', 'WontFix', 'WaitingOnPlayer', 'Closed', 'WorkingAsIntended'];

const EoMIssue = ({ issue, statusChange, assignToUser, addComment, nextIssue, previousIssue }) => {

    const { currentUser } = useAuthState();
    const [commenting, setCommenting] = useState(false);
    const [assigning, setAssigning] = useState(false);
    const [assignText, setAssignText] = useState(issue === undefined ? '' : issue.issue_assignee);
    const [sourceText, setSourceTextText] = useState('');
    const [labels, setLabels] = useState([]);

    //    console.log(issue);
    if (issue === undefined)
        return null;

    let transistion_options = transistions[(issue.issue_status)];

    if (transistion_options === undefined) {
        transistion_options = [];
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const setStatusOfIssue = async (newStatus) => {
        statusChange(newStatus);
        //console.log(`Change Status to ${newStatus}`);
    }

    const assignToCurrentUser = async () => {
        assignToUser(currentUser);
    }

    const assignToEnteredUser = async () => {
        assignToUser(assignText);
        setAssigning(false);
    }

    const createNewComment = async () => {
        addComment(currentUser, sourceText);
        setCommenting(false);
    }

    const commentingClick = async (e) => {
        setSourceTextText('');
        setCommenting(true);
    }

    const cancelCommentingClick = async (e) => {
        setSourceTextText('');
        setCommenting(false);

    }

    const assigningClick = async (e) => {
        setAssignText(issue.issue_assignee);
        setAssigning(true);
    }

    const cancelAssigningClick = async (e) => {
        setAssignText(issue.issue_assignee);
        setAssigning(false);
    }

    const handleAssignChange = e => {
        let text = e.target.value;
        setAssignText(text);
    }

    const handleChange = e => {
        let text = e.target.value;
        setSourceTextText(text);
    }

    const sortedComments = () => {
        return issue.comments.sort((a, b) => a.comment_updated - b.comment_updated);
    }

    const updateLabels = (newLabels) => {
        console.log("Updating Labels", newLabels);
        setLabels(newLabels);
        issue.labels = newLabels;
        statusChange(issue.issue_status);
    }

    return (
        <div className={"eomIssue p-1 m-1"}>
            <Row className={"p-2"}>
                <Col xs={1}>
                    <FontAwesomeIcon onClick={previousIssue} className="fa fa-lg fa-eom m-2" icon={"arrow-up"} />
                    <FontAwesomeIcon onClick={nextIssue} className="fa fa-lg fa-eom m-2" icon={"arrow-down"} />


                </Col>
                <Col>
                <h3>
                    <strong>{issue.issue_type}
                        <span> {issue.issue_id}</span></strong> - {capitalizeFirstLetter(issue.issue_description)}
                </h3>

                </Col>


            </Row>
            <Row className={"m-2"}>
                <Col xs={1} className={"mx-2 p-1"}>
                    {assigning
                        ?
                        <InputGroup>
                            <Button className={"btn-issue"} onClick={assignToEnteredUser}>
                                Assign
                            </Button>
                            <textarea className="eomAssignEditor" value={assignText} onChange={handleAssignChange} autoFocus rows="1"></textarea>
                            <Button className={"btn-issue"} onClick={cancelAssigningClick}>
                                Cancel
                            </Button>
                        </InputGroup>
                        :
                        <Button className={"m-2 btn-issue"} onClick={assigningClick}>
                            Assign
                        </Button>
                    }
                </Col>
                <Col xs={1} className={"mx-2 p-1"}>
                    <Dropdown className={"m-2 btn-eom"}>
                        <Dropdown.Toggle id="dropdown-basic">
                            Update Status
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {transistion_options.map((option, index) =>
                                <Dropdown.Item key={index} onClick={() => setStatusOfIssue(option)}>{option}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

            </Row>
            <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen >
                <Accordion.Item eventKey={'0'} className={"accordion-body"}>
                    <Accordion.Header className={"m-2"}>
                        <h4>Details</h4>
                    </Accordion.Header>
                    <Accordion.Body className={"m-2"}>
                        <Row>
                            <Col xs={3}>
                                <strong>Type:</strong>
                            </Col>
                            <Col xs={6}>
                                <span>{issue.issue_type}</span>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3}>
                                <strong>Reported By:</strong>
                            </Col>
                            <Col xs={6}>
                                <span>{issue.issue_reporter}</span>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3}>
                                <strong>Assigned to:</strong>
                            </Col>
                            <Col xs={6}>
                                {issue.issue_assignee === ''
                                    ? <Button onClick={assignToCurrentUser}>Assign To Me</Button>
                                    : <span>{issue.issue_assignee}</span>}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3}>
                                <strong>Raised:</strong>
                            </Col>
                            <Col xs={6}>
                                {timeConverter(issue.issue_timestamp)}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3}>
                                <strong>Updated:</strong>
                            </Col>
                            <Col xs={6}>
                                {timeConverter(issue.issue_updated)}
                            </Col>
                        </Row>


                        <Row>
                            <Col xs={3}>
                                <strong>Location:</strong>
                            </Col>
                            <Col xs={6}>
                                <a className="dryLink" href={`/builder/area/${escape(issue.issue_location)}`}>
                                    <span>{issue.issue_location}</span>
                                </a>


                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3}>
                                <strong>Status:</strong>
                            </Col>
                            <Col xs={6}>
                                <span>{issue.issue_status}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <strong>Labels:</strong>
                            </Col>
                            <Col xs={6}>
                                <TextLabels editing={true} defaultLabels={issue.labels} labelChange={(value) => updateLabels(value)} />
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={'1'} className={"accordion-body"}>
                    <Accordion.Header>
                        <h4>Description</h4>
                    </Accordion.Header>
                    <Accordion.Body className={"m-2"}>
                        <span>{issue.issue_description}</span>
                    </Accordion.Body>
                </Accordion.Item>

                {issue.comments.length > 0 ?
                    <Accordion.Item eventKey={'2'} className={"accordion-body"}>
                        <Accordion.Header>
                            <h4>Comments ({sortedComments().length})</h4>
                        </Accordion.Header>
                        <Accordion.Body className={"m-2"}>
                            {issue.comments.map((comment, index) =>
                                <div key={index}>
                                    <Row>

                                        <strong>{comment.comment_author} </strong>

                                        <span className="px-2"> on {timeConverter(comment.comment_updated)}</span>
                                    </Row>
                                    <Row>
                                        <span>{comment.comment_text}</span>
                                    </Row>
                                    <br />
                                </div>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                    : null}
            </Accordion>
            {commenting
                ?
                <div className="p-2">
                    <Row>
                        <textarea className="my-2 px-2 eomCommentEditor" value={sourceText} onChange={handleChange} autoFocus rows="3"></textarea>
                    </Row>
                    <Row>
                        <Button className={"m-2 btn-issue"} onClick={createNewComment}>
                            Add
                        </Button>
                        <Button className={"m-2 btn-issue"} onClick={cancelCommentingClick}>
                            Cancel
                        </Button>
                    </Row>
                </div>
                :
                <Button className={"m-2 btn-issue"} onClick={commentingClick}>
                    Comment
                </Button>
            }
        </div>

    )
}

export default EoMIssue;