import React, { Component } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

class PlayerNavBar extends Component {
  render() {
    return (
      <NavDropdown title="Player Access" id="basic-nav-dropdown">
        <NavDropdown.Item as={Link} to="/editor">Colour Editor</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/player/channels">Channel Backlog</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/player/rpsheet">Roleplay Sheets</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/player/scoresheet">Score Sheet</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/player/account">Linked Characters</NavDropdown.Item>
        <NavDropdown.Divider className="divider2" />
        <NavDropdown.Item as={Link} to="/player/kinks">Kinks Editor</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/player/messages">Messages</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/player/devblog">Developer's Blog</NavDropdown.Item>
        <NavDropdown.Divider className="divider2" />
        <NavDropdown.Item as={Link} to="/player/scenes">Scenes</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/player/stories">Stories</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/player/storysubmission">Story Editor</NavDropdown.Item>
        <NavDropdown.Divider className="divider2" />
        <NavDropdown.Item as={Link} to="/player/goingon">What's Going On?</NavDropdown.Item>
      </NavDropdown>
    );
  }
}

export default PlayerNavBar;