import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { logout, useAuthState, useAuthDispatch, toggleMenu } from './../components/index';
import PasswordChange from './../components/PasswordChange';
import ManageAvatar from './../components/ManageAvatar';
import Config from './../../../config/Config';


function PlayerMenu() {
  const { loading, showPlayerMenu, token, currentUser, avatar } = useAuthState();
  const dispatch = useAuthDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = async () => {
    setShow(true);
  }

  const handleLogout = async (e) => {
    e.preventDefault();
    let response = await logout(dispatch);
  }

  var imagelocation = '/images/avatar.png';
  let basics = {};
  if (avatar != null) {
    //console.log('Avatar is .' + avatar+ '.');
    basics = JSON.parse(localStorage.getItem('basicinfo'));
    if ((avatar !== undefined) && (avatar.trim() != "")) {
      imagelocation = `https://${Config.middleware.host}:${Config.middleware.port}/` + avatar;
    }
  }

  useEffect(() => {
    //  console.log('showPlayerMenu', showPlayerMenu)
  }, [showPlayerMenu]);

  if (showPlayerMenu && Boolean(token)) {
    return (
      <div className="sidenav">
        <ManageAvatar showManager={show} hideManager={handleClose} />
        <Row className="navbar-dark px-4 align-self-center menu-accordian">
          <Col xs md="auto">
            <div className="containerx rounded-circle">
              <img width="150" height="150" src={imagelocation} alt="Avatar" className={"imagex rounded-circle content-image"}/>
              <div className="middle fadeIn-bottom ">
                <div className="text" onClick={handleShow}>Edit Avatar</div>
              </div>
            </div>
          </Col>
          <Col>
            <h4 className={"inline-text"} >{currentUser} {basics.surname}</h4>
            <span className="menu-text">Level {basics.level} {basics.class}</span>
          </Col>
        </Row>
        <div>
          <a href="/player/editor" className="navbar-dark px-3 align-self-center menu-accordian">Edit Profile</a>
          <PasswordChange className={"menu-accordian"} />

          <a href="#contact" onClick={handleLogout} disabled={loading}>Logout</a>
        </div>
      </div>
    );
  } else {
    return (
      <div />
    );
  }
}

export default PlayerMenu;