import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Config from '../../../config/Config';
import { getStandardHeader } from '../utils/FetchHeaders';
import { useParams } from 'react-router-dom';
import EomMarkdown from '../components/EomMarkdown';

const pages = [
  { 'id': 'crafting1', 'title': 'Overview', "type": "html" },
  { 'id': 'crafting2', 'title': 'Making your First Item', "type": "html" },
  { 'id': 'styling-guide', 'title': 'Styling', "type": "md" },
  { 'id': 'crafting3', 'title': 'Advanced Dyeing', "type": "html" },
  { 'id': 'crafting4', 'title': 'Embellishments', "type": "html" },
  { 'id': 'crafting6', 'title': 'Expertises', "type": "html" },
  { 'id': 'crafting7', 'title': 'Armour Crafting', "type": "html" },
  { 'id': 'crafting8', 'title': 'Weapon Crafting', "type": "html" },
  { 'id': 'crafting9', 'title': 'Materials', "type": "html" },
  { 'id': 'crafting10', 'title': 'Missions', "type": "html" },
  { 'id': 'crafting11', 'title': 'Cooking', "type": "html" },
];

function GameplayCrafting({ match }) {
  const params = useParams();

  let defaultPage = undefined;
  if (params.page !== undefined) {
    defaultPage = params.page;
  }

  const [currentPage, setCurrentPage] = useState();
  const [pageContent, setPageContent] = useState('');

  const loadPage = (page) => {
    setCurrentPage(page);
  }

  const fetchExternalHTML = (releaseToLoad) => {
    const headers = getStandardHeader();
    fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/crafting/page/` + releaseToLoad, { mode: 'cors', headers: headers })
      .then((r) => r.text())
      .then(text => {
        setPageContent(text);
      })
  }

  useEffect(() => {
    console.log('default',defaultPage);
    if (defaultPage !== undefined) {
      const page = pages.find(e => e.id === defaultPage);
      setCurrentPage(page);
    }
  }, [defaultPage]);

  useEffect(() => {
    console.log(currentPage);
    if (currentPage !== undefined) {
      if (currentPage.type === 'html')
        fetchExternalHTML(currentPage.id);
      else if (currentPage.type === 'md') {
        fetch('/guides/' + currentPage.id + ".md")
        .then((r) => r.text())
        .then(text => {
          setPageContent(text);
        })
      }
    }
  }, [currentPage]);

  const currentPageType = () => {

    if (currentPage === undefined) {
      return 0;
    } else if (currentPage.type=="md") {
      return 1;
    } else
      return 2;
  }

  return (
    <div>
      <br></br>
      <Row className={"align-items-left flex-nowrap"}>
        <Col xs="3">
          <h4>Quick Links to Crafting Guides</h4>
          {pages.map(page =>
            <div className="p-2 m-2" onClick={() => loadPage(page)} name={page.id} key={page.id}>
              {page.title}
            </div>
          )}
        </Col>
        {(currentPageType() == 2) ?
          <Col>
            <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
          </Col>
          :
          <Col>
            <EomMarkdown markdown={pageContent}/>
          </Col>
        }

      </Row>
      <br></br>
    </div>
  );
}

export default GameplayCrafting;