import React, { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const TextLabels = ({ editing, labelChange, defaultLabels, selectedLabels, selectLabel }) => {
    const [labels, setLabels] = useState([]);
    const [activeLabels, setActiveLabels] = useState([]);
    const [newLabel, setNewLabel] = useState('');


    useEffect(() => {
        setLabels(defaultLabels);
    }, [defaultLabels]);


    useEffect(() => {
        if (selectedLabels !== undefined)
            setActiveLabels(selectedLabels);
        else
            setActiveLabels([]);

    }, [selectedLabels]);

    const deleteLabel = (value) => {
        console.log("delete", value);
        let newLabels = labels.filter(a => a !== value);
        setLabels(newLabels);
        labelChange(newLabels);
    }

    const addLabel = (value) => {
        console.log("add", value);
        if (value.length == 0)
            return;
        let newLabels = [...labels, value];
        setLabels(newLabels);
        labelChange(newLabels);
        setNewLabel('');
    }

    const selectLocalLabel = (label) => {
        if(selectLabel!==undefined) {
            selectLabel(label);
        }
    }

    return (
        <div className={"markdown-editor-header"}>
            {labels.map((label, index) => {
                const isActive = activeLabels.indexOf(label) > -1;

                return (

                    <span key={index} onClick={() => selectLocalLabel(label)} className={"mx-1 px-2 badge text-" + (isActive ? "bg-success" : "bg-secondary")}>
                        {label}
                        {editing ?
                            <FontAwesomeIcon onClick={() => deleteLabel(label)} className={"fa fa-lg fa-eom"} icon={"times-circle"} />
                            : null}
                    </span>)
            })}
            {editing ?
                <>
                    <textarea className="eomAssignEditor" value={newLabel} onChange={(e) => setNewLabel(e.currentTarget.value)} rows="1"></textarea>
                    <FontAwesomeIcon onClick={() => addLabel(newLabel)} className={"px-2 fa fa-lg fa-eom"} icon={faPlus} />
                </> : null}
        </div>
    )
}

export default TextLabels;