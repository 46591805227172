import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductSelector from './ProductSelector';

function ProductEntry(props) {
    let editing = props.editing;
    let value = props.value;
    let field = props.field;
    let callDispatcher = props.callDispatcher;
    let data = props.data;
    let validProducts = props.validProducts
    let description = props.description;
    let deleteProduct = props.onDelete;

    const [selectingProduct, setSelectingProduct] = useState(false);

    const choseProductLine = (new_product_id) => {
        setSelectingProduct(false);
        console.log(new_product_id);
        callDispatcher(description, field, 'description', new_product_id);
    }

    const displayEntry = () => {
        if (editing) {
            return (
                <>
                    <ProductSelector validProducts={validProducts} title={"Select Product Line"} show={selectingProduct} cancelModal={() => { choseProductLine(null) }} confirmModal={(product_id) => { choseProductLine(product_id) }} />

                    <Col xs={2} className={"numbercolumn"}>
                        <input className={"numberedit"} type="number"
                            value={value}
                            onChange={(e) => callDispatcher(description, field, 'level', e.currentTarget.value)} />
                    </Col>
                    <Col xs={8}>
                        <input type="text" className="form-control"
                            value={description}
                            onChange={(e) => callDispatcher(description, field, 'description', e.currentTarget.value)} ></input>

                    </Col>
                    <Col xs={1} >
                        <FontAwesomeIcon onClick={() => setSelectingProduct(true)} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"} icon={"edit"} />
                        <FontAwesomeIcon onClick={() => deleteProduct(description)} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"} icon={"trash-alt"} />
                    </Col>
                </>
            );
        } else {
            return (
                <>
                    <Col xs={2}>
                        {value}
                    </Col>
                    <Col>

                        <a className="dryLink" href={`/builder/productlines/${data.product_id}`}>
                            {description}
                        </a>
                    </Col>
                </>
            );
        }
    }

    return (displayEntry());

}

export default ProductEntry;