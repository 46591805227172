import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import ColouredText from '../../components/ColouredText';
import { removeColourCodes } from '../../utils/ColourMap';
import { useBuilder, useBuilderDispatch } from '../../components/index';
import { useMobBuilder, useMobDispatch } from './MobProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setBuilderMobData } from '../../components/builderRequests';

import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function StockItem(props) {

    const [show, setShow] = useState(false);
    const { currentmob, currentroom, editing } = useBuilder();
    const { currentMobData } = useMobBuilder();
    const mobDispatch = useMobDispatch();
    let dispatch = useBuilderDispatch();

    let stock = props.stock;
    let index = props.index;

    console.log(stock);

    const expandRow = () => {
        setShow(!show);
    }

    const trashItem = () => {
        console.log('Deleting Behaviour from Mob', index);
        if (index >= 0) {
            mobDispatch({ type: "DELETE_STOCK", field: 'stock', payload: index });
        }
    }

    const editingDone = () => dispatch({ type: 'EDITING_DISABLED' });

    const selectItem = async (itemid) => {
        dispatch({ type: 'SELECT_ITEM', payload: itemid, saveCallback: saveStockItem });
    }

    const saveStockItem = async (itemdata) => {
        mobDispatch({ type: "UPDATE_STOCKITEM", field: 'stock', payload: itemdata });
        currentMobData.sourceRoomId = currentroom.id;
        let data = await setBuilderMobData(currentMobData);
        if (data.result) {
            editingDone();
            mobDispatch({ type: "SELECT_CURRENTMOB", payload: data.data });
            dispatch({ type: "UPDATE_MOB", payload: data.data });
            mobDispatch({ type: 'SELECT_ERROR', payload: "" });
        } else {
            mobDispatch({ type: 'SELECT_ERROR', payload: data.reason });
        }
    }

    const callDispatcher = (action, newStockLevel) => {
        let value = 1;
        if (typeof (newStockLevel) == 'string') {
            console.log('Converting from string', typeof (newStockLevel));
            value = parseInt(newStockLevel);
        } else {
            value = newStockLevel;
        }
        let newValue = { index: index, value: value };
        mobDispatch({ type: action, field: 'stock', payload: newValue });
    }

    const getQuantityField = () => {
        if (editing) {
            return (
                <Row className="floatingdiv">
                    <Col md={2}>
                        <strong>Quantity</strong>
                    </Col>

                    <Col md={3} className={"stock-item"}>
                        <FontAwesomeIcon onClick={() => callDispatcher('SET_STOCK_LEVEL', stock.quantity + 1)} className="fa fa-lg fa-eom" icon={faPlus} color="red" />
                        <input type="text" className="form-control"
                            value={stock.quantity}
                            onChange={(e) => callDispatcher('SET_STOCK_LEVEL', e.currentTarget.value)} />
                        <FontAwesomeIcon onClick={() => callDispatcher('SET_STOCK_LEVEL', stock.quantity - 1)} className="fa fa-lg fa-eom" icon={faMinus} color="red" />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="floatingdiv">
                    <Col md={2}>
                        <strong>Quantity</strong>
                    </Col>
                    <Col>
                        {stock.quantity}
                    </Col>
                </Row>
            );
        }
    }

    const getPriceField = () => {
        if (editing) {
            return (

                <Row className="floatingdiv">
                    <Col md={2}>
                        <strong>Price</strong>
                    </Col>
                    <Col md={3} className={"stock-item"}>
                        <FontAwesomeIcon onClick={() => callDispatcher('SET_STOCK_PRICE', stock.price + 1)} className="fa fa-lg fa-eom" icon={faPlus} color="red" />
                        <input type="text" className="form-control"
                            value={stock.price}
                            onChange={(e) => callDispatcher('SET_STOCK_PRICE', e.currentTarget.value)} />
                        <FontAwesomeIcon onClick={() => callDispatcher('SET_STOCK_PRICE', stock.price + 1)} className="fa fa-lg fa-eom" icon={faMinus} color="red" />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="floatingdiv">
                    <Col md={2}>
                        <strong>Price</strong>
                    </Col>
                    <Col>
                        {stock.price}
                    </Col>

                </Row>
            );
        }
    }

    const showProduct = () => {
        if (stock.item === undefined) {
            return null;
        } else
            return (
                <>
                    <Row className="floatingdiv">
                        <Col md={2}>
                            <strong>Name:</strong>
                        </Col>
                        <Col>
                            <ColouredText ColouredText={stock.name} />
                        </Col>
                    </Row>
                    <Row className="floatingdiv ">
                        <Col md={2}>
                            <strong>Description:</strong>
                        </Col>
                        <Col>
                            <ColouredText ColouredText={stock.item.description} />
                        </Col>
                    </Row>
                </>
            );
    }

    return (<>

        {show ?
            <div className="stock-row px-2">
                <Row className="floatingdiv" >

                    <Col md={2} onClick={expandRow}>
                        <strong>Stock Item</strong>
                    </Col>

                    <Col onClick={expandRow}>
                        {removeColourCodes(stock.name)}
                    </Col>
                    {editing ?
                        <>
                            <Col>
                                <FontAwesomeIcon className="fa fa-trash fa-lg fa-eom" icon={"trash-alt"} color="maroon" onClick={trashItem} />
                            </Col>

                        </>
                        : null}
                </Row>
                {getPriceField()}
                {getQuantityField()}
                {showProduct()}
            </div>
            :

            <Row className={"floatingdiv " + (show ? "stock-row-top" : "")} >
                <Col xs={1} >
                    {editing
                        ? <FontAwesomeIcon className="ml-2 fa fa-trash fa-lg fa-eom" icon={"trash-alt"} color="maroon" onClick={trashItem} />
                        : <FontAwesomeIcon onClick={() => { selectItem(stock.item); }} className="fa fa-lg fa-eom" icon={"box-open"} />
                        
                    }
                </Col>
                <Col md={6} onClick={expandRow}>
                    {removeColourCodes(stock.name)}
                </Col>
                <Col>
                    {stock.price}
                </Col>
                <Col>
                    {stock.quantity}
                </Col>

            </Row>}
    </>

    );
}

export default StockItem;