import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormCheck, Dropdown } from 'react-bootstrap';
import ColouredText from '../../components/ColouredText';

import { getWearSlots, chooseArmourTypes, chooseArmourLayers } from '../BuilderUtils';
import { useItemBuilder } from './ItemProvider';

function WearEditor(props) {
    let editing = props.editing;
    let value = props.value;
    let field = props.field;
    let callDispatcher = props.callDispatcher;
    let description = props.description;

    const attributelist = [
        { description: 'See Through', mask: 1 },
        { description: 'Multi Wear', mask: 2 },
        { description: 'Remove Lower', mask: 4 },
        { description: 'Can See Beneath', mask: 8 }
    ];

    let wearslots = getWearSlots('');

    const { currentItemData, error } = useItemBuilder();


    const selectArmourType = (newArmourType) => {
        console.log('New Armour Type', newArmourType);
        callDispatcher('armourtype', newArmourType.id);
        callDispatcher(field, newArmourType.wearslot);
        callDispatcher('transparent', newArmourType.opens);
        callDispatcher('attributes', newArmourType.attributes);
    }

    const selectArmourLayer = (newArmourLayer) => {
        console.log('New Armour Layer', newArmourLayer);
        callDispatcher('layer', newArmourLayer);
    }

    const updateAttribute = (mask, enabled) => {
        var newAttribute = currentItemData.attributes;
        //console.log('Attribute <=', mask, newAttribute);
        if (enabled) {
            newAttribute = newAttribute | mask;
        } else {
            newAttribute = newAttribute & ~mask;
        }
        //console.log('Attribute => ', mask, newAttribute);
        callDispatcher('attributes', newAttribute);
    }

    const displayAttribute = (attribute) => {
        //console.log('The Mask is', attribute.mask);
        let enabled = (currentItemData.attributes & attribute.mask) > 0;
        return (
            <FormCheck
                key={attribute.mask}
                className={"mx-2"}
                id={attribute.mask}
                type="switch"
                checked={enabled}
                onChange={(e) => {
                    console.log('The Mask is', attribute.mask);
                    updateAttribute(attribute.mask, e.currentTarget.checked)
                }
                }
                label={attribute.description}
            />);
    }

    const shortWearSlot = (slot) => {
        var shortString = "";
        var delimiter = "";
        for (var i = 1; i < wearslots.length; i++) {
            let isUsed = (wearslots[i].code & slot) > 0;
            if (isUsed) {
                shortString = shortString + delimiter + wearslots[i].description;
                delimiter = ", ";
            }
        }

        return (
            <Row>
                {shortString}
            </Row>)

    }

    const showWearSlot = (slot, index) => {
        let isUsed = (slot.code & value) > 0;
        let isTransparent = (slot.code & currentItemData.transparent) > 0;
        //let isTransparent = (slot.opens & value) > 0;

        //console.log(slot.description, isUsed);
        if (isUsed) {
            return (
                <Row key={index}>
                    <Col>
                        {slot.description}
                    </Col>
                    <Col>
                        {isUsed ? "Yes" : "No"}
                    </Col>
                    <Col>
                        {isTransparent ? "Yes" : ""}
                    </Col>
                </Row>

            )
        } else
            return null;
        //        console(isUsed);
    }

    // let test = 16777216 & value;
    // console.log('Test', test   );

    // let test2 = 268435456 & value;
    // console.log('Test2', test2 );

    return (
        <>
            <Row>
                <strong>{editing ? <span>Editing </span> : null}{description}:</strong>
            </Row>
            <>
                {editing ?
                    <>
                        <Row>
                            <Col>
                                Layer: {chooseArmourLayers(currentItemData.layer, selectArmourLayer)}
                            </Col>
                            <Col>
                                Armour Type: {chooseArmourTypes(currentItemData.armourtype, selectArmourType, currentItemData.layer)}
                            </Col>

                        </Row>
                        <br />
                        <Row>
                            <Col>
                                Location
                            </Col>
                            <Col>
                                Worn
                            </Col>
                            <Col>
                                Transparent/Opens
                            </Col>
                        </Row>

                        {wearslots.map((slot, index) => {
                            return showWearSlot(slot, index);
                        }
                        )}
                        <Row>
                            <strong>Attributes</strong>
                        </Row>
                        <Row>
                            {attributelist.map((attribute, index) => displayAttribute(attribute))}

                        </Row>
                    </>
                    :
                    shortWearSlot(value)
                }

            </>
        </>)
}

export default WearEditor;