import React from 'react';
import Config from '../../../config/Config';

const getStandardHeader =() => {
    var newHeader = {
        'Content-Type': 'application/json',
        'LewdSecret' : Config.middleware.secret
    }
    return newHeader;
}

const getAuthenticatedHeader =() => {

    const token = localStorage.getItem('token');
    const currentUser = localStorage.getItem('currentUser');

    var newHeader = {
        'Content-Type': 'application/json',
        'auth-token': token,
        'username': currentUser,
        'LewdSecret' : Config.middleware.secret
    }
    return newHeader;
}

const getAuthenticatedBinaryHeader =() => {

    const token = localStorage.getItem('token');
    const currentUser = localStorage.getItem('currentUser');

    var newHeader = {
        'auth-token': token,
        'username': currentUser,
        'LewdSecret' : Config.middleware.secret
    }
    return newHeader;
}

export {getStandardHeader, getAuthenticatedHeader, getAuthenticatedBinaryHeader}



