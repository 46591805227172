import React, { useState, useEffect } from 'react';
import EomMarkdown from '../components/EomMarkdown';

function IntroWebClient() {

    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch('/guides/ansible-messenger.md')
            .then((r) => r.text())
            .then(text => {
                setMarkdown(text);
            })
    }, []);



    return (
        <div className="container" id="divMainContent">
                <EomMarkdown markdown={markdown}/>
        </div>
    );
}

export default IntroWebClient;