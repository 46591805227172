import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { removeColourCodes } from '../../utils/ColourMap';
import ColouredText from '../../components/ColouredText';
import { ExitProvider, useExitBuilder, useExitDispatch } from './ExitProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function ExitStandardDoorway(props) {
    let editing = props.editing;
    const { currentExitData } = useExitBuilder();

    //console.log(currentExitData);



    return (
        <>
            <Row>
                <strong>Destination:</strong>
                <span>{currentExitData.destination} </span>
            </Row>
            <Row>
                <strong>{editing ? <span>Editing </span> : null}Misc Text:</strong>
                {editing ?
                    <input type="text" className="form-control" value={currentExitData.text}/>
                    : <span>{currentExitData.text} </span>}
            </Row>
        </>
    );
}

export default ExitStandardDoorway;