import React, { Component } from 'react';

function GameplayParties() {

    return (
        <div className="container" id="divMainContent">
            <h1>Adventuring Parties</h1>
            <p>Adventuring Parties can be used for a number of things in the edge of midnight. Their primary function however is, as the name implies, a way to form a group of adventurers and head out into the realm and carry out quests, slay monsters and earn experience together.</p>
            <h1>Managing a Party</h1>
            <h2>Recruiting into your party</h2>
            <p>To recruit a player into your party enter the command&nbsp;<strong>PARTY RECRUIT</strong>&nbsp;with their name and this will attempt to recruit the specified player into the party.</p>
            <h2>Dismissing someone from your party</h2>
            <p>To dismiss a party member enter the command&nbsp;<strong>PARTY DISMISS</strong>&nbsp;with their name and this will dismiss the specified player into the party.</p>
            <p>Only party leaders can use this command.</p>
            <h2>Joining a party</h2>
            <p>To join a party enter the command&nbsp;<strong>PARTY JOIN</strong>&nbsp;with name of the lader and this will attempt to join their party.</p>
            <h2>Disbanding a party</h2>
            <p>To disband a party enter the command&nbsp;<strong>PARTY DISBAND</strong>&nbsp;and this will disband the party.</p>
            <p>Only party leaders can use this command.</p>
            <h2>Leaving a party</h2>
            <p>To leave a party enter the command&nbsp;<strong>PARTY LEAVE</strong>&nbsp;and you will then leave the party.</p>
            <h2>Promoting a new Leader</h2>
            <p>The leader of a party can promote a new leader, to do this they enter the command&nbsp;<strong>PARTY PROMOTE</strong>&nbsp;with the members name and they will become the party leader.</p>
            <h1>Benefits of a Party</h1>
            <p>When you are a member of a party you will automatically&nbsp;<strong>follow</strong>&nbsp;the leader when they travel through the relam.</p>
            <p>During combat you will&nbsp;<strong>share experience</strong>&nbsp;gained through combating monsters.</p>
            <p>You gain&nbsp;<strong>trusted</strong>&nbsp;status with other group members allowing you to do body piercing, work on their home etcetera.</p>
            <p>Many characters will have passive abilities that can aid your character and you will gain the benefits of these when grouped with them.</p>
            <h1>Pets within a Party</h1>
            <p>All members pets will implicitly become members of the party and are included in the party size limit.</p>
        </div>
    );
}

export default GameplayParties;