import React from 'react';

import './Toast.css';
import useToast from './useToasts';
import { useAuthDispatch, useAuthState } from '../components';

const ToastMaker = () => {
    const { toastRef, hideToast } = useToast(false);

    const authDispatch = useAuthDispatch();
    const { toastMessages } = useAuthState();

    const deleteMessage = (index) => {
        if (toastMessages.length <= 1) {
            hideToast();
            authDispatch({ type: 'DELETE_TOAST_MESSAGE', payload: index });
        } else {
            authDispatch({ type: 'DELETE_TOAST_MESSAGE', payload: index });
        }
    }

    return (
        <div>
            <div className="toast position-absolute bottom-0 end-0 m-4" role="alert" ref={toastRef}  >
                {toastMessages.map((toastInfo, index) =>
                    <div key={index}>

                        <div  className="toast-header">
                            <strong className="me-auto">{toastInfo.title}</strong>
                            <button type="button" className="btn-close" onClick={() => deleteMessage(toastInfo.index)} aria-label="Close"></button>
                        </div>
                        <div className="toast-body">
                            {toastInfo.body}
                        </div>
                    </div>)}
            </div>
        </div>
    );
}

export default ToastMaker;