import React, { Component } from 'react';

class IntroRoleplay extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">   
      <br/>
        <h1>Introduction to Roleplaying</h1>  
      <br/>
            
      <p>Roleplay (RP) is one of the major focuses of the Edge of Midnight and it is something that everyone playing  here is expected to participate in all of the time. This being In Character (IC) is the normal state of being for your characters.</p>

      <p>This guide is aimed and providing a primer as to what we expect of you the players and how you can expected to be treated in return.</p>

      <h2>Being In Character (IC)</h2>
      <p>As mentioned above - this is the normal state of being for all characters in the realm and is you the player acting out a living (sometimes) breathing (mostly) character who inhabits this realm. This will frequently require you to ignore knowledge that your character would not have access to. This is generally referred to as Meta-gaming and is probably one of the most difficult things for most players to deal with.</p>
      <ol><strong>A couple of examples</strong>
        <li>From playing a character (bob) you discover that another character (jane) is planning to murder one of your other characters (freddie). Although you the player know that jane is planning to kill freddie, Freddie the character does NOT know this and it is completely unacceptable to change Freddie's behaviour or to confront Jane with this knowledge before Freddie himself finds out</li>.
        <li>Walking into the square you replayroom and discover that your characters wife has been fucking someone your character absolutely detests. Your character DOES NOT KNOW this information yet and it is absolutely forbidden to act upon it. (no matter how angry your char would be, or even how upset you the player are)
        </li>
      </ol>
      <br/>
      <p>As a player you will gradually learn more and more about the realm - and know things that perhaps your character would not so please, think before you assume some piece of knowledge acting IC the player must act and think as their character would, look at the world as their character would.</p>

      <ul><strong>Some things to bear in mind</strong>
        <li>You only have as much IC knowledge as your character.  </li>
        <li>Your thoughts/actions/reaction/beliefs should be based on your character's history and be in keeping with their class &amp; background  </li>
        <li>Your character should reflect their races thoughts, ideas, emotions, prejudices, fears, strengths and weaknesses as closely as possible.  </li>
        <li>There is more to playing a character than being a combat or sex god. A well rounded character is often more fun to Roleplay.  </li>
        <li>Your character is limited by his abilities and you must stay within the bounds of those abilities.  </li>
        <li>In roleplay you are your character.</li>
      </ul>

      <p>The actions your character take, including crafting and combat are IC actions that they are carrying out - some leeway is okay with interpreting how this is carried out - but if you are mobbing, your character is fighting.</p>

      <h2>Roleplay (RP)</h2>  
      <p>Rolepay is the act of playing your character. It is simply not possible to roleplay effectively or fairly without keeping your characters actions IC.
      It is important that when interacting with other characters that you keep your actions within the realm of that characters own skills and abilities. If you are a pure fighter - no making fireballs!</p>
      <p>Please, when roleplaying provide a decent amount of detail for others to act off and react to. Think about what they can sense from your actions - what they can see, hear, smell, touch or taste - this includes your characters expression, body movements, speech, gestures. It does NOT include ideas, thoughts or feelings. (Facial expressions might give a tell to those emotions).</p>

      <h3>Separation of IC and OOC</h3>  
      <p>This is absolutely critical to the health and well-being of players, you must keep your OOC emotions and feelings seperate from those of your characters. If you find one bleeding into the other you need to take a step back and either send an otell to the other party that is causing the problem and ask them to ease up as you are having problems with it OOCly and if you feel you cannot do that then seek out a member of staff to act as an intermediary. </p>

      <h3>Godmodding, Godmoting, Godmoding.</h3>  
      <p>Do ever attempt to control the reactions of another player, it is the absolute height of rudeness as well as poor roleplay.</p>

      <p>Examples of this:  BAD: Freddie reaches out and crushes your neck with a quick flex of his fingers.  Why: Freddie's player has assumed that you cannot get out of the way, that your throat can be crushed, that your throat is in fact crushed and there is little you can do to respond to this.  BETTER: Freddie reaches out to try and crush your next with a quick flex of his fingers.  Why: Well now you get the opportunity to escaping if your character is quick enough, or able to escape.</p>

      <p>Though just as bad is <strong>NEVER EVER</strong> losing. At anything, EVER. This simply is not fun for anyone.  </p>
      <br/>

      <h3>What happens IC, happened IC</h3>
      <p>If your character does something that does not pan out quite as you intended, or just as you intended and you realise that you really didn't want to suffer the consequences. Tough. It has happened and you have to learn to deal with it.</p>  
      <p>Also if someone takes 'your' RP and does something you don't like, or didn't expect with it then you must play it through - once RP is out in the wild there is no bringing it back. </p> 
      <p>For example: Bob plays with the trigger of his crossbow, stroking and caressing it when suddenly there is a *thunk* and Bob looks about sheepishly a fresh cut on his cheek weeping blood a bolt launched into the rafters'  </p>
      <p>Bob has just done something a bit silly and meant no harm by it, but unknown to him...  Jane stretches out as she looks down from the rafters, smiling at the people in the inn when suddenly there is the unmistakable sound of a crossbow being fired accompanied by a searing pain in her calf, with a surprised scream she falls from the rafter and tumbles toward the ground.</p>
      <p>  Oops... Bob certainly didn't intend to shoot Jane, but accidents happen and he must deal with the fallout from his foolish mistake! Also this could lead to some great RP and adds to the history between the two characters.  (20 IC years later)  Pris asks curiously 'Daddy, how did you meet mummy'   Bob laughs softly and shakes his head 'I was in the inn, playing with my crossbow...' </p>

      <h3>Do not hijack other peoples RP</h3>
      <p>Putting a new twist in some RP or your character reacting in a fitting but unexpected way is one thing, but hijacking the RP is simply rude. Don't do it. If you are uncertain ASK!  </p>

      <h3>Consideration of others</h3>
      <p>Always remember that you are dealing with real people behind these characters. Some of whom will be very attached to their character, some of whom will have had an awful day, others who will have had a wonderful day.  Please Don't be mean just because you can, even if your character would be, without making sure that the other parties are okay with it. </p>

      <p>Just because you are playing an Evil character does not give you the right to ruin their day or their character.  Everyone is here to have fun and enjoy themselves and if you start making it an unpleasant place to be you will only drive other people away or get yourself ignored or worse, banned. </p>

      <p>We do not expect the game to be all sweetness and light but this IS a game and having players behind characters feeling miserable because of what is happening IC is completely unacceptable. If this happens seek a member of staff to mediate, speak to the source of the problem (They may not realise they are making you miserable!) above all take it OOC and talk it out before doing anything drastic or letting it fester.</p>
      <p>As an example, I'll use myself - a character I had was involved in some fairly big RP that grew into a global event that a lot of others wanted in on - but a lot of it she didn't trust to share with certain people who felt entitled to be a part of it - this resulted in her being subjected to IC arguments and fights every single day for nearly a month and in the end I simply hated logging in - it was just misery compounded upon misery and I felt trapped - not wanting to play, obligated to play. </p>
      <p> In the end I walked away from the game for a while and have avoided large RP since - what I should have done is simply talked to the players behind the other characters earlier and nipped it in the bud. Please <strong>DON'T</strong> make the same mistake I did!  </p>

      <h2>Tips for getting started</h2>
      <p>Give your character some history. Think about where did they  come from? What events shaped their life? And how does that affect how they react to situations they face in the game? This does require a certain familiarity with the realm but begin early! </p>

      <p>Try not to make your character too powerful or too weak. Either choice is boring for anyone dealing with them.  evelopment and growth require conflict, interest, failures and around a character that is too powerful this will be lacking.   </p>

      <p>Be modest and be reasonable. Give your character some strengths. And give them some weaknesses as well. Both fitting with her history. This offers you situations your character can confidentially handle and situations she will need the help of others for.  </p>

      <p>Familiarise yourself with your character. This may seem a little strange please take the time to do this. You will have to decide how your character is going to behave facing unpredictable situations. Often in split second decisions. If you can really feel into your character you do not have to worry about how to do this. It will come naturally to you.</p>  

      <p>Do not tell the story of your life to the first stranger you meet! Also if you do not tell too much about your character it allows you to change and develop your character as you get a better feel for them and make them fit into the RP you like. Nobody gets their character right the first time and you may have to live with the consequences for a long time...  unless you don't tell all on day one!  </p>

      <p>Please make your character at least a little bit sociable, this seems obvious to me as the aim of the game is to interact with other players and play out the life of your characters to the events, making up the history as you do so. Antisocial characters or evil characters are extremely difficult to play well and have a much finer line to tread and this is recommended for the more experienced roleplayers.  You cannot just assume power in EoM - You have to earn it and build toward it - we have an RP level system that will act as a guide to the relative powers of characters in their own fields of expertise but this is a guide and players are free to suspend it if they all agree.</p>   

      <p>Patience. This is one of the hardest aspects of roleplay. Getting involved in the game especially when you are new to it can be daunting and difficult. Do not try to force your way into things, wait for people to notice you, to engage with you - The inn can be a good place to start as it tends to be sociable and people will say hello and give you an opening.</p>  

      <p>Please, please please ask questions! Nobody expects you to know it all and they will be tolerant of mistakes and seemingly silly questions - keep it polite and don't spam so much you prevent others from playing! Exercise some temperance in your roleplay, we have no desire to see you stressed and burnt out over something that should be fun and exciting/relaxing.</p>


    </div>

    );
  }
}

export default IntroRoleplay;