import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { setScript, deleteScript } from '../../components/builderRequests';


function ScriptEditor(props) {
    const selectedScript = props.script;
    const postDeletion = props.postDeletion;

    const [sourceText, setSourceTextText] = useState(selectedScript.content);
    const [sourceScript, setSourceScript] = useState(selectedScript);
    const [pathText, setPathText] = useState(selectedScript.path);
    const [name, setName] = useState(selectedScript.name);
    
    const [error, setError] = useState('');

    if(selectedScript===undefined) {
        return null;
    }

    const handleChange = e => {
        let text = e.target.value;
        setSourceTextText(text);
    }

    const handleSubmit = async e => {
        sourceScript.content = sourceText;
        sourceScript.oldpath = sourceScript.path;
        sourceScript.path = pathText;
        
        sourceScript.oldname = sourceScript.name;
        sourceScript.name= name;
        
        console.log('Saving', sourceScript);

        saveScriptChange(sourceScript);
    }

    const handleDelete = async e => {
        console.log("Deleting Script");
        let data = await deleteScript(sourceScript.path, sourceScript.name);

        if (data.result) {
            console.log("script deleted");
            postDeletion();
        } else {
            setError('Mob could not be deleted, ', data.reason);
            // console.log(data.reason);
            postDeletion();
        }
    }

    const handleCancel = () => {
        postDeletion();
    }

    const saveScriptChange = async (scriptData) => {
        let data = await setScript(JSON.stringify(scriptData));

        if (data.result) {
            console.log("script saved");
            postDeletion(true);
        } else {
            setError('Script could not be saved, ', data.reason);
            postDeletion();
            // console.log(data.reason);
        }
    }

    console.log("Display Script called with:", props.script);
    return (
        <div>
            <Row style={{ textAlign: "left" }}>
                <Col xs={2}><strong>Name</strong></Col>
                <Col style={{ textAlign: "left" }}>
                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.currentTarget.value)} />
                </Col>
                
            </Row>
            <Row className="my-2">
                <Col xs={2}><strong>Path</strong></Col>
                <Col style={{ textAlign: "left" }}>
                    <input type="text" className="form-control" value={pathText} onChange={(e) => setPathText(e.currentTarget.value)} />
                </Col>
            </Row>
            <Row>
                <textarea style={{ fontFamily: "monospace" }} rows="30" cols="80" value={sourceText} onChange={handleChange} />
            </Row>
            <Button className={"btn-themed-dark m-2"} onClick={handleSubmit}>Update Script</Button>
            <Button className={"btn-themed-dark m-2"} onClick={handleDelete}>Delete Script</Button>
            <Button className={"btn-themed-dark m-2"} onClick={handleCancel}>Cancel Changes</Button>
        </div>)
}

export default ScriptEditor;