import React, { useState, useEffect } from 'react';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import { setQuestControl, deleteQuest, updateQuest } from '../../components/QuestRequests';
import ConfirmationDialog from './../common/ConfirmationDialog';

function QuestData(props) {
    let questin = props.quest;
    let index = props.index;
    let forceRefresh = props.forceRefresh;
    const [quest, setQuest] = useState();
    const [deleting, setDeleting] = useState(false);
    const [viewScript, setViewScript] = useState(false);
    const [showQuestDelete, setShowQuestDelete] = useState(false);

    useEffect(() => {
        setQuest(questin);
    }, []);

    const getStatus = () => {
        var text = "";
        var additionalinfo = "";
        let variant = "badge text-bg-success";
        if (quest.suspended) {
            text = "Waiting";
            variant = "badge text-bg-danger";
        }
        else if (quest.waiting) {
            text = "Waiting";
            variant = "badge text-bg-info";
            additionalinfo = Math.round(quest.waitremaining / 15) + " minutes left.";
        }
        else if (quest.running) {
            text = "Running";
            variant = "badge text-bg-success";
            additionalinfo = Math.round(quest.remaining / 15) + " minutes remaining.";
        }
        else {
            text = "Loaded";
            variant = "badge text-bg-warning";
        }

        return (
            <>
                <Col xs={1}>
                    <span className={variant}>{text}</span>
                </Col>
                <Col>
                    <span>{additionalinfo}</span>
                </Col>
            </>
        )
    }

    if (quest === undefined) {
        return <Row>Loading...</Row>
    }

    let rowclass = "d-flex align-items-center justify-content-center";
    if (index % 2 == 0) {
        rowclass += " row-even";
    }

    if (deleting) {
        return <Row className={rowclass}>
            <Col xs={3}>
                Deleting {quest.name}
            </Col>
        </Row>
    }

    const updateQuestState = (action) => {
        if (deleting)
            return;
        setQuestControl(quest.name, action).then(result => {
            setQuest(result.quest);
        });
    }

    const deleteTheQuest = () => {
        if (deleting)
            return;
        setDeleting(true);
        deleteQuest(quest.name).then(result => {
            if (result.result)
                forceRefresh(index);
        }).catch(error => {
            setDeleting(false);
        })
    }

    const postDeletion = () => {
        //oh no!
    }

    const handleChange = (e) => {
        console.log(e.target.value);
        setQuest(prev => ({ ...prev, script: e.target.value }));
    }

    const saveScriptChanges = async () => {
        updateQuest(quest.name, quest);
    }

    return (<>
    <ConfirmationDialog title={"Delete Quest '" + quest.name + "'?"} show={showQuestDelete} cancelModal={()=>setShowQuestDelete(false)} confirmModal={() => { deleteTheQuest() }} />
        <Row className={rowclass}>
            <Col xs={3} onClick={() => setViewScript(prev => (!prev))}>
                {quest.name}
            </Col>

            {getStatus()}
            <Col className='px-2'>
                {quest.running
                    ? <Button className={"m-1 p-1"} variant="" type="button" onClick={() => { updateQuestState('stop'); }}>Stop</Button>
                    : <Button className={"m-1 p-1"} variant="" type="button" onClick={() => { updateQuestState('start'); }}>Start</Button>}
                {quest.suspended
                    ? <Button className={"m-1 p-1"} variant="" onClick={() => { updateQuestState('enable'); }}>Enable</Button>
                    : <Button className={"m-1 p-1"} variant="" onClick={() => { updateQuestState('disable'); }}>Disable</Button>
                }
                <Button className={"m-1 p-1"} onClick={() => setShowQuestDelete(true)} variant="">Delete</Button>

            </Col>
        </Row>
        {viewScript ?
            <>
                <Row>
                    <textarea style={{ fontFamily: "monospace" }} rows="10" cols="180" value={quest.script} onChange={handleChange} />
                </Row>
                <Row>
                    <Button className={"m-1 p-1"} variant="" type="button" onClick={() => { saveScriptChanges(); }}>Update</Button>
                </Row>
            </>
            : null}
    </>
    );
}

export default QuestData;
