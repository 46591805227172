import React, { useState, useEffect } from 'react';
import { getKinkDefinitions, putKinkDefinitions, updatePlayerKink } from '../components';
import { Modal, Row, Col, Accordion, Button, Form } from 'react-bootstrap';
import { listPlayerKinks } from '../components/kinksApi';

function AdminKinkEditor() {
    const [kinkList, setKinkList] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState();
    const [filter, setFilter] = useState([]);
    const [currentKink, setCurrentKink] = useState();
    const [currentKinkData, setCurrentKinkData] = useState('');
    const [currentKinkTitle, setCurrentKinkTitle] = useState('');
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = async () => {
        let data = await putKinkDefinitions(currentKinkData);
        //console.log('System Review', data);
        if (data.result) {
            updateList(currentKinkData);
            handleClose();
        } else {
            setError('Kink Could not be saved, ', data.reason);
        }
    };

    const getKinkList = async () => {
        let data = await getKinkDefinitions();
        console.log('System Review', data);
        if (data.result) {
            setKinkList(data.kinks);
        } else {
            setKinkList([]);
        }
    }

    const getKinkFromId = (idToSearch) => {
        const found = kinkList.find(kink => kink.id === idToSearch);
        return found;

    };

    const updateList = (changedKink) => {
        // 1. Make a shallow copy of the items
        let kinks = [...kinkList];
        console.log(kinks);
        // 2. Make a shallow copy of the item you want to mutate
        const i = kinks.findIndex(kink => kink.id === changedKink.id);
        console.log('Index:', i);

        kinks[i] = changedKink;
        // 5. Set the state to our new copy
        setKinkList(kinks);
    }

    const clickKinks = async (id) => {
        console.log('Kinks!' + id);
        setCurrentKink(id);
        const kink = getKinkFromId(id);
        setCurrentKinkData(kink);
        setCurrentKinkTitle(kink.title)
        handleShow();
    }

    useEffect(() => {
        getKinkList();
        let kinkRecord = {};
        kinkRecord.kink_giving = 1;
        kinkRecord.kink_receiving = 2;
        kinkRecord.kink_count = 69;
        kinkRecord.kink_deleted = true;
        kinkRecord.kink_privacy = "";

        updatePlayerKink('Suki', '23', kinkRecord);
        listPlayerKinks('Suki');
    }, []);

    const filterMatch = (kink) => {
        var catcheck = true;
        if (categoryFilter > -1) {
            (kink.category === categoryFilter)
        }
        return catcheck && kink.title.toLowerCase().indexOf(filter) > -1;
    }

    const kinkAccordian = (kink) => {
        return (
            <Accordion defaultActiveKey={[]} alwaysOpen >
                <Accordion.Item eventKey={'0'} className={"accordion-body"}>
                    <Accordion.Header>
                        <Row id={kink.id} >
                            <Col xs={3}>
                                {kink.title}
                            </Col>
                            <Col xs={6}>
                                {kink.description}
                            </Col>


                        </Row>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row id={kink.id} key={kink.id}>
                            <Col xs={1}>
                                {kink.id}
                            </Col>
                            <Col xs={1}>
                                {kink.mode}
                            </Col>
                            <Col xs={2}>
                                {kink.title}
                            </Col>
                            <Col xs={5}>
                                {kink.description}
                            </Col>
                            <Col xs={2}>
                                {kink.keywords}
                            </Col>
                            <button onClick={() => clickKinks(kink.id)}>Edit</button>
                        </Row>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>);
    }


    return (
        <div className="container container-eom" id="divMainContent">
            <div className="input-group mb-3">
                <div className="input-group-prepend" >
                    <button className="btn btn-themed-dark" type="submit">Filter</button>
                </div>
                <input type="text" className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
            </div>
            <div>
                {kinkList.filter(kink => filterMatch(kink)).map((kink, index) =>
                    <div key={index}> {kinkAccordian(kink)} </div>
                )}

            </div>

            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Changing {currentKinkTitle}</Modal.Title>
                    <div className="alignright">
                        <small className="alignright" id="area_status">id {currentKink}</small>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control type="text" placeholder="Kink Title" value={currentKinkData.title} onChange={(e) => {
                            let newValue = e.currentTarget.value;
                            setCurrentKinkData(prevstate => ({
                                ...prevstate,
                                title: newValue
                            }))
                        }} />
                        <br />
                        <Form.Control type="text" placeholder="Normal text" value={currentKinkData.description} onChange={(e) => {
                            let newValue = e.currentTarget.value;
                            setCurrentKinkData(prevstate => ({
                                ...prevstate,
                                description: newValue
                            }))
                        }} />
                        <br />
                        <Form.Control type="text" placeholder="Normal text" value={currentKinkData.keywords} onChange={(e) => {
                            let newValue = e.currentTarget.value;
                            setCurrentKinkData(prevstate => ({
                                ...prevstate,
                                keywords: newValue
                            }))
                        }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <span className="error"><font color="red">{error}</font></span>
                    <Button variant="secondary" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminKinkEditor;