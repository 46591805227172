import { getProfessions, getProductList, getSchematics, getSchematicData, updateProfessionProducts } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useProfessionBuilder, useProfessionDispatch } from './ProfessionProvider';
import ProductEntry from './ProductEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductLineSelector from './ProductLineSelector';

function ProfessionEditor(props) {
    let undoChanges= props.undoChanges;
    const { profession, error } = useProfessionBuilder();
    const [ editing, setEditing ] = useState(false);
    const [ products, setProducts] = useState([]);
    const [ selectingProduct , setSelectingProduct] = useState(false); 

    const providerDispatch = useProfessionDispatch();

    useEffect(() => {
        getProductList().then(data => {
            console.log(data);
            if (data.result) {
                setProducts(data.products);
            } else {
                setProducts([]);
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }, []);

    if(profession==null) {
        return <Row className={"professionheader"}></Row>;
    }

    const callDispatcher = (id, entrytype, fieldname, value) => 
    {
        providerDispatch({ type: "SET_PRODUCT_LEVEL", id, entrytype, fieldname, value });
    }

    const selectProduct = async (product) => {
        console.log('Selected' , product);
        providerDispatch({ type: "SET_PRODUCT", payload : product });
        getSchematicList(profession, product.product_id);
    }

    const deleteProduct = (product) => {
        console.log('Delete' , product);
        providerDispatch({ type: "DELETE_PRODUCT", payload : product });

    }

    const newProductLine = () => {
        console.log('Add Schematic Entry');
        setSelectingProduct(true);
    }

    const getSchematicList = async (profession, productLine) => {
        let data = await getSchematics(profession, productLine);
        console.log('Schematic Data', data);
        if (data.result) {
            console.log('Schematic Data', data);
            providerDispatch({ type: "SET_SCHEMATIC_LIST", payload : data.schematics }); 
        } else {
            providerDispatch({ type: "SET_SCHEMATIC_LIST", payload : [] }); 
        }
    }

    const saveProfession = async (profession) => {
        let data = await updateProfessionProducts(profession);
        console.log('Schematic Data', data);    
    }
    
    const toggleEditing = () => { 
        setEditing(true);
    }

    const onUndoChanges = () => {
        setEditing(false); 
        console.log('Undoing Changes for', profession);
        undoChanges(profession.profession);
    }

    const saveChanges = () => {
        console.log('Save Changes?');
        saveProfession(profession);
        providerDispatch({ type: "SORT_PRODUCTS" }); 
        setEditing(false); 
    }

    const choseProduct = (product) => {
        setSelectingProduct(false);
        console.log('Chose ', product);
        if(product!==null) {
            providerDispatch({ type: "ADD_PRODUCT_TO_PROFESSION", "product" : product, "level" : 1});
            providerDispatch({ type: "SORT_PRODUCTS" }); 
        }
    }

    return (
    <>
         <ProductLineSelector title={"Select Product"} show={selectingProduct} cancelModal={() => { choseProduct(null) }} confirmModal={(product) => { choseProduct(product) }} />
            <Row className={"professionheader"}>

            <Col>
                <FontAwesomeIcon onClick={toggleEditing} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"+(editing?" xyz":"")} icon={"edit"} />
                <FontAwesomeIcon onClick={saveChanges} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"+(editing?"":" xyz")} icon={"check-circle"} />
                <FontAwesomeIcon onClick={onUndoChanges} data-tip data-tooltip-id="tooltipEditArea" className={"fa fa-lg fa-eom m-1"+(editing?"":" xyz")} icon={"undo"} />
            </Col>

          </Row>
          <Row>            
            <Col>
                <strong>Professsion Information</strong>
            </Col>
        </Row>
        <Row>
            <Col>
                <span><strong>Name: </strong>{profession.profession_name}</span> 
            </Col>
        </Row>
        <Row>
            <Col>
                <span><strong>Product Lines </strong></span> 
            </Col>
        </Row>
        {editing?<Row><Button onClick={newProductLine}>New</Button></Row>:null}
        {profession.products.map((product, index) =>
            <Row key={index}>
                <ProductEntry editing={editing}
                    field={"product"}
                    value={product.product_level}
                    callDispatcher={callDispatcher}
                    onSelect={selectProduct}
                    data={product}
                    onDelete={deleteProduct}
                    validProducts={products}
                    description={product.product_id}
                    fieldType={2} />
            </Row>
        )}
    </>);
}

export default ProfessionEditor;