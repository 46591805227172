import Config from '../../../config/Config';
import { getStandardHeader, getAuthenticatedHeader } from '../utils/FetchHeaders';

export async function listDeities() {
  return genericBasicFetch('deity/list');
}

export async function listClans() {
  return genericBasicFetch('clans/list');
}

export async function listRaces() {
  return genericBasicFetch('race/list');
}

export async function getRaceData(raceId) {
  return genericBasicFetch('race/data/' + raceId);
}

export async function listCharacterClasses() {
  return genericBasicFetch('classes/list');
}

export async function getCharacterClassData(classId) {
  return genericBasicFetch('classes/data/' + classId);
}

export async function getHelpForTopic(topic, subtopic) {
  if (subtopic === undefined)
    return genericBasicFetch('help/' + topic);
  else
    return genericBasicFetch('help/' + topic + "/" + subtopic);
}

export async function getRoleplaySheetData(playername) {
  return genericAuthenticatedFetch('roleplay/data/' + playername);
}

export async function getScoreSheetData(playername) {
  return genericAuthenticatedFetch('player/data/' + playername);
}

export async function getPlayersOnline() {
  return genericBasicFetch('online');
}

export async function getPlayersOnlineAdmin() {
  return genericAuthenticatedFetch('admin/online');
}

export async function getSystemLogFromMud() {
  return genericAuthenticatedFetch('admin/log');
}

export async function getPlayerWealth(playername, starttime, endtime) {
  let escapedStartTimestamp = encodeURIComponent(starttime);
  let escapedEndTimestamp = encodeURIComponent(endtime);
  return genericAuthenticatedFetch(`wealth/player/${playername}/${escapedStartTimestamp}/${escapedEndTimestamp}`);
}

export async function getPlayerWealthOverview(starttime, endtime) {
  //console.log('Looking for overview')
  let escapedStartTimestamp = encodeURIComponent(starttime);
  let escapedEndTimestamp = encodeURIComponent(endtime);
  return genericAuthenticatedFetch(`wealth/overview/players/${escapedStartTimestamp}/${escapedEndTimestamp}`);
}

export async function getWealthActivity(location, timestamp) {
  let escapedRoomId = encodeURIComponent(location);
  let escapedTimestamp = encodeURIComponent(timestamp);
  return genericAuthenticatedFetch('wealth/activity/' + escapedRoomId + '/' + escapedTimestamp);
}

export async function getPlayerList() {
  return genericAuthenticatedFetch('admin/player/list');
}

export async function getBankingOverview(bankid) {
  if (bankid === undefined)
    return genericAuthenticatedFetch('wealth/overview');
  else
    return genericAuthenticatedFetch('wealth/overview/' + bankid);
}

export async function getBankTransactions(bankid, accountid) {
  return genericAuthenticatedFetch(`wealth/bank/transactions/${bankid}/${accountid}`);
}

export async function getBankAccount(bankid, playername) {
  return genericAuthenticatedFetch(`wealth/account/${bankid}/${playername}`);
}

export async function getSystemReportFromMud() {
  return genericAuthenticatedFetch('admin/systemreport');
}

export async function getRumours() {
  return genericAuthenticatedFetch('news/rumours');
}

export async function getTopRpList() {
  return genericAuthenticatedFetch('news/toprp');
}

export async function getEventList() {
  return genericAuthenticatedFetch('news/events');
}

export async function getRecentActivity() {
  return genericAuthenticatedFetch('activity/get');
}

export async function getListOfMaps() {
  return genericAuthenticatedFetch('maps/list');
}

export async function getPlayerAccountData(playername) {
  return genericAuthenticatedFetch('player/account/' + playername);
}

export async function updateShortDescription(shortId, shortDesc) {
  return genericAuthenticatedFetch(`player/update/short/${shortId}/${shortDesc}`);
}

function forcedLogout() {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
  localStorage.removeItem('showPlayerMenu');
  localStorage.removeItem('avatar');
  localStorage.removeItem('basicinfo');
  localStorage.removeItem('roles');
  localStorage.removeItem('newMailCount');
  window.location.href = "/unauthorised";
  return {
    'result': false,
    'reason': 'Not authorised'
  };
}

export async function genericAuthenticatedFetch(path) {
  try {
    const headers = getAuthenticatedHeader();

    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/` + path, { headers: headers  });
    if (response.status == 401) {
      return forcedLogout();
    } else {
      let data = await response.json();
      return data;
    }
  } catch (error) {
    console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function genericAuthenticatedPut(path, payload) {
  //console.log(payload);
  try {
    const headers = getAuthenticatedHeader();

    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/` + path, { method: 'PUT', headers: headers, body: payload });
    if (response.status == 401) {
      //console.log(response);
      return forcedLogout();
    } else {
      let data = await response.json();
      //console.log(data);
      return data;
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function genericAuthenticatedPost(path, payload) {
  //console.log(payload);
  try {
    const headers = getAuthenticatedHeader();
    if(payload===undefined) {
        payload = JSON.stringify({});
    }
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/` + path, { method: 'POST', headers: headers, body: payload });
    if (response.status == 401) {
      //console.log(response);
      return forcedLogout();
    } else {
      let data = await response.json();
      //console.log(data);
      return data;
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function genericBasicFetch(path) {
  try {
    const headers = getStandardHeader();

    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/` + path, { headers: headers });
    //console.log(response);
    let data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function genericAuthenticatedDelete(path, payload) {
  //console.log(payload);
  try {
    const headers = getAuthenticatedHeader();
    if(payload===undefined) {
        payload = JSON.stringify({});
    }
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/` + path, { method: 'DELETE', headers: headers, body: payload });
    if (response.status == 401) {
      //console.log(response);
      return forcedLogout();
    } else {
      let data = await response.json();
      //console.log(data);
      return data;
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}
