import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card, Badge } from 'react-bootstrap';
import ColouredText from '../../components/ColouredText';
import { removeColourCodes } from '../../utils/ColourMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Config from '../../../../config/Config';
import { timeConverter } from '../../utils/NiceStrings';

function SceneHeader(props) {

    const [scene, setScene] = useState(props.scene);

    const getAvatar = (playerAvatar) => {
        var imagelocation = null;
        if (playerAvatar !== undefined) {
            imagelocation = `https://${Config.middleware.host}:${Config.middleware.port}/` + playerAvatar;
        }
        return imagelocation;
    }

    const showTags = () => {
        return (
            <div className={"my-2"}>
                {scene.scene_labels.map((label, index) => {

                    let classforBadge = "badge-misc";
                    switch (label) {
                        case "nsfw":
                            classforBadge = "badge-warn";
                            break;
                        case "open":
                            classforBadge = "badge-good";
                            break;

                        default:
                            break;
                    }
                    return <Badge key={index} className={classforBadge} pill>{label}</Badge>
                })}
            </div>
        )
    }

    const showParticpants = () => {
        return (
            <>
                {
                    scene.scene_participants.map((participant, index) => {
                        let imageLocation = getAvatar(participant.avatar);

                        return (
                            <Col key={index} xs={8} sm={8} md={2} lg={1}>
                                <Card className={"participant-card"} >
                                    <Card.Body xs={8} md={1}className={"participant-card-body"}>
                                        <img  width="50" height="50" src={imageLocation} 
                                            className={"rounded-circle mr-2"+(participant.online?"": " avatar-offline") } alt={"avatar-" + participant.avatar}></img>

                                    </Card.Body>
                                    <Card.Title className={"participant-card-header"}>
                                        <a className="dryLink p-2" href={`/player/rpsheet/${participant.name}`}>
                                            {participant.name}
                                        </a>
                                    </Card.Title>
                                </Card>
                            </Col>
                        )
                    })}
            </>
        )
    }
 
    return (
        <>
            <Row className={"scene-header"} >
                <Col xs={4}>
                    <a className="dryLink p-2" href={`/player/scene/${scene.scene_id}`}>
                    {scene.scene_type} Scene - {scene.scene_id} - {scene.scene_title}
                    </a>

                </Col>
                <Col>
                    {showTags()}
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <Row>
                        {showParticpants()}
                    </Row>
                </Col>
                <Col>
                    <span>Created: {timeConverter(scene.scene_date, true)}</span><br/>
                    <span>Updated: {scene.scene_updated}</span><br/>
                    {scene.scene_nexteventdate==='1970-01-01 00:00'?null:
                    <span>Next Event: {scene.scene_nexteventdate}</span>}
                </Col>
            </Row>
        </>

    );
}

export default SceneHeader;