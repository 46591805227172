import React, { useState, useEffect } from 'react';
import Config from '../../config/Config';
import { getStandardHeader } from './utils/FetchHeaders';
import { removeColourCodes } from './utils/ColourMap';

const timeConverter = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp * 1);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}

function NewsHeadlines() {

  const [news, setNews] = useState([]);


  // Retrieves the list of items from the Express app
  const getList = async () => {
    const headers = getStandardHeader();
    fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/news/recent`, { headers: headers })
      .then(res => res.json())
      .then(list => {
        if (list.result) {
          setNews(list.data)
        }
      }
      ).catch(error => {
          console.log('Failed to connect to server for news headlines.')
      }
      );
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="App">
      {/* Check to see if any items are found*/}
      {news != null ? (
        <div className="text-left">
          {news.map((data, key) => {
            return (
              <div key={key}>
                <p>{removeColourCodes(data.cmsubj)}<br />{timeConverter(data.cmdate)}</p>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <h4>No News Articles Found</h4>
        </div>
      )
      }
    </div>
  );
}

export default NewsHeadlines;