import React, { useState, useEffect } from 'react';
import { listChannels, getChannelMessages } from '../components/index';

const maxPageSize = 20; 

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function Channels () {
  const [channels, setChannels] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentChannel, setCurrentChannel] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const loadChannel = e => {
    const newChannelName = e.target.getAttribute('name'); 
    
    //console.log('Loading ' + newChannelName);
    setCurrentChannel( newChannelName );
    setCurrentPage( 1 );
    getMessages(newChannelName, currentPage, maxPageSize);
  }
  
  const loadChannelPage = e => {
    let newPage = parseInt(e.target.innerText);
    setCurrentPage(newPage);
    getMessages(currentChannel, newPage, maxPageSize);
    window.scrollTo(0, 0)
  }

  const loadNextChannelPage = e => {
    let newPage = currentPage+1;
    setCurrentPage(newPage);
    getMessages(currentChannel, newPage, maxPageSize);
    window.scrollTo(0, 0)
  }

  const loadPrevChannelPage = e => {
    let newPage = currentPage-1;
    if(newPage<1) {
      newPage=1;
    }
    setCurrentPage(newPage);
    getMessages(currentChannel, newPage, maxPageSize);
    window.scrollTo(0, 0)
  }

  const getChannelList = async() => {
    const data = await listChannels();

    setChannels(data.channels);
  }
  
  const getMessages = async(piChannel, piPage, piPageSize) => {
    if(piChannel===undefined) {
      setMessages({});
      setPageCount(0);
      return;
    }
     const data = await getChannelMessages(piChannel, piPage, piPageSize);
    setMessages(data.messages);
    setPageCount(parseInt(data.pageCount));
  }

  useEffect(() => {
      setCurrentPage(1);
      setCurrentChannel('');
      getChannelList();
    },[]);


  let pageItems = [];
  
  let start = currentPage-2;
  if(start<1) {
    start=1;
  }
  
  let end = currentPage+2;
  if(end>=pageCount) {
    end=pageCount;
  }
  
  for (var i = start; i <= end; i++) {
    pageItems.push(i);
  } 
  let prev = (currentPage>1);
  let next = (currentPage<pageCount);
  let showPagination = pageCount > 1;

  return (
    <div className="container">
      <br></br>
      <div className="row">
        <div className="col-md-4">
          <h3>Channels</h3>
        </div>
        <div className="col-sm">
        <div className="alert alert-success">{(currentChannel.length>0)?'Viewing page '+ currentPage + ' of the ' +  capitalizeFirstLetter(currentChannel) + ' backlog.':('Select a Channel')}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          
          <ul>
              {channels.map(channel => <div onClick={loadChannel} name={channel.channel} key={channel.channel}>{capitalizeFirstLetter(channel.channel)}</div>)}
          </ul>
        </div>
        <div className="col-sm">
          <div>
            {messages.length>0?(
              messages.map(message => 
              <div key={message.timestamp}>{message.message}
              <span> ({message.timeAgo})</span>
              </div>)
            ):(null)}
          </div>
          <div>
            <br key="break.1"/>
          </div>
          {showPagination?(  
             
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li key="p" className= {(prev?"page-item":"page-item disabled")} onClick={loadPrevChannelPage}>
                <a className="page-link eomLink" href="#" tabIndex="-1" aria-disabled={prev?"false":"true"}>Previous</a>
              </li>
             
              {
                pageItems.map((page, i) => <li key={i} name={''+page} className="page-item" onClick={loadChannelPage}>
                <span className="page-link eomLink">
                  {page}
                </span>
                </li> )
              }
              <li key="n" className={(next?"page-item":"page-item disabled")} onClick={loadNextChannelPage}>
                <span className="page-link eomLink">Next</span>
              </li>
            </ul>
          </nav>
          ):(<div/>)}
        </div>
      </div>
      
    </div>
    );
}

export default Channels;