import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Row, Col, Accordion, Card, Button, Form, InputGroup, DropdownButton, FormControl } from 'react-bootstrap';
import { findSchematic } from '../../components/professionRequests';

function SchematicSelector(props) {

    let show = props.show;
    let cancelModal = props.cancelModal;
    let confirmModal = props.confirmModal;
    let title = props.title;
    const [schematic, setSchematic] = useState(null);
    const [schematics, setSchematics] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [error, setError] = useState('');
    
    useEffect(() => {
        if(!show) {
            setSchematics([]);
        }
        else if (searchCriteria === '') {
            setSchematics([]);
        } else {
            const delayDebounceFn = setTimeout(() => {
                // console.log(searchString)
                searchForSchematic();
                // Send Axios request here
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchCriteria]);

    const searchForSchematic = () => {
        setError('');
        console.log("Searching for", searchCriteria);
        if (searchCriteria.length < 3) {
            setSchematics([]);
            return;
        }
        findSchematic(searchCriteria).then(data => {
            if (data.result) {
                setSchematics(data.schemas);
            } else {
                //error
                setSchematics([]);
                setError(`Failed to find schema for ${searchCriteria}.`);
            }
        }).catch(
            error => {
                setError(`Could not find schema for ${searchCriteria}.`);
            }
        );
    }

    const getSchematicList = (schematics, currentSchematic, setSchematic) => {   
        return (
            <InputGroup className="mb-3">
                <DropdownButton
                    variant="outline-secondary"
                    title={currentSchematic==null?"Select":currentSchematic.SCHEMAID}
                    id="input-group-dropdown-1"
                    disabled={schematics.length===0}
                >
                    {schematics.map(schema =>
                        <Dropdown.Item key={schema.SCHEMAID} name={schema.SCHEMAID} onClick={() => setSchematic(schema)}>{schema.SCHEMAID}</Dropdown.Item>
                    )}
    
                </DropdownButton>
                <p>{currentSchematic==null?"":currentSchematic.NAME}</p>
            </InputGroup>
        )
    }

    return (

        <Modal centered show={show} onHide={cancelModal}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <input type="text" className="form-control" onChange={(e) => setSearchCriteria(e.currentTarget.value)} />
                {getSchematicList(schematics, schematic, setSchematic)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelModal}>
                    Cancel
                </Button>

                <Button variant="secondary" onClick={()=>confirmModal(schematic)} disabled={schematic==null}>
                    Confirm
                </Button>

            </Modal.Footer>
        </Modal>
    );
}

export default SchematicSelector;