import React, { Component, useEffect } from 'react';
import InlineUserLoggedOut from './navigation/InlineUserLoggedOut';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from './components';



const Unauthorised = () => {
  const { token } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (token !== null) {
      console.log("Lets navigate back...", token);
      navigate(-1);
    }
  }, [token]);

  return (
    <div className="container" id="divMainContent">
      <h1>Please log in!</h1>
      <InlineUserLoggedOut />
    </div>
  );
}

export default Unauthorised;