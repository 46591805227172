import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { getScoreSheetData } from './../components'

function RoleplaySheet() {
  const [playerName, setPlayerName] = useState(localStorage.getItem('currentUser'));
  const [playerData, setPlayerData] = useState();

  const getScoresheet = async e => {
    const data = await getScoreSheetData(playerName);
    if (data.result) {
      setPlayerData(data);
    } else {
      setPlayerData();
    }
  }

  useEffect(() => {
    getScoresheet();
  }, []);

  const getGeneralCard = (title, description) => {
    return (
      <Card className={"m-2 score-card-eom"} >
        <Card.Body>
          <Card.Title>General Information</Card.Title>
          <Card.Text>
            <Row>
              <Col>
                <strong>Gender </strong> {playerData.gender}
                <br />
                <strong>Height</strong> {playerData.height}
                <br />
                <strong>Level</strong> {playerData.weight}
                <br />
                <strong>Age</strong> {playerData.age}
                <br />
                <strong>Alignment</strong> {playerData.alignment}

              </Col>
              <Col>
                <strong>Race </strong> {playerData.race}
                <br />
                <strong>Weight</strong> {playerData.weight}
                <br />

                <strong>Class</strong> {playerData.class}
                <br />
                <strong>Bithday</strong> {playerData.birthday}

              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>

    );
  }

  const getClassCard = (title, description) => {
    return (
      <Card className={"m-2 score-card-eom"} >
        <Card.Body>
          <Card.Title>Character Class</Card.Title>
          <Card.Text>
            <ul className="kinks-ul-single">
              <Row>
                <Col>
                  <strong>Name</strong>
                </Col>
                <Col>
                  <strong>Level</strong>
                </Col>
              </Row>

              {playerData.classes.map(charclass =>
                <li key={charclass.classname}>
                  <Row>
                    <Col>
                      {charclass.classname}
                    </Col>
                    <Col>
                      {charclass.classlevel}
                    </Col>
                  </Row>
                </li>
              )}
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>

    );
  }

  const getStatsCard = () => {
    return (
      <Card className={"m-2 score-card-eom"} >
        <Card.Body>
          <Card.Title>Stats</Card.Title>
          <Card.Text>
            <Row>
              <Col>
                <strong>Strength</strong> {playerData.str} /  {playerData.strmax}
                <br />
                <strong>Intelligence</strong> {playerData.int} /  {playerData.intmax}
                <br />
                <strong>Wisdom</strong> {playerData.wis} / {playerData.wismax}
                <br />
                <strong>Dexterity</strong> {playerData.dex} /  {playerData.dexmax}
                <br />
                <strong>Constitution</strong> {playerData.con} /  {playerData.conmax}
                <br />
                <strong>Charisma</strong> {playerData.chr} /  {playerData.chrmax}
              </Col>
              <Col>
                <strong>Hit Points</strong> {playerData.hit} /  {playerData.hitmax}
                <br />
                <strong>Mana</strong> {playerData.mana} /  {playerData.manamax}
                <br />
                <strong>Movement</strong> {playerData.move} /  {playerData.movemax}
              </Col>

            </Row>
          </Card.Text>
        </Card.Body>
      </Card>

    );
  }

  const getCombatCard = () => {
    return (
      <Card className={"m-2 score-card-eom"} >
        <Card.Body>
          <Card.Title>Combat Stats</Card.Title>
          <Card.Text>
            <strong>Initiative </strong>{playerData.initiative}
            <br/>
            <strong>Defense</strong> {playerData.armour}
            <br/>
            <strong>Attack</strong> {playerData.attack}
            <br/>
            <strong>Damage</strong> {playerData.damage}
          </Card.Text>
        </Card.Body>
      </Card>

    );
  }

  const getEquipementCard = () => {
    return (
      <Card className={"m-2 score-card-eom-wide"} >
        <Card.Body>
          <Card.Title>Current Equipment</Card.Title>
          <Card.Text>
            <ul className="kinks-ul-single">
              <Row>
                <Col>
                  <strong>Name</strong>
                </Col>
                <Col>
                  <strong>Type</strong>
                </Col>
                <Col>
                  <strong>Worn</strong>
                </Col>
              </Row>

              {playerData.equipment.map((item, index) =>
                <li key={index}>
                  <Row>
                    <Col>
                      {item.name}
                    </Col>
                    <Col>
                      {item.layer}
                    </Col>
                    <Col>
                      {item.wornon}
                    </Col>
                  </Row>
                </li>
              )}
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>

    );
  }

  const getAbilitiesCard = () => {
    return (
      <Card className={"m-2 score-card-eom"} >
        <Card.Body>
          <Card.Title>Current Equipment</Card.Title>
          <Card.Text>
            <ul className="kinks-ul-single">
              <Row>
                <Col>
                  <strong>Name</strong>
                </Col>
                <Col>
                  <strong>Type</strong>
                </Col>
                <Col>
                  <strong>Worn</strong>
                </Col>
              </Row>

              {playerData.equipment.map(item =>
                <li key={item.classname}>
                  <Row>
                    <Col>
                      {item.name}
                    </Col>
                    <Col>
                      {item.layer}
                    </Col>
                    <Col>
                      {item.wornon}
                    </Col>
                  </Row>
                </li>
              )}
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>

    );
  }



  return (
    <div style={{
      display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignItems: "flex-start",
      justifyContent: "flex-start"
    }}>

      {playerData!==undefined?(
        <>
      {getGeneralCard('test', 'testttt')}
      {getClassCard('test', 'testttt')}
      {getStatsCard()}
      {getCombatCard()}
      {getEquipementCard()}
      </>):null}
    </div>
  )
}

export default RoleplaySheet;