import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Row, Col, Accordion, Card, Button, Form, InputGroup, DropdownButton, FormControl } from 'react-bootstrap';
import { findSchematic, getProductList } from '../../components/professionRequests';

function ProductLineSelector(props) {

    let show = props.show;
    let cancelModal = props.cancelModal;
    let confirmModal = props.confirmModal;
    let title = props.title;
    const [productId, setProductId] = useState(null);
    const [products, setProducts] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [error, setError] = useState('');
    
    useEffect(() => {
        if(!show) {
            setProducts([]);
        }
        else if (searchCriteria === '') {
            setProducts([]);
        } else {
            const delayDebounceFn = setTimeout(() => {
                // console.log(searchString)
                searchForProduct();
                // Send Axios request here
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchCriteria]);

    const searchForProduct = () => {
        setError('');
        console.log("Searching for", searchCriteria);
        if (searchCriteria.length < 3) {
            setProducts([]);
            return;
        }
      getProductList().then(data => {
            console.log(data);
            if (data.result) {
                console.log(data);
                data.products = data.products.filter(p => (p.product_id.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1));
                setProducts(data.products);
            } else {
                //error
                setProducts([]);
                setError(`Failed to find products for ${searchCriteria}.`);
            }
        }).catch(
            error => {
                setError(`Could not find products for ${searchCriteria}.`);
                console.log(error);
            }
        );
    }

    const displayProductList = (products, currentProduct, setSchematic) => {   
        console.log(products);
        return (
            <InputGroup className="mb-3">
                <DropdownButton
                    variant="outline-secondary"
                    title={currentProduct==null?"Select":currentProduct.product_id}
                    id="input-group-dropdown-1"
                    disabled={products.length===0}
                >
                    {products.map(product =>
                        <Dropdown.Item key={product.product_id} name={product.product_id} onClick={() => setSchematic(product)}>{product.product_id}</Dropdown.Item>
                    )}
    
                </DropdownButton>
                <p>{currentProduct==null?"":currentProduct.product_name}</p>
            </InputGroup>
        )
    }

    return (

        <Modal centered show={show} onHide={cancelModal}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <input type="text" className="form-control" onChange={(e) => setSearchCriteria(e.currentTarget.value)} />
                {displayProductList(products, productId, setProductId)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelModal}>
                    Cancel
                </Button>

                <Button variant="secondary" onClick={()=>confirmModal(productId)} disabled={productId==null}>
                    Confirm
                </Button>

            </Modal.Footer>
        </Modal>
    );
}

export default ProductLineSelector;