import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getTemplateList } from '../../components/ProceduralGenerationRequests';
import ProcGenTemplate from './ProcGenTemplate';


function ProcGenTemplates() {
    const [templates, setTemplates] = useState([]);
    const [filter, setFilter] = useState('');
    const [maskFilter, setMaskFilter] = useState('');

    useEffect(() => {
        console.log('Requesting Templates');
        populateTemplates();
    }, []);

    const populateTemplates = () => {
        getTemplateList().then(data => {
            console.log(data);
            setTemplates(data.templates);

        }).catch(error => {
            console.log(error);
        });
    }

    const showFilters = () => {
        return (
            <Row>
                <Col xs={1}>
                    <input type="text" value={filter} className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                </Col>
                <Col xs={1}>
                    <input type="text" value={maskFilter} className="form-control" onChange={(e) => setMaskFilter(e.currentTarget.value)} />
                </Col>
            </Row>);
    }

    const filterMatch = (template) => {
        let result = template.key.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        let maskCheck = template.mask.toLowerCase().indexOf(maskFilter.toLowerCase()) > -1;
        return result && maskCheck;
    }

    const filteredTemplates = () => {
        let filteredTemplates = templates.filter(template => filterMatch(template));
        console.log(filteredTemplates);
        return filteredTemplates;
    }

    const deleteTemplate = (templateId) => {
        console.log('Deleted Template', templateId);
        setTemplates(templates.filter(template => template.id !== templateId));
    }

    const refreshList = () => {
        populateTemplates();
    }

    return (
        <div>
            <Row>
                <Col xs={1}>
                    <strong>Template Type</strong>
                </Col>
                <Col xs={3}>
                    <strong>Mask</strong>
                </Col>
                <Col>
                    <strong>Value</strong>
                </Col>
            </Row>
            {showFilters()}
            {filteredTemplates().map((template, index) =>
                <ProcGenTemplate key={index} feature={template} deleteCallback={deleteTemplate} refreshCallback={refreshList}/>)}
        </div>

    );
}

export default ProcGenTemplates;

