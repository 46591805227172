import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { generateParameters } from '../../components/ProceduralGenerationRequests';

function Parameter(props) {
    const septile = props.septile;
    const title = props.title;

    useEffect(() => {
        console.log('Display Septile', septile)
    }, []);

    if(septile===undefined)
        return null;

    return (

    <Row>
        <Col xs={2}><strong>{title}:</strong></Col>
        <Col xs={2}>{septile.description}</Col>
        {septile.value!==undefined
            ?<span>{septile.value}</span>
            :null
        }
        <Col xs={2}>({septile.septile.toLowerCase()} septile)</Col>
    </Row>

    );
}

export default Parameter;