import React, { useState } from 'react';
import { Row, Button} from 'react-bootstrap';
import { useMobDispatch } from './MobProvider';
import { useBuilder } from './../../components/index';
import InventoryItem from './InventoryItem';
import CreateItemPrompt from '../items/CreateItemPrompt';

function MobInventory(props) {
    let inventory = props.inventory;
    const [show, setShow] = useState(false);
    const { editing } = useBuilder();
    const mobDispatch = useMobDispatch();

    const createItem = (newItem) => {
        newItem.uniqueid = "";
        console.log(newItem);
        mobDispatch({type : "ADD_ITEM", payload : newItem});
        setShow(false);
    }

    const closeCreate = () => {
        setShow(false);
    }

    if (inventory === undefined) {
        if(editing)
            return <Button onClick={()=>setShow(true)} >Create Item</Button>;
        else 
            return null;
    }
    return (
        <>
            
            <Row className={"floatingdiv my-2"}>
                <strong>Inventory</strong>
            </Row>

            <CreateItemPrompt show={show} closeModal={closeCreate} createItem={createItem}/>

            {inventory.map((item, index) => (
                <InventoryItem item={item} index={index} key={index} />
            )
            )}
            {editing? <Button onClick={()=>setShow(true)} >Create Item</Button>:null}

        </>
    );
}

export default MobInventory;