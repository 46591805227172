import React from 'react';
import { Row, FormCheck } from 'react-bootstrap';


function BitmapField(props) {
    let editing = props.editing;
    let value = props.value;
    let field = props.field;
    let callDispatcher = props.callDispatcher;
    let description = props.description;
    let choices = props.choices;
    
    // var choices = [
    //     { description: 'See Through', mask: 1 },
    //     { description: 'Multi Wear', mask: 2 },
    //     { description: 'Remove Lower', mask: 4 },
    //     { description: 'Can See Beneath', mask: 8 }
    // ];

    const getList = () => {
        return choices();
    }
    
    const updateBitmap = (mask, enabled) => {
        if (!editing)
            return;
        var newAttribute = value;
        console.log('Attribute <=', mask, newAttribute);
        if (enabled) {
            newAttribute = newAttribute | mask;
        } else {
            newAttribute = newAttribute & ~mask;
        }
        console.log('Attribute => ', mask, newAttribute);
        callDispatcher(field, newAttribute);
    }

    const displayChoice = (choice) => {
        // console.log('The Mask is', choice.mask);
        let enabled = (value & choice.mask) > 0;
        return (
            <FormCheck
                key={choice.mask}
                className={"m-2"}
                id={choice.mask}
                type="switch"
                checked={enabled}
                onChange={(e) => {
                    //console.log('The Mask is', choice.mask);
                    updateBitmap(choice.mask, e.currentTarget.checked)
                }
                }
                label={choice.description}
            />);
    }


    return (
        <>
            <Row>
                <strong>{editing ? <span>Editing </span> : null}{description}:</strong>
            </Row>

            <Row>
                {getList().map((choice, index) => displayChoice(choice))}

            </Row>
        </>)
}

export default BitmapField;