import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { removeColourCodes } from '../utils/ColourMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AdminWealthActivity(match) {

    const cancelDetail = match.clearDetail;

    const [activity, setActivity] = useState([]);
    const [location, setLocation] = useState('');

    useEffect(() => {
        if (match !== undefined) {
            setActivity(match.activity);
            if(activity.length>0) {
                setLocation( activity[0].location);
            }
        }
    }, [match]);

    if(activity.length==0){
        return null;
    }
    return (
        <Col>

            <br />
            
            <h2><FontAwesomeIcon onClick={cancelDetail} className="fa fa-lg fa-eom m-2" icon={"times-circle"} />Activity Detail {location!=''?" - " + location : ""}</h2>
            {activity.map((fa, index) =>

                <Row key={index}>
                    <Col>
                        <span> {moment(fa.timestamp, 'YYYY.MM.DD.HH.mm.SS.T').format('Do MMM HH:mm:ss')} </span>
                    </Col>
                    <Col>
                        <span> {fa.narrative} </span>
                    </Col>
                    <Col>
                        <span> {removeColourCodes(fa.fromEntity)} </span>
                    </Col>
                    <Col>
                        <span> {removeColourCodes(fa.toEntity)} </span>
                    </Col>
                    <Col>
                        <span> {fa.value} </span>
                    </Col>
                </Row>
            )
            }
        </Col>

    );
}

export default AdminWealthActivity;