import React, { useState, useEffect } from 'react';
import { useBuilder, useBuilderDispatch } from '../../components/index';
import { ExitProvider, useExitBuilder, useExitDispatch } from './ExitProvider';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExitStandardDoorway from './ExitStandardDoorway';
import ExitGenDoor from './ExitGenDoor';
import { setBuilderRoomExit } from '../../components/builderRequests';
import { getExitList } from '../BuilderUtils';

function ExitEditor(props) {
    const { currentExitData, error } = useExitBuilder();
    const exitDispatch = useExitDispatch();

    const { currentexit, currentroom, editing } = useBuilder();
    const dispatch = useBuilderDispatch();

    const unloadExit = async () => {
        dispatch({ type: 'SELECT_EXIT', payload: null });
    }

    useEffect(() => {
        var exitcopy = JSON.parse(JSON.stringify(currentexit))
        exitDispatch({ type: 'SELECT_CURRENTEXIT', payload: exitcopy });
    }, [currentexit]);


    let active = props.active;

    const cancelExit = async (e) => {
        unloadExit();
        exitDispatch({ type: 'SELECT_CURRENTEXIT', payload: null });
        dispatch({ type: 'EDITING_DISABLED' });
        unloadExit(e);
    }

    const handleEditing = () => {
        if (editing) {
            dispatch({ type: 'EDITING_DISABLED' });
            handleRevert();
        }
        else
            dispatch({ type: 'EDITING_ENABLED' });
    }
    const editingDone = () => dispatch({ type: 'EDITING_DISABLED' });

    const handleRevert = () => {
        console.log('Reverting Exit', currentexit);
        var exitcopy = JSON.parse(JSON.stringify(currentexit))
        exitDispatch({ type: 'SELECT_CURRENTEXIT', payload: exitcopy });
    }

    const saveChanges = async () => {
        currentExitData.sourceRoomId = currentroom.id;
        let data = await setBuilderRoomExit(currentExitData);

        if (data.result) {
            editingDone();
            exitDispatch({ type: 'SELECT_ERROR', payload: "" });
        } else {
            exitDispatch({ type: 'SELECT_ERROR', payload: data.reason });
        }
    }

    if (currentExitData === undefined) {
        return null;
    }

    const getDoorwayComponent = () => {
        switch (currentExitData.type) {
            case 'GenDoor':
            case 'GenExit':
                return (<ExitGenDoor editing={editing} currentExitData={currentExitData} />);
            default:
                return (<ExitStandardDoorway editing={editing} currentExitData={currentExitData} />);
        }
    }

    if (currentExitData == null) return null;
    const selectExitType = (type) => {
        exitDispatch({ type: "SET_FIELD", field: 'type', payload: type });
    }

    
    let width = 2;
    if (active) { width = 5 }
    return (
        <Col md={width} className={"mx-2"}>
            <Row>
                <FontAwesomeIcon onClick={cancelExit} className="fa fa-lg fa-eom m-2" icon={"times-circle"} />
                <h4><strong> {editing ? "Editing Exit:" : "Viewing Exit:"}</strong> {currentExitData.type} {currentExitData.direction}</h4>
            </Row>
            <FontAwesomeIcon onClick={handleEditing} className="fa fa-lg fa-eom m-2" icon={"edit"} />

            {editing ? <>
                <FontAwesomeIcon onClick={saveChanges} className="fa fa-lg fa-eom m-2" icon={"check-circle"} />
                <FontAwesomeIcon onClick={handleRevert} className="fa fa-lg fa-eom m-2" icon={"undo"} />
            </> : null}
            <span className="error"><font color="red">{error}</font></span>
            <Row>
                <strong>{editing ? <span>Editing </span> : null}Exit Type:</strong>
                {editing
                    ? getExitList(currentExitData.type, selectExitType)
                    : <span>{currentExitData.type}</span>}

            </Row>
            {getDoorwayComponent()}
        </Col>
    )
}

export default ExitEditor;