import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBuilder } from './../../components/index';

import { getRoomListSimple } from './../BuilderUtils';
import { Col, Row } from 'react-bootstrap';


function AreaSummary(props) {

    let loadRoom = props.loadRoom;
    let unloadArea = props.unloadArea;

    const { currentarea, currentitem, currentroom, currentmob, currentexit, editing } = useBuilder();
    //console.log('Current Room', currentroom);
    return (<>
        <Row className="row fluid align-content-center">
            <Col xs={2}>
                <FontAwesomeIcon onClick={unloadArea} className="fa fa-lg fa-eom m-2" icon={"times-circle"} />
            </Col>
            <Col xs={10}>
                <h4> Viewing {currentarea.id} </h4>
            </Col>
        </Row>
        <Row className='py-1'>
            <span><strong>Rooms: </strong>{currentarea.roomids.length}</span>
        </Row>
        {getRoomListSimple(currentarea.roomids, currentroom.id, loadRoom)}
    </>)
}

export default AreaSummary;