import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { getRoomList } from './BuilderUtils';
import { useBuilder, useBuilderDispatch, BuilderProvider } from './../components/index';

function SelectRoom(props) {
    let showModal = props.showModal;
    let hideModal = props.hideModal;
    let linkRooms = props.selectRoom;

    const [destination, setDestination] = useState('');
    const { currentarea, currentroom, direction } = useBuilder();

    const loadRoom = async(roomid) => {
        setDestination(roomid);
    }

    if (currentroom === null)
        return null;
    else
        return (
            <Modal show={showModal} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Linking {currentroom.id} ({direction})</Modal.Title>
                </Modal.Header>
                <Modal.Body>Select a room or enter one manually.
                    {getRoomList(currentarea.roomids, destination, loadRoom)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>Close</Button>
                    <Button variant="secondary" onClick={()=>linkRooms(currentroom.id, destination, direction)}>Okay</Button>
                </Modal.Footer>
            </Modal>
        );
}

export default SelectRoom;