import React from "react";
import AdminPlayerManager from "../admin/PlayerManagement/AdminPlayerManager";
import WebContentSubmission from "../admin/WebContentSubmission";
import RandomGeneration from "../builder/procgen/ProceduralGeneration";
import AdminWealthPortal from "../admin/AdminWealthPortal";
import AdminBankingPortal from "../admin/AdminBankingPortal";
import AdminOverview from '../admin/AdminOverview';
import AdminLogView from '../admin/AdminLogView';
import AdminSystemReview from '../admin/AdminSystemReview';
import AdminKinkEditor from '../admin/AdminKinkEditor';

import BuilderAreaEditor from "../builder/BuilderAreaEditor";
import BuilderScriptRepository from "../builder/BuilderScriptRepository";
import QuestManager from "../builder/quests/QuestManager";
import ResourceStylingManager from "../builder/resources/ResourceStylingEditor";
import BuilderArmourTypeView from "../builder/BuilderArmourTypeView";
import BuilderWeaponTypeView from "../builder/BuilderWeaponTypeView";
import BuilderIssueTracker from "../builder/IssueTracker";
import BuilderSchematicManager from "../builder/schematics/SchematicManager";
import BuilderProfessionEditorMain from "../builder/schematics/ProfessionEditorMain"
import BuilderProductEditorMain from "../builder/schematics/ProductEditorMain"
import ContentViewer from "../player/ContentViewer";
import AdminCharacterData from "../admin/PlayerManagement/AdminCharacterData";

const navigation = [

    { role: 'builder', title : 'Area Editor', path: '/builder/area/:room_id?', component: BuilderAreaEditor },
    { role: 'builder', title : 'Script Repository1', divider : true},
    { role: 'builder', title : 'Script Repository', path: '/builder/scripts', component: BuilderScriptRepository },
    { role: 'builder', title : 'Quest Manager', path: '/builder/quests', component: QuestManager },
    { role: 'builder', title : 'Styling Dictionary', path: '/builder/dictionary', component: ResourceStylingManager },
    { role: 'builder', title : 'Styling Dictionary1', divider : true},
    { role: 'builder', title : 'View Armour Types', path: '/builder/armours', component: BuilderArmourTypeView },
    { role: 'builder', title : 'View Weapon Types', path: '/builder/weapons', component: BuilderWeaponTypeView },
    { role: 'builder', title : 'Professions', divider : true},
    { role: 'builder', title : 'Issue Tracker', path: '/builder/issues', component: BuilderIssueTracker },
    { role: 'builder', title : 'Professions', divider : true},
    { role: 'builder', title : 'Maintain Professions', path: '/builder/profession/:profession_id?', component: BuilderProfessionEditorMain},
    { role: 'builder', title : 'Maintain Product Lines', path: '/builder/productlines/:product_id?', component: BuilderProductEditorMain},
    { role: 'builder', title : 'Maintain Schematics & Blueprints', path: '/builder/schematics/:schematic_id?', component: BuilderSchematicManager},
    { role: 'builder', title : 'Maintain a', divider : true},
    { role: 'builder', title: 'Procedural Generation', path: '/builder/procgen', component: RandomGeneration },
    { role: 'builder', title : 'Maintain a', divider : true},
    { role: 'builder', title : 'Builder Guides', path: '/builder/guides/:guideid?', component: ContentViewer, extraParams : { type :'guideid', content: "builderguide", showdates: false } },
    { role: 'webadmin', title: 'Procedural Generation', path: '/admin/procgen', component: RandomGeneration },
    { role: 'webadmin', title: 'Wealth Portal', path: '/admin/wealth/:playername?/:daterange?', component: AdminWealthPortal },
    { role: 'webadmin', title: 'Wealth Banking', path: '/admin/banking/:type?/:id?', component: AdminBankingPortal },
    { role: "webadmin", path: "/admin/players", title: "Manage Players", component: AdminPlayerManager },
    { role: "webadmin", path: "/admin/webcontent", title: "Content for Web Pages", component: WebContentSubmission },
    { role: 'webadmin', title: 'Admin Overview', path: '/admin/overview', component: AdminOverview },
    { role: 'webadmin', title: 'System Review', path: '/admin/system', component: AdminSystemReview },
    { role: 'webadmin', title: 'System Log', path: '/admin/log', component: AdminLogView },
    { role: 'webadmin', title: 'Kink Editor', path: '/admin/kinks', component: AdminKinkEditor },
    { role: 'webadmin', title : 'Character Maintenance', path: '/admin/character/:charactername?', component: AdminCharacterData },    
];

export default navigation;