import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import ColouredText from '../../components/ColouredText';
import { removeColourCodes } from '../../utils/ColourMap';
import { useBuilder, useBuilderDispatch } from '../../components/index';
import { useMobBuilder, useMobDispatch } from './MobProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setBuilderMobData } from '../../components/builderRequests';

function InventoryItem(props) {

    const [show, setShow] = useState(false);
    const { currentroom, editing } = useBuilder();
    const { currentMobData } = useMobBuilder();
    const mobDispatch = useMobDispatch();
    const dispatch = useBuilderDispatch();

    let item = props.item;
    let index = props.index;

    const expandRow = () => {
        setShow(!show);
    }

    const editingDone = () => dispatch({ type: 'EDITING_DISABLED' });

    const deleteItem = async (name, uniqueid) => {
        let itemToDelete = {
            name: name,
            uniqueid: uniqueid,
        };
        
        mobDispatch({ type: "DELETE_ITEM", field: 'inventory', payload: itemToDelete });
       
        currentMobData.sourceRoomId = currentroom.id;
        let data = await setBuilderMobData(currentMobData);
   
        if (data.result) {
            editingDone();
            mobDispatch({ type: "SELECT_CURRENTMOB",  payload: data.data });
            dispatch({ type: "UPDATE_MOB",  payload: data.data });
            dispatch({ type: "SELECT_ITEM", field: 'inventory', payload: null });
            mobDispatch({ type: 'SELECT_ERROR', payload: "" });
        } else {
            mobDispatch({ type: 'SELECT_ERROR', payload: data.reason });
        }

    }

    const saveInventoryItem = async (itemdata) => {
        //console.log('Updating Item on MOB', itemdata);
        mobDispatch({ type: "UPDATE_ITEM", field: 'inventory', payload: itemdata });
        currentMobData.sourceRoomId = currentroom.id;
        //console.log('Saving Mob', currentMobData);
        let data = await setBuilderMobData(currentMobData);
        if (data.result) {
            //console.log('d', data);
            editingDone();
            
            mobDispatch({ type: "SELECT_CURRENTMOB",  payload: data.data });
            dispatch({ type: "UPDATE_MOB",  payload: data.data });
            //mobDispatch({ type: "SELECT_ITEM", field: 'inventory', payload: null });
            mobDispatch({ type: 'SELECT_ERROR', payload: "" });
        } else {
            //console.log('e');
            mobDispatch({ type: 'SELECT_ERROR', payload: data.reason });
        }
    }

    const selectItem = async (itemdata) => {
        dispatch({ type: 'SELECT_ITEM', payload: itemdata, saveCallback: saveInventoryItem });
    }

    const showItem = () => {
        if (item === undefined) {
            return null;
        } else
            return (
                <>
                    <Row className="floatingdiv">
                        <Col md={2}>
                            <strong>Name:</strong>
                        </Col>
                        <Col>
                            <ColouredText ColouredText={item.name} />
                        </Col>
                    </Row>
                    <Row className="floatingdiv ">
                        <Col md={2}>
                            <strong>Description:</strong>
                        </Col>
                        <Col>
                            <ColouredText ColouredText={item.description} />
                        </Col>
                    </Row>
                </>
            );
    }

    return (
        <>
            {show ?
                <div className="stock-row px-2">
                    <Row className="floatingdiv" >
                        <Col md={2} onClick={expandRow}>
                            <strong>Item</strong>
                        </Col>

                        <Col onClick={expandRow}>
                            {removeColourCodes(item.name)}
                        </Col>

                        <Col>
                            ({item.type})
                        </Col>
                        {editing ?
                            <>
                                <Col xs={1} >
                                    <FontAwesomeIcon onClick={() => {
                                        deleteItem(item.name, item.uniqueid);
                                    }} className="fa fa-lg fa-eom" icon={"backspace"} />
                                </Col>
                                <Col xs={1} >
                                    <FontAwesomeIcon onClick={() => {
                                        selectItem(item);
                                    }} className="fa fa-lg fa-eom" icon={"box-open"} />
                                </Col>
                            </>
                            : null}

                    </Row>
                    {showItem()}
                </div>
                :

                <Row key={index} className="floatingdiv">
                    {editing ?
                        <Col xs={1} >
                            <FontAwesomeIcon onClick={() => {
                                deleteItem(item.name, item.uniqueid);
                            }} className="fa fa-lg fa-eom" icon={"backspace"} />
                        </Col>
                        : null}
                    <Col xs={1} >
                        <FontAwesomeIcon onClick={() => {
                            selectItem(item);
                        }} className="fa fa-lg fa-eom" icon={"box-open"} />
                    </Col>
                    <Col md={8} onClick={expandRow}>
                        {removeColourCodes(item.name)}
                    </Col>
                    <Col>
                        {item.equipped ? '(Equipped)' : ''}
                    </Col>
                </Row>
            }
        </>
    );
}

export default InventoryItem;