import * as React from 'react'

const ExitContext = React.createContext();
const ExitDispatchContext = React.createContext();

const initialState = {
    currentExitData: null,
    originalExit: null,
    error: ''
};

function useExitDispatch() {
    const context = React.useContext(ExitDispatchContext);
    if (context === undefined) {
        throw new Error("useExitDispatch must be used within an ExitProvider");
    }
    return context;
}

const exitReducer = (initialState, action) => {
    let newExitData = { ...initialState.currentExitData };
    switch (action.type) {
        case "SELECT_CURRENTEXIT":
            return {
                ...initialState,
                currentExitData: action.payload
            }
        case "SET_FIELD":
            console.log(action);
            newExitData[action.field] = action.payload;
            return {
                ...initialState,
                currentExitData: newExitData
            }
        case 'SET_AFFECT': {
            let affects = newExitData[action.field];
            console.log('data-2', affects);
            console.log('action', action.payload);
            affects[action.payload.index].id = action.payload.id;
            affects[action.payload.index].params = action.payload.params;
            newExitData[action.field] = affects;
            // currentExitData.effects[effectIndex].id = effectId;
            // currentExitData.effects[effectIndex].params = effectParameters;

            //exitDispatch({ type: "SET_AFFECT", field: affectField, payload: updateAffect });

            return {
                ...initialState,
                currentExitData: newExitData
            }
        }
        case "SELECT_ORIGINALEXIT":
            return {
                ...initialState,
                originalExit: action.payload
            }
        case "SELECT_ERROR":
            return {
                ...initialState,
                error: action.payload
            }
        case 'DELETE_AFFECT': {
            console.log('data-1', newExitData);
            //currentExitData.behaviours = currentExitData.behaviours.splice(effectIndex, 1);
            let behaviours = newExitData[action.field];
            console.log('data-2', behaviours);
            behaviours.splice(action.payload, 1);

            newExitData[action.field] = behaviours;//newExitData[action.field].splice(action.payload, 1);
            console.log('data-3', newExitData);
            return {
                ...initialState,
                currentExitData: newExitData
            }
        }
        case 'ADD_AFFECT': {
            //currentExitData.behaviours = currentExitData.behaviours.splice(effectIndex, 1);
            console.log('action', action);
            console.log('payload', action.payload);
            console.log('field', newExitData[action.field]);
            let addEfffects = newExitData[action.field];
            addEfffects.push(action.payload);

            newExitData[action.field] = addEfffects;// newExitData[action.field].push(action.payload);
            console.log('data', newExitData);
            return {
                ...initialState
            }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

function ExitProvider({ children }) {
    const [state, dispatch] = React.useReducer(exitReducer, initialState);
    return <ExitContext.Provider value={state}>
        <ExitDispatchContext.Provider value={dispatch}>
            {children}
        </ExitDispatchContext.Provider>
    </ExitContext.Provider>
}

function useExitBuilder() {
    const context = React.useContext(ExitContext)
    if (context === undefined) {
        throw new Error('useExitBuilder must be used within a ExitProvider')
    }
    return context
}

export { ExitProvider, useExitBuilder, useExitDispatch }
