import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { listCharacterClasses, getCharacterClassData } from './../components';

function GameplayClasses() {
    const [classList, setClassList] = useState([]);
    const [classData, setClassData] = useState();
    const [filter, setFilter] = useState('');

    const loadClassData = async (e) => {
        let classId = e.target.getAttribute('name');
        const data = await getCharacterClassData(classId);
        if (data.result) {
            setClassData(data);
        } else {
            setClassData({});
        }
    }

    const getRaceList = async () => {
        const data = await listCharacterClasses();
        if (data.result) {
            setClassList(data.subClasses);
        } else {
            setClassList([]);
        }
    }

    useEffect(() => {
        getRaceList();
    }, []);

    const filterMatch = (ability) => {
        return ability.type.toLowerCase().indexOf(filter.toLowerCase()) > -1 || ability.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    }

    const sortedAbilities = () => {
        let filteredlist = classData.abilities.filter(ability => filterMatch(ability));
        let sortedAbilities = filteredlist.sort((a, b) => {
            return a.level - b.level;

        });

        return sortedAbilities;
    }

    return (
        <div className="container" id="divMainContent">
            <Row>
                <Col>
                    <br />
                    <div className='row justify-content-between'>
                        <h1>Classes of the Realm</h1>
                    </div>
                    <br />

                </Col>
            </Row>
            <Row>
                <Col xs="3">
                    <ul>
                        {classList.map(classinfo =>
                            <div key={classinfo.class_id} onClick={loadClassData}>
                                <h4 name={classinfo.class_id} >{classinfo.class_name} </h4>
                            </div>
                        )}
                    </ul>
                </Col>

                <Col>
                    <div>
                        {classData !== undefined
                            ? (
                                <>
                                    <h1>{classData.class_name}</h1>
                                    <strong>Description</strong>
                                    <p>{classData.class_description}</p>
                                    <strong>Weapons</strong>
                                    <p>{classData.class_weapons}</p>
                                    <strong>Armour</strong>
                                    <p>{classData.class_armor}</p>
                                    <strong>Hit Points</strong>
                                    <p>{classData.class_hit}</p>
                                    <strong>Mana Points</strong>
                                    <p>{classData.class_mana}</p>
                                    <strong>Move Points</strong>
                                    <p>{classData.class_move}</p>
                                    <strong>Attack Points</strong>
                                    <p>{classData.class_attack}</p>
                                    <strong>Damage Points</strong>
                                    <p>{classData.class_damage}</p>
                                    {classData.subClasses.length > 0 ?
                                        (<>
                                            <strong>Sub-classes</strong>
                                            <ul className="flatContainer">
                                                {classData.subClasses.map(classinfo =>
                                                    <span className="mx-2 px-2" key={classinfo.class_id} onClick={loadClassData}>
                                                        <strong name={classinfo.class_id} >{classinfo.class_name} </strong>
                                                    </span>
                                                )}

                                            </ul>
                                        </>)
                                        : null}

                                    <strong>Abilities</strong>
                                    <Row className="mx-3 px-2">
                                        <input type="text" className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                                    </Row>
                                    <Row className="mx-2 px-2">
                                        <Col xs={4}>
                                            <strong>Name</strong>
                                        </Col>
                                        <Col>
                                            <strong>Level</strong>
                                        </Col>
                                        <Col>
                                            <strong>Cost</strong>
                                        </Col>
                                        <Col>
                                            <strong>Type</strong>
                                        </Col>
                                    </Row>

                                    {sortedAbilities().map((ability, index) =>
                                        <Row className="mx-2 px-2" key={index}>
                                            <Col xs={4}>
                                                {ability.autogain ?
                                                    <strong>{ability.name}</strong>
                                                    : <>{ability.name}</>}

                                            </Col>
                                            <Col>
                                                {ability.level}
                                            </Col>
                                            <Col>
                                                {ability.cost}
                                            </Col>
                                            <Col>
                                                {ability.type}
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )
                            : null}

                    </div>
                </Col>
            </Row>







        </div>
    );
}

export default GameplayClasses;