import React, { useEffect, useState } from 'react';

import { ButtonGroup, ToggleButton, Form, Button } from 'react-bootstrap';
import { getHelpForTopic } from './../components';
import ColouredText from '../components/ColouredText';

function GameplayHelp() {
  const [radioValue, setRadioValue] = useState('3');
  const [helpTopic, setHelpTopic] = useState('');
  const [helpResult, setHelpResult] = useState();

  const radios = [
    { name: 'Area', value: '1', topic: 'area' },
    { name: 'Class', value: '2', topic: 'class' },
    { name: 'General', value: '3', topic: 'misc' },
    { name: 'Materials', value: '4', topic: 'materials' },
    { name: 'Race', value: '5', topic: 'race' },
    { name: 'Recipe', value: '6', topic: 'recipe' },
    { name: 'Profession', value: '7', topic: 'profession' }
  ];


  useEffect(() => {
    console.log('Mode', radioValue);
  }, [radioValue]);

  const getHelp = async e => {
    let topic = radios[radioValue - 1].topic;
    let subtopic = helpTopic;

    if (topic == null) {
      topic = subtopic;
      subtopic = undefined;
    }
    //console.log("Getting Help for " + topic + "/" +  subtopic);
    const data = await getHelpForTopic(topic, subtopic);
    //console.log(data);
    if (data.result) {
      setHelpResult(data.help_entry);
    } else {
      setHelpResult();
    }
  }

  return (
    <div className="container" id="divMainContent">
      <ButtonGroup toggle>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="danger"
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onClick={(e) => setRadioValue(radio.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <Form.Control onChange={(e) => setHelpTopic(e.currentTarget.value)} type="text" placeholder="Normal text" />
      <Button className="btn-themed-dark" onClick={getHelp}>Help</Button>
      <br />
      <ColouredText ColouredText={helpResult} />
    
    </div>
  );
}

export default GameplayHelp;