import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { makeNiceString, timeConverter, makeNiceRpsheetLinks } from '../../utils/NiceStrings'
import { getPlayersOnlineAdmin, getRumours, getTopRpList, getEventList, } from '../../components';
import { getPlayerList } from '../../components/deityRequests';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerWealthOverview from '../PlayerWealthOverview';

function PlayerRow(props) {
    let player = props.player;
    let index = props.index;

    let rowclass = "";
    if (index % 2 == 0) {
        rowclass = "row-even";
    }


    return (
        <Row key={player.name} className={rowclass}>
            <Col >
                <a className="dryLink p-2" href={`/admin/character/${player.name}`}>
                    {player.name}
                </a>
            </Col>
            <Col xs={2}>
                {player.race}
            </Col>
            <Col xs={2}>
                {player.charclass}
            </Col>
            <Col>
                {player.level}
            </Col>
            <Col>
                {player.remort}
            </Col>
            <Col>
                {player.ip}
            </Col>

            <Col xs={3}>
                {timeConverter(player.laston)}
            </Col>
        </Row>
    )
}

export default PlayerRow;