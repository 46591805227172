import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Badge, Container } from 'react-bootstrap';
import { getPlayerStoryList, getPlayerStory } from '../components/webContentRequests'
import TextLabels from '../components/TextLabels';
import { useParams } from 'react-router-dom';
import { timeConverter } from '../utils/NiceStrings';
import EomMarkdown from '../components/EomMarkdown';

function PlayerStories() {

  let match = useParams();
  const topOfDocRef = useRef(null);

  let defaultstory = undefined;
  if (match.storyid !== undefined) {
    defaultstory = match.storyid;
  }
  const [storyList, setStoryList] = useState([]);
  const [currentStoryId, setCurrentStoryId] = useState(defaultstory);
  const [storyData, setStoryData] = useState(undefined);
  const [labels, setLabels] = useState([]);
  const [activeLabels, setActiveLabels] = useState([]);

  const getStoryList = async e => {
    const data = await getPlayerStoryList();
    //console.log(data);
    if (data.result) {
      data.stories.sort((a, b) => b.story_publishdate - a.story_publishdate);
      setStoryList(data.stories);

    } else {
      //setStoryList([]);
    }
  }
  const loadStoryFromId = (id) => {
    setCurrentStoryId(id);
    getPlayerStory(id).then(data => {
      //console.log('Received', data);
      setStoryData(data);
      topOfDocRef.current.scrollIntoView({ behavior: 'auto' });
    })
  }

  const getStoryInfo = (storyId) => {
    const storyInfo = storyList.find((story) => story.story_id == storyId);
    //console.log(storyId, storyInfo);
    return storyInfo;
  }

  const getPlayerList = () => {
    let players = [...new Set(storyList.map(story => story.story_owner))];
    return players;
  }

  const getStoriesForPlayer = (playername) => {
    //console.log('current story list:', storyList)
    let playerStoryList = storyList.filter(story => story.story_owner === playername);
    //console.log('current story list:', playerStoryList)
    return playerStoryList;
  }

  const players = getPlayerList();
  //console.log(players);

  const getPlayerSection = (playername) => {
    //console.log('Getting Section: ', playername);
    let subList = getStoriesForPlayer(playername);
    //console.log('Getting Section: ', subList)
    return (
      <div>
        <h4>{playername}'s Tales</h4>
        <ul className="kinks-ul-single">
          {subList.map(story =>

            <Row key={story.story_id} name={story.story_id} className={"py-2"} onClick={() => loadStoryFromId(story.story_id)}>{story.story_title}</Row>

          )}
        </ul>
      </div>
    );
  }

  useEffect(() => {
    if (storyList.length === 0)
      getStoryList();
    else if (currentStoryId !== undefined) {
      // console.log('Try to load story', currentStoryId);
      loadStoryFromId(currentStoryId);
    }

  }, [currentStoryId, storyList]);


  useEffect(() => {
    if (storyList.length > 0) {
      let tags = [];
      storyList.forEach(story => {
        story.story_labels.forEach(label => {
          if (tags.indexOf(label) == -1)
            tags = [...tags, label];
        }

        )
      });
      setLabels(tags);
      console.log(tags)
    }


  }, [storyList]);

  useEffect(() => {
    console.log(activeLabels)
  }, [activeLabels]);

  const selectLabel = (label) => {
    console.log(label);
    if (activeLabels.indexOf(label) == -1)
      setActiveLabels([...activeLabels, label]);
    else {
      setActiveLabels(activeLabels.filter(activelabel => activelabel != label));
    }
  }

  const labelledStories = () => {
    return storyList.filter(story => {
      const matchedLabels = story.story_labels.filter(label => activeLabels.includes(label));
      return matchedLabels.length == activeLabels.length;
    });
  }

  // console.log(labelledStories());
  console.log(storyData);
  let storyInfo = getStoryInfo(currentStoryId);
  return (
    <Container fluid ref={topOfDocRef}>
      <Row>
        <Col xs={4} md={2} xl={2} lg={2}>
          <Row>
            <TextLabels defaultLabels={labels} selectedLabels={activeLabels} selectLabel={selectLabel} />
          </Row>
          <br />

          {activeLabels.length >= 1 ?
            <Row>
              <h3>Tagged Stories</h3>
              <ul className="kinks-ul-single">
                {storyList !== undefined ? (
                  <Row>

                    {labelledStories().map((story, index) =>
                      <Row key={index} name={story.story_id} className={"py-2"} onClick={() => loadStoryFromId(story.story_id)}>
                        {story.story_owner} - {story.story_title}
                      </Row>

                    )}
                  </Row>
                ) : null}
              </ul>
            </Row>
            : null}

          <h3>Recent Stories</h3>

          <ul className="kinks-ul-single">
            {storyList !== undefined ? (
              <>
                {storyList.slice(0, 3).map(story =>
                  <Row key={story.story_id} name={story.story_id} className={"py-2"} onClick={() => loadStoryFromId(story.story_id)}>
                    {story.story_owner} - {story.story_title}
                  </Row>

                )}
              </>
            ) : null}
          </ul>
          <br />
          {players.map((player, index) =>
            <div key={index}>{getPlayerSection(player)}</div>
          )}
        </Col>
        <Col>
          {storyInfo !== undefined
            ?
            <>
              <h2>{storyInfo.story_owner} - {storyInfo.story_title}</h2>
              {(storyData !== undefined) ?
                <small><strong>Published:</strong> {timeConverter(storyData.story_publishdate, true)}</small> : null}
              {(storyData !== undefined) && (storyData.story_labels.length > 0) ?
                <TextLabels editing={false} defaultLabels={storyData.story_labels} selectedLabels={activeLabels} selectLabel={selectLabel} />
                : null}
              {storyData !== undefined ?
                <EomMarkdown markdown={storyData.story_text.replace('`', '\\`')} />
                : null}
            </>

            : null}

        </Col>
      </Row>
    </Container>
  )
}

export default PlayerStories;