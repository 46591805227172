import React, { useRef, useState, useEffect } from 'react';
import { Row, Col} from 'react-bootstrap';

import DisplayMessage from './DisplayMessage';

import { useClientContext } from './ClientProvider';



function AnsibleRightPanel(props) {

    const { enableswipe, channelMessages, fontSize, fontFamily } = useClientContext();

    const channelsEndRef = useRef(null);
    const channelsEndRefOoc = useRef(null);

    useEffect(() => {
        scrollToBottomOfChannels(true);
    }, [channelMessages]);

    const scrollToBottomOfChannels = (forcedScroll) => {
        if (forcedScroll) {
            channelsEndRef.current.scrollIntoView({ behavior: 'auto' });
            channelsEndRefOoc.current.scrollIntoView({ behavior: 'auto' });
        }
    }

    const icChannelMessages = () => {
        return channelMessages.filter(msg => !msg.isooc);
    }

    const oocChannelMessages = () => {
        return channelMessages.filter(msg => msg.isooc);
    }

    let divStyle = {};
    divStyle.fontSize = fontSize + "px";
    divStyle.fontFamily = fontFamily;
    divStyle.textAlign = 'left';
    divStyle.color = 'white';

    return (
        <Col className={"px-3 d-md-block d-sm-none eomLeftBorders " + (enableswipe ? "" : " limitWidth")}>

            <Row className={"pb-2 eomCharacter"}>
                <Col className={"eomCharacterLeft"}>
                    <span>Out of Character Communications</span>
                </Col>
            </Row>

            <Row className={"output-container-half py-2"} >
                <div id="myInput" style={divStyle}>
                    {oocChannelMessages().map((message, index) =>
                        <DisplayMessage
                            key={index}
                            message={message}
                            name="eom"
                            displayType={"channel"}
                        />,
                    )}
                    <div key={"end"} ref={channelsEndRefOoc} />
                </div>
            </Row>

            <Row className={"py-2 eomIntervalBorderTop"}>
            </Row>
            <Row className={"pb-2 eomCharacter"}>
                <Col className={"eomCharacterLeft"}>
                    <span>In Character Communications</span>
                </Col>
            </Row>

            <Row className={"output-container-half py-2"} >
                <div id="myInput" style={divStyle}>
                    {icChannelMessages().map((message, index) =>
                        <DisplayMessage
                            key={index}
                            message={message}
                            name="eom"
                            displayType={"channel"}
                        />,
                    )}
                    <div key={"end"} ref={channelsEndRef} />
                </div>
            </Row>
        </Col>

    );
}

export default AnsibleRightPanel;