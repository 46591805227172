import React, { Component } from 'react';

class Home extends Component {
  render() {
    return (
      <div>

        <div className="container container-eom" id="divMainContent">
          <h2 className="divTagLine" id="divtTagLine">where your dreams and nightmares can become reality</h2>
          <div className="row fluid">
            <div className="col">
              <div className="card border-light mb-3">
                <img className="card-img-top card-icon" src="/images/card-ace-spades.png" />
                <div className="card-body">
                  <h4 className="card-title">Realm in Chaos</h4>
                  <div className="card-text" id="textRealm">
                    <p>The Edge of Midnight exists within the mind of its creator <strong>Pyros</strong>. </p>
                    <p>His memories and experiences shaping the realm of midnight.</p>
                    <p>Fantasy, steam-punk and science-fiction sitting alongside contemporary themes as films are watched, books read and ideas flit and dance through his mind.</p>
                    <p>And within this strange world some adventurers have taken on a stronger imprint, battling to survive and carve out an existence as chaos, insanity and lustful thoughts seek to overwhelm and corrupt them.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card border-light mb-3">
                <img className="card-img-top card-icon" src="/images/drama-masks.png" />

                <div className="card-body">
                  <h4 className="center card-title">Roleplaying is Expected</h4>
                  <div className="card-text" id="textRole">
                    <p>The Edge of Midnight is a place where people can come to live out an alternate existence, to choose to be something different for a time and as such it is, first and foremost, a roleplaying game.</p>
                    <p>You are expected as a player to remain in character when communicating with other characters and only use the OOC (<strong>Out of Character</strong>) commands and channels if you wish to communicate to other players.</p>
                    <p>It still has a combat engine and an depth crafting system that allow your character to grow and progress but if you do not wish to roleplay this isn't the MUD for you!</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card border-light mb-3">
                <img className="card-img-top card-icon" src="/images/dagger-rose.png" />


                <div className="card-body">
                  <h4 className="card-title">Adult MUD</h4>
                  <div className="card-text" id="textAdult">
                    <p>This is an <strong>adult</strong> mud with strong themes of <strong>sex and violence</strong> running through the core of it.</p>
                    <p>Many of the realm's citizens are good and kind folk, whilst others lurk behind pleasant facades. Demons such as succubi and incubi walk amongst the peoples of the realm whilst Orcs vie for power in brutal encounters that pass for politics in their minds.</p>
                    <p>But within this there is goodness too, those that seek to care and nurture others, to help them as they find their way in this strange realm.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default Home;