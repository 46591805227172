import React, { useEffect, useState } from 'react';
import EoMMap from './../builder/EoMMap';
import RoomLayout from '../builder/RoomLayout';

function debounce(fn, ms) {
    //console.log('Debounce!');
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

function MapContainer(props) {
    let currentAreaData = props.currentAreaData;
    let currentRoom = props.currentRoom;
    let selectRoom = props.selectRoom;
    let editing = props.editing;
    let showelevation = props.showelevation;
    let simpleMode = props.simpleMode;
    let createRoom = props.createRoom;
    let linkRooms = props.linkRooms;
    let exploring = props.exploring;

    const [width, setWidth] = useState(250);
    const [height, setHeight] = useState(250);

    if (props.currentAreaData === undefined) {
        return null;
    }

    const displaymap = () => {
        if ((currentAreaData !== null) && (currentAreaData.roomids === undefined)) {
            return (<EoMMap mapwidth={width} mapheight={height}
                currentAreaData={currentAreaData} currentRoom={currentRoom}
                selectRoom={selectRoom}
                editing={false}
                showElevaton={false}
                simpleMode={true} />);

        } else if ((currentRoom !== null) && (!exploring)) {
            return (
                <RoomLayout width={width} height={height}
                    currentAreaData={currentAreaData}
                    currentRoom={currentRoom}
                    selectRoom={selectRoom}
                    createRoom={createRoom}
                    linkRooms={linkRooms}
                    editing={editing} />
            );
        } else if ((currentAreaData !== null) && (currentAreaData.roomids.length > 0))
            return (<EoMMap mapwidth={width} mapheight={height}
                currentAreaData={currentAreaData} currentRoom={currentRoom}
                selectRoom={selectRoom}
                editing={editing}
                showElevaton={true}
                simpleMode={simpleMode} />);
        else {
            return null;
        }
    }

    useEffect(() => {
        var myDiv = document.getElementById("#mapcontainer");
        if (myDiv != null) {
            //console.log('Div first set to: ', myDiv.offsetWidth, 'x', myDiv.offsetHeight);
            setWidth(myDiv.offsetWidth);
            setHeight(myDiv.offsetHeight);
        }

        const debouncedHandleResize = debounce(function handleResize() {
            var myDiv = document.getElementById("#mapcontainer");
            if (myDiv != null) {
                //  console.log('Div Resized to: ', myDiv.offsetWidth, 'x', myDiv.offsetHeight);
                setWidth(myDiv.offsetWidth);
                setHeight(myDiv.offsetHeight);
            }
        }, 100)

        window.addEventListener('resize', debouncedHandleResize);

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    })

    return (<div id="#mapcontainer" className={"map-row "+ (simpleMode?"":"map-row-fixed")}>
        {displaymap()}
    </div>);
}

export default MapContainer;