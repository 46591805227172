import React, { Component } from 'react';

class IntroEvents extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">  
        <br/>
        <h1>Events in the Edge of Midnight</h1>
        <br/>
        <h3>What is an Event?</h3>
        <p>An event is a planned public or social occasion that is going on somewhere in the realm/</p>		
        <p>This is official piece of metagaming designed to encourage people to move out from the usual locations but not nescessarily be 
        doing something private - if you are having a game of some sort or a party and you want people to come see - this is the tool for you!</p>
        <h3
        >Creating an Event</h3>
        <p>To create an event, use the <strong>event</strong> command followed by a short description of the event you are holding.</p>
        <p>This will create an event that everyone can see by entering the <strong>events</strong> command and anyone with <strong>autoevents</strong>
        enabled will be notified off when it is created or when they log in!</p>
        <p>An event can further be refined with some additional switches (parameters).</p>
        
        <ul className="list-unstyled">
          <li><strong>-expires</strong> : This takes a second option which is the number of minutes till expiry.</li>
          <li><strong>-invite</strong>  : This can be either 'friends' or a list of players you wish to invite.</li>
          <li><strong>-minplayers</strong> : This sets the minimum number of players that must be present for the event to continue.</li>
          <li><strong>-maxplayers</strong> : Once this number of players has arrived then the event is filled and it vanishes.</li>
          <li><strong>-dates</strong> : Takes two further parameters - start and end times. Once the event passes the start date/time the event begins and then expires at the specified time.</li>
          <li><strong>-local</strong> : This will prevent the event from being published globably and will show up in the room description.</li>
        </ul>
        
        <h3>Ending an Event</h3>
        <p>Use the <strong>clear</strong> parameter to clear the current event, e.g. <strong>event clear</strong>.</p>

        <h3>Examples of different types of Events</h3>
        <ul className="list-unstyled">
          <li>
            EVENT There is a bar brawl going on here! -minplayers 3
            <p>This creates an event that will vanish when there are less than three players present.</p>
            
          </li>
          <li>
            EVENT The clock is counting down before prizes are handed out. -expires 60
            <p>This creates an event that will end after sixty minutes.</p>
          </li>
          <li>
            EVENT There is an orgy here on the beach. -invite friends
            <p>This creates an event that will only appear to people on your friends lists.</p>
          </li>
          <li>
            EVENT Someone is waiting to meet you. -invite Suki
            <p>This creates an event that will only appear to the invited character</p>
          </li>
          <li>
            EVENT A double date. -invite "Chase Tawariell"
            <p>This creates an event that will only appear to the invited characters, note the double quotes around the names!</p>
          </li>
          <li>
            EVENT A sparring partner is requested. -maxplayers 2
            <p>This creates an event that will only disappear once there are two player characters present!</p>
          </li>
          <li>
            EVENT Someone is waiting to meet you, but they won't wait forever -expires 10 -invite Suki
            <p>This creates an event that will only appear to the invited character, but will expire within ten minutes.</p>
          </li>
          <li>
            EVENT The gods walk the realm for Samhain -dates 31/10/2018 01/12/2018
            <p>This creates an event that will be flagged as active on the 31st of October and runs up until (not including) the 1st of December.
            It's important to note that this works off of the local server time and takes the UK date format of <strong>dd/mm/yyyy</strong> or <strong>dd/mm/yyyy HH:mm</strong>.</p>
          </li>

        </ul>			

      </div>
    );
  }
}

export default IntroEvents;