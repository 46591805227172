import React, { useEffect, useState } from 'react';
import { loginUser, logout, useAuthState, useAuthDispatch, toggleMenu, } from './../components/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import PlayerMenu from './PlayerMenu'
import Config from './../../../config/Config';
import { useThemeDispatch } from '../../theming/ThemeProvider';

const InlineUserLoggedIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [inputType, setInputType] = useState('password');
    const themeDispatch = useThemeDispatch();

    const dispatch = useAuthDispatch();
    const { loading, avatar, token, currentUser, newMailCount, showPlayerMenu } = useAuthState();

    function updateStatus() {

    }

    useEffect(() => {
    }, [showPlayerMenu]);

    setInterval(updateStatus, 30000);

    const handleToggleMenu = async (e) => {
        e.preventDefault();
        let response = await toggleMenu(dispatch);
    }

    const handleSubmit = async (e) => {
        //    alert('A name was submitted: ' + username + ' / ' + password);
        setPasswordError('');

        e.preventDefault();
        if (password.length > 0) {
            let response = await loginUser(dispatch, { username, password });
            //console.log(response);
            if (!response.result) {
                setPasswordError(response.reason);
            } else {
                setPasswordError('');
            }

        } else {
            setPasswordError('Invalid Password');
        }
    }

    const handleVisibilityChange = (e) => {
        setInputType('password' === inputType ? "text" : "password");
    }

    const handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setPasswordError('');

        if (name === 'eompassword')
            setPassword(e.target.value);
        else
            setUsername(e.target.value);
    }

    var imagelocation = '/images/avatar.png';
    if (avatar != null) {
        // console.log('Avatar is .' + avatar+ '.');
        var basics = JSON.parse(localStorage.getItem('basicinfo'));
        if ((avatar !== undefined) && (avatar.trim() != "")) {
            imagelocation = `https://${Config.middleware.host}:${Config.middleware.port}/` + avatar;
        }
    }

    const toggleTheme = () => {
        themeDispatch({ type: 'TOGGLE_THEME' });
    }

    const unreadmail = localStorage.getItem('newMailCount');
    //console.log('Mail Count = ' + unreadmail);
    let showMail = (unreadmail > 0) || (newMailCount > 0);


    return (

        
        <div className={"d-flex m-2 align-items-center"}>
            <FontAwesomeIcon className="mx-3 fa fa-trash fa-lg" icon={"paint-roller"} color="white" onClick={() => toggleTheme()} />

            {showMail ? (
                <a href="/player/messages">
                    <FontAwesomeIcon className="fa fa-trash fa-lg" icon="envelope" color="white" />
                </a>
            ) : null}

            <div onClick={handleToggleMenu} className={"px-4"}>
                <img width="40" height="40" src={imagelocation} className="rounded-circle mr-2" alt="avatar"></img>
            </div>
            <span className={"inline-text"} onClick={handleToggleMenu}>{currentUser}</span>
            <div onClick={handleToggleMenu} className={"px-4"}>
                <FontAwesomeIcon className="fa fa-trash fa-lg" icon={`chevron-${showPlayerMenu ? "down" : "right"}`} color="white" />
            </div>
            <PlayerMenu />
        </div>);
}

export default InlineUserLoggedIn;