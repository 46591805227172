import { getAmmoTypes, getComponentTypes, getCraftingProcesses, getCraftingTables, getCraftingTools, getEmbellishmentTypes, getMaterials, getWeaponTypes } from "../../BuilderUtils";

export function getFieldMappingsForTemplate(type, itemclass) {
    let schemaFields = [];
    schemaFields = [...schemaFields, { name: 'SCHEMAID', type: 2 }];
    schemaFields = [...schemaFields, { name: 'TYPE', type: 4 }];
    schemaFields = [...schemaFields, { name: 'NAME', type: 2 }];
    switch (type) {
        case 'GenItemDesign' :
            schemaFields = [...schemaFields, { name: 'ITEMCLASS', type: 2 }];
            schemaFields = [...schemaFields, { name: 'NAMEEQUATION', type: 3 }];
            schemaFields = [...schemaFields, { name: 'DESCEQUATION', type: 0 }];
            break;    
        case 'GenFabricRecipe':
            schemaFields = [...schemaFields, { name: 'DESCRIPTOR', type: 2 }];
            break;
        case 'GenHeelDesign':
        case 'GenSoleDesign':
        case 'GenComponentDesign':

            schemaFields = [...schemaFields, { name: 'NAMEEQUATION', type: 4 }];
            schemaFields = [...schemaFields, { name: 'DESCEQUATION', type: 4 }];
            schemaFields = [...schemaFields, { name: 'DESCRIPTOR', type: 4 }];
            break;
        default:

            schemaFields = [...schemaFields, { name: 'NAMEEQUATION', type: 3 }];
            schemaFields = [...schemaFields, { name: 'DESCEQUATION', type: 0 }];
            break;
    }
    schemaFields = [...schemaFields, { name: 'CraftingTool', type: 5, choices: getCraftingTools }];
    schemaFields = [...schemaFields, { name: 'CraftingTable', type: 5, choices: getCraftingTables }];

    schemaFields = [...schemaFields, { name: 'CLASSIFICATION', type: 2 }];
    schemaFields = [...schemaFields, { name: 'SUBCLASS', type: 2 }];
    schemaFields = [...schemaFields, { name: 'QUALITYADJ', type: 11 }];
    schemaFields = [...schemaFields, { split: true }];
    switch (type) {
        case 'GenClothingDesign':
        case 'GenArmorTemplate':
            schemaFields = [...schemaFields, { name: 'ITEMCLASS', type: 2 }];
            schemaFields = [...schemaFields, { name: 'ARMORTYPE', type: 2 }];
            schemaFields = [...schemaFields, { name: 'ARMORCLASS', type: 2 }];
            schemaFields = [...schemaFields, { name: 'ARMORSTYLE', type: 2 }];
            switch (itemclass) {
                case 'GenWeaponStrap':
                case 'GenArmor':
                    schemaFields = [...schemaFields, { split: true }];
                    schemaFields = [...schemaFields, { name: 'CAPACITY', type: 2 }];
                    schemaFields = [...schemaFields, { name: 'CONTAINER', type: 2 }];
                    break;
            }
            break;
        case 'GenWeaponTemplate':
        case 'GenBoffWeaponTemplate':
            schemaFields = [...schemaFields, { name: 'WEAPONCLASS', type: 5, choices: () => getWeaponTypes('id') }];
            schemaFields = [...schemaFields, { name: 'EXOTIC', type: 1 }];
            schemaFields = [...schemaFields, { name: 'AMMUNITION', type: 5, choices: getAmmoTypes }];
            schemaFields = [...schemaFields, { name: 'AMMOCAPACITY', type: 11 }];
            schemaFields = [...schemaFields, { name: 'MINRANGE', type: 11 }];
            schemaFields = [...schemaFields, { name: 'MAXRANGE', type: 11 }];
            break;
        case 'GenHeelDesign':
        case 'GenSoleDesign':
            schemaFields = [...schemaFields, { name: 'COMPONENTTYPE', type: 5, choices: getComponentTypes }];
            schemaFields = [...schemaFields, { name: 'AMOUNT', type: 11 }];
            break;
        case 'GenComponentDesign':
            schemaFields = [...schemaFields, { name: 'COMPONENTTYPE', type: 5, choices: getComponentTypes }];
            schemaFields = [...schemaFields, { name: 'AMOUNT', type: 11 }];
            break;
        case 'GenCageRideableDesign':
        case 'GenCageDesign':
            schemaFields = [...schemaFields, { name: 'ORGANISED', type: 1 }];
            schemaFields = [...schemaFields, { name: 'HASALID', type: 1 }];
            schemaFields = [...schemaFields, { name: 'HASALOCK', type: 1 }];
            schemaFields = [...schemaFields, { name: 'ISOPEN', type: 1 }];
            schemaFields = [...schemaFields, { name: 'CAPACITY', type: 11 }];
            break;
        case 'GenConsumableRecipe':
            schemaFields = [...schemaFields, { name: 'NOURISHMENT', type: 11 }];
            schemaFields = [...schemaFields, { name: 'PORTION', type: 11 }];
            schemaFields = [...schemaFields, { name: 'FOODTYPE', type: 2 }];
            schemaFields = [...schemaFields, { name: 'OUTPUT', type: 5, choices: getMaterials }];
            schemaFields = [...schemaFields, { name: 'CONSUMABLETYPE', type: 11 }];    
            break;
        case 'GenFountainDesign':
        case 'GenContainerDesign':
            schemaFields = [...schemaFields, { name: 'ORGANISED', type: 1 }];
            schemaFields = [...schemaFields, { name: 'HASALID', type: 1 }];
            schemaFields = [...schemaFields, { name: 'HASALOCK', type: 1 }];
            schemaFields = [...schemaFields, { name: 'ISOPEN', type: 1 }];
            schemaFields = [...schemaFields, { name: 'CAPACITY', type: 11 }];
            break;
        case 'GenEmbellishDesign':
            schemaFields = [...schemaFields, { name: 'EMBELLISHMENT', type: 2 }];
            schemaFields = [...schemaFields, { name: 'LINKWORD', type: 2 }];
            schemaFields = [...schemaFields, { name: 'SUFFIX', type: 2 }];
            break;
        case 'GenFabricRecipe':
            schemaFields = [...schemaFields, { name: 'OUTPUT', type: 5, choices: getMaterials }];
            schemaFields = [...schemaFields, { name: 'COMPONENTTYPE', type: 5, choices: getComponentTypes }];
            schemaFields = [...schemaFields, { name: 'AMOUNT', type: 11 }];
            break;
        case 'GenInstrumentDesign':
            schemaFields = [...schemaFields, { name: 'INSTRUMENT', type: 2 }];
            break;
        case 'GenItemDesign':
            break;
        case 'GenLimbDesign':
            break;
        case 'GenTubDesign':
        case 'GenRideableDesign':
            schemaFields = [...schemaFields, { name: 'ORGANISED', type: 1 }];
            schemaFields = [...schemaFields, { name: 'HASALID', type: 1 }];
            schemaFields = [...schemaFields, { name: 'HASALOCK', type: 1 }];
            schemaFields = [...schemaFields, { name: 'ISOPEN', type: 1 }];
            schemaFields = [...schemaFields, { name: 'CAPACITY', type: 11 }];

            schemaFields = [...schemaFields, { name: 'RIDEBASIS', type: 2 }];
            schemaFields = [...schemaFields, { name: 'RIDECAPACITY', type: 11 }];

            schemaFields = [...schemaFields, { name: 'PUTSTRING', type: 2 }];
            schemaFields = [...schemaFields, { name: 'STATESTRING', type: 2 }];
            schemaFields = [...schemaFields, { name: 'STATESTRINGSUBJECT', type: 2 }];
            schemaFields = [...schemaFields, { name: 'MOUNTSTRING', type: 2 }];
            schemaFields = [...schemaFields, { name: 'DISMOUNTSTRING', type: 2 }];
            break;
    }
    return schemaFields;
}

export function getCustomFieldMappingsForTemplate(type, itemclass) {
    console.log('Checking Item Class', itemclass)
    let schemaFields = [];
    switch(itemclass) {
        case 'GenWeaponStrap' :
            schemaFields = [...schemaFields, { description : 'Max Weapons Held', name: 'max_weapon_held', type: 11 }];
            break;
    }
    return schemaFields;
}

export const getFieldMappingsForProcess = () => {
    let processFields = [];
    processFields = [...processFields, { name: 'type', type: 5, choices: getCraftingProcesses }];
    processFields = [...processFields, { name: 'duration', type: 11 }];

    return processFields;
}

export const getFieldMappingsForEffect = () => {
    let processFields = [];
    processFields = [...processFields, { name: 'effect_id', type: 2 }];
    processFields = [...processFields, { name: 'parameters', type: 2 }];

    return processFields;
}


export const getFieldMappingsForList = (list, header) => {
    let fields = [];
    switch (list) {
        case 'effects':
            fields = [...fields, { name: 'effect_id', type: 2 }];
            fields = [...fields, { name: 'parameters', type: 2 }];
            break;
        case 'requirements':
            fields = [...fields, { name: 'requirement_type', type: 2 }];
            fields = [...fields, { name: 'requirement_id', type: 2 }];
            break;
        case 'processes':
            if (header) {
                fields = [...fields, { name: 'type', type: 4 }];
                fields = [...fields, { name: 'duration', type: 4 }];
            } else {
                fields = [...fields, { name: 'type', type: 5, choices: getCraftingProcesses }];
                fields = [...fields, { name: 'duration', type: 11 }];
            }
            break;

        case 'inputs':
            if (header) {
                fields = [...fields, { name: 'key', type: 4 }];
                fields = [...fields, { name: 'list', type: 4 }];
            } else {
                fields = [...fields, { name: 'key', type: 2 }];
                fields = [...fields, { name: 'list', type: 2 }];
                fields = [...fields, { name: 'maxlength', type: 11 }];
                fields = [...fields, { name: 'prompt', type: 2 }];
                fields = [...fields, { name: 'value', type: 2 }];
            }
            break;
    }
    return fields;
}

const getSourceTypes = () => {
    return ["NONE", "AREA", "RACE", "MOB", "MATERIAL", "NAME"];
}

export function getFieldMappingsForIngredient(ingredient, mode) {
    let ingredientFields = [];
    if (mode === 'header') {
        ingredientFields = [...ingredientFields, { name: 'IngredientType', title: "Type", type: 4 }];
        ingredientFields = [...ingredientFields, { name: 'IngredientName', type: 4 }];
        ingredientFields = [...ingredientFields, { name: 'Quantity', type: 4 }];
    } else {
        ingredientFields = [...ingredientFields, { name: 'IngredientType', title: "Type", type: 4 }];

        switch (ingredient.IngredientType) {
            case 'RAWMATERIAL':
                ingredientFields = [...ingredientFields, { name: 'Resource', title: "Material", type: 5, choices: getMaterials }];
                ingredientFields = [...ingredientFields, { name: 'source_type', title: "Source Type", type: 5, choices: getSourceTypes }];
                ingredientFields = [...ingredientFields, { name: 'source_name', title: "Source Name", type: 2 }];
                break;
            case 'COMPONENT':
                ingredientFields = [...ingredientFields, { name: 'Component', type: 5, choices: getComponentTypes }];
                ingredientFields = [...ingredientFields, { name: 'Resource', title: "Material", type: 5, choices: getMaterials }];
                ingredientFields = [...ingredientFields, { name: 'Descriptor', title: "Descriptor", type: 2 }];
                ingredientFields = [...ingredientFields, { name: 'source_type', title: "Source Type", type: 5, choices: getSourceTypes }];
                ingredientFields = [...ingredientFields, { name: 'source_name', title: "Source Name", type: 2 }];
                break;
            case 'EMBELLISHMENT':
                ingredientFields = [...ingredientFields, { name: 'embellishment', title : "Embellishment", type: 5, choices: getEmbellishmentTypes }];
                ingredientFields = [...ingredientFields, { name: 'Resource', title: "Material", type: 5, choices: getMaterials }];
                break;
                case 'FOODSTUFF':
                ingredientFields = [...ingredientFields, { name: 'FoodType', type: 2 }];
                ingredientFields = [...ingredientFields, { name: 'Resource', title: "Material", type: 5, choices: getMaterials }];
                ingredientFields = [...ingredientFields, { name: 'source_type', title: "Source Type", type: 5, choices: getSourceTypes }];
                ingredientFields = [...ingredientFields, { name: 'source_name', title: "Source Name", type: 2 }];
                break;
        }
        ingredientFields = [...ingredientFields, { name: 'Index', type: 11 }];
        ingredientFields = [...ingredientFields, { name: 'Quantity', type: 11 }];
        ingredientFields = [...ingredientFields, { name: 'Optional', type: 1 }];
        ingredientFields = [...ingredientFields, { name: 'DefaultIngredient', title: "Default", type: 1 }];
    }
    return ingredientFields;
}