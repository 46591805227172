import React, { useState, useEffect } from 'react';
import { useBuilder, useBuilderDispatch } from '../../components/index';
import { Row, Col, Button } from 'react-bootstrap';
import { getBuilderRoomItemData, setBuilderRoomData, deleteBuilderItemData, setBuilderItemData } from '../../components/builderRequests';
import { removeColourCodes } from '../../utils/ColourMap';
import ColouredText from '../../components/ColouredText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AffectEditor from '../AffectEditor'
import { defaultMob } from '../config/DefaultObjects';
import { setBuilderMobData, deleteBuilderMobData } from '../../components/builderRequests';
import { getRoomLocales } from '../BuilderUtils';
import EditableField from '../EditableField';
import CreateItemPrompt from '../items/CreateItemPrompt';
import { Tooltip as ReactTooltip } from "react-tooltip";
import RoomContents from './RoomContents';

function RoomEditor(props) {

    const [originalRoom, setOriginalRoom] = useState();
    const [currentRoomData, setCurrentRoomData] = useState();
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');

    const { currentroom, editing } = useBuilder();
    const dispatch = useBuilderDispatch();

    let unloadRoom = props.unloadRoom;
    let loadMob = props.loadMob;
    let loadRoom = props.loadRoom;
    let loadExit = props.loadExit;
    let deleteRoom = props.deleteRoom;
    let unlinkExit = props.unlinkExit;
    let linkRooms = props.linkRooms;
    let refreshroom = props.refreshroom;

    useEffect(() => {
        //console.log('currentRoomData Triggered', currentroom);
        if (currentroom !== null) {
            if ((currentRoomData === undefined) || (currentroom.id !== currentRoomData.id)) {
                // console.log('Switching area', currentroom.id);
                setCurrentRoomData(currentroom);
                setOriginalRoom(JSON.parse(JSON.stringify(currentroom)));
            }
        } else {
            setCurrentRoomData();
            setOriginalRoom();
        }
    }, [currentroom]);


    let active = props.active;

    const cancelRoom = async (e) => {
        unloadRoom();
        setCurrentRoomData();
        setOriginalRoom();
        dispatch({ type: 'EDITING_DISABLED' });
        setError('');
        unloadRoom(e);
    }

    const loadMobLocal = async (index) => {
        dispatch({ type: 'EDITING_DISABLED' });
        loadMob(index);
    }

    const deleteMob = async (databaseId, name) => {
        console.log('Delete Mob', databaseId, name, currentroom.Id);
        dispatch({ type: 'EDITING_DISABLED' });
        let mobToDelete = {
            databaseid: databaseId,
            name: name,
            sourceRoomId: currentroom.id
        };
        let data = await deleteBuilderMobData(mobToDelete);

        if (data.result) {
            editingDone();
            refreshroom();
        } else {
            setError('Mob could not be deleted, ', data.reason);
        }
    }

    const deleteItem = async (name, uniqueid) => {
        console.log('Delete Item', name, uniqueid, currentroom.id);
        dispatch({ type: 'EDITING_DISABLED' });
        let itemToDelete = {
            name: name,
            uniqueid: uniqueid,
            sourceRoomId: currentroom.id
        };
        let data = await deleteBuilderItemData(itemToDelete);

        if (data.result) {
            editingDone();
            refreshroom();
        } else {
            setError('Item could not be deleted, ', data.reason);
        }
    }

    const handleEditing = () => {
        if (editing)
            dispatch({ type: 'EDITING_DISABLED' });
        else
            dispatch({ type: 'EDITING_ENABLED' });
    }
    const editingDone = () => dispatch({ type: 'EDITING_DISABLED' });
    const handleRevert = () => setCurrentRoomData(originalRoom);

    const updateRoomDisplay = async (newValue) => {
        setCurrentRoomData(prevstate => ({ ...prevstate, display: newValue }));
    }

    const selectLocale = async (newValue) => {
        console.log('Change Locale', newValue);
        setCurrentRoomData(prevstate => ({ ...prevstate, type: newValue }));
    }

    const updateRoomDescription = async (newValue) => {
        setCurrentRoomData(prevstate => ({ ...prevstate, description: newValue }));
    }

    const saveChanges = async () => {
        console.log(currentRoomData);
        let data = await setBuilderRoomData(currentRoomData);
        if (data.result) {
            editingDone();
            setCurrentRoomData(data.room);
            setOriginalRoom(data.room);
            dispatch({ type: 'SELECT_ROOM', payload: data.room });
            //dispatch here
        } else {
            setError('Room could not be saved, ', data.reason);
            // console.log(data.reason);
        }
    }

    const deleteAffect = (field, effectIndex) => {
        console.log('Delete', field, effectIndex);
        if (effectIndex >= 0) {
            let affects = currentRoomData[field];
            console.log('Delete', field, effectIndex, affects);
            affects.splice(effectIndex, 1);
            console.log('Delete', field, effectIndex, affects);

            currentRoomData[field] = affects;// currentRoomData[field].splice(effectIndex, 1);
            setCurrentRoomData(prevstate => ({ ...prevstate }));
        }
    }

    const createAffect = (field) => {
        let affects = currentRoomData[field];
        console.log('Room ' + field, affects);
        let newEffect = {};
        newEffect.id = '';
        newEffect.params = '';
        affects.push(newEffect);
        console.log('Room ' + field, affects);
        currentRoomData[field] = affects;
        console.log('Room ' + field, currentRoomData);
        setCurrentRoomData(prevstate => ({ ...prevstate }));
    }

    const saveRoomItem = async (itemdata) => {
        console.log('Updating Item in Room', itemdata);
        //mobDispatch({ type: "UPDATE_ITEM", field: 'inventory', payload: itemdata });
        // console.log('b', currentMobData);
        // currentMobData.sourceRoomId = currentroom.id;
        itemdata.sourceRoomId = currentroom.id;
        const data = await setBuilderItemData(itemdata);
        console.log('Saved Item', data);

        if (data.result) {
            console.log('d');
            editingDone();
            refreshroom();
        } else {
            console.log('e');
        }
    }

    const selectItem = async (itemid) => {
        const data = await getBuilderRoomItemData(currentRoomData.id, itemid);
        if (data.result) {
            dispatch({ type: 'SELECT_ITEM', payload: data.data, itemsource: currentRoomData.id, itemsourcetype: 'room', saveCallback: saveRoomItem });
        } else {
            dispatch({ type: 'SELECT_ITEM', payload: null, itemsource: null, itemsourcetype: null });
        }
    }

    const updateAffect = (field, effectIndex, effectId, effectParameters) => {
        if (effectIndex >= 0) {
            currentRoomData[field][effectIndex].id = effectId;
            currentRoomData[field][effectIndex].params = effectParameters;
        }
    }

    const unlinkRoom = (id, direction) => {
        //console.log('Delete exit from ', id, direction);
        unlinkExit(id, direction);
    }

    if (currentRoomData === undefined) {
        return null;
    }

    const createMob = async () => {
        let newMob = JSON.parse(JSON.stringify(defaultMob));
        newMob.sourceRoomId = currentroom.id;
        let data = await setBuilderMobData(newMob);

        if (data.result) {
            editingDone();
            refreshroom();
        } else {
            //error!
        }
    }

    const callDispatcher = (field, value) => {
        console.log(field, value);
        switch (field) {
            case 'xsize':
                setCurrentRoomData(prevstate => ({ ...prevstate, xsize: value }));
                break;
            case 'ysize':
                setCurrentRoomData(prevstate => ({ ...prevstate, ysize: value }));
                break;
        }
    }

    const createItem = async (newItem) => {
        newItem.uniqueid = "";
        console.log(newItem);
        saveRoomItem(newItem);
        setShow(false);
    }

    const closeCreate = () => {
        setShow(false);
    }

    //console.log(currentRoomData);
    return (
        <>
            <ReactTooltip id="tooltipUndoChanges" place="bottom" effect="solid">
                Undo Changes to Room
            </ReactTooltip>
            <ReactTooltip id="tooltipSaveChanges" place="bottom" effect="solid">
                Save Changes to Room
            </ReactTooltip>
            <ReactTooltip id="tooltipDeleteRoom" place="bottom" effect="solid">
                Delete Room
            </ReactTooltip>
            <ReactTooltip id="tooltipEditRoom" place="bottom" effect="solid">
                Toggle Editing of Room
            </ReactTooltip>
            <ReactTooltip id="tooltipCloseRoom" place="bottom" effect="solid">
                Close Room
            </ReactTooltip>
            <Row>
                <Col xs={1}>
                    <FontAwesomeIcon onClick={cancelRoom} className="fa fa-lg fa-eom m-2" icon={"times-circle"}
                        data-tip data-tooltip-id="tooltipCloseRoom" />
                </Col>
                <Col>
                    <h4><strong> {editing ? "Editing Room:" : "Viewing Room:"}</strong> {currentRoomData.id} </h4>
                </Col>
            </Row>
            <FontAwesomeIcon onClick={handleEditing} className="fa fa-lg fa-eom m-2" icon={"edit"} data-tip data-tooltip-id="tooltipEditRoom" />

            {editing ? <>
                <FontAwesomeIcon onClick={saveChanges} className="fa fa-lg fa-eom m-2" icon={"check-circle"} data-tip data-tooltip-id="tooltipSaveChanges" />
                <FontAwesomeIcon onClick={handleRevert} className="fa fa-lg fa-eom m-2" icon={"undo"} data-tip data-tooltip-id="tooltipUndoChanges" />
            </> : null}
            <FontAwesomeIcon
                onClick={() => { deleteRoom(currentRoomData.id) }}
                className="fa fa-lg fa-eom m-2" icon={"trash-alt"}
                data-tip data-tooltip-id="tooltipDeleteRoom" />
            <span className="error"><font color="red">{error}</font></span>

            <Row>
                <strong>{editing ? <span>Editing </span> : null}Room Type:</strong>
                {editing
                    ? getRoomLocales(currentRoomData.type, selectLocale)
                    : <span>{currentRoomData.type}</span>}

            </Row>


            <Row>
                <strong>{editing ? <span>Editing </span> : null}Display:</strong>
                {editing ?
                    <input type="text" className="form-control" value={currentRoomData.display} onChange={(e) => updateRoomDisplay(e.currentTarget.value)} />
                    : null}
                <ColouredText ColouredText={currentRoomData.display} />
            </Row>
            <Row>
                <strong>{editing ? <span>Editing </span> : null}Description:</strong>
                {editing ?
                    <textarea rows={10} className="form-control" value={currentRoomData.description} onChange={(e) => updateRoomDescription(e.currentTarget.value)} />
                    : null}
                <ColouredText ColouredText={currentRoomData.description} />
            </Row>

            {currentRoomData.xsize !== undefined ?
                <>
                    <EditableField editing={editing}
                        field={'xsize'}
                        value={currentRoomData.xsize}
                        callDispatcher={callDispatcher}
                        description="X Grid Size"
                        fieldType={2} />
                    <EditableField editing={editing}
                        field={'ysize'}
                        value={currentRoomData.ysize}
                        callDispatcher={callDispatcher}
                        description="Y Grid Size"
                        fieldType={2} />
                </>
                : null}

            <Row className={"my-2"}>
                <strong> Effects:</strong>
            </Row>
            <Row>
                {currentRoomData.effects.length === 0 ?
                    <span>No effects applied to Area</span>
                    : null}
                {currentRoomData.effects.map((effect, index) =>
                    <div key={index} name={effect.id}>
                        <AffectEditor field={'effects'} effectIndex={index} effectId={effect.id} effectParameters={effect.params} editing={editing}
                            deleteAffect={deleteAffect} updateAffect={updateAffect} />
                    </div>)}
                {editing
                    ? <Button className={"btn-themed-dark m-2"} onClick={() => createAffect('effects')}>Add Effect</Button>
                    : null
                }
            </Row>

            <Row className={"my-2"}>
                <strong> Behaviours</strong>
            </Row>
            <Row>
                <div>
                    {currentRoomData.behaviours.length === 0 ?
                        <span>No behaviours applied to Area</span>
                        : null}
                    {currentRoomData.behaviours.map((behaviour, index) =>
                        <div key={index} name={behaviour.id}>
                            <AffectEditor field={'behaviours'} effectIndex={index} effectId={behaviour.id} effectParameters={behaviour.params} editing={editing}
                                deleteAffect={deleteAffect} updateAffect={updateAffect} />

                        </div>)}
                    {editing
                        ? <Button className={"btn-themed-dark m-2"} onClick={() => createAffect('behaviours')}>Add Behaviour</Button>
                        : null
                    }
                </div>

            </Row>

            <Row className={"my-2"}>
                <strong> Items:</strong>
            </Row>
            <Row>
                {currentRoomData.items.length === 0 ?
                    <span>No items in the Room</span>
                    : null}
                <div className="container">
                    <RoomContents contents={currentRoomData.items} depth={0} selectItem={selectItem} deleteItem={deleteItem} />
                </div>

                <CreateItemPrompt show={show} closeModal={closeCreate} createItem={createItem} />
                {editing ? null : <Button onClick={() => setShow(true)} >Create Item</Button>}
            </Row>
            <Row className={"my-2"}>
                <strong> Mobs:</strong>
            </Row>
            <Row>

                <div className="container">
                    {currentRoomData.mobs.length === 0 ?
                        <span>No Mobs in the Room</span>
                        : null}
                    {currentRoomData.mobs.map(mob =>
                        <Row key={mob.index} name={mob.index}>

                            <Col md={1} >
                                <FontAwesomeIcon onClick={() => {
                                    if (window.confirm('Are you sure you wish to delete this mob?'))
                                        deleteMob(mob.databaseid, mob.name)
                                }} className="fa fa-lg fa-eom" icon={"user-minus"} />
                            </Col>

                            <Col>
                                <div onClick={() => loadMobLocal(mob.index)}>
                                    <span >{removeColourCodes(mob.name)} </span>
                                </div>
                            </Col>
                        </Row>)}
                    <Row>
                        <FontAwesomeIcon onClick={createMob} className="fa fa-lg fa-eom m-3" icon={"user-plus"} />
                    </Row>
                </div>

            </Row>
            <Row className={"my-2"}>
                <strong> Exits:</strong>
            </Row>
            <Row>
                {currentRoomData.exits.length === 0 ?
                    <span>No Exits from Room</span>
                    : null}
                <div className="container">
                    {currentRoomData.exits.map(exit =>
                        <Row key={exit.code} name={exit.code}>
                            {editing ? null : <Col md={1} >
                                <FontAwesomeIcon onClick={() => unlinkRoom(currentRoomData.id, exit.code)} className="fa fa-lg fa-eom" icon={"unlink"} />
                            </Col>}
                            <Col md={2} className={"px-2"} >
                                <span onClick={() => loadExit(currentRoomData.id, exit.direction)}>{exit.direction} </span>
                            </Col>
                            <Col md={7} >
                                <span onClick={() => loadRoom(exit.destination)}>{exit.destination} </span>
                            </Col>
                        </Row>)}
                </div>
            </Row>
        </>
    )
}

export default RoomEditor;