import React, { Component } from 'react';

class RealmSocieties extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">

			<h1>Societies</h1>
			
			<p>Adventuring Parties can be used for a number of things in the edge of midnight. Their primary function however is, as the name implies, a way to form a group of adventurers
			and head out into the realm and carry out quests, slay monsters and earn experience together.</p>
			
			<h2>Founding a society</h2>
			<p>To create a new society enter the command <strong>SOCIETY FOUND</strong>.</p>
			<p>You will be prompted to enter a title, a premise and wether or not the society is public or private.</p>
			
			<h3>Society Title</h3>
			<p>This is the name of the socity and as such is used to both describe it and as the key when sending messages to other society members, so bear in 
			mind the longer this is the more difficult it is to use.</p>
			<p>The title is allowed to contain some colour characters but no punctuation and can be at most 20 characters (40 with colour codes).</p>
		
			<h3>Society Premise</h3>
			<p>This is the reason for the socity's existence and as such is used to give everyone an idea of what it is about.
			it and as the key when sending messages to other society members, so bear in 
			mind the longer this is the more difficult it is to use.</p>
			<p>The premise is allowed to contain some colour characters and punctuation and can be at most 1000 characters (3000 with colour codes).</p>
			<br/>

			<h3>Public/Private</h3>
			<p>This determines if anyone can join the society and if it is listed for people to see.</p>
			<br/>

			<h3>Single Line Creation</h3>
			<p>If preferrered the society can be created with a single command as shown below:</p>
			<pre>
			SOCIETY FOUND -title "Name of Society" -premise "The premise of the society"
			</pre>
			<br/>
			
			<h2>Disbanding a Society</h2>
			<p>To <strong>disband</strong> a society enter the command <strong>SOCIETY DISBAND [TITLE]</strong>.</p>
			<br/>
			
			<h2>Viewing a Society</h2>
			<p>To see <strong>info</strong>rmation about a society enter the command <strong>SOCIETY INFO [TITLE]</strong>.</p>
			<p>This will display the title, premise and membership details of a society. </p>
			<p>If the society is private you will only be able to see information if you are a member of the society.</p>
			<br/>

			<h2>Updating a Society</h2>
			<p>To <strong>update</strong> information about a society enter the command <strong>SOCIETY UPDATE [TITLE]</strong>.</p>
			<p>This will allow you to update the title, premise and membership details of a society. </p>
			<br/>

			<h2>Joining a Society</h2>
			<p>To <strong>join</strong> a society enter the command <strong>SOCIETY JOIN [TITLE]</strong>.</p>
			<p>If the society is public you will be able to join, otherwise you will be notified that the society is by invite only.</p>
			<br/>

			<h2>Leaving a Society</h2>
			<p>To <strong>leave</strong> a society enter the command <strong>SOCIETY LEAVE [TITLE]</strong>.</p>
			<p>If the society is private and you are the founder, the society will be disbanded.</p>
			<br/>

			<h2>Inviting someone into a private Society</h2>
			<p>Founders of private societies can <strong>invite</strong> other players to join their society. To do this enter the command 
			<strong>SOCIETY INVITE [TITLE] [PLAYER]</strong> and the player will join the society.</p>
			<br/>

			<h2>Dismissing someone from a private Society</h2>
			<p>Founders of private societies can <strong>dismiss</strong> other players from their society. To do this enter the command 
			<strong>SOCIETY DISMISS [TITLE] [PLAYER]</strong> and the player will be dismissed from the society.</p>
			<br/>

			<h2>Communicating with Society Members</h2>
			<p>Using the command <strong>SOCCHAT [TITLE]</strong> you can send a message like any other channel to the society.</p>



      </div>
    );
  }
}

export default RealmSocieties;