import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { timeConverter } from '../utils/NiceStrings';

const EoMIssueSummary = ({ issue, selected, onSelect }) => {
    if (issue == undefined)
        return null;

    let summarytext = issue.issue_description.slice(0, 24);
    
    if (summarytext.length < issue.issue_description.length) {
        summarytext += '...';
    }

    return (
        <Row className={"d-flex eomissue" + (selected?"-selected":"")} onClick={()=> onSelect(issue.issue_id)}>
            <Col xs={6} md={4} lg={2} xl ={1}>
                <strong>Id:</strong>
                <span>{issue.issue_id}</span>
            </Col>
            <Col xs={12} md={8} lg={7} xl={5} >
                <span>{summarytext}</span>
            </Col>
            <Col xs={6} md={6} xl={4}>
                <span>{timeConverter(issue.issue_updated)}</span>
            </Col>
            <Col xs={6} md={6} lg={2} xl={2}>
                <strong>(<span>{issue.issue_status}</span>)</strong>
                
            </Col>
        </Row>
    )
}

export default EoMIssueSummary;