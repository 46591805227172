import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkToc from 'remark-toc';

const EomMarkdown = ({ markdown }) => {
    return <Markdown
        remarkPlugins={[remarkGfm, remarkToc]}
        components={
            {
                p: ({ node, ...props }) => <p className="pm" {...props} />,
                h1: ({ node, ...props }) => <h1 className="h1m" {...props} />,
                h2: ({ node, ...props }) => <h2 className="h2m" {...props} />,
                h3: ({ node, ...props }) => <h3 className="h3m" {...props} />
            }}>{markdown}</Markdown >
}

export default EomMarkdown;