import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormCheck } from 'react-bootstrap';
import { generateParameters } from '../../components/ProceduralGenerationRequests';
import Parameter from './Parameter';
import ProcGenTemplate from './ProcGenTemplate';
import ProcGenFeature from './ProcGenFeature';
import { raceSelector, genderSelector,roleSelector } from '../BuilderUtils';
import ProcGenMutator from './ProcGenMutator';


const defaultParams = {
    race: 'Elf',
    vitals: {bust : 0},
    candidatetemplates: [],
    candidatefeatures: [],
    templates: [],
    features: [],
    mutators: []
}

const defaultoptions = {
    includeitems: false,
    getcandidates: true,
    getselected: true,
    generatename: false,
    generatedescriptions: true
}

function ProcGenParameters() {
    const [showMutators, setShowMutators] = useState(false);
    const [showCandidateTemplates, setShowCandidateTemplates] = useState(false);
    const [showCandidateFeatures, setShowCandidateFeatures] = useState(false);
    const [showSelectedTemplates, setShowSelectedTemplates] = useState(false);
    const [showSelectedFeatures, setShowSelectedFeatures] = useState(false);
    const [parameters, setParameters] = useState(defaultParams);
    const [maskFilter, setMaskFilter] = useState('');
    const [options, setOptions] = useState(defaultoptions);


    const generateParametersClick = async (e) => {
        //console.log('Generating Parameters Templates');
        parameters.options = options;
        //console.log('Generating Parameters Templates', parameters);
        generateParameters(parameters).then(data => {
            console.log(data);
            setParameters(data);
        }).catch(error => {
            console.log('Error', error);
        })
    }

    useEffect(() => {
        console.log('Parameters changed')
    }, [parameters]);

    const toggleDisplayCandidateTemplates = async () => {
        setShowCandidateTemplates(prev => (!prev));
    }

    const toggleDisplayMutators = async () => {
        setShowMutators(prev => (!prev));
    }

    const toggleDisplayCandidateFeatures = async () => {
        setShowCandidateFeatures(prev => (!prev));
    }

    const setRace = (race) => {
        console.log("new Race", race);
        setParameters((prev) => ({ ...prev, race: race }));
        //parameters.race = race;
    }

    const setGender = (gender) => {
        setParameters((prev) => ({ ...prev, gender: gender }));
    }


    const setRole = (role) => {
        setParameters((prev) => ({ ...prev, role: role }));
        //parameters.race = race;
    }

    const deleteMutator = (id) => {
        console.log('Deleted Mutator', id);
        const index = parameters.mutators.findIndex(mutator => mutator.id === id);
        console.log('Deleted Mutator', id, index);
        console.log('Deleted Mutator', parameters.mutators);
        parameters.mutators.splice(index, 1);
        console.log('Deleted Mutator', parameters.mutators);
        setParameters({ ...parameters })
    }

    const deleteFeature = (id) => {
        console.log('Deleted Feature', id);
        const index = parameters.candidatefeatures.findIndex(feature => feature.id === id);
        console.log('Deleted Feature', id, index);
        console.log('Deleted Feature', parameters.candidatefeatures);
        parameters.candidatefeatures.splice(index, 1);
        console.log('Deleted Feature', parameters.candidatefeatures);
        setParameters({ ...parameters })
    }

    const deleteTemplate = (id) => {
        console.log('Deleted Template', id);
        const index = parameters.candidatetemplates.findIndex(feature => feature.id === id);
        parameters.candidatetemplates.splice(index, 1);
        setParameters({ ...parameters })
    }

    const getVitals = (params) => {
        if(params!=undefined) {
            switch(params.gender){
                case "FEMALE" :
                case "FUTANARI" :
                    return params.vitals.bust + params.vitals.cup + "-"+ params.vitals.waist + "-" + params.vitals.hips + " with a " +params.vitals.cervix + "\" deep cervix";
                case "MALE" :
                    return params.vitals.bust +"\" chest and a " + params.vitals.length + " cock that can cum " + params.vitals.ejaculate + "ml.";
                default :
                    return "";
            }
        }
    }

    return (
        <div>
            <Row>
                <Col xs={1}>
                    <Button onClick={generateParametersClick}>Generate</Button>
                </Col>
                <Col>
                    <FormCheck
                        key="1"
                        className={"m-2"}
                        id="1"
                        type="switch"
                        checked={options.generatedescriptions}
                        onChange={(e) => {
                            //console.log('The Mask is', choice.mask);
                            setOptions(prev => ({
                                ...prev,
                                generatedescriptions: !prev.generatedescriptions
                            }))
                        }
                        }
                        label="Generate Descriptions"
                    />
                </Col>
                <Col>
                    <FormCheck
                        key="2"
                        className={"m-2"}
                        id="2"
                        type="switch"
                        checked={options.getcandidates}
                        onChange={(e) => {
                            //console.log('The Mask is', choice.mask);
                            setOptions(prev => ({
                                ...prev,
                                getcandidates: !prev.getcandidates
                            }))
                        }
                        }
                        label="Generate Candidates"
                    />
                </Col>
                <Col>
                    <FormCheck
                        key="3"
                        className={"m-2"}
                        id="3"
                        type="switch"
                        checked={options.getselected}
                        onChange={(e) => {
                            //console.log('The Mask is', choice.mask);
                            setOptions(prev => ({
                                ...prev,
                                getselected: !prev.getselected
                            }))
                        }
                        }
                        label="Select Features and Templates"
                    />
                </Col>
            </Row>
            <div className={"m-2 p-2 divider3"}>
                <Row>
                    <Col xs={2}><strong>Race:</strong></Col>
                    <Col xs={2}>
                        {raceSelector(parameters.race, setRace)}
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}><strong>Gender:</strong></Col> 
                    <Col xs={2}> {genderSelector(parameters.gender, setGender)}</Col>
                </Row>
                <Row>
                    <Col xs={2}><strong>Role:</strong></Col> 
                    <Col xs={2}> {roleSelector(parameters.role, setRole)}</Col>
                </Row>
                <Row>
                    <Col xs={2}><strong>Gender Characteristics:</strong></Col> 
                    <Col xs={2}> {parameters.genderbias}</Col>
                </Row>
                <Parameter title={"Height"} septile={parameters.height} />
                <Parameter title={"Weight"} septile={parameters.weight} />
                <Parameter title={"Hair"} septile={parameters.hair} />
                <Row>
                    <Col xs={2}><strong>Physique:</strong></Col> 
                    <Col xs={2}> {parameters.physique}</Col>
                </Row>
                <Row>
                    <Col xs={2}><strong>Vitals:</strong></Col> 
                    <Col xs={2}> {getVitals(parameters)}</Col>
                </Row>

                <Row onClick={toggleDisplayMutators}>
                    <Col xs={2}><strong>Mutators:</strong></Col>
                    <Col xs={1}>{parameters.mutators.length}</Col>
                </Row>
                {showMutators ?
                    parameters.mutators.map((mutator, index) =>
                        <ProcGenMutator key={index} feature={mutator} deleteCallback={deleteMutator} />)
                    : null}

                <Row onClick={toggleDisplayCandidateTemplates}>
                    <Col xs={2}><strong>Candidate Templates:</strong></Col>
                    <Col xs={1}>{parameters.candidatetemplates.length}</Col>
                </Row>

                {showCandidateTemplates ?

                    parameters.candidatetemplates.map((template, index) =>
                        <ProcGenTemplate key={index} feature={template} deleteCallback={deleteTemplate}/>)

                    : null
                }
                <Row onClick={toggleDisplayCandidateFeatures}>
                    <Col xs={2}><strong>Candidate Features:</strong></Col>
                    <Col xs={1}>{parameters.candidatefeatures.length}</Col>
                </Row>
                {showCandidateFeatures ?
                    parameters.candidatefeatures.map((feature, index) =>
                        <ProcGenFeature key={index} feature={feature} deleteCallback={deleteFeature} />)
                    : null}
                {parameters.description !== undefined
                    ? 
                    <>
                    <Row>
                        <Col xs={2}><strong>Description:</strong></Col>
                        <Col>{parameters.description}</Col>
                    </Row>
                    <Row>
                        <Col xs={2}><strong>Short:</strong></Col>
                        <Col>{parameters.description_short}</Col>
                    </Row>
                    <Row>
                        <Col xs={2}><strong>Eye Description:</strong></Col>
                        <Col>{parameters.description_eye}</Col>
                    </Row>
                    <Row>
                        <Col xs={2}><strong>Hair Description:</strong></Col>
                        <Col>{parameters.description_hair}</Col>
                    </Row>
                    </>
                    
                    : null}

                <Row onClick={() => setShowSelectedTemplates(prev => (!prev))}>
                    <Col xs={2}><strong>Selected Templates:</strong></Col>
                    <Col xs={1}>{parameters.templates !== undefined ? parameters.templates.length : ""}</Col>
                </Row>
                {showSelectedTemplates ?
                    parameters.templates.map((template, index) =>
                        <ProcGenTemplate key={index} feature={template} editable={false} />)
                    : null}

                <Row onClick={() => setShowSelectedFeatures(prev => (!prev))}>
                    <Col xs={2}><strong>Selected Features:</strong></Col>
                    <Col xs={1}>{parameters.features !== undefined ? parameters.features.length : ""}</Col>
                </Row>
                {showSelectedFeatures ?
                    parameters.features.map((feature, index) =>
                        <ProcGenFeature key={index} feature={feature} editable={false} />)
                    : null}
            </div>
        </div>
    );

}

export default ProcGenParameters;