import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown, ButtonGroup, Tab, Tabs } from 'react-bootstrap';
import { decodeMessage } from './messageutils';
import AnsibleCentralView from './AnsibleCentralView';
import AnsibleLeftPanel from './AnsibleLeftPanel';
import { useClientDispatch, useClientContext } from './ClientProvider';
import AnsibleRightPanel from './AnsibleRightPanel';
import UserInput from './UserInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


let count = 0;

const mapView = 0;
const centralView = 1;
const channelView = 2;
const views = 2;

const defaultTitle = 'Ansible Messenger';
const defaultHide = true;

const inputAndOutput = 0;
const inputOnly = 1;
const outputOnly = 2;

const getDefaultValue = (field, defaultValue) => {
    var stickyValue = localStorage.getItem(field);
    if (stickyValue === null) {
        stickyValue = defaultValue;
    } else if (stickyValue % 1 === 0) {
        stickyValue = parseInt(stickyValue);
    } else if (stickyValue === 'true') {
        return true;
    } else if (stickyValue === 'false') {
        return false;
    }
    return stickyValue;
}

function AnsibleMessenger() {


    const clientDispatch = useClientDispatch();

    const { autoScroll, messages, currentCharacter, channelMessages, fontSize, fontFamily, rpmode, callback, enableswipe, renderLineLimit } = useClientContext();

    const [delimitCommands, setDelimitCommands] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [clearInput, setClearInput] = useState(false);
    const [localEcho, setLocalEcho] = useState(false);
    const [updatesReceived, setUpdatesReceived] = useState(false);
    const [touchStartX, setTouchStartX] = useState();
    const [touchStartY, setTouchStartY] = useState();
    const [userInputMode, setUserInputMode] = useState(inputAndOutput);
    const [viewMode, setViewMode] = useState(centralView);
    const [hideAdditionalInformation, setHideAdditionalInformation] = useState(getDefaultValue('hideAdditionalInformation', defaultHide));

    // User has switched back to the tab
    const onFocus = async () => {
        setUpdatesReceived(false);
    };

    useEffect(() => {
        window.addEventListener('focus', onFocus);

        window.addEventListener("touchstart", onTouchStart, { passive: false });
        window.addEventListener("touchend", onTouchEnd, { passive: false });
        window.addEventListener("touchmove", onTouchMove, { passive: false });

        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener("touchstart", onTouchStart);
            window.removeEventListener("touchend", onTouchEnd);
            window.removeEventListener("touchmove", onTouchMove);
        };
    });

    useEffect(() => {
        /**
         * Be a little careful about changing the DOM
         */

        const changeTitle = async () => {
            if (currentCharacter !== null) {
                if (document.title !== currentCharacter)
                    document.title = currentCharacter;
            }
            else if (document.title !== defaultTitle)
                document.title = defaultTitle;

            if (document.hidden) {
                //console.log('Change Happened');
                if (document.getElementById('favicon').href !== '/favicon-dot.ico')
                    document.getElementById('favicon').href = '/favicon-dot.ico';
            } else if (document.getElementById('favicon').href !== '/favicon.ico') {
                document.getElementById('favicon').href = '/favicon.ico';
            }
        }

        if (updatesReceived) {
            changeTitle();
            setUpdatesReceived(false);
        }
    },
        [updatesReceived, currentCharacter]);

    useEffect(() => {
        setUpdatesReceived(true);
    }, [messages]);

    useEffect(() => {
        setUpdatesReceived(true);
    }, [channelMessages]);

    const echoCommand = (inMessage) => {
        let msgJson = {};
        msgJson.body = "(Echo) " + inMessage;
        msgJson.type = 'output';
        count++;
        msgJson.decoded = decodeMessage(msgJson, count);

        clientDispatch({ type: "ADD_MESSAGE", payload: msgJson });
    }

    const submitMessage = (messageString) => {
        if (delimitCommands) {
            messageString.split(";").forEach(function (myString) {
                if (localEcho) {
                    echoCommand(myString);
                }

                let messageObject = {};
                messageObject.type = 'message';
                messageObject.data = myString;
                callback(messageObject);
            });
        } else {
            let messageObject = {};
            messageObject.type = 'message';
            messageObject.data = messageString;
            callback(messageObject);
            if (localEcho) {
                echoCommand(messageString);
            }
        }
        if (enableswipe && (userInputMode == inputOnly))
            setUserInputMode(outputOnly);
        else
            setUserInputMode(inputAndOutput);
    }



    const toggleAutoScroll = () => {
        clientDispatch({ type: "SET_AUTOSCROLL", payload: !autoScroll });
    }

    const toggleDelimitCommands = () => {
        setDelimitCommands(!delimitCommands);
    }

    const toggleEchoInput = () => {
        setLocalEcho(!localEcho);
    }

    const clearBuffer = () => {
        clientDispatch({ type: "CLEAR_MESSAGES", payload: [] });
    }

    const handleToggleMenu = async e => {
        setShowMenu(prev => !prev);
    }

    useEffect(() => {
        localStorage.setItem('hideAdditionalInformation', hideAdditionalInformation);
        clientDispatch({ type: "SET_RPMODE", payload: !hideAdditionalInformation });
    }, [hideAdditionalInformation]);


    useEffect(() => {
        localStorage.setItem('rpmode', rpmode);
        if (callback !== null) {
            //console.log('Updating RP Mode', rpmode);
            let messageObject = {};
            messageObject.type = 'command';
            messageObject.command = { command: 'rpmode', value: rpmode };
            callback(messageObject);
        }
    }, [callback, rpmode, currentCharacter]);

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob(["<div style=\"background-color: black;color: white;white-space: pre-wrap;font-family:" + fontFamily + "\">" + document.getElementById('myInput').innerHTML + "<div>"], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        const date = new Date();
        const dateString = "" + date.getFullYear() + "" + padTime((date.getMonth() + 1)) + padTime(date.getDate()) + "-" + padTime(date.getHours()) + padTime(date.getMinutes());
        element.download = "log-" + dateString + ".html";
        document.body.appendChild(element);
        element.click();
    }
    const padTime = (theTime) => {
        var paddedTime = "00" + theTime;
        return paddedTime.slice(-2);
    }

    const shrinkFont = () => {
        if (fontSize > 1)
            clientDispatch({ type: "SET_FONTSIZE", payload: fontSize - 1 });
    }

    const growFont = () => {
        if (fontSize < 80)
            clientDispatch({ type: "SET_FONTSIZE", payload: fontSize + 1 });
    }

    const shrinkLimit = () => {
        if (renderLineLimit > 25)
            clientDispatch({ type: "SET_RENDERLINELIMIT", payload: renderLineLimit - 25 });
    }

    const growLimit = () => {
        if (renderLineLimit < 800)
            clientDispatch({ type: "SET_RENDERLINELIMIT", payload: renderLineLimit + 25 });
    }
    const neededTouch = 2;

    const touchActive = () => {
        return enableswipe && (touchStartX !== undefined) && (touchStartY != undefined);
    }
    const onTouchStart = (e) => {

        if (enableswipe && (e.touches.length == neededTouch)) {
            // echoCommand('I was touched ' + e.touches.length + ' times!');
            // console.log('Touch Started', e.touches);
            setTouchStartX(e.touches[0].screenX);
            setTouchStartY(e.touches[0].screenY);
            e.preventDefault();
            //    e.stopPropagation();
        }
    }

    const onTouchMove = (e) => {
        if (touchActive()) {
            //echoCommand('I was moved ' + e.touches.length + ' times!');
            e.preventDefault();
            //e.stopPropagation();
        }
    }


    const onTouchEnd = (e) => {
        //echoCommand('I was stopped with ' + e.changedTouches.length + ' fingers!');
        if (touchActive()) {
            //echoCommand('I was stopped ' + e.changedTouches.length + ' times!');

            let endX = e.changedTouches[0].screenX;
            let endY = e.changedTouches[0].screenY;

            let diffX = endX - touchStartX;
            let diffY = endY - touchStartY;

            //console.log('Touch Ended', diffX, diffY);


            if (Math.abs(diffY) > 50) {
                //console.log('Vertial Touch Ended', diffY);
                if (diffY > 0) {
                    swipedUp();
                } else {
                    swipedDown();
                }
                e.preventDefault();
                e.stopPropagation();
            } else if (Math.abs(diffX) > 50) {
                if (diffX > 0) {
                    swipedRight();
                } else {
                    swipedLeft();
                }
                e.preventDefault();
                e.stopPropagation();
            }
        }
        setTouchStartX();
        setTouchStartY();
    }

    const swipedRight = () => {
        if (viewMode > 0) {
            setViewMode(prev => prev - 1);
        }
    }

    const swipedLeft = () => {
        if (viewMode < views) {
            setViewMode(prev => prev + 1);
        }
    }

    const swipedDown = () => {
        let newMode = userInputMode - 1;
        if (newMode < inputAndOutput)
            newMode = outputOnly;
        console.log('Chaning View  Mode (u)', userInputMode, newMode);

        setUserInputMode(newMode);
    }

    const swipedUp = () => {
        let newMode = userInputMode + 1;
        if (newMode > outputOnly)
            newMode = inputAndOutput;
        console.log('Changing View Mode (d)', userInputMode, newMode);
        setUserInputMode(newMode);
    }


    const showButtons = () => {
        return (
            <div className="sidenav sidenavclient">
                <Button variant="tool" className={"btn-block"} onClick={downloadTxtFile}>
                    Download Buffer
                </Button>
                <Button variant="tool" className={"btn-block"} onClick={toggleAutoScroll}>
                    Autoscroll {autoScroll ? "On" : "Off"}
                </Button>
                <Button variant="tool" className={"btn-block"} onClick={toggleDelimitCommands}>
                    Delimit Commands {delimitCommands ? "On" : "Off"}
                </Button>
                <Button variant="tool" className={"btn-block"} onClick={toggleEchoInput}>
                    Echo Input Commands {localEcho ? "On" : "Off"}
                </Button>
                <Button variant="tool" className={"btn-block"} onClick={clearBuffer}>
                    Clear Buffer
                </Button>
                <Button variant="tool" className={"btn-block"} onClick={() => setClearInput(prev => !prev)}>
                    Clear Input {clearInput ? "On" : "Off"}
                </Button>
                <ButtonGroup>
                    <Button variant="tool" onClick={shrinkLimit} >Less</Button>
                    <span className="p-2" onClick={() => { clientDispatch({ type: "SET_RENDERLINELIMIT", payload: 200 }) }} >Show {renderLineLimit} Lines</span>
                    <Button variant="tool" onClick={growLimit}>More</Button>
                </ButtonGroup>
                <Button variant="tool" className={"btn-block"} onClick={() => clientDispatch({ type: "SET_RPMODE", payload: !rpmode })}>
                    Roleplaying Mode {rpmode ? "On" : "Off"}
                </Button>
                <Button variant="tool" className={"btn-block"} onClick={() => {
                    clientDispatch({ type: "SET_SWIPE", payload: !enableswipe });
                    setUserInputMode(0);
                }}>
                    Swipe Navigation {enableswipe ? "On" : "Off"}
                </Button>
                <Button variant="tool" className={"btn-block"} onClick={() => setHideAdditionalInformation(prev => !prev)}>
                    Additional Information {hideAdditionalInformation ? "Off" : "On"}
                </Button>

                <Dropdown >
                    <Dropdown.Toggle variant="tool" id="dropdown-basic">
                        Font Family
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => clientDispatch({ type: "SET_FONT", payload: 'Consolas' })}>Consolas</Dropdown.Item>
                        <Dropdown.Item onClick={() => clientDispatch({ type: "SET_FONT", payload: 'Inconsolata' })}>Inconsolata</Dropdown.Item>
                        <Dropdown.Item onClick={() => clientDispatch({ type: "SET_FONT", payload: 'Lucida Console' })}>Lucida Console</Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>

                <ButtonGroup>
                    <Button variant="tool" onClick={shrinkFont} >Smaller</Button>
                    <span className="p-2" onClick={() => { clientDispatch({ type: "SET_FONTSIZE", payload: 16 }) }} >Font</span>
                    <Button variant="tool" onClick={growFont}>Larger</Button>
                </ButtonGroup>
            </div>
        )
    }

    const showLeftPanel = () => {
        let show = true;

        if (enableswipe) {
            show &= (viewMode === mapView)
        } else {
            show &= !hideAdditionalInformation
        }

        if (show) {
            return (<AnsibleLeftPanel />)
        }
        else {
            return null;
        }

    }

    const showRightPanel = () => {
        let show = true;

        if (enableswipe) {
            show &= (viewMode === channelView)
        } else {
            show &= !hideAdditionalInformation
        }

        if (show)
            return (<AnsibleRightPanel />)
        else
            return null;
    }

    const showCenterPanel = () => {
        let show = true;

        if (enableswipe) {
            show &= (viewMode === centralView);
        }

        if (show) {
            switch (userInputMode) {
                case 1:
                    return (
                        <Col className={"output-column"}><Row >
                            <UserInput onSubmitMessage={submitMessage} clearInput={clearInput}
                                layout={userInputMode ? "userinput-full" : ""}
                            />
                        </Row>
                        </Col>);
                case 2:
                    return (
                        <Col className={"output-column"}> <AnsibleCentralView
                            handleToggleMenu={handleToggleMenu}
                            fontFamily={fontFamily}
                            fontSize={fontSize}
                            messages={messages}
                            submitMessage={submitMessage}
                            clearInput={clearInput}
                            showMenu={showMenu}
                            showButtons={showButtons}
                            hideInput={true}
                        />
                        </Col>);
                default:
                    return (
                        <Col className={"output-column"}>

                            <AnsibleCentralView
                                handleToggleMenu={handleToggleMenu}
                                fontFamily={fontFamily}
                                fontSize={fontSize}
                                messages={messages}
                                submitMessage={submitMessage}
                                clearInput={clearInput}
                                showMenu={showMenu}
                                showButtons={showButtons}
                                hideInput={false}
                            />
                        </Col>);
            }

        }
        else
            return null;
    }

    return (
        <>
            <FontAwesomeIcon onClick={handleToggleMenu} className="fa fa-lg icon-cog toprighticon" icon={"cog"} />
            <Row className={"eom-client"}>
                {showLeftPanel()}
                {showCenterPanel()}
                {showRightPanel()}
                {showMenu && showButtons()}

            </Row>
        </>
    );
}

export default AnsibleMessenger;