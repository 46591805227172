import { getIssues, getIssue, updateIssue, searchIssues, useAuthState } from '../components';
import React, { useState, useEffect } from 'react';
import EoMIssue from '../components/EoMIssue';
import EoMIssueSummary from '../components/EoMIssueSummary';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { BuilderProvider } from './../components/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSearchoptions, getSearchCriteria } from './IssueSearchCriteria';

function BuilderIssueTracker() {
    return (<BuilderProvider><IssueTracker /></BuilderProvider>);
}


const issueTypes = ['Bug', 'Idea', 'Typo'];
const sortIcons = ['sort-numeric-down-alt', 'sort-numeric-up', 'sort-amount-down-alt', 'sort-amount-up'];


function IssueTracker({ match }) {

    const searchoptions = getSearchoptions();

    const { currentUser } = useAuthState();

    const [searchOption, setSearchOption] = useState(0);
    const [issues, setIssues] = useState([]);
    const [issueType, setIssueType] = useState(0);
    const [sortType, setSortType] = useState(0);
    const [selectedIssue, setSelectedIssue] = useState(-1);
    const [currentIssue, setCurrentIssue] = useState();
    const [searchAssignee, setSearchAssignee] = useState('');
    const [searchReporter, setSearchReporter] = useState('');

    const [filter, setFilter] = useState('');



    const filterMatch = (issue) => {
        return (issue.issue_description.toLowerCase().indexOf(filter) > -1)
            || (issue.issue_reporter.toLowerCase().indexOf(filter) > -1)
            || ((issue.issue_id + "").toLowerCase().indexOf(filter) > -1)
            || (issue.issue_assignee.toLowerCase().indexOf(filter) > -1)
            || (issue.labels.indexOf(filter) > -1);
    }

    const getIssueList = async () => {
        let issueSearch = getSearchCriteria(searchoptions[searchOption].name, issueTypes[issueType], searchAssignee, searchReporter, currentUser);
        let data = await searchIssues(issueSearch);
        //console.log('Issue Data', data);
        if (data.result) {
            setIssues(data.issues);
        } else {
            setIssues([]);
        }
    }

    const getSelectedIssue = async (issue_id) => {
        let data = await getIssue(issue_id);
        //console.log('Issue Data', data);
        if (data.result) {
            setCurrentIssue(data.issue);
        } else {
            setCurrentIssue();
        }
    }

    useEffect(() => {
        console.log(searchOption);
        getIssueList();

    }, [issueType, searchOption]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getIssueList();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchAssignee, searchReporter]);

    useEffect(() => {
        getSelectedIssue(selectedIssue);
    }, [selectedIssue]);

    const onSelectIssue = async (id) => {
        setSelectedIssue(id);
        window.scrollTo(0, 0);
    }

    const setStatusOfIssue = async (newStatus) => {
        currentIssue.issue_status = newStatus;
        let data = await updateIssue(currentIssue);
        //console.log('Issue Update Result', data);
        getSelectedIssue(currentIssue.issue_id)
    }

    const setAssignee = async (newUser) => {
        currentIssue.issue_assignee = newUser;
        let data = await updateIssue(currentIssue);
        //console.log('Issue Update Result', data);
        getSelectedIssue(currentIssue.issue_id)
    }

    const addComment = async (comment_author, comment_text) => {
        let new_comment = {};
        new_comment.comment_author = comment_author;
        new_comment.comment_text = comment_text;
        new_comment.comment_updated = 0;

        currentIssue.comments = [...currentIssue.comments, new_comment];


        console.log('Comment Update Result', currentIssue);
        let data = await updateIssue(currentIssue);
        console.log('Issue Update Result', data);
        getSelectedIssue(currentIssue.issue_id)
    }

    const cycleSortType = () => {
        let newSortType = (sortType + 1) % sortIcons.length;
        setSortType(newSortType);
    }

    const sortedIssues = () => {
        let sortedIssues = issues.sort((a, b) => {
            switch (sortType) {
                case 0:
                    return b.issue_timestamp - a.issue_timestamp;
                case 1:
                    return a.issue_timestamp - b.issue_timestamp;
                case 2:
                    return a.issue_updated - b.issue_updated;
                default:
                    return b.issue_updated - a.issue_updated;

            }
        });
        // console.log(sortedIssues);
        return sortedIssues;
    }

    const nextIssue = () => {
        if (selectedIssue < 0) {
            setSelectedIssue(0);
        } else {
            let index = issues.findIndex((issue) => {
                return (issue.issue_id == selectedIssue)
            });

            if (index < (issues.length - 1)) {
                setSelectedIssue(issues[index + 1].issue_id);
            }
        }
    }

    const previousIssue = () => {
        if (selectedIssue < 0) {
            setSelectedIssue(0);
        } else {
            let index = issues.findIndex((issue) => {
                return (issue.issue_id == selectedIssue)
            });

            if (index > 0) {
                setSelectedIssue(issues[index - 1].issue_id);
            }
        }
    }

    return (
        <div className="container-eom" id="divMainContent">
            <Row className={"d-flex "}>
                <Col className={"issue-column"} xs={12} md={3} lg={4}>
                    <Row className="my-1">
                        <Col xs={12} md={4} >
                            <Dropdown>
                                <Dropdown.Toggle variant="eom" id="dropdown-basic">
                                    {issueTypes[issueType]}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {issueTypes.map((issueType, index) =>
                                        <Dropdown.Item key={index} onClick={() => setIssueType(index)}>{issueTypes[index]}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xs={12} md={8}>
                            <Dropdown>
                                <Dropdown.Toggle variant="eom" id="dropdown-basic">
                                    {searchoptions[searchOption].description}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {searchoptions.map((option, index) =>
                                        <Dropdown.Item key={index} onClick={() => setSearchOption(index)}>{option.description}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <div className="input-group m-1">
                                <input placeholder="Assignee" type="text" className="form-control"
                                    onChange={(e) => setSearchAssignee(e.currentTarget.value)} />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="input-group m-1">
                                <input placeholder="Reporter" type="text" className="form-control"
                                    onChange={(e) => setSearchReporter(e.currentTarget.value)} />
                            </div>
                        </Col>
                    </Row>
                    <hr
                        style={{
                            color: "red",
                            height: 1
                        }}
                    />
                    <Row>

                        <Col xs={10}>
                            <div className="input-group m-1">
                                <div className="input-group-prepend" >
                                    <button className="mx-1 btn btn-themed-dark" type="submit">Filter</button>
                                </div>
                                <input placeholder="Filter Issues" type="text" className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                            </div>
                        </Col>
                        <Col xs={1}>
                            <FontAwesomeIcon className="a fa-lg fa-eom my-2" icon={sortIcons[sortType]} onClick={cycleSortType} />
                        </Col>

                    </Row>

                    <div>
                        {sortedIssues().filter(issue => filterMatch(issue)).map((issue, index) =>
                            <EoMIssueSummary key={index} issue={issue} selected={selectedIssue === issue.issue_id} onSelect={onSelectIssue} />
                        )}
                    </div>
                </Col>
                <Col xs={12} md={9} lg={8}>
                    <EoMIssue issue={currentIssue} statusChange={setStatusOfIssue} assignToUser={setAssignee}
                        addComment={addComment}
                        nextIssue={nextIssue} previousIssue={previousIssue}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default BuilderIssueTracker;