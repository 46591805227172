import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import { getRoleplaySheetData } from './../components';
import { useParams } from 'react-router-dom';
import PlayerAvatar from '../components/PlayerAvatar';

function RoleplaySheet() {
  const match = useParams();

  let defaultPlayer = localStorage.getItem('currentUser');
  if (match.playername !== undefined) {
    defaultPlayer = match.playername;
  }

  const [playerName, setPlayerName] = useState(defaultPlayer);
  const [playerData, setPlayerData] = useState();

  const getRoleplaysheet = async e => {
    const data = await getRoleplaySheetData(playerName);
    if (data.result) {
      setPlayerData(data);
    } else {
      setPlayerData();
    }
  }

  const onKeyUpEvent = async e => {
    if (e.key === 'Enter') {
      getRoleplaysheet();
    }
  }

  useEffect(() => {
    getRoleplaysheet();
  }, []);

  const showkinks = (kinks, borderStyle) => {
    if (kinks.length === 0)
      return null;

    return (
      <Col>
        <div className={borderStyle} >
          <ul className="kinks-ul-single">
            {kinks.map(kink =>
              <li key={kink.title}>{kink.title}</li>
            )}
          </ul>
        </div>
      </Col>
    );
  }

  const showkinksSizzle = (kinks, borderStyle) => {
    if (kinks.length === 0)
      return null;

    return (
      <Row className={borderStyle} >

        {kinks.map(kink =>

          <Col className={"p-1"} xs={3} key={kink.title}>
            <span >{kink.title}</span>

          </Col>
        )}
      </Row>

    );
  }

  const getBiographySection = (index) => {
    if (playerData.biography === '')
      return null;


    return (
      <Accordion.Item eventKey={index} className={"pb-1 accordion-body"}>
        <Accordion.Header>
          <h3>
            Biography
          </h3>
        </Accordion.Header>
        <Accordion.Body>
          {playerData.biography}
        </Accordion.Body>
      </Accordion.Item>
    );
  }

  const getVitalStatsSection = (index) => {
    if (playerData.vital === 'unknown') {
      return null;
    }

    return (
      <Accordion.Item eventKey={index} className={"pb-1 accordion-body"}>
        <Accordion.Header>
          <h3>
            Vital Statistics
          </h3>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="kinks-ul-single py-1 ">
            {playerData.vs_chest > 0 ? <li><strong>Chest: </strong> {playerData.vs_chest}</li> : null}
            {playerData.vs_cup !== '' ? <li><strong>Cup: </strong> {playerData.vs_cup}</li> : null}
            {playerData.vs_waist > 0 ? <li><strong>Waist: </strong> {playerData.vs_waist}</li> : null}
            {playerData.vs_hips > 0 ? <li><strong>Hips: </strong> {playerData.vs_hips}</li> : null}

            {playerData.vs_length !== '' ? <li><strong>Cock Length: </strong> {playerData.vs_length}</li> : null}
            {playerData.vs_girth !== '' ? <li><strong>Cock Girth: </strong> {playerData.vs_girth}</li> : null}
            {playerData.vs_testes !== '' ? <li><strong>Testes: </strong> {playerData.vs_testes}</li> : null}
            {playerData.vs_ejaculate !== '' ? <li><strong>Ejaculate: </strong> {playerData.vs_ejaculate}</li> : null}

          </ul>
        </Accordion.Body>
      </Accordion.Item>)
  }

  const getGeneralSection = (index) => {
    return (
      <Accordion.Item eventKey={index} className={"pb-1 accordion-body"}>
        <Accordion.Header>
          <h3>
            General
          </h3>
        </Accordion.Header>
        <Accordion.Body >
          <ul className="kinks-ul-single py-1 ">
            <li><strong>Race: </strong> {playerData.race}</li>
            <li><strong>Weight: </strong> {playerData.weight}</li>
            <li><strong>Height: </strong> {playerData.height}</li>
            <li><strong>Age: </strong> {playerData.age}</li>
            <li><strong>Scent: </strong> {playerData.scent}</li>
            <li><strong>Gender: </strong> {playerData.gender}</li>
            <li><strong>Sexual Preference: </strong> {playerData.orientation}</li>
            <li><strong>Dominant/Submissive: </strong> {playerData.domsubrole}</li>
            <li><strong>Kinkiness: </strong> {playerData.kinks}</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>)
  }

  const getDescriptionSection = (index) => {
    return (
      <Accordion.Item eventKey={index} className={"pb-1 accordion-body"}>
        <Accordion.Header >
          <h3>
            Description
          </h3>
        </Accordion.Header>
        <Accordion.Body className={"py-1 px-4"}>
          {playerData.description}
        </Accordion.Body>
      </Accordion.Item>)
  }

  const getPostingSection = (index) => {
    return (
      <Accordion.Item eventKey={index} className={"pb-1 accordion-body"}>
        <Accordion.Header>
          <h3>
            Posting Preferences
          </h3>
        </Accordion.Header>
        <Accordion.Body className={"py-1"}>

          <ul className="kinks-ul-single">
            <li><strong>Posting Style: </strong> {playerData.posting_style}</li>
            <li><strong>Posting Frequency: </strong> {playerData.posting_frequency}</li>
            <li><strong>Grammar Preferences: </strong> {playerData.posting_grammar}</li>
            <li><strong>Roleplay Style: </strong> {playerData.roleplay_style}</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>)
  }

  const getKinkSection = (index) => {
    if (playerData.kinkslikes === undefined) {
      return null;
    }
    let total = playerData.kinkssizzles.length + playerData.kinksloves.length +
      playerData.kinkslikes.length + playerData.kinksdislikes.length + playerData.kinkshates.length;
    //console.log(total);
    if (total === 0) {
      return null;
    }

    return (
      <Accordion.Item eventKey={index} className={"pb-1 accordion-body"}>
        <Accordion.Header>
          <h3>
            Kinks
          </h3>
        </Accordion.Header>
        <Accordion.Body className={"p-2"}>
          <Card.Body>
            {showkinksSizzle(playerData.kinkssizzles, "border-danger px-3 border-sizzle")}
            <Row className={"py-2"}>
              {showkinks(playerData.kinksloves, "card border-warning")}
              {showkinks(playerData.kinkslikes, "card border-success")}
              {showkinks(playerData.kinksdislikes, "card border-info")}
              {showkinks(playerData.kinkshates, "card border-dark")}
            </Row>
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>)
  }

  return (
    <div className="container container-eom" id="divMainContent">
      <div className="input-group mb-3" >
        <div className="input-group-prepend px-2" >
          <button className="btn btn-themed-dark" onClick={getRoleplaysheet}>Get Roleplay Sheet</button>
        </div>
        <input type="text" className="form-control" defaultValue={playerName} onKeyUp={onKeyUpEvent} onChange={(e) => setPlayerName(e.currentTarget.value)} />
      </div>
      {playerData !== undefined ? (
        <>
          <Row>

            {(playerData.avatar.trim() != "")
              ?
              <Col xs={8} sm={6} md={2}>
                <PlayerAvatar avatarlocation={playerData.avatar} size={120} />
              </Col>
              : null}
            <Col>
              <h2>

                Roleplay Data for {playerData.name}
              </h2>
              {playerData.rpstatus !== ''
                ? <p><strong>Status: </strong>{playerData.rpstatus}</p>
                : null}
              {playerData.erpstatus !== ''
                ? <p><strong>ERP Status: </strong>{playerData.erpstatus}</p>
                : null}
              <br />
            </Col>
          </Row>

          <Accordion defaultActiveKey={['1', '2']} alwaysOpen  >
            {getGeneralSection('1')}
            {getDescriptionSection('2')}
            {getPostingSection('3')}
            {getVitalStatsSection('4')}
            {getBiographySection('5')}
            {getKinkSection('6')}
          </Accordion >
        </>
      )
        : (null)}
    </div>
  )
}

export default RoleplaySheet;