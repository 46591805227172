import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Card, Container, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPlayerStoryList, getPlayerStory, savePlayerStory } from '../components/webContentRequests';
import EditableField from '../builder/EditableField';
import TextLabels from '../components/TextLabels';
import MarkdownEditor from '../components/MarkdownEditor';


function PlayerStorySubmission() {
    const [story, setStory] = useState(undefined);
    const [storyList, setStoryList] = useState([]);
    const [metadata, setMetadata] = useState({});
    const [editing, setEditing] = useState(false);
    const [currentPlayer, setCurrentPlayer] = useState(localStorage.getItem('currentUser'));

    const storyRef = useRef();

    useEffect(() => {
        if (storyList.length === 0)
            getStoryList();

    }, [storyList]);

    const loadStoryFromId = (id) => {
        getPlayerStory(id).then(data => {
            //console.log('Received', data);
            setMetadata(data);
            setStory(data.story_text);
        })
    }

    const getStoryList = async e => {
        //const data = await getPlayerStoryList(localStorage.getItem('currentUser'));
        //console.log('Getting Story List', currentPlayer);
        const data = await getPlayerStoryList(currentPlayer);
        //console.log('Retreived Story List', data);
        if (data.result) {
            data.stories.sort((a, b) => b.story_publishdate - a.story_publishdate);
            setStoryList(data.stories);

        } else {
            //setStoryList([]);
        }
    }

    const saveStory = (content) => {
        savePlayerStory(metadata, content).then(data => {
            //console.log('Saved' ,data); 
            if (data.result) {
                setEditing(false);
                getStoryList();
                setMetadata(data.data);
            } else {
                window.alert('Could not save story.');
            }
        }).catch(error => {
            window.alert('Could not save story.');
        });
    }

    const markdownSave = (content) => {
        setStory(content);
        saveStory(content);
    }

    const createStory = () => {
        var newmetadata = {
            story_owner: currentPlayer,
            story_title: '',
            story_category: '',
            story_id: -1,
            story_text: '',
            story_status: 'DRAFT',
            story_labels : []
        };

        setMetadata(newmetadata);
        setStory('');
        setEditing(true);
    }

    const callDispatcher = (fieldName, newValue) => {
        metadata[fieldName] = newValue;
        setMetadata({ ...metadata });
    }

    const canAdminStories = () => {
        let roles = localStorage.getItem('roles');
        if (roles === null)
            roles = [];

        return roles.indexOf('webadmin') > -1;
    }

    const canChangeStatus = () => {
        if (canAdminStories()) {
            return true;
        } else {
            return metadata.story_status !== 'APPROVED';
        }
    }

    const getValidStatuses = () => {
        if (canAdminStories())
            return ['DRAFT', 'PENDING', 'SUBMITTED', 'APPROVED', 'REJECTED', 'DELETED'];
        else
            return ['DRAFT', 'PENDING', 'SUBMITTED', 'DELETED'];
    }

    const updateLabels = (value) => {
        console.log(value);
        metadata.story_labels = value;
        setMetadata({ ...metadata });
    }
    
    const getCurrentPlayer = () => {

        if (canAdminStories()) {
            return (<>
                <input type="text" className="form-control"
                    value={currentPlayer}
                    onChange={(e) => {
                        setCurrentPlayer(e.currentTarget.value)
                    }
                    } />
                <FontAwesomeIcon onClick={() => getStoryList()} className={"fa fa-lg m-2 fa-eom"} icon={"undo"} />
            </>
            )
        } else {
            return <h3>{currentPlayer} Stories</h3>
        }

    }
    return (
        <Container fluid>

            <Row className="container-eom" id="divMainContent">

                <Col xs="2">
                    <Row>
                        {getCurrentPlayer()}

                    </Row>
                    {storyList.map(story =>
                        <Row key={story.story_id} name={story.story_id} className={"py-2"} onClick={() => loadStoryFromId(story.story_id)}>
                            {story.story_owner} - {story.story_title}
                        </Row>

                    )}
                    <Row>
                        <Button onClick={createStory}>Create New Story</Button>
                    </Row>
                </Col>
                {story === undefined ? null :
                    <Col>
                        <Row>
                            <h2>{metadata.story_owner} - {metadata.story_title}</h2>
                            <FontAwesomeIcon onClick={() => setEditing(prev => !prev)} className={"ml-5 fa fa-lg m-2 fa-eom"} icon={"edit"} />
                        </Row>
                        {canChangeStatus()
                            ?
                            <EditableField editing={editing}
                                field={"story_status"}
                                value={metadata.story_status}
                                callDispatcher={callDispatcher}
                                description="Status"
                                fieldType={5}
                                choices={getValidStatuses} />
                            : <Row>
                                <strong>Status:</strong>
                                <span>{metadata.story_status}</span>
                            </Row>
                        }

                        <Row >
                            <strong>Story Id:</strong>
                            <span>{metadata.story_id}</span>
                        </Row>

                        <EditableField editing={editing}
                            field={"story_owner"}
                            value={metadata.story_owner}
                            callDispatcher={callDispatcher}
                            description="Owner"
                            fieldType={2} />

                        <EditableField editing={editing}
                            field={"story_title"}
                            value={metadata.story_title}
                            callDispatcher={callDispatcher}
                            description="Title"
                            fieldType={2} />

                        <EditableField editing={editing}
                            field={"story_category"}
                            value={metadata.story_category}
                            callDispatcher={callDispatcher}
                            description="Series"
                            fieldType={2} />
                        <Row>
                        <strong>Labels:</strong>
                            <TextLabels 
                                editing={editing}
                                defaultLabels={metadata.story_labels} 
                                labelChange={(value) => updateLabels(value)}/>
                        </Row>
                        <Row>
                            <MarkdownEditor documentToEdit={story} editing={editing} saveDocument={markdownSave}/>
                        </Row>
                    </Col>}
            </Row>
        </Container >
    )
}

export default PlayerStorySubmission;