import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import EditableField from '../EditableField';

function ResourceStylingEntryAction(props) {
    const [editing, setEditing] = useState(true);

    let action = props.action;
    let callDispatcher = props.dispatcher;
    let index = props.index;
    let deleteAction = props.deleteAction;

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 4,
                borderRadius: 15
            }}
        />
    );

    const localDispatcher = (field, value) => {
        callDispatcher(index, field, value);   
    }

    return (
        <>
            <ColoredLine color="maroon" />

            <EditableField editing={editing}
                field={"action"}
                value={action.action}
                callDispatcher={localDispatcher}
                description="Action"
                fieldType={2} />
            <EditableField editing={editing}
                field={"slots"}
                value={action.slots}
                callDispatcher={localDispatcher}
                description="Wear Slots"
                fieldType={2} />
            <Button onClick={() => deleteAction(index)}>Delete</Button>
        </>
    )

}



export default ResourceStylingEntryAction;
