import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from "react-dom/client";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import "./custom.css";
//import "./theme.css";

import App from './App/App';
import { AuthProvider } from './App/pages/components';
import { ThemeProvider } from './App/theming/ThemeProvider';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider>
        <AuthProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AuthProvider>
    </ThemeProvider>

);