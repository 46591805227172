import React from 'react';
import { genericAuthenticatedFetch, genericAuthenticatedPost, genericAuthenticatedPut } from './index';
import { getAuthenticatedHeader } from '../utils/FetchHeaders';
import Config from '../../../config/Config';


export async function getIssues(issueType, searchoption) {
  //console.log(`Getting Issues ${issueType}`)
  return genericAuthenticatedFetch(`issues/list/${issueType}?search=${searchoption}`);
}

export async function searchIssues(search) {
  const body = JSON.stringify(search);
  return genericAuthenticatedPost('issues/search', body);
}


export async function getIssue(issue_id) {
  //console.log(`Getting Issue ${issue_id}`)
  return genericAuthenticatedFetch(`issues/issue/${issue_id}`);
}

export async function updateIssue(issue) {
  const body = JSON.stringify(issue);
  return genericAuthenticatedPut('issues/update', body);
}