import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';

const FatigueIndicator = ({ value, title, iconname }) => {

    
    const fatigued = 16000000;
    const exhausted = 160000000;
    const tired =16000000-30000;


    let valueTest = parseInt(value);
    let faIconClassName = "fa fa-lg icon-" + iconname;
    let warningColour = "green";
    if (valueTest > exhausted) {
        warningColour = "red";
    } else if (valueTest > fatigued) {
        warningColour = "orange";
    } else if (valueTest > tired) {
        warningColour = "yellow";
    }
    return (
        <Col className={"eomIndicator"}>
            <span>{title} </span> <FontAwesomeIcon className={faIconClassName} icon={iconname} color={warningColour} />
        </Col>
    )
}

export default FatigueIndicator