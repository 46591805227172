import React, { useState, useEffect } from 'react';
import { useBuilder, useBuilderDispatch, BuilderProvider } from '../components/index';
import { Row, Col, Button } from 'react-bootstrap';
import { getScriptList, getScript, setScript, deleteScript } from '../components/builderRequests';
import ScriptEditor from './quests/ScriptEditor';


function BuilderScriptRepository() {
    return (<BuilderProvider><BuilderScriptRepositoryInternal /></BuilderProvider>);
}


function BuilderScriptRepositoryInternal() {
    const [scripts, setScripts] = useState([]);
    const [filter, setFilter] = useState('');
    const [warning, setWarning] = useState('');
    const [selectedScript, setSelectedScript] = useState();

    const { currentarea, currentitem, currentroom, currentmob, currentexit, editing } = useBuilder();
    const dispatch = useBuilderDispatch();

    const loadScript = async (path, name) => {

        console.log('name', name);
        console.log('path', path);
        setSelectedScript(undefined);
        const script = await getScript(path, name);
        if (script.result) {
            console.log(script);
            setSelectedScript(script);
            //saveScriptChange(script);

        } else {
            console.log('Failed script get');
        }
    }

    const handleCreate = () => {
        let script = {
            path: "",
            name: "New Script.script",
            content: "##Generic Script"
        };

        setSelectedScript(script);
    }

    const listScripts = async () => {
        console.log('Getting Script list');
        const data = await getScriptList();
        if (data.result) {
            console.log(data);
            setScripts(data.scripts);

        } else {
            console.log('Failed script list');
        }
    }

    const postDeletion = (keepSelection) => {
        console.log('Script Deleted');

        listScripts();
        if(!keepSelection)
            setSelectedScript();
    }
    useEffect(() => {
        if (scripts.length == 0)
            listScripts();
        console.log('Rendering again ffs', selectedScript);
    }, [selectedScript]);

    const filterMatch = (script) => {
        return (script.name.toLowerCase().indexOf(filter) > -1) || (script.path.toLowerCase().indexOf(filter) > -1);
    }

    return (
        <div className="container-eom" id="divMainContent">
            <Row >
                <Col md={4} className={"mx-2"}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend" >
                            <button className="btn btn-themed-dark" type="submit">Filter</button>
                        </div>
                        <input type="text" value={filter} className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                    </div>
                </Col>
                <Col>
                    <Button className={"btn-themed-dark m-2"} onClick={handleCreate}>Create New Script</Button>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <div>
                        {scripts.filter(script => filterMatch(script)).map((script, index) =>
                            <div className="p-2 m-2" name={script.name} key={index} onClick={() => { loadScript(script.path, script.name) }}>
                                {script.path}{script.name}
                            </div>
                        )}
                    </div>
                </Col>
                <Col md={6}>
                    {selectedScript !== undefined ? <ScriptEditor script={selectedScript} postDeletion={postDeletion} /> : null}
                </Col>
            </Row >
        </div>

    )
}

export default BuilderScriptRepository;