import React, { Component } from 'react';
import { Card, Accordion } from 'react-bootstrap';

class IntroCommunication extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">
        <br />
        <h1>Communicating with other Players</h1>
        <br />
        <h2>Channels</h2>
        <p>There are a number of channels available to players to allow communication across the realm. It should be noted that althoough some of these are considered <strong>in character (IC)</strong> many cannot hear or sense these interactions.</p>
        <ul>
          <li><strong>Gossip</strong> this is the general meet and greet channel, widely used and should be considered OOC.</li>
          <li><strong>Roleplay</strong> this is the general roleplay channel and should be considered IC.</li>
          <li><strong>Newbie</strong> this is the channel where players can ask questions and get help, particularly when new! This should be considered OOC</li>
          <li><strong>Chat</strong> this is a general chat channel for prolonged dicussions and should be considered OOC.</li>
          <li><strong>Crafting</strong> this is a dedicated to dicussions and questions about the profession system and should be considered OOC.</li>
        </ul>
        <p>To speak on one of the channels simply use the name of the channel as the command word, e.g. GOSSIP, and then append the message that you wish to send.</p>
        <p>You can also use socials, simply use the name of the channel and then social as you would use it in game, that is to say with a period, followed by the social and any required targets or carry out an action by preficing your sentence with a colon.</p>
        <h2>Emote</h2>
        <p>Causes a message to be displayed which will describe your character as performing the action described by the given emote message.  For instance, the above example, if done by an adventurer named "Bob" would tell everyone else in the room that 'Bob whistles at the big Dragon.'</p>
        <p>Enclosing any text in square brackets would present that in the default speech colour for the receiving players as well as translate any text within it if you are speaking something other than common.</p>
        <br />
        <Accordion defaultActiveKey={[]} alwaysOpen >
          <Accordion.Item eventKey={'0'} className={"accordion-body"}>
            <Accordion.Header>
              <div className="alert alert-primary alert-nm">
                Emote Examples
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row fluid">
                <div className="col-md-8">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> emote smiles warmly at everyone</p>
                    <p><strong>Everyone Sees: </strong>A buxum white-haired bunny-girl (Felicity) smiles warmly at everyone.</p>
                  </div>
                </div>
              </div>
              <div className="row fluid">
                <div className="col-md-8">
                  <div className="shout-box">
                    <p><strong>Felcity enters: </strong>emote smiles warmly at everyone and says [Hello!]</p>
                    <p><strong>Everyone Sees: </strong>A buxum white-haired bunny-girl (Felicity) smiles warmly at everyone and says 'Hello!'.</p>
                  </div>
                </div>
              </div>
            </Accordion.Body>

          </Accordion.Item>
        </Accordion>
        <br />
        <h2>Say/Saymote</h2>
        <p><strong>Say</strong> or <strong>sayto</strong> cause your character to speak to one or more characters or mobs in the same room as yourself. You may specify the mob to speak to, or just speak in general.</p>
        <p>Enclosing any text in square brackets would present that in the default speech colour for the receiving players as well as translate any text within it if you are speaking something other than common.</p>
        <p>A more complex scenario comes into play, using the square brackets to end the speech, you can interject actions to your speech.</p>
        <Accordion defaultActiveKey={[]} alwaysOpen >
          <Accordion.Item eventKey={'0'} className={"accordion-body"}>
            <Accordion.Header>
              <div className="alert alert-primary alert-nm">
                Say/Saymote Examples
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> saymote Hello..] she smiles warmly [everyone!</p>
                    <p><strong>Character Sees: </strong>You say 'Hello..' she smiles warmly 'everyone!'.</p>
                    <p><strong>Room Sees: </strong>A buxum white-haired bunny-girl (Felicity) says 'Hello..' she smiles warmly 'everyone!'.</p>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br />
        <h2>Whisper</h2>
        <p><strong>Whisper</strong> causes your character to speak to one or more characters or mobs in the same room as yourself. You may specify the mob to speak to, or just speak in general.</p>
        <p>If you do not specify a target, the whisper will be heard by anyone <strong>riding</strong> the same item (table, couch, bed horse) as you.</p>
        <Accordion defaultActiveKey={[]} alwaysOpen >
          <Accordion.Item eventKey={'0'} className={"accordion-body"}>
            <Accordion.Header>
              <div className="alert alert-primary alert-nm">
                Whisper Examples
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> whisper suki come with me!</p>
                    <p><strong>Character Sees: </strong>You whisper to A cyan haired pirate lass (Suki) 'come with me!'.</p>
                    <p><strong>Target Sees: </strong>A buxum white-haired bunny-girl (Felicity) whispers to you 'come with me!'.</p>
                    <p><strong>Room Sees: </strong>A buxum white-haired bunny-girl (Felicity) whispers something to a cyan haired pirate lass (Suki).</p>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br />

        <h2>Smote</h2>
        <p>Causes a message to be displayed which will describe your character as performing the action described by the given emote message.</p>
        <p>For exmaple, entering thecommand <strong>smote Whistling loudly, * beckon(s) everyone over</strong> if done by an adventurer named "River" would tell everyone else in the room that "Whistling loudly, River beckons everyone over.</p>
        <Accordion defaultActiveKey={[]} alwaysOpen >
          <Accordion.Item eventKey={'0'} className={"accordion-body"}>
            <Accordion.Header>
              <div className="alert alert-primary alert-nm">
                Smote Examples
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> smote Grinning, * wave(s) to everyone</p>
                    <p><strong>Character Sees: </strong>Grinning, you wave to everyone.</p>
                    <p><strong>Room Sees: </strong>Grinning, a buxum white-haired bunny-girl (Felicity) waves to everyone.</p>
                    <p>The character view needs updating!</p>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br />
        <h2>Pmote</h2>
        <p>Causes a message to be displayed which will describe your character as performing the action described by the given command.</p>
        <p>The pmote will take another character or item as the first parameter and this can then be referenced in the action.</p>
        <ul>
          <li><strong>*</strong> will substitute "target" when displayed to the room and "you" to the targeted character.</li>
          <li><strong>*r</strong> will substitute "target's" when displayed to the room and "your" to the targeted character.</li>
          <li><strong>*s</strong> will substitute "target's" when displayed to the room and "yours" to the targeted character.</li>
          <li><strong>*i</strong> will substitute "target is" when displayed to the room and "you are" to the targeted character.</li>
          <li><strong>*h</strong> will substitute "his/her" when displayed to the room and "your" to the targeted character.</li>
          <li><strong>*m</strong> will substitute "him/her" when displayed to the room and "you" to the targeted character.</li>
        </ul>
        <br />
        <Accordion defaultActiveKey={[]} alwaysOpen >
          <Accordion.Item eventKey={'0'} className={"accordion-body"}>
            <Accordion.Header>
              <div className="alert alert-primary alert-nm">
                Pmote Examples
              </div>
            </Accordion.Header>
            <Accordion.Body>

              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> pmote suki blushes as she catches sight of * naked</p>
                    <p><strong>Character Sees: </strong>A buxum white-haired bunny-girl (Felicity) blushes as she catches sight of A cyan haired pirate lass (Suki) naked.</p>
                    <p><strong>Room Sees: </strong>A buxum white-haired bunny-girl (Felicity) blushes as she catches sight of A cyan haired pirate lass (Suki) naked.</p>
                    <p><strong>Target Sees: </strong>A buxum white-haired bunny-girl (Felicity) blushes as she catches sight of you naked. </p>
                  </div>
                </div>
              </div>

              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> pmote suki gazes steadily at * before reaching out to take +h hand</p>
                    <p><strong>Character Sees: </strong>A buxum white-haired bunny-girl (Felicity) gazes steadily at A cyan haired pirate lass (Suki) before reaching out to take her hand.</p>
                    <p><strong>Room Sees: </strong>A buxum white-haired bunny-girl (Felicity) gazes steadily at A cyan haired pirate lass (Suki) before reaching out to take her hand. </p>
                    <p><strong>Target Sees: </strong>A buxum white-haired bunny-girl (Felicity) gazes steadily at you before reaching out to take your hand. </p>
                  </div>
                </div>
              </div>

              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> pmote suki looks at * and + to demonstrate the difference between asterisk and plus.</p>
                    <p><strong>Character Sees: </strong>A buxum white-haired bunny-girl (Felicity) looks at A cyan haired pirate lass (Suki) and Suki to demonstrate the difference between asterisk and plus.</p>
                    <p><strong>Room Sees: </strong>A buxum white-haired bunny-girl (Felicity) looks at A cyan haired pirate lass (Suki) and Suki to demonstrate the difference between asterisk and plus.</p>
                    <p><strong>Target Sees: </strong>A buxum white-haired bunny-girl (Felicity) looks at you and you to demonstrate the difference between asterisk and plus.</p>
                  </div>
                </div>
              </div>

              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <h3>Using the substitutes * and *h</h3>

                    <p><strong>Felicity's player enters:</strong><br />pmote efrem runs a hand through her hair, fluffing it up as she admires *. She reaches out to touch *h wings [May I?] she asks.</p>

                    <p><strong>Felicity sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires a tall rangy angel (Efrem). She reaches out to touch his wings 'May I?' she asks.</p>

                    <p><strong>Efrem sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires you. She reaches out to touch your wings 'May I?' she asks.</p>

                    <p><strong>The room sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires a tall rangy angel (Efrem). She reaches out to touch his wings 'May I?' she asks. </p>

                  </div>
                </div>
              </div>
              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <h3>Using the substitutes *s in place of *h</h3>

                    <p><strong>Felicity's player enters: </strong><br />
                      pmote efrem runs a hand through her hair, fluffing it up as she admires *. She reaches out to touch *s wings [May I?] she asks.
                    </p>
                    <p><strong>Felicity sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires a tall rangy angel (Efrem). She reaches out to touch a tall rangy angel (Efrem)'s wings 'May I?' she asks.
                    </p>
                    <p><strong>Efrem sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires you. She reaches out to touch your wings 'May I?' she asks.
                    </p>
                    <p><strong>The room sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires a tall rangy angel (Efrem). She reaches out to touch Efrem's wings 'May I?' she asks.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <h3>Using the substitutes + in place of *</h3>

                    <p><strong>Felicity's player enters:</strong><br />
                      pmote efrem runs a hand through her hair, fluffing it up as she admires *. She reaches out to touch +s wings [May I?] she asks.

                    </p>
                    <p><strong>Felicity sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires a tall rangy angel (Efrem). She reaches out to touch Efrem's wings 'May I?' she asks.

                    </p>
                    <p><strong>Efrem sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires you. She reaches out to touch your wings 'May I?' she asks.

                    </p>
                    <p><strong>The room sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) runs a hand through her hair, fluffing it up as she admires a tall rangy angel (Efrem). She reaches out to touch Efrem's wings 'May I?' she asks.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <h3>Using the substitute +m</h3>

                    <p><strong>Felicity's player enters</strong><br />

                      pmote efrem shakes her head at * and gives +m a flat look [No, you can't!]

                    </p>
                    <p><strong>Felicity sees:</strong><br />

                      A buxum white-haired bunny-girl (Felicity) shakes her head at a tall rangy angel (Efrem) and gives him a flat look 'No, you can't!'.

                    </p>
                    <p><strong>Efrem sees:</strong><br />

                      A buxum white-haired bunny-girl (Felicity) shakes her head at you and gives you a flat look 'No, you can't!'.

                    </p>
                    <p><strong>Room Sees:</strong><br />

                      A buxum white-haired bunny-girl (Felicity) shakes her head at a tall rangy angel (Efrem) and gives him a flat look 'No, you can't!'.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <h3>Using the substitute +i</h3>

                    <p><strong>Felicity's player enters</strong><br />
                      pmote efrem thinks *i a bit wet

                    </p>
                    <p><strong>Felicity sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) thinks a tall rangy angel (Efrem) is a bit wet.

                    </p>
                    <p><strong>Efrem sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) thinks you are a bit wet.

                    </p>
                    <p><strong>Room Sees:</strong><br />
                      A buxum white-haired bunny-girl (Felicity) thinks a tall rangy angel (Efrem) is a bit wet.
                    </p>

                  </div>
                </div>
              </div>


            </Accordion.Body>
          </Accordion.Item>

        </Accordion>
        <br />

        <h2>Xmote</h2>
        <p>Causes a message to be displayed which will describe your character as performing the action described by the given command.</p>
        <p>The xmote will take a number of characters or items as a parameter enclosed in curly brackets and these can then be referenced in the action.</p>
        <ul>
          <li><strong>*</strong> will substitute "target" when displayed to the room and "you" to the targeted character.</li>
          <li><strong>*r</strong> will substitute "target's" when displayed to the room and "your" to the targeted character.</li>
          <li><strong>*s</strong> will substitute "target's" when displayed to the room and "yours" to the targeted character.</li>
          <li><strong>*i</strong> will substitute "target is" when displayed to the room and "you are" to the targeted character.</li>
          <li><strong>*h</strong> will substitute "his/her" when displayed to the room and "your" to the targeted character.</li>
          <li><strong>*m</strong> will substitute "him/her" when displayed to the room and "you" to the targeted character.</li>

        </ul>
        <br />
        <Accordion defaultActiveKey={[]} alwaysOpen >
          <Accordion.Item eventKey={'0'} className={"accordion-body"}>
            <Accordion.Header>
              <div className="alert alert-primary alert-nm">
                Xmote Examples
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> xmote {"{"}suki{"}"} smiles at *1</p>
                    <p><strong>Character Sees: </strong>A buxum white-haired bunny-girl (Felicity) smiles at A cyan haired pirate lass (Suki).</p>
                    <p><strong>Room Sees: </strong>A buxum white-haired bunny-girl (Felicity) smiles at A cyan haired pirate lass (Suki).</p>
                    <p><strong>Target Sees: </strong>A buxum white-haired bunny-girl (Felicity) smiles at you.</p>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br />


        <h2>Tell</h2>
        <p>Allows your character to speak to the specified character(s), regardless of what room they presently occupy. Some people will always regard this as an <strong>out of character</strong> communication, but it can be
          used to provide information discretely.</p>
        <Accordion defaultActiveKey={[]} alwaysOpen >
          <Accordion.Item eventKey={'0'} className={"accordion-body"}>
            <Accordion.Header>
              <div className="alert alert-primary alert-nm">
                Tell Examples
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> tell suki hi! </p>
                    <p><strong>Character Sees: </strong>You tell A cyan haired pirate lass (Suki) 'hi!'.</p>
                    <p><strong>Target Sees: </strong>Felicity tells you 'hi!'.</p>
                  </div>
                </div>
              </div>

              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p><strong>Felcity enters:</strong> tell suki :sends a mental image of her dancing the can-can </p>
                    <p><strong>Character Sees: </strong>Far and away A cyan haired pirate lass (Suki) senses 'Felicity sends a mental image of her dancing the can-can.'.</p>
                    <p><strong>Target Sees: </strong>From far and away, you sense 'Felicity sends a mental image of her dancing the can-can.'.</p>
                  </div>
                </div>
              </div>


              <div className="row fluid">
                <div className="col-md-10">
                  <div className="shout-box">
                    <p>An example of a discrete action might be as follows:</p>
                    <p><strong>Felcity enters:</strong> tell suki :'s foot brushes against yours beneath the table, out of sight of everyone else. </p>
                    <p><strong>Character Sees: </strong>Far and away A cyan haired pirate lass (Suki) senses 'Felicity's foot brushes against yours beneath the table, out of sight of everyone else.'.</p>
                    <p><strong>Target Sees: </strong>From far and away, you sense 'Felicity's foot brushes against yours beneath the table, out of sight of everyone else.'.</p>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion>

        <h2>Out of Character Communication</h2>
        <p>Sometimes you need to send a message to someone or the room that isn't from your character, but from you the player and this should be handled using one of <strong>omote, osay or otell</strong> these work just like their IC counterparts but they add three key behviours</p>
        <ol>
          <li>inserts OOC text at the start of the message so that people can see it is an out of character message.</li>
          <li>Flags the message as OOC so that it doesn't get flagged as part of roleplay.</li>
          <li>prevents the translation of any text to the language you are currently speaking.</li>
        </ol>

        <h2>Immersion</h2>
        <p>Using the command <strong>SETOOC FILTER</strong> a player can filter out all out of character messages, adding the paramter %O to your prompt will alert you of any out of character activity you may wish to review.</p>

        <h2>Quiet</h2>
        <p>Turns off all global channels, such as GOSSIP, GRATZ, and others. To turn them back on, you may issue the GOSSIP, GRATZ command agin, or reissue the QUIET command to turn them all back on.</p>
        <p>This command will also block tells unless the -ALLOWTELLS switch is passed in too.</p>
      </div >

    );
  }
}

export default IntroCommunication;