import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { updateShortDescription } from '../components';
import ColouredText from '../components/ColouredText';

function PlayerEditor() {
  const [shortId, setShortId] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [errorMessage, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateValue = (inputId, what, maxlength, descriptionMode) => {
    if (inputId.length == 0) {
      setError(what + ' too short.');
      return false;
    }
    if (inputId.length > maxlength) {
      setError(what + ' too long.');
      return false;
    }
    if (!descriptionMode) {
      let testString = /[^0-9a-zA-Z]/;
      if (testString.test(inputId)) {
        setError(what + ' contains invalid Values.');
        return false;
      }
    }
    return true;
  }

  const setShortDescription = async e => {
    setError('');
    setSuccessMessage('')
  

    console.log('Validating', shortId);
    if (!validateValue(shortId, 'Quick Id', 10, false)) {
      return;
    }

    if (!validateValue(shortDesc, 'Short Description', 200, true)) {
      return;
    }
    const data = await updateShortDescription(shortId, shortDesc);
    console.log(data);
    if (data.result) {
      console.log('Success!');
      setError('');
      setSuccessMessage('Short description updated!')
    } else {
      console.log('Failure!');
      setError('Failure');
      setSuccessMessage('')
    }
  }

  useEffect(() => {
    //getScoresheet();
  }, []);

  return (
    <div className="container container-eom" id="divMainContent">
      <h2>Edit Short Description</h2>
      <Row>
        <Col xs={2}>
          <input type="text" placeholder="Short Code" className="form-control" defaultValue={shortId} onChange={(e) => setShortId(e.currentTarget.value)} />
        </Col>
        <Col xs={6}>
          <input type="text" className="form-control" defaultValue={shortDesc} onChange={(e) => setShortDesc(e.currentTarget.value)} />
        </Col>
        <Col xs={3}>
          <button className="btn btn-themed-dark" type="button" onClick={setShortDescription}>Change Short Description</button>
        </Col>

      </Row>

      <Row>
        <Col>
          <ColouredText className={"textarea eom-box"} ColouredText={shortDesc} />
        </Col>


      </Row>
      <br />
      <Row className={"px-2"}> 

        <span className="error"><font color="red">{errorMessage}</font></span>
        <span className="error"><font color="green">{successMessage}</font></span>
      </Row>
    </div>
  )
}

export default PlayerEditor;