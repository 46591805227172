import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Stage, Layer, Line, Text, Group, Circle } from 'react-konva';

const width = 40;
const roomWidth = width / 2;

const spacing = 40;
const updownOffset = spacing / 4;

function StandardExit(props) {


    //0,0 is top left, not bottom left, so we need to reverse x/y to get this map as expected

    let exit = props.exit;
    let piX1 = exit.x1;
    let piY1 = exit.y1 * -1;
    let piX2 = exit.x2;
    let piY2 = exit.y2 * -1;
    let piZ1 = exit.z1;
    let piZ2 = exit.z2;

    const w = roomWidth / 2;
    const xo = piX1 * width;
    const yo = piY1 * width;
    const xt = piX2 * width;
    const yt = piY2 * width;


    const xd = xt - xo;
    const yd = yt - yo;
    //console.log('Draw Exit: Deltas', xd, yd);

    const x2 = xo + (xd / 2);
    const y2 = yo + (yd / 2);

    //console.log('Draw Exit: New Destination', x2, y2);

    let y1 = yo;
    if (piY1 < piY2) {
        y1 = yo + w;
    } else if (piY1 > piY2) {
        y1 = yo - w;
    }

    let x1 = xo;
    if (piX1 < piX2) {
        x1 = xo + w;
    } else if (piX1 > piX2) {
        x1 = xo - w;
    }

    if (piZ1 != piZ2) {
        let xov = xo + 2;
        let yov = yo + (piZ1 < piZ2 ? -2 : 2);
        let ypeak = yov + (piZ1 < piZ2 ? -7 : 7);
        return <Line key={props.index} closed points={[
            xov + updownOffset, yov,
            xov + (updownOffset + 4), ypeak,
            xov + updownOffset + 8, yov]} fill="Red" stroke="PeachPuff" strokeWidth={0}
        />
    }
    // if (piZ1 != piZ2) {
    //     console.log('Up Exit');
    //     let xov = xo - 1;
    //     let yov = yo + (piZ1 < piZ2 ? -1 : 1);
    //     let ypeak = yov + (piZ1 < piZ2 ? -7 : 7);

    //     return <Line key={props.index} closed points={[
    //         xov, yov,
    //         xov + 5, ypeak,
    //         xov + 10, yov]} fill="Red" stroke="PeachPuff" strokeWidth={1.5} />
    // }
    return <Line key={props.index} closed points={[
        x1, y1,
        x2, y2]} fill="blue" stroke="silver" strokeWidth={2} />
}

export default StandardExit;