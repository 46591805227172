import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBuilder } from '../../components/index';
import { removeColourCodes } from './../../utils/ColourMap';
import { Col, Row } from 'react-bootstrap';

function MobSummary(props) {

    let unloadMob = props.unloadMob;

    const { currentmob } = useBuilder();

    console.log(currentmob);

    return (
        <Row key={currentmob.name} className="row fluid align-content-center py-2">
            <Col xs={2}>
                <FontAwesomeIcon onClick={unloadMob} className="fa fa-lg fa-eom m-2" icon={"times-circle"} />

            </Col>
            <Col xs={10}>
                <h4> Viewing {removeColourCodes(currentmob.name)} </h4>
            </Col>
            {currentmob.items !== undefined
                ?
                <>
                    <span><strong>Items: </strong>{currentmob.items.length}</span>
                    <br />
                </>
                : null
            }

            {currentmob.stock !== undefined
                ?
                <>
                    <span><strong>Stock: </strong>{currentmob.stock.length}</span>
                    <br />
                </>
                : null
            }
            <span><strong>Effects: </strong>{currentmob.effects.length}</span><br />
        </Row>);

}

export default MobSummary;