

const dispositionlist = [
    { description: 'Is Not Seen', mask: 1 },
    { description: 'Is Hidden', mask: 2 },
    { description: 'Is Invisible', mask: 4 },
    { description: 'Is Evil', mask: 8 },
    { description: 'Is Good', mask: 16 },
    { description: 'Is Sneak', mask: 32 },
    { description: 'Is Bonus', mask: 64 },
    { description: 'Is Dark', mask: 128 },
    { description: 'Non Detect', mask: 4194304 }
];

export const getDispositionList= ()=> {
    return dispositionlist;
}

const attributelist = [
    { description: 'See Through', mask: 1 },
    { description: 'Multi-Wear', mask: 2 },
    { description: 'Remove Lower', mask: 4 },
    { description: 'Can See Beneath', mask: 8 }
];

export const getAttributeList= ()=> {
    return attributelist;
}