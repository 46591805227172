import React, { useState } from 'react';
import { loginUser, useAuthState, useAuthDispatch, toggleMenu } from './../components/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'react-bootstrap';
import { useThemeDispatch } from '../../theming/ThemeProvider';

const InlineUserLoggedOut = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [inputType, setInputType] = useState('password');
    const themeDispatch = useThemeDispatch();

    const dispatch = useAuthDispatch();
    const { loading, avatar, token, currentUser, newMailCount, showPlayerMenu } = useAuthState();

    function updateStatus() {

    }

    setInterval(updateStatus, 30000);

    const handleToggleMenu = async (e) => {
        e.preventDefault();
        let response = await toggleMenu(dispatch);
    }

    const handleSubmit = async (e) => {
        //    alert('A name was submitted: ' + username + ' / ' + password);
        setPasswordError('');

        e.preventDefault();
        if (password.length > 0) {
            let response = await loginUser(dispatch, { username, password });
            console.log(response.reason);
            if (!response.result) {
                setPasswordError(response.reason);
            } else {
                setPasswordError('');
            }

        } else {
            setPasswordError('Invalid Password');
        }
    }

    const handleVisibilityChange = (e) => {
        setInputType('password' === inputType ? "text" : "password");
    }

    const handleChange = e => {
        const target = e.target;
        const name = target.name;
        setPasswordError('');

        if (name === 'eompassword')
            setPassword(e.target.value);
        else
            setUsername(e.target.value);
    }

    const toggleTheme = () => {
        themeDispatch({ type: 'TOGGLE_THEME' });
    }

    return (
        <div className={"d-flex m-2 align-items-center"}>
            <FontAwesomeIcon className="mx-3 fa fa-trash fa-lg" icon={"paint-roller"} color="white" onClick={() => toggleTheme()} />
            <Form className="d-flex" onSubmit={handleSubmit}>
                <Form.Control
                    type="text"
                    placeholder="Character"
                    className="me-2"
                    name="eomname"
                    aria-label="username"
                    value={username} autoComplete="on" onChange={handleChange}
                    
                />
                <Form.Control
                    placeholder="Password"
                    className="me-2"
                    name="eompassword"
                    type={inputType}
                    aria-label="password"
                    value={password} onChange={handleChange}
                />

                <span className="text-white p-2 icon rounded-right" onClick={handleVisibilityChange}>
                    <FontAwesomeIcon className="fa fa-trash fa-lg" icon={(inputType === 'text') ? "eye-slash" : "eye"} color="white" />
                </span>
                <Button variant="outline-success" type="submit" disabled={loading}>Login</Button>

                {((passwordError!==undefined)&&(passwordError.length > 0))
                    ? <span className="error pl-2"><font color="red">{passwordError}</font></span>
                    : null
                }

            </Form>
        </div>

    )
}

export default InlineUserLoggedOut;