import React, { Children } from 'react';
import { BrowserRouter, Route, Link, redirect, Navigate, useParams } from 'react-router-dom';
import { useAuthState } from '../components/';

const AdminRoute = ({children, role}) => {
    const { token, currentUser } = useAuthState();
    let roles = localStorage.getItem('roles');
    if (roles === null)
        roles = [];

    const tokenNotEmpty = () => {
        let localToken = localStorage.getItem('token');
        return (localToken !== undefined) && (localToken !== null);
    }

    let valid = (roles.indexOf(role) > -1) && tokenNotEmpty();
    return valid ? children : <Navigate to="/" />

};

export default AdminRoute;