import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { getPlayerAccountData } from './../components'

function RoleplaySheet() {
  const [playerName, setPlayerName] = useState(localStorage.getItem('currentUser'));
  const [playerData, setPlayerData] = useState();

  const getScoresheet = async e => {
    const data = await getPlayerAccountData(playerName);
    if (data.result) {
      setPlayerData(data);
    } else {
      setPlayerData();
    }
  }

  useEffect(() => {
    getScoresheet();
  }, []);

  return (
    <Container fluid>
      <h2>Characters linked to this account</h2>
      <br/>
      <ul className="kinks-ul-single">
        <Row>
          <Col><strong>Character</strong></Col>
          <Col><strong>Class</strong></Col>
          <Col><strong>Race</strong></Col>
          <Col><strong>Last On</strong></Col>
          <Col><strong>Purge</strong></Col>
          <Col><strong>Messages</strong></Col>
        </Row>

        {playerData !== undefined ? (
          <>
            {playerData.accounts.map(account =>
              <Row py-2 key={account.charactername}>
                <Col><strong>{account.charactername}</strong></Col>
                <Col>{account.charclass}</Col>
                <Col>{account.race}</Col>
                <Col>{account.laston}</Col>
                <Col>{account.purgeon}</Col>
                <Col>{account.messages?"Yes":"No"}</Col>
              </Row>

            )}
          </>
        ) : null}
      </ul>
      <br/>
    </Container>
  )
}

export default RoleplaySheet;