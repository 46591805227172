import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ColouredText from './components/ColouredText';
import ColouredMatrix from './components/ColourMatrix'

function EoMEditor() {

  const [transformedText, setTransformedText] = useState('');
  const [sourceText, setSourceTextText] = useState('');

  const elementRef = useRef();

  const spanningElements = {};
  const handleChange = e => {
    let text = e.target.value;
    setSourceTextText(text);
  }

  const clickCode = (text) => {
    const divElement = elementRef.current;
    let cursorStart = divElement.selectionStart;
    var output = [sourceText.slice(0, cursorStart), text.code, sourceText.slice(cursorStart)].join('');
    setSourceTextText(output);
    divElement.focus();
    divElement.selectionStart=cursorStart;
  }

  useEffect(() => {
    setTransformedText(sourceText);
  }, [sourceText]);

  return (
    <div className="container px-2">
      <Container fluid>
        <br />
        <Row>
          <div>
            <span>
              This simple editor will allow you to test out the colour codes quickly and easily before entering them into the mud!
            </span>
          </div>
          <br />
          <br />
          <br />

        </Row>
        <Row>
          <Col xs lg="2">
            <span>Enter Raw Text</span>
          </Col>
          <Col lg>
            <textarea className="eomEditor my-2 p-2 eom-box"  ref={elementRef} value={sourceText} onChange={handleChange} id="exampleFormControlTextarea1" rows="3"></textarea>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs lg="2">
            <span>View Result</span>
          </Col>
          <Col>

            <ColouredText ColouredText={transformedText} />

          </Col>
        </Row>
        <br />
        <br />
        <ColouredMatrix clickCode={clickCode} />
      </Container>
    </div>
  )
}

export default EoMEditor;