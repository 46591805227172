import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, DropdownButton, Tabs, Tab } from 'react-bootstrap';
import EditableField from '../../builder/EditableField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { getCharacterData } from '../../components/characterRequests';
import PlayerAvatar from '../../components/PlayerAvatar';
import CharacterSecurity from './CharacterSecurity';

function AdminCharacterData() {
  const match = useParams();

  let defaultCharacterName = '';
  if (match.charactername !== undefined) {
    defaultCharacterName = match.charactername;
  }

  const [characterName, setCharacterName] = useState(defaultCharacterName);
  const [characterHeader, setCharacterHeader] = useState();
  const [characterGeneral, setCharacterGeneral] = useState();
  const [characterTitles, setCharacterTitles] = useState();
  const [characterAbilities, setCharacterAbilities] = useState();
  const [characterProperty, setCharacterProperty] = useState();


  useEffect(() => {
    if (characterName !== '') {
      getCharacterData(characterName, 'general').then(data => {
        if (data.result) {
          setCharacterHeader(data.header);
          setCharacterGeneral(data.general);
        }
        else {
          setCharacterHeader();
          setCharacterGeneral();
        }
        console.log(data);
      }).catch(error => {
        setCharacterHeader();
        setCharacterGeneral();
      });

      getCharacterData(characterName, 'titles').then(data => {
        if (data.result) {
          setCharacterTitles(data.titles);
        }
        else {
          setCharacterTitles();
        }
        console.log(data.titles);
      }).catch(error => {
        setCharacterTitles();
      });

      getCharacterData(characterName, 'abilities').then(data => {
        if (data.result) {
          setCharacterAbilities(data.abilities);
        }
        else {
          setCharacterAbilities();
        }
        console.log(data.abilities);
      }).catch(error => {
        setCharacterAbilities();
      });

      getCharacterData(characterName, 'property').then(data => {
        if (data.result) {
          setCharacterProperty(data.property);
        }
        else {
          setCharacterProperty();
        }
        console.log(data.property);
      }).catch(error => {
        setCharacterProperty();
      });
    }
  }, [characterName]);

  const callDispatcher = (fieldName, newValue) => {
    characterGeneral[fieldName] = newValue;
    setCharacterGeneral({ ...characterGeneral });
  }

  const getTitlesTabContent = () => {
    return (
      characterTitles.titles.map((title, index) => {
        return (
  
            <EditableField key={index} editing={false}
              field={"user_name"}
              value={title}
              callDispatcher={null}
              description={"Title " + index}
              fieldType={2}
            />

        );
      })
    )
  }

  

  const getPropertyTabContent = () => {
    return (
      <>
        {
          characterProperty.property.map((property, index) => {
            return (
              <Row key={index}>
                <Col xs={3}>
                  <strong>
                    {property.title_id}
                  </strong>
                </Col>
                <Col>
                  <span>
                    {property.title_room}
                  </span>
                </Col>
                <Col xs={3}>
                  <span>
                    {property.title_instance}
                  </span>
                </Col>

              </Row>
            );
          })


        }
      </>

    )
  }


  const getAbilityTabContent = () => {
    return (
      <>
        {
          characterAbilities.abilities.map((ability, index) => {
            return (
              <Row key={index}>
                <Col>
                  <strong>
                    {ability.ability_id}
                  </strong>
                </Col>
                <Col>
                  <span>
                    {ability.ability_proficiency}%
                  </span>

                </Col>

                <Col>
                  <span>
                    {ability.ability_text}
                  </span>

                </Col>
              </Row>
            );
          })


        }
      </>

    )
  }

  const getGeneralTabContent = () => {
    return (
      <>
        <EditableField editing={false}
          field={"user_name"}
          value={characterHeader.user_id}
          callDispatcher={callDispatcher}
          description="Character Name"
          fieldType={2}
        />

        <EditableField editing={false}
          field={"user_race"}
          value={characterGeneral.user_race}
          callDispatcher={callDispatcher}
          description="Race"
          fieldType={2}
        />

        <EditableField editing={false}
          field={"user_name"}
          value={characterHeader.location}
          callDispatcher={callDispatcher}
          description="Location"
          fieldType={2}
        />

        <EditableField editing={false}
          field={"avatar"}
          value={characterHeader.avatar.avatar}
          callDispatcher={callDispatcher}
          description="Avatar"
          fieldType={12}
        />

        <EditableField editing={false}
          field={"user_name"}
          value={characterGeneral.user_name}
          callDispatcher={callDispatcher}
          description="Short Description"
          fieldType={3}
        />

        <EditableField editing={false}
          field={"user_description"}
          value={characterGeneral.user_description}
          callDispatcher={callDispatcher}
          description="Description"
          fieldType={0}
        />

        <EditableField editing={false}
          field={"user_email"}
          value={characterGeneral.user_email}
          callDispatcher={callDispatcher}
          description="Email"
          fieldType={2}
        />
      </>
    );
  }

  if (characterName === '') {
    return (<h1>Character Maintenance</h1>);
  }
  return (

    <div className="container container-eom" id="divBuilderContent">
      <h1>Character Maintenance for {characterName}</h1>
      <Tabs defaultActiveKey="general" className={"maintabs"}>
        <Tab eventKey="general" title="General">
          {characterGeneral === undefined ? <span>Loading...</span> : getGeneralTabContent()}
        </Tab>
        <Tab eventKey="abilities" title="Abilities">
          {characterAbilities === undefined ? <span>Loading...</span> : getAbilityTabContent()}
        </Tab>
        <Tab eventKey="property" title="Property">
          {characterProperty === undefined ? <span>Loading...</span> : getPropertyTabContent()}
        </Tab>
        <Tab eventKey="titles" title="Titles">
          {characterTitles === undefined ? <span>Loading...</span> : getTitlesTabContent()}
        </Tab>
        <Tab eventKey="inventory" title="Inventory">

        </Tab>
        <Tab eventKey="security" title="Security">
          <CharacterSecurity characterName={characterName}/>
        </Tab>
      </Tabs>
    </div>
  );
}

export default AdminCharacterData;