import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card, FormCheck } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';

function KinkEntry(props) {

    const [record, setRecord] = useState();

    useEffect(() => {
        //console.log(props.record);
        if (props.record === undefined) {
            let newRecord = {};
            newRecord.kink_id = kink.id;
            newRecord.kink_giving = 0;
            newRecord.kink_receiving = 0;
            newRecord.kink_count = 0;
            newRecord.kink_deleted = false;
            newRecord.kink_privacy = "";
            newRecord.modified = false;
            setRecord(newRecord);
        } else
            setRecord(props.record);
    }, [props.record]);

    useEffect(() => {
        if ((record!==undefined)&&(record.modified === true)) {
            //console.log('Updating Kink', record.kink_id);
            const delayDebounceFn = setTimeout(() => {
                props.updateKink(record);
            }, 500)

            return () => clearTimeout(delayDebounceFn);
        }
    }, [record]);

    const updateLocalKink = (field, value) => {
        record[field] = value;
        record.modified=true;
        if((record.kink_giving===0)&&(record.kink_receiving===0)){
            record.kink_deleted = true;
        } else
            record.kink_deleted = false;
        setRecord({...record});
    }

    const updateKinkGiving = (value) => {
        //console.log('Giving set to', value);
        updateLocalKink('kink_giving', value);
    }

    const updateKinkReceiving = (value) => {
        //console.log('Receiving set to', value);
        updateLocalKink('kink_receiving', value);
    }

    const getRating = (value) => {
        switch (value) {
            case 0:
                return "Unset";
            case 1:
                return "Freezing";
            case 2:
                return "Cold";
            case 3:
                return "Neutral";
            case 4:
                return "Warm";
            case 5:
                return "Hot";
            case 6:
                return "Sizzling";
            default:
                return "Unset";
        }
    }

    let kink = props.kink;
    let variant = 'primary';
    let giving=0;
    let    receiving=0;
    if(record!==undefined) {
        giving=record.kink_giving;
        receiving=record.kink_receiving;      
    }

    return (
        <Row>
            <Col xs={4} m={2} l={1}>{kink.title}</Col>
            <Col xs={1}>
                {getRating(giving)}
            </Col>
            <Col xs={3} m={3} l={2}>
                <RangeSlider
                    min={0}
                    max={6}
                    value={giving}
                    onChange={e => updateKinkGiving(e.target.valueAsNumber)}
                    variant={variant}
                />
            </Col>
            <Col xs={3} m={3} l={2}>
                <RangeSlider
                    min={0}
                    max={6}
                    value={receiving}
                    onChange={e => updateKinkReceiving(e.target.valueAsNumber)}
                    variant={variant}
                />
            </Col>
            <Col xs={1}>
                {getRating(receiving)}
            </Col>
        </Row>
    );
}

export default KinkEntry;