import React, { Component } from 'react';

class IntroGettingHelp extends Component {

  render() {
    return (
      <div className="container" id="divMainContent">
        <br/>
        <h1>Getting Help</h1>
        <br/>

			  <p>There are a number of ways of getting help in game the first and foremost is the NEWBIE channel, but there are also extensive help files in game, guides here and just asking those around you!</p>
			
			  <h3>The NEWBIE channel</h3>
			  <p>They say that there are no such thing as stupid questions and this is the place to put that to the test - the people listening on this channel and who respond are there to help you answer any questions youmight have, no matter how trivial you might feel they are!</p>
        
        <h3>Help Files</h3>
        <p>The game has extensive help files, these can be accessed through the <strong>help</strong> command.</p>
        <div className="row fluid">
         	<div className="col-md-4">
            <p><strong>HELP RACE [RACENAME]</strong></p>
            <p>You can get specific help on a race by adding the word RACE after help.</p>
      		</div>
       		<div className="col-md-8">
       		<pre width="60">{`Name:        Sable
Description: Sables are humanoid bunnies.  They are shorter than humans with their
ears often adding much to their height. Some times, they look just like humans, 
except with bunny ears.
Age:         Infant: 0 to 1, Toddler: 1 to 3, Child: 3 to 15, Young adult: 15 to 35, 
             Adult: 35 to 53, Mature: 53 to 70, Old: 70 to 74, Venerable: 74 to 78, 
             Ancient: 78+
Height:      59 to 71 inches.
Weight:      80 to 160 pounds.
Stats:       dexterity+2, constitution+1, charisma+3, max dexterity adj.+2, max constitution 
             adj.+1 and max charisma adj.+3.
Mitigation:  None.
Senses:      darkvision.
Abilities:   Hide, Sneak, Prey's Instinct and Trained Olfactory Senses.
Availability:Fantasy
Starting EQ: a delicate green shirt, a pair of sandals and some delicate leggings. 
        		`}</pre>
        		</div>
        	</div>
        	<div className="row fluid">
            	<div className="col-md-4">
        			<p><strong>HELP AREA [AREANAME]</strong></p>
        			<p>You can get specific help on an area by adding the word AREA after help.</p>
        		</div>
            	<div className="col-md-8">
        			<pre>{`
Shou Lung is an ancient city that has been part of Neulandra for a very long 
time. The city was attacked repeatedly by the Hafran nation when it was 
governed by a non-human, though the attacks quelled once Tyler was annointed as 
mayor. 
Since the Hafran war's final battle, Hyp has provided leadership to this quaint 
city. Shou Lung is also home of the martial artist trainer and several dojos. 
(OOC: Strong Asian theme)
Number of rooms: 187
Region         : Shou Lung, Neulandra, Midnight
Population     : 125
Currency       : Waygate
Climate        : Cool Temperate
Level range    : 0 to 640
Average level  : 80
Median level   : 15
Avg. alignment : pure neutral
Med. alignment : pure neutral`}</pre>
				</div>
			</div>

        	<div className="row fluid">
            	<div className="col-md-4">
        			<p><strong>HELP RECIPE [RECIPE]</strong></p>
        			<p>You can get specific help on crafting recipe by adding the word RECIPE after help.</p>
        		</div>
        		<div className="col-md-8">
        			<pre>{`Product:       a cloth hakama
Description:   A Shou lungese style outer garment made from cloth. It is a
               baggy pants-like garment, tied about one's waist, lending
               proper gait and posture. Traditionally it has seven pleats,
               five on the front, two at the back, representing the seven
               virtues of the warrior.
Award:         185
Difficulty:    Red
Makes:         1.0
Clothing Type: Armour
Wears on:      Waist, Legs, Bottom, Groin, Lower legs, Upper legs
Ingredients:   Bolt of cloth (17)
               Clothing detail* (1)
               Enchantment* (1)
               Quality boost* (1)
               Thread (4)
Processes:     Measuring (12)
               Cutting (12)
               Sewing (13)
Tool:          sewing box
`}</pre>
        		</div>
        	</div>
        	
        	<h3>The website</h3>
        	<p>Here on the website there are numerous guides to help you get started! </p>
			<h3>The Grey Archives</h3>    
			<a href="http://www.greyarchive.info/neox/index.php?board=51.0">The Grey Archives</a> has a section dedicated the edge of midnight, once registered you can navigate to this board 
			<h3>Asking Staff</h3>
			<p>In general staff (Immortals, characters ranked level 400+) are there to keep the mud running smoothly and amicably and they are often a good source of help, particularly if you are having problems!</p>
			<p><strong>However</strong> they are also often working and can be busy people, so do be patient and polite!</p>
			<h3>Asking other players</h3>
			<p>Players you are interacting with may also be willing to help you, but please do not disrupt any ongoing roleplay and if people are enaged in a scene and are staying <strong>in character</strong> please do repsect this.</p>



      </div>
    );
  }
}

export default IntroGettingHelp;