import React, { Component } from 'react';
import { Card, Accordion } from 'react-bootstrap';

class IntroSetup extends Component {

	render() {
		return (
			<div className="container" id="divMainContent">
				<br />
				<h1>Getting Setup</h1>
				<p>Once you are logged in there are a number of useful commands that can help personalise the mudding experience or help you find the detail you need and this page will cover off these.</p>
				<p>It will deal primarily with viewing information and how it is presented to you, character creation and getting your character set-up for roleplay are covered elsewhere!</p>

				<h2>The Prompt</h2>
				<p>Your prompt is what you see whenever MUD is awaiting a command and will, if enabled, be displayed after fresh updates have been displayed.</p>
				<Accordion defaultActiveKey={[]} alwaysOpen >
					<Accordion.Item eventKey={'0'} className={"accordion-body"}>
						<Accordion.Header>
							<div className="alert alert-primary alert-nm">
								Click here to see the valid prompt codes
							</div>

						</Accordion.Header>
						<Accordion.Body>
							<ul>
								<li>%h  :  Display your current hits</li>
								<li>%H  :  Display your maximum hits</li>
								<li>%m  :  Display your current mana</li>
								<li>%M  :  Display your maximum mana</li>
								<li>%v  :  Display your current moves</li>
								<li>%V  :  Display your maximum moves</li>
								<li>%x  :  Display your current experience</li>
								<li>%X  :  Display your needed experience</li>
								<li>%G  :  Display the amount and name of your money</li>
								<li>%g  :  Display the value of your money</li>
								<li>%a  :  Display your alignment in numbers</li>
								<li>%A  :  Display your alignment as text</li>
								<li>%w  :  Display your weight encumbrance</li>
								<li>%W  :  Display the maximum weight you can carry</li>
								<li>%c  :  Display your item encumbrance</li>
								<li>%C  :  Display the maximum items you can carry</li>
								<li>%r  :  Display the room name you are in</li>
								<li>%R  :  Display the room number you are in (ARCHON ONLY)</li>
								<li>%z  :  Display the area name you are in (ARCHON ONLY)</li>
								<li>%e  :  Display name of person under attack</li>
								<li>%E  :  Display state of person under attack</li>
								<li>%I  :  Display hidden/invisibility status</li>
								<li>%N  :  Display Noble status (NOBLE ONLY)</li>
								<li>%s  :  Ticks till burning under the sun</li>
								<li>%b  :  Blood recently drunk</li>
								<li>%B  :  Go to a new line</li>
								<li>%d  :  Display range to the person you are fighting</li>
								<li>%t  :  Display the time of day</li>
								<li>%T  :  Display the hour of the day</li>
								<li>%@  :  Display the weather where you are</li>
								<li>%Kh :  Display the tanks current hits</li>
								<li>%KH :  Display the tanks maximum hits</li>
								<li>%Km :  Display the tanks current mana</li>
								<li>%KM :  Display the tanks maximum mana</li>
								<li>%Kv :  Display the tanks current moves</li>
								<li>%KV :  Display the tanks maximum moves</li>
								<li>%Ke :  Display the tanks name</li>
								<li>%KE :  Display the tanks health condition</li>
								<li>%F  :  Display the forum statuses (Will appear even with noprompt)</li>
								<li>%f  :  Display the forum statuses</li>
								<li>%p  :  Professions TNL</li>
								<li>%P  :  Professions TNL (long)</li>
								<li>%o  :  Flags if there is any OOC chatter you have missed </li>
								<li>%O  :  Flags if there is any OOC chatter you have missed even with NOPROMPT.</li>
								<li>%l  :  Gives the current crafting progress precentage.</li>
								<li>%L  :  Gives details of the current crafting process.</li>
								<li>^h :  Standard hit point color</li>
								<li>^m :  Standard mana color</li>
								<li>^v :  Standard movement color</li>
								<li>^N :  Normal color</li>
								<li>^w :  White</li>
								<li>^g :  Light green</li>
								<li>^b :  Light blue</li>
								<li>^r :  Light red</li>
								<li>^y :  Yellow</li>
								<li>^c :  Cyan</li>
								<li>^p :  Light purple</li>
								<li>^W :  Grey</li>
								<li>^k :  Dark Grey</li>
								<li>^G :  Dark Green</li>
								<li>^B :  Dark Blue</li>
								<li>^R :  Maroon</li>
								<li>^Y :  Dark yellow</li>
								<li>^C :  Dark Cyan</li>
								<li>^P :  Dark Purple</li>
								<li>^? :  Previous color/End color</li>
							</ul>


						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<div className="row fluid">
					<div className="col-md-4">
						<p>To<strong> view</strong> your current prompt configuration enter the <strong>PROMPT</strong> with no additional parameters.</p>
					</div>
					<div className="col-md-8">
						<pre>{`Your prompt is currently set at:
^N%E&lt;^h%hhp ^m%mm ^v%vmv^N&gt;
                	`}</pre>
					</div>
				</div>

				<br />
				<div className="row fluid">
					<div className="col-md-4">
						<p>To<strong> configure</strong> your prompt enter the <strong>PROMPT</strong> with with the codes you wish to see. For example, to add your characters name to the prompt so you can see which screen you are in</p>
					</div>
					<div className="col-md-8">
						<pre width="80">{`<hp 369m 654mv>Change your prompt to: <MyCharacter %hhp %mm %vmv>, are you sure (Y/n)?
Your prompt is currently now set at:
^N%E<MyCharacter ^h%hhp ^m%mm ^v%vmv^N>;

<MyCharacter 711hp 369m 654mv>  `}
						</pre>
					</div>
				</div>

				<p>When <strong>roleplaying</strong> the prompt can be intrusive or unnecessary to switch it off enter the command <strong>NOPROMPT</strong> to switch it on again, simply add the command <strong>PROMPT</strong></p>

				<h2>The Score Sheet</h2>
				<p>The score sheet in the edge of midnight is designed to give a flexible  view of your characters state- allowing you to tailor what you see to a degree.</p>
				<br />
				<div className="row fluid">
					<div className="col-md-4">
						<br />
						<p>The command on its own will display your saved configuration</p>
						<p><strong>SCORE FULL</strong> will display the full view of the available data.
						</p><p><strong>SCORE CONFIG</strong> will put you into a menu system where you can chose what you want to see and in what order you want to see it</p>
						<p>You can also use PERSONAL CLASS STATS EXPERIENCE FINANCE FLAGS AFFECTS BAR as parameters.</p>
					</div>
					<div className="col-md-8">
						<pre width="80">{`o--------------------------------EoM v4.10.12--------------------------------o
| Name: Bethany                                   Gender: Female             |
| Race: Half Elf          Height: 5' 0" tall.     Weight: 110lbs.            |
| Level: 3/64             Class: Dancer           Alignment: pure neutral.   |
| Age: 20 years                                                              |
| Birthday: Ventil, the 15th day of Turning, Adamantium 58                   |
o----------------------------------------------------------------------------o
| You also have levels in:                                                   |
| Bard (61)                                                                  |
o----------------------------------------------------------------------------o
| Str:  22/ 23       Int:  18/ 19     Fire :    4       Cold :    4          |
| Dex:  20/ 20       Wis:  18/ 18     Water:    4       Para :    4          |
| Con:  10/ 18       Cha:  27/ 24     Dise :    2       Magic:    4          |
|                                     Pois :    2       Acid :    4          |
| Hit points: 711/711                 Gas  :    4       Dead :    2          |
| Mana:       369/369                 Elec :    4       Mind :    5          |
| Movement:   654/654                                                        |
| TPS:        0/6290                  Ambidexterity:  0%                     |
| Initiative: 1                       Sense of Smell: Poor                   |
|                                                                            |
| Armored defence: vulnerable                                                |
| Combat prowess:  almost skilled (290) x 1                                  |
| Combat damage:   grazing(10)                                               |
o----------------------------------------------------------------------------o
| Parry: 0                Dodge: 0                Block: 0                   |
o----------------------------------------------------------------------------o
| Experience: 205362      XP2LVL: 1738            Role Playing: 9970         |
| Trains: 5               Practices: 97           Quest Points: 0            |
| RP Level: 61            Remort: 0               Last Remort:  Never        |
| Online: 39 hours        Respec Points: 0                                   |
o----------------------------------------------------------------------------o
| You are at 0% of your Total Point Allowance.                               |
| You are carrying 169/250 items weighing 189lbs 13ozs/736lbs.               |
| Money Carried:                                                             |
| 1 glass bead, 2 shiny pebbles, 1 carrot, 3 skins, 1                        |
| feather, 2 yarn balls, 4 plushie cthulhus (42871)                          |
o----------------------------------------------------------------------------o
| Wimpy: 269 hit points.  You are standing.                                  |
| You are thirsty.        You are roleplaying.                               |
o----------------------------------------------------------------------------o
| (ElfSight)                                                                 |
o----------------------------------------------------------------------------o`}
						</pre>
					</div>
				</div>
				<div className="row fluid">
					<div className="col-md-4">
						<p>To <strong>configure</strong> the score sheet enter the command SCORE CONIFG</p>
					</div>
					<div className="col-md-8">
						<pre>{`Score configuration, select option to enable/disable.

1) PERSONAL          ON  (1)
2) CLASS             ON  (3)
3) STATS             ON  (4)
4) NATURAL          
5) EXPERIENCE        ON  (6)
6) FINANCE           ON  (7)
7) FLAGS             ON  (8)
8) AFFECTS           ON  (9)
9) PROFESSIONS      
10) BAR             
11) PDB              ON  (5)
12) SPECIAL          ON  (2)
13) Compact mode    
14) Line character  _ 

Enter a selection: (Return to exit)
					`}</pre>
					</div>
				</div>
				<div className="row fluid">
					<div className="col-md-4">

						<p>Entering a selection gives you the option to switch it on or off or to change the order in which it is displayed.</p>
						<p>You can also enter a section as a parameter to the command to view only that for example <strong>SCORE FLAGS</strong></p>
					</div>
					<div className="col-md-8">
						<pre>{`o--------------------------------EoM v4.10.12--------------------------------o
| Wimpy: 269 hit points.  You are standing.                                  |
| You are thirsty.        You are roleplaying.                               |
o----------------------------------------------------------------------------o`}</pre>
					</div>
				</div>
				<br />
				<h2>Colours</h2>

				<p>As covered briefly in the character creation the Edge of Midnight supports several different colour modes.</p>
				<p>To <strong>switch colour off</strong> enter the command <strong>NOCOLOUR</strong></p>
				<p>To <strong>switch colour on</strong> enter the command <strong>COLOUR</strong> or <strong>ANSI</strong></p>
				<p>To <strong>change the colour mode</strong> enter the command <strong>XCOLOUR</strong>. The following paramters are valid:</p>
				<ul>
					<li>ANSI  - ANSI returns you to the default coffeemud setting of 16 colours. </li>
					<li>MXP   - MXP uses the MXP protocol to set the colours.</li>
					<li>XTERM - XTERM uses a wide range of the XTERM colour palette and is the <strong>recommended</strong> option if your client suports it</li>
				</ul>
				<br />
				<div className="alert alert-danger">
					<a href="/editor" className="eomLink">The Colour Editor Tool</a>
				</div>
			</div>
		);
	}
}

export default IntroSetup;