var Config = {};

Config.mud = {};
Config.mud.salt='SALTYPIRATE';
Config.mud.port=8010;
Config.mud.secure={}
Config.mud.secure.port=8011;
Config.mud.discord='discord.gg/Gppu8ZP';

Config.name='The Edge of Midnight';
Config.name1='Edge';
Config.name2='of';
Config.name3='Midnight';

Config.middleware = {};
Config.middleware.host='thedeepvale.ddns.net';
Config.middleware.port=5558;
Config.middleware.secret='creampie';

Config.port = 8010;
Config.host = 'thedeepvale.ddns.net';
//Config.connectionString = 'wss://edgeofmidnight.org:8030';
Config.connectionString = 'wss://thedeepvale.ddns.net:27744';
//config.connectionString = 'ws://localhost:27744';

module.exports = Config;
