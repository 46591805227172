import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import ResourceStylingEntry, { getAdverbOrders } from './ResourceStylingEntry';
import { getStylingDictionary, updateStylingDictionary } from '../../components/ResourceRequests';

function ResourceStylingManager() {
    const [dictionary, setDictionary] = useState([]);
    const [currentEntry, setCurrentEntry] = useState(undefined);
    const [filter, setFilter] = useState([]);
    const [adjectiveOrder, setAdjectiveOrder] = useState('');

    const [editing, setEditing] = useState(true);

    useEffect(() => {
        getStylingDictionary().then(result => {
            result.dictionary.sort((a, b) => a.word.localeCompare(b.word));
            setDictionary(result.dictionary);
        }).catch(error => {
            setDictionary([]);
        });
    }, []);

    const selectEntry = (word) => {
        const i = dictionary.findIndex(entry => entry.word === word);
        setCurrentEntry(i);
        window.scrollTo(0, 0);
    }

    const callDispatcher = (fieldName, newValue, index, actionIndex) => {
        if (actionIndex === undefined) {
            dictionary[index][fieldName] = newValue;
        } else {
            if (fieldName === 'slots') {
                dictionary[index].actions[actionIndex][fieldName] = newValue.toUpperCase().split(',');
            } else
                dictionary[index].actions[actionIndex][fieldName] = newValue;
            console.log(dictionary[index].actions[actionIndex][fieldName]);

        }
        setDictionary([...dictionary])
    }

    const getEntry = (index) => {
        if (index === undefined)
            return null;

        return (
            <ResourceStylingEntry entry={dictionary[index]} dispatcher={callDispatcher} entryId={index}
                deleteEntry={deleteEntry}
                addAction={addAction}
                deleteAction={deleteAction}
            />
        );
    }

    const refresh = () => {
        getStylingDictionary().then(result => {
            result.dictionary.sort((a, b) => a.word.localeCompare(b.word));
            setDictionary(result.dictionary);
        }).catch(error => {
            console.log(error);
            setDictionary([]);
        });
    }

    const deleteEntry = (index) => {
        dictionary.splice(index, 1);
        setDictionary([...dictionary]);
    }

    const addAction = () => {
        let newAction = {};
        newAction.action = "";
        newAction.slots = "";
        dictionary[currentEntry].actions.push(newAction);
        setDictionary([...dictionary]);
    }

    const deleteAction = (index) => {
        dictionary[currentEntry].actions.splice(index, 1);
        setDictionary([...dictionary]);
    }


    const create = () => {
        let newEntry = {};
        newEntry.word = 'xxx';
        newEntry.tyoe = 'ALL';
        newEntry.cost = '25';
        newEntry.level = '1';
        newEntry.actions = [];
        let max = dictionary.push(newEntry);
        setCurrentEntry(max - 1);
        setDictionary([...dictionary]);
    }

    const save = () => {
        updateStylingDictionary(dictionary).then(result => {
            console.log(result);
            result.dictionary.sort((a, b) => a.word.localeCompare(b.word));
            setDictionary(result.dictionary);
        }).catch(error => {
            console.log(error);
            setDictionary([]);
        });
    }

    const filterEntry = (entry) => {
        let okay = entry.word.indexOf(filter) > -1;
        if(okay&&adjectiveOrder!=='') {
            okay = entry.order === adjectiveOrder;             
        }
        return okay;
    };

    return (
        <div className="container-eom" id="divMainContent">
            <h2>Styling Dictionary Editor</h2>
            <Row>

                <Col xs={3}>
                    <Button className={"btn-themed-dark m-1"} onClick={refresh}>Refresh</Button>
                    <Button className={"btn-themed-dark m-1"} onClick={create}>New</Button>
                    <Button className={"btn-themed-dark m-1"} onClick={save}>Save Changes</Button>

                    <div className="input-group mb-3">
                        <div className="input-group-prepend" >
                            <button className="btn btn-themed-dark" type="submit">Filter</button>
                        </div>
                        <input type="text" className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                    </div>

                    <Dropdown className={"my-1"}>
                        <Dropdown.Toggle variant="editor" id="dropdown-basic">
                            {adjectiveOrder}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={"dropdown-menu"}>
                            {getAdverbOrders().map((choice, index) =>
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => setAdjectiveOrder(choice)}>
                                    {choice}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>

                    {dictionary.filter(entry => filterEntry(entry)).map((entry, index) =>
                        <div key={index}>
                            <span onClick={() => (selectEntry(entry.word))}>{entry.word}</span>
                        </div>
                    )}
                </Col>
                <Col>
                    {getEntry(currentEntry)}
                </Col>
            </Row>
        </div>
    );
}

export default ResourceStylingManager;
