import React, { useState, useEffect } from 'react';

import {listDeities} from './../components';


const getJumboClassName = (gender) => {
  var classString = "jumbotron deity-" + gender.toLowerCase();
  return classString;
}

function RealmDeities() {

  const [deityList, setDeityList] = useState([]);

  const getDeityList = async () => {
    const data = await listDeities();
    console.log(data);
    if(data.result) {
      setDeityList(data.deities);
    } else {
      setDeityList([]);
    }
  }

  useEffect(() => {
    getDeityList();
  }, []);

  return (
    <div className="container" id="divMainContent">
      <br />
      <div className='row justify-content-between'>
        <h1>Deities of the Realm</h1>
      </div>
      <br />
      <ul>
        {deityList.map(deity =>
        <div key = {deity.deity_name} className={getJumboClassName(deity.deity_gender)}>
          <h1 className="display-4">{deity.deity_name}</h1>
          <p className="lead">{deity.deity_description}</p>
          <hr className="my-4"/>
            <p>{deity.deity_requirements}</p>
            <p>{deity.deity_cleric}</p>
        </div>

        )}
      </ul>
    </div>
  );
}

export default RealmDeities;