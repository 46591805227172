import React, { useState, useEffect } from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { listPlayerMessages, deleteMessage, markMessageAsRead, sendNewMessage } from '../components/messageApi';
import { useAuthDispatch } from './../components/index';
import ColouredText from '../components/ColouredText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PlayerMessages() {
  const [playerMessages, setPlayerMessages] = useState([]);
  const [activeMessage, setActiveMessage] = useState('');
  const [previewColour, setPreviewColour] = useState(false);

  const [composeTo, setComposeTo] = useState('');
  const [composeSubject, setComposeSubject] = useState('');
  const [composeBody, setComposeBody] = useState('');
  const [composing, setComposing] = useState(false);

  const cancelComposingClick = e => {
    setComposing(false);
  }

  const dispatch = useAuthDispatch();

  const handleVisibilityChange = e => {
    setPreviewColour(!previewColour);
  }

  const handleChange = e => {
    let text = e.target.value;
    let id = e.target.id;
    //console.log(id + '/' + text);
    if (id === undefined) {
      return;
    } else {
      switch (id) {
        case 'composeTo':
          setComposeTo(text);
          break;
        case 'composeSubject':
          setComposeSubject(text);
          break;
        case 'composeBody':
          setComposeBody(text);
          break;
      }
    }
  }

  const sendMessageClick = async e => {
    // console.log(composeTo);
    // console.log(composeSubject);
    // console.log(composeBody);

    const data = await sendNewMessage(composeTo, composeSubject, composeBody);
    // console.log(data);
    if (data.result) {
      getPlayerMessages();
      setComposeTo('');
      setComposeSubject('');
      setComposeBody('');
      setComposing(false);
    }
  }

  const deleteCurrentMessage = async (e) => {
    const message = playerMessages.find((amessage) => amessage.msgid === activeMessage);
    const data = await deleteMessage(message.msgkey);
    if (data.result) {
      setActiveMessage("");
      getPlayerMessages();
    }
  }

  const composeNewClick = async (e) => {
    setComposing(true);
  }

  const replyToCurrentMessage = async (e) => {
    const message = playerMessages.find((amessage) => amessage.msgid === activeMessage);
    setComposeTo(message.from);
    setComposeSubject('Re: ' + message.subject);
    setComposeBody('');
    setComposing(true);
  }

  const loadMessageDetail = e => {
    const newMessageId = e.target.getAttribute('name');
    setComposing(false);
    setActiveMessage(newMessageId);
    const message = playerMessages.find((amessage) => amessage.msgid === newMessageId);
    if ((message !== undefined) && (message.senttoplayer)) {
      //console.log('Mark Message As Read');
      markMessageAsRead(dispatch, message.msgkey);
      message.views++;
    }
  }

  const getPlayerMessages = async () => {
    const data = await listPlayerMessages();

    setPlayerMessages(data.messages);
  }

  useEffect(() => {
    getPlayerMessages();
  }, []);


  var message = [];
  if (playerMessages !== undefined)
    message = playerMessages.find((amessage) => amessage.msgid === activeMessage);
  //console.log(message);
  return (


    <div className="px-5 box flex">
      <Row className={'flex p-2 justify-content-between align-items-center '}>
        <Col>
          <h1>Player Messages</h1>
        </Col>
        <Col xs={3}>
          <Button className={"px-3 mb-3 mx-3 btn-themed-dark float-right"} onClick={composeNewClick}>Compose New Message</Button>
        </Col>
      </Row>

      <Row className={"row.content"}>
        <Col className="message-col">
          <ul>
            {playerMessages.map(message =>

              <Alert variant={message.senttoplayer ? "info" : "warning"} onClick={loadMessageDetail} name={message.msgid} key={message.msgid}>
                {message.senttoplayer
                  ? (<> <strong>From </strong>{message.from}</>)
                  : (<><strong>Sent To </strong> {message.to}</>)}
                <strong> on </strong>
                {message.date}{((message.views === 0) && message.senttoplayer) ? <span> (Unread) </span> : null}
                <br />
                {message.subject}

              </Alert>)}
          </ul>
        </Col>
        <Col className={"container"}>
          {composing ?
            (<div>
              <strong className="ml-3">To: </strong>
              <textarea className="eomEditor eom-box pz-2" id="composeTo" rows="1" onChange={handleChange} value={composeTo} />
              <strong className="ml-3">Subject: </strong>
              <textarea className="eomEditor eom-box pz-2" id="composeSubject" rows="1" onChange={handleChange} value={composeSubject} />
              <strong className="ml-3">Message: </strong> <FontAwesomeIcon onClick={handleVisibilityChange} className="fa fa-trash fa-lg" icon={previewColour ? "eye-slash" : "eye"} color="red" />
              <textarea className="eomEditor eom-box pz-2" id="composeBody" rows="3" onChange={handleChange} value={composeBody} />
              {previewColour ? (

                <div className="eom-box border pz-3 mz-3">
                  <font color="white">Preview of Message with colour</font><br /><ColouredText className={"textarea eom-box"} ColouredText={composeBody} />
                </div>
              ) : null}
              <Button className={"px-3 mb-3 mx-3 btn-themed-dark"} onClick={sendMessageClick}>Send</Button>
              <Button className={"px-3 mb-3 mx-3 btn-themed-dark"} onClick={cancelComposingClick}>Cancel</Button>
            </div>)
            :
            activeMessage.length > 0 ? (
              <>
                {message.senttoplayer
                  ? (<><strong className="ml-3">From </strong>{message.from}</>)
                  : (<><strong className="ml-3">To </strong>{message.to}</>)}
                <strong> on </strong> {message.date}
                <br />
                <strong className="ml-3">Subject: </strong> {message.subject}
                <br />

                <ColouredText className={"textarea eom-box"} ColouredText={message.body} />
                <Button onClick={deleteCurrentMessage} className={"px-3 mb-3 mx-3 btn-themed-dark"}>Delete Me</Button>
                <Button onClick={replyToCurrentMessage} className={"px-3 mb-3 mx-3 btn-themed-dark"}>Reply</Button>
              </>
            ) : (null)}
        </Col>

      </Row>

    </div>
  );
}

export default PlayerMessages;