import React, { useState, useEffect } from 'react';
import { Row , Col} from 'react-bootstrap';

import { listRaces, getRaceData } from './../components';

function GameplayRaces() {
    const [raceList, setRaceList] = useState([]);
    const [raceData, setRaceData] = useState({});

    const loadRaceData = async(e) => {
        let raceId = e.target.getAttribute('name');
        const data = await getRaceData(raceId);
        if (data.result) {
            setRaceData(data);
        } else {
            setRaceData({});
        }
    }   

    const getRaceList = async () => {
        const data = await listRaces();
        if (data.result) {
            setRaceList(data.races);
        } else {
            setRaceList([]);
        }
    }

    useEffect(() => {
        getRaceList();
    }, []);

    return (
        <div className="container" id="divMainContent">
        <Row>
            <Col>
            <br />
            <div className='row justify-content-between'>
                <h1>Races of the Realm</h1>
            </div>
            <br />
            
            </Col>
        </Row>
        <Row>
            <Col xs="3">
            <ul>
                {raceList.map(race =>
                    <div key={race.race_id} onClick={loadRaceData}>
                        <h4 name={race.race_id} >{race.race_name} </h4>
                    </div>
                )}
            </ul>
            </Col>

            <Col>
            <div>
            {raceData.name!==undefined
            ?(
                <>
                <h1>{raceData.name}</h1>  
                <strong>Description</strong> 
                <p>{raceData.description}</p> 

                <strong>Age Ranges</strong> 
                <p>{raceData.age}</p> 

                <strong>Height</strong><small> (inches)</small> 
                <p>{raceData.heightf} (female) {raceData.heightm} (male) </p>

                <strong>Weight</strong><small> (pounds)</small>  
                <p>{raceData.weight}</p>

                <strong>Stat Adjusters</strong>
                <p>{raceData.stats}</p>

                <strong>Mitigation</strong>
                <p>{raceData.mitigation}</p>
                
                <strong>Abilities</strong>
                <p>{raceData.abilities}</p>
                
                <strong>Starting Gear</strong>
                <p>{raceData.equipment}</p>

                </>
            )
            :null}
            
            </div>
            </Col>
        </Row>

  



    
            
        </div>
    );
}

export default GameplayRaces;