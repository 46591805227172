import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import { useClientDispatch, useClientContext } from './ClientProvider';

function UserInput(props) {

    let layout = props.layout;
    let onTouchStart = props.onTouchStart;
    let onTouchEnd = props.onTouchEnd;
    let onTouchMove = props.onTouchMove;

    const clientDispatch = useClientDispatch();

    const { message } = useClientContext();

    const [messageHistory, setMessageHistory] = useState([]);

    const [status, setStatus] = useState('');
    const [cursor, setCursor] = useState(0);

    const setMessage = async (text) => {
        clientDispatch({ type: "SET_MESSAGE", payload: text });
    }

    const onKeyDownEvent = async e => {
        if (e.key === 'Enter') {
            e.preventDefault()
            props.onSubmitMessage(message);
            if (props.clearInput)
                setMessage('');
            else
                e.target.select();
            updateMessageHistory(message);
        }
    }

    const updateMessageHistory = (newMessage) => {
        var newHistory = [...messageHistory, newMessage];
        if (newHistory.length > MAXHISTORY) {
            newHistory = newHistory.slice(1);
        }
        setMessageHistory(newHistory);
    }
    const storeDraft = async (e) => {
        updateMessageHistory(message);
    }

    const recallHistory = async () => {
        console.table(messageHistory);
        console.log("history", cursor);

        setMessage(messageHistory[cursor]);
        const newCursor = cursor - 1;
        if (newCursor >= 0) {
            setCursor(newCursor);
        } else
            setCursor(messageHistory.length - 1);
    }

    const MAXHISTORY = 20;

    const onKeyUpEvent = async e => {
        if (e.keyCode === 38 && e.ctrlKey) {
            if (cursor >= 0) {
                setMessage(messageHistory[cursor]);
                const newCursor = cursor - 1;
                setCursor(newCursor);
            }
        }
        else if (e.keyCode === 40 && e.ctrlKey) {
            if (cursor < messageHistory.length) {
                setMessage(messageHistory[cursor]);
                const newCursor = cursor + 1;
                setCursor(newCursor);

            }
        } else {
            let characterCount = message.length;
            let wordCount = message.split(' ').length;
            if (characterCount === 0)
                wordCount = 0;
            let statusMessage = "Word Count: " + wordCount + " from " + characterCount + " characters.";
            setStatus(statusMessage);
        }
    }


    useEffect(() => {
        setCursor(messageHistory.length - 1);
    }, [messageHistory]);

    return (
        <>
            <Form
                className={"input-form"}
                action="."
                onSubmit={e => {
                    e.preventDefault()
                    props.onSubmitMessage(message)
                    setMessage('')
                }}
            >
                <InputGroup className={"input-group-eom"}>

                    <textarea rows={5} className={layout + " userinput"}
                        autoFocus
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        onKeyDown={onKeyDownEvent}
                        onKeyUp={onKeyUpEvent}
                        placeholder={'Enter emote/command...'}
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                        onTouchMove={onTouchMove}
                    />
                    {layout === "" ?
                        <>
                            <Button className={"userinput-btn"} variant="" type="submit">
                                Send
                            </Button>

                            <Col className={"userinput-col"}>
                                <Button className={"userinput-col-btn"} variant="" type="button" onClick={storeDraft}>
                                    Draft
                                </Button>
                                <Button className={"userinput-col-btn"} variant="" type="button" onClick={recallHistory}>
                                    Recall
                                </Button>
                            </Col>
                        </>
                        : null}
                </InputGroup>
                <span className={"inputStatus"}>{status}</span>
                {layout !== "" ?
                    <Row className={"ml-3"}>
                        <Button className={"m-1"} variant="" type="submit">
                            Send
                        </Button>
                        <Button className={"m-1"} variant="" type="button" onClick={storeDraft}>
                            Draft
                        </Button>
                    </Row>
                    : null}

            </Form>

        </>
    )
}

export default UserInput;