import React, { useRef, useEffect } from 'react';

import { decodeMessage } from './messageutils';
import Config from '../../config/Config';

import { useClientDispatch, useClientContext } from './ClientProvider';


function AnsibleConnection() {
    const webSocket = useRef(null);
    const { fontSize, fontFamily,renderLineLimit } = useClientContext();
    const clientDispatch = useClientDispatch();
    let count = 0;

    const dispatchMessage = (message) => {
        clientDispatch({ type: "ADD_MESSAGE", payload: message });
    }

    const sendCommandToMud = (thing) => {
        try {
            webSocket.current.send(JSON.stringify(thing));
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        clientDispatch({ type: "SET_CALLBACK", payload: sendCommandToMud });
    }, []);

    useEffect(() => {
        localStorage.setItem('fontFamily', fontFamily);
        localStorage.setItem('fontSize', fontSize);
        localStorage.setItem('renderLineLimit', renderLineLimit);
    }, [fontSize, fontFamily,renderLineLimit]);

    useEffect(() => {
        const sendMessageToScreen = (inMessage) => {
            let msgJson = {};
            msgJson.body = inMessage;
            msgJson.type = 'output';
            msgJson.lineCount = 1;
            count++;
            msgJson.decoded = decodeMessage(msgJson, count);
            dispatchMessage(msgJson);
        }

        webSocket.current = new WebSocket(Config.connectionString);
        webSocket.current.onopen = () => {
        }

        webSocket.current.onclose = () => {
            clientDispatch({ type: "SET_AREADATA", payload: undefined });
            sendMessageToScreen('Disconnected from MUD, refresh page to reconnect.');
        }

        return () => {
            webSocket.current.close();
        }
    }, []);

    useEffect(() => {
        //console.log(webSocket);

        webSocket.current.onmessage = (evt) => {
            let msgJson = JSON.parse(evt.data);
            msgJson.lineCount = 1;
            var ignoremessage = false;
            count++;
            if (msgJson.type === 'output') {
                if (msgJson.body === '\x00') {
                    ignoremessage = true;
                } else {
                    msgJson.decoded = decodeMessage(msgJson, count);
                }
            } else
                ignoremessage = handleMessage(msgJson, count);

            if (!ignoremessage) {
                dispatchMessage(msgJson);
            }
        };

    }, [webSocket])

    const handleGMCP = (eventname, message, count) => {
        var result = true;
        //console.log(eventname);
        switch (eventname) {
            case 'character.login':
                clientDispatch({ type: "SET_CHARACTER", payload: message.character });
                break;
            case 'room.change':
                clientDispatch({ type: "SET_AREADATA", payload: message });
                break;
            case 'room.inhabitants':
                clientDispatch({ type: "SET_ROOMINHABITANTS", payload: message.inhabitants });
                clientDispatch({ type: "SET_ROOMITEMS", payload: message.items });
                break;
            case 'character.health':
                console.log('Character Health event', message);
                break;
            case 'char.vitals':
                clientDispatch({ type: "SET_VITALS", payload: message });
                break;
            case 'comm.channel': {
                count++;
                let channelMessage = { body: message.msg, decoded: '', type: 'channel', isooc: message.isooc };
                channelMessage.decoded = decodeMessage(channelMessage, count);

                clientDispatch({ type: "ADD_CHANNELMESSAGE", payload: channelMessage });
            }
                break;
            case 'comm.tell': {
                count++;
                let tellMessage = { body: message.msg, decoded: '', type: 'channel', isooc: message.isooc };
                tellMessage.decoded = decodeMessage(tellMessage, count);

                clientDispatch({ type: "ADD_CHANNELMESSAGE", payload: tellMessage });
            }
                break;
            default:
                console.log('Unhandled gmcp event', eventname, message);
                result = false;
                break;
        }
        return result;
    }

    const handleMessage = (message, count) => {
        var handled = false;
        switch (message.type) {
            case 'gmcp':
                handled = handleGMCP(message.eventName, message.body, count);
                break;
            case 'ping':
            case 'noop':
                handled = true;
                break;

            default:
                //console.log('Unhandled Message Type', message);
                break;

        }
        return handled;
    }

    return null;
}

export default AnsibleConnection;