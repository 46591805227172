import React, { useReducer } from "react";

let currentUser = localStorage.getItem("currentUser");
// ? JSON.parse(localStorage.getItem("currentUser")).currentUser
// : "";
let token = localStorage.getItem("token");
let avatar = localStorage.getItem("avatar");
let basicinfo = localStorage.getItem("basicinfo");
let roles = localStorage.getItem("roles");
let mudStatus = localStorage.getItem("mudStatus");
let theme = localStorage.getItem("theme");
// ? JSON.parse(localStorage.getItem("token")).auth_token
// : "";

export const initialState = {
  currentUser: "" || currentUser,
  token: "" || token,
  loading: false,
  errorMessage: null,
  showPlayerMenu: false,
  basicinfo: null || basicinfo,
  newMailCount: 0,
  avatar: avatar,
  roles: roles,
  mudStatus: mudStatus,
  toastMessages: [],
  toastCount: 0
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "TOGGLE_MENU":
      return {
        ...initialState,
        showPlayerMenu: !initialState.showPlayerMenu
      };

    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true
      };
    case "LOGIN_SUCCESS":
      //console.log("Setting new Token: " , token, action.payload.auth_token);
      return {
        ...initialState,
        currentUser: action.payload.username,
        token: action.payload.auth_token,
        newMailCount: action.payload.newmessages,
        avatar: action.payload.playerbasic.avatar,
        roles: action.payload.roles,
        loading: false,
        showPlayerMenu: false,
      };
    case "LOGOUT":
      //localStorage.clear();
      return {
        ...initialState,
        currentUser: "",
        token: "",
        avatar: "",
        basicinfo: null,
        roles: [],
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };
    case "PASS_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };
    case "NEW_MESSAGES":
      localStorage.setItem('newMailCount', action.payload.newmessages);
      return {
        ...initialState,
        newMailCount: action.payload.newmessages
      };
    case "NEW_AVATAR":
      //console.log("Setting new Avatar: " + action.payload.avatar);
      return {
        ...initialState,
        avatar: action.payload.avatar
      };
    case "STATUS_CHANGE":
      return {
        ...initialState,
        mudStatus: action.payload
      };
    case "ADD_TOAST_MESSAGE": {
      initialState.toastCount++;
      action.payload.index = initialState.toastCount;
      let newToastMessages = [...initialState.toastMessages, action.payload];
      return {
        ...initialState,
        toastMessages: newToastMessages
      };
    }

    case "DELETE_TOAST_MESSAGE": {
      let toastMessages = initialState.toastMessages.filter(x => x.index !== action.payload);

      return {
        ...initialState,
        toastMessages: toastMessages
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};