import React, { Component, useCallback, useEffect, useState } from 'react';

import { Toast } from "bootstrap";
import { useRef } from "react";
import { useAuthDispatch, useAuthState } from '../components';

const useToast = () => {
    const toastRef = useRef();
    const authDispatch = useAuthDispatch();

    let myToast = toastRef.current;
    let bsToast = Toast.getInstance(myToast);

    var [toast, setToast] = useState(false);

    const { toastMessages } = useAuthState();

    useEffect(() => {
        let myToast = toastRef.current
        let bsToast = new Toast(myToast, { delay: 5000 })

        if (toastMessages.length > 0) {
            //          console.log('Must show!', toastMessages);

            if (toastRef.current !== undefined) {
                bsToast.show();
                //                toast ? bsToast.show() : false
                // myToast.addEventListener('hidden.bs.toast', () => {

                // });
            }
        }
        // else if (toast) {
        //     //            console.log('Must hide!', toastMessages, bsToast);
        //     //        setToast(false);
        //     //       bsToast.hide();
        // }
    },
        [toastMessages]);

    useEffect(() => {

    });

    const addToastMessage = (messageTitle, messageBody) => {
        //console.log("New Message", messageTitle, messageBody);
        setToast(toast => !toast);
        authDispatch({ type: 'ADD_TOAST_MESSAGE', payload: { title: messageTitle, body: messageBody } })
    }

    const showToast = () => {

        let myToast = toastRef.current;
        bsToast = new Toast(myToast, { autohide: true, delay: 5000 });
        bsToast.show();
    };

    const hideToast = () => {
        //console.log('hide', toastRef);

        let myToast = toastRef.current;
        if (myToast !== undefined) {
            //console.log('hide', myToast);
            let bsToast = Toast.getInstance(myToast);
            bsToast.hide();
        }
    };

    return { toastRef, setToast, showToast, hideToast, addToastMessage };
}

export default useToast;