import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';

const Indicator = ({ value, title, iconname }) => {

    let valueTest = parseInt(value);
    let faIconClassName = "fa fa-lg icon-" + iconname;
    let warningColour = "green";
    if (valueTest < 1) {
        warningColour = "red";
    } else if (valueTest < 100) {
        warningColour = "orange";
    } else if (valueTest < 400) {
        warningColour = "yellow";
    }
    return (
        <Col className={"eomIndicator"}>
            <span>{title}</span> <FontAwesomeIcon className={faIconClassName} icon={iconname} color={warningColour} /> 
        </Col>
    )
}

export default Indicator