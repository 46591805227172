import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getMutatorList } from '../../components/ProceduralGenerationRequests';
import ProcGenMutator from './ProcGenMutator';

function ProcGenMutators() {
    const [mutators, setMutators] = useState([]);
    const [filter, setFilter] = useState('');
    const [maskFilter, setMaskFilter] = useState('');

    useEffect(() => {
        console.log('Requesting Mutators');
        populateMutators();
    }, []);

    const populateMutators = () => {
        getMutatorList().then(data => {
            console.log(data);
            setMutators(data.mutators);

        }).catch(error => {
            console.log(error);
        });
    }

    const showFilters = () => {
        return (
            <Row>
                <Col xs={1}>
                    <input type="text" value={filter} className="form-control" onChange={(e) => setFilter(e.currentTarget.value)} />
                </Col>
                <Col xs={1}>
                    <input type="text" value={maskFilter} className="form-control" onChange={(e) => setMaskFilter(e.currentTarget.value)} />
                </Col>
            </Row>);
    }

    const filterMatch = (feature) => {
        let result = feature.key.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        let maskCheck = feature.mask.toLowerCase().indexOf(maskFilter.toLowerCase()) > -1;
        return result && maskCheck;
    }

    const filteredfeatures = () => {
        return mutators.filter(feature => filterMatch(feature));
    }

    const deleteFeature = (featureId) => {
        console.log("Feature was deleted", featureId);
        setMutators(mutators.filter(feature => feature.id !== featureId));
    }

    const refreshList = () => {
        populateMutators();
    }

    return (
        <div>

            <Row>
                <Col xs={1}>
                    <strong>Feature Type</strong>
                </Col>
                <Col xs={3}>
                    <strong>Mask</strong>
                </Col>
                <Col>
                    <strong>Value</strong>
                </Col>
            </Row>
            {showFilters()}
            {filteredfeatures().map((feature, index) => 
            <div key={index}>

            <ProcGenMutator feature={feature} deleteCallback={deleteFeature} refreshCallback={refreshList}/>
            </div>
                )}
        </div>
    );
}

export default ProcGenMutators;

