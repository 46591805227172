import * as React from 'react'

const MobContext = React.createContext();
const MobDispatchContext = React.createContext();

const initialState = {
    currentMobData: null,
    originalMob: null,
    error: ''
};

function useMobDispatch() {
    const context = React.useContext(MobDispatchContext);
    if (context === undefined) {
        throw new Error("useMobDispatch must be used within an MobProvider");
    }
    return context;
}

const mobReducer = (initialState, action) => {
    //console.log(action.type);
    let newMobData = { ...initialState.currentMobData };
    switch (action.type) {
        case "SELECT_CURRENTMOB":
            return {
                ...initialState,
                currentMobData: action.payload
            }
        case "SET_STOCK_LEVEL":
            newMobData.stock[action.payload.index].quantity = action.payload.value;
            return {
                ...initialState,
                currentMobData: newMobData
            }
        case "SET_STOCK_PRICE":
            newMobData.stock[action.payload.index].price = action.payload.value;
            return {
                ...initialState,
                currentMobData: newMobData
            }
        case 'DELETE_STOCK': {
            console.log('data-1', newMobData);
            var stock = newMobData.stock;
            console.log('data-2', stock);
            stock.splice(action.payload, 1);
            newMobData.stock = stock;//newMobData[action.field].splice(action.payload, 1);
            console.log('data-3', newMobData);
            return {
                ...initialState,
                currentMobData: newMobData
            }
        }
        case "SET_FIELD":
            newMobData[action.field] = action.payload;
            return {
                ...initialState,
                currentMobData: newMobData
            }
        case 'SET_AFFECT': {
            let affects = newMobData[action.field];
            console.log('data-2', affects);
            console.log('action', action.payload);
            affects[action.payload.index].id = action.payload.id;
            affects[action.payload.index].params = action.payload.params;
            newMobData[action.field] = affects;
            return {
                ...initialState,
                currentMobData: newMobData
            }
        }
        case "SELECT_ORIGINALMOB":
            return {
                ...initialState,
                originalMob: action.payload
            }
        case "SELECT_ERROR":
            return {
                ...initialState,
                error: action.payload
            }
        case 'UPDATE_ITEM':
            {
                let inventory = newMobData['items'];
                console.log('Updating Inventory', action.payload);


                let index = inventory.findIndex((item) => {
                    return (item.uniqueid == action.payload.uniqueid)
                });
                console.log('Updating Inventory', index);

                inventory[index] = action.payload;
                console.log('Updating Inventory', inventory);
                newMobData['items'] = inventory;
                return {
                    ...initialState,
                    currentMobData: newMobData
                }
            }
        case 'UPDATE_STOCKITEM':
            {
                stock = newMobData['stock'];
                console.log('Updating Stock Inventory', action.payload);


                let index = stock.findIndex((stockitem) => {
                    return (stockitem.item.uniqueid == action.payload.uniqueid)
                });
                console.log('Updating Stock Inventory', index);

                stock[index].item = action.payload;
                console.log('Updating Stock Inventory', stock);
                newMobData['stock'] = stock;
                return {
                    ...initialState,
                    currentMobData: newMobData
                }
            }
        case 'DELETE_ITEM': {
            var inventory = newMobData['items'];

            let index = inventory.findIndex((item) => {
                return (item.uniqueid == action.payload.uniqueid)
            });
            inventory.splice(index, 1);

            newMobData['items'] = inventory;
            return {
                ...initialState,
                currentMobData: newMobData
            }
        }
        case 'DELETE_AFFECT': {
            console.log('data-1', newMobData);
            //currentMobData.behaviours = currentMobData.behaviours.splice(effectIndex, 1);
            let behaviours = newMobData[action.field];
            console.log('data-2', behaviours);
            behaviours.splice(action.payload, 1);

            newMobData[action.field] = behaviours;//newMobData[action.field].splice(action.payload, 1);
            console.log('data-3', newMobData);
            return {
                ...initialState,
                currentMobData: newMobData
            }
        }
        case 'ADD_AFFECT': {
            //currentMobData.behaviours = currentMobData.behaviours.splice(effectIndex, 1);
            console.log('action', action);
            console.log('payload', action.payload);
            console.log('field', newMobData[action.field]);
            let addEfffects = newMobData[action.field];
            addEfffects.push(action.payload);

            newMobData[action.field] = addEfffects;// newMobData[action.field].push(action.payload);
            console.log('data', newMobData);
            return {
                ...initialState
            }
        }
        case 'ADD_ITEM': {
            inventory = newMobData['items'];

            // let index = inventory.findIndex((item) => {
            //     return (item.uniqueid == action.payload.uniqueid)
            // });
            inventory.push(action.payload);

            newMobData['items'] = inventory;
            return {
                ...initialState,
                currentMobData: newMobData
            }
        }
        case 'ADD_STOCK': {
            stock = newMobData['stock'];

            // let index = inventory.findIndex((item) => {
            //     return (item.uniqueid == action.payload.uniqueid)
            // });
            let newStock = {};
            newStock.item = action.payload;
            newStock.item.uniqueid = "";
            newStock.quantity = 10;
            newStock.price = -1;
            newStock.name = action.payload.name;
            newStock.type = action.payload.type;

            console.log('Adding Stock', newStock);

            stock.push(newStock);

            newMobData['stock'] = stock;
            return {
                ...initialState,
                currentMobData: newMobData
            }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

function MobProvider({ children }) {
    const [state, dispatch] = React.useReducer(mobReducer, initialState);
    return <MobContext.Provider value={state}>
        <MobDispatchContext.Provider value={dispatch}>
            {children}
        </MobDispatchContext.Provider>
    </MobContext.Provider>
}

function useMobBuilder() {
    const context = React.useContext(MobContext)
    if (context === undefined) {
        throw new Error('useMobBuilder must be used within a MobProvider')
    }
    return context
}

export { MobProvider, useMobBuilder, useMobDispatch }
