var defaultArea = {};

defaultArea.rooms = {};
defaultArea.creating=true;
defaultArea.type='StdArea';
defaultArea.id='NewArea';
defaultArea.name='A default area name';
defaultArea.description='A default description';
defaultArea.region='Waygate';
defaultArea.world='Midnight';
defaultArea.continent='Neulandra';
defaultArea.currency='WAYGATE';
defaultArea.author='';
defaultArea.staff='';
defaultArea.climate='';
defaultArea.parents='';
defaultArea.children='';
defaultArea.effects = [];
defaultArea.roomids = [];
defaultArea.behaviours =[];

var defaultMob = {}
defaultMob.type = 'GenMob';

module.exports = {defaultArea, defaultMob};