import * as React from 'react'

const BuilderContext = React.createContext();
const BuilderDispatchContext = React.createContext();

const initialState = {
    currentarea: null,
    currentroom: null,
    currentmob: null,
    currentitem: null,
    currentexit: null,
    editing: false,
    direction: -1,
    saveItem: null
};

function useBuilderDispatch() {
    const context = React.useContext(BuilderDispatchContext);
    if (context === undefined) {
        throw new Error("useBuilderDispatch must be used within a BuilderProvider");
    }
    return context;
}

const builderReducer = (initialState, action) => {
    switch (action.type) {
        case "SELECT_DIRECTION":
            return {
                ...initialState,
                direction: action.payload
            }
        case "UPDATE_AREA":
            //console.log('Area Updated');
            return {
                ...initialState,
                currentarea: action.payload,
            };
        case "UPDATE_MOB":
            //console.log('Area Updated');
            return {
                ...initialState,
                currentmob: action.payload,
            };
        case "SELECT_AREA":
            return {
                ...initialState,
                currentroom: null,
                currentmob: null,
                currentitem: null,
                currentexit: null,
                direction: -1,
                currentarea: action.payload,
            };
        case "SELECT_ROOM":
            return {
                ...initialState,
                currentmob: null,
                currentitem: null,
                currentexit: null,
                direction: -1,
                currentroom: action.payload,
            };
        case "SELECT_MOB":
            return {
                ...initialState,
                currentitem: null,
                currentexit: null,
                direction: -1,
                currentmob: action.payload,
            };
        case "SELECT_ITEM":
            return {
                ...initialState,
                saveItem: action.saveCallback,
                currentitem: action.payload,
            };
        case "SELECT_EXIT":
            //console.log("Selecting " + action.type, action.payload);
            return {
                ...initialState,
                currentexit: action.payload,
            };
        case "EDITING_ENABLED":
            return {
                ...initialState,
                editing: true,
            };
        case "EDITING_DISABLED":
            return {
                ...initialState,
                editing: false,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

function BuilderProvider({ children }) {
    const [state, dispatch] = React.useReducer(builderReducer, initialState);
    return <BuilderContext.Provider value={state}>
        <BuilderDispatchContext.Provider value={dispatch}>
            {children}
        </BuilderDispatchContext.Provider>
    </BuilderContext.Provider>
}

function useBuilder() {
    const context = React.useContext(BuilderContext)
    if (context === undefined) {
        throw new Error('useBuilder must be used within a BuilderProvider')
    }
    return context
}

export { BuilderProvider, useBuilder, useBuilderDispatch }
