import React, { Component } from 'react';

function GameplayRemorting() {

    return (
        <div className="container" id="divMainContent">
            <h1>Remorting a Character</h1>
            <h2>What is remorting?</h2>
            <p>Remorting in the Edge of Midnight allows a player to start over in a new more powerful form. It is a costly experience and the levelling requirements grow increasingly steep the more that you remort. This is a huge step in any characters life and we expect it to be fully supported by good roleplay.</p>
            <p>But it gives your character a chance to start over, to freshen things up and choose a new direction.</p>
            <h2>When can you remort?</h2>
            <p>There are several qualifying criteria for remorting, once you have met these - contact an Immortal and they will flag you for remort and then you can remort at your leisure!</p>
            <p>&nbsp;</p>
            <ul>
                <li>When you reach Hero status (level 301)</li>
                <li>It must be at least six months from creation or your last remort</li>
                <li>You most have at least 200,000xNew Remort Tier roleplaying experience available to spend*</li>
                <li>Post a short (or long!) story on the roleplaying board covering your remort story.</li>
            </ul>
            <p>*Costs may increase.</p>
            <h2>A remort story?</h2>
            <p>While your remort story can take any reasonable form or length, it should cover a few points.</p>
            <ul>
                <li>There should be a sense of what your PC was before remort with some mention of past RP or connections with other PC's, clan, places or events on EoM.</li>
                <li>Your story should have a sense of what brings on the the transformation of your PC.</li>
                <li>There should be some conclusion to your story that gives a sense of rebirth to your new remort PC.</li>
                <li>Finally it should be in a form that can be posted on the website.</li>
            </ul>
            <div className="alert alert-info">
                <p><strong>Reasonable length!</strong>&nbsp;It can be difficult to judge what is a reasonable length, many words does not in itself lend to greater quality but some effort should be put into this event and the deeper into the remort system you go the more effort is expected. This is intended to be a short story giving you the opportunity to show the depth you have imbued your character with.</p>
                <p>As a rough guide a remort story should be at least 1000 words, with each subsequent remort adding another 250 words.</p>
            </div>
            <p>I have kept these guidelines simple because I want folks to be able to create their own unique stories or poems about their Characters, however all stories should cover these 4 points in some way for you to be whitelisted for remort.</p>
            <h2>How do I remort?</h2>
            <p>Once you have permission to remort then you can use the REMORT command to begin the process</p>
            <p>&nbsp;</p>
            <div className="row fluid">
                <div className="col-md-8">
                    <pre>{`Command : REMORT
Usage   : REMORT [RACE] [CLASS]
Example : remort sidhe "martial artist"
Example : remort seraphim fighter
Remorting in EoM allows a player to start over in a new, more powerful, form. This is a 
costly  experience and the levelling requirements will grow, but it gives players at the 
end-game a chance to start over, refresh their RP and choose a new path.
Warning You WILL lose all items you carry, all abilities, all professions and all your 
levels! 
A remort will get the opportunity to pick some perks during the process as follows: 
Strength gives +2 Strength 
Intelligence gives +2 Intelligence 
Dexterity gives +2 Dexterity 
Constitution gives +2 Constitution 
Charisma gives +2 Charisma 
Wisdom gives +2 Wisdom 
Attack gives +50 Attack 
Damage gives +50 Damage 
Armor gives +50 Armor 
Hit gives +100 Hit Points 
Mana gives +100 Mana 
Move gives +100 Move 
The will get 2 x the remort tier picks to choose from, from there they will be able to 
re-roll their base stats.`}</pre>
                </div>
            </div>
            <p>You specify the race you wish to remort into and the class you wish to start with and you will be transformed into a shiny new level 1 player character of that race and class!</p>
            <h2>What race do you choose?</h2>
            <p>You can choose from the next tier that are in your current race's remort path (e.g.&nbsp;<strong>help race angel</strong>). If your race has not been coded up yet, then contact a member of staff (Suki really in this case) with details of the race and it will be added at the next patch or hotfix once it is done - you are welcome to remort into your existing race whilst you wait.</p>
            <p>If you wish to move outside your tree this is an extra 50,000 RPXP and if you wish for a custom race prior to Tier 3 this is an extra 100,000 rpxp</p>
            <h2>What classes can you choose from?</h2>
            <p>Any BASE class that your race qualifies for.</p>
            <h2>What are remort perks?</h2>
            <p>When you remort, you may choose two perks that grant a bonus to stats or a small shortcut.</p>
            <div className="row fluid">
                <div className="col-md-8">
                    <pre>{`Strength gives +2 Strength 
Intelligence gives +2 Intelligence 
Dexterity gives +2 Dexterity 
Constitution gives +2 Constitution 
Charisma gives +2 Charisma 
Wisdom gives +2 Wisdom 
Attack gives +50 Attack 
Damage gives +50 Damage 
Armor gives +50 Armor 
Hit gives +100 Hit Points 
Mana gives +100 Mana 
Move gives +100 Move `}
                    </pre>
                </div>
            </div>
            <h2>What happens when you remort?</h2>
            <p>All the items you carry, all the coin in your purse, all your abilities and bonuses are removed, You lose it all! So anything precious put somewhere safe (e.g. The bank, your home).</p>
            <p>You are effectively starting over again.</p>
            <p>Only for each remort, the experience requirements are multiplied out by 1+remort!</p>
            <h2>Things to bear in mind</h2>
            <p><strong>Statement:&nbsp;</strong>My level 1 remort is stronger than your un-remorted hero.&nbsp;<strong>Response</strong>&nbsp;No, no they are not - you are level 1 kitten.</p>
            <p><strong>Statement:&nbsp;</strong>My level 301 remort is WAY stronger than your un-remorted hero.&nbsp;<strong>Response</strong>&nbsp;No, no they are not - you're a bit stronger, a few percent maybe on a good day.</p>
            <p><strong>Statement:&nbsp;</strong>But it cost so much to level, it was so hard.&nbsp;<strong>Response</strong>&nbsp;Mhmm.</p>
        </div>
    );
}

export default GameplayRemorting;