import React from 'react';
import Config from '../../../config/Config';
import { getStandardHeader, getAuthenticatedHeader } from '../utils/FetchHeaders';
import { genericAuthenticatedFetch, genericAuthenticatedPut } from '.';

export async function putKinkDefinitions(kink) {
  try {
    console.log('Saving Kink');
    const headers = getAuthenticatedHeader();

    const body = JSON.stringify({ kink });

    //console.log(areaname);
    const response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/kinks/update`, { method: 'PUT', headers: headers, body: body });
    const data = await response.json();
    return data;
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function getKinkDefinitions() {
  try {

    const headers = getAuthenticatedHeader();
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/kinks/list`, { headers: headers });
    let data = await response.json();
    //console.log(data);

    if (data.result) {

      localStorage.setItem('newMailCount', data.newmessages);

      return data
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function updatePlayerKink(player_id, kink_id, kink_record) {
  return genericAuthenticatedPut(`kinks/player/${player_id}/update/${kink_id}`, JSON.stringify(kink_record));
}

export async function listPlayerKinks(player_id) {
  return genericAuthenticatedFetch(`kinks/player/${player_id}/list`);
}