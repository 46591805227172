import React from 'react';
import Config from '../../../config/Config';
import { getStandardHeader, getAuthenticatedHeader } from '../utils/FetchHeaders';

export async function listPlayerMessages() {
  try {

    const headers = getAuthenticatedHeader();
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/messages/list`, { headers: headers });
    let data = await response.json();
    //console.log(data);

    if (data.result) {

      localStorage.setItem('newMailCount', data.newmessages);

      return data
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function markMessageAsRead(dispatch, messageId) {
  try {
    const headers = getAuthenticatedHeader();
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/messages/read/` + messageId, { headers: headers });
    let data = await response.json();
    //console.log(data);

    if (data.result) {
      localStorage.setItem('newMailCount', data.newmessages);
      dispatch({ type: 'NEW_MESSAGES', payload: data });
      return data
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function deleteMessage(messageId) {
  try {

    const headers = getAuthenticatedHeader();
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/messages/delete/` + messageId, { headers: headers });
    let data = await response.json();
    //console.log(data);

    if (data.result) {
      return data
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function sendNewMessage(messageTo, messageSubject, messageBody) {
  try {

    const headers = getAuthenticatedHeader();

    const body = JSON.stringify({
      to: messageTo,
      subject: messageSubject,
      body: messageBody
    });

    //console.log(areaname);
    const response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/messages/send`, { method: 'PUT', headers: headers, body: body });
    const data = await response.json();
    return data;
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}

export async function getUserStatus() {
  try {
    const headers = getAuthenticatedHeader();

    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/player/status` , { headers: headers });
    let data = await response.json();
    if (data.result) {
      //console.log('user Status: ', data);
      return data
    }
  } catch (error) {

    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}


async function getMessageData(messageId) {
  try {

    const headers = getAuthenticatedHeader();

    //console.log(areaname);
    let response = await fetch(`https://${Config.middleware.host}:${Config.middleware.port}/api/message/get/` + messageId, { headers: headers });

    let data = await response.json();

    //console.log(data);

    if (data.result) {
      //console.log(data);
      return data
    }
  } catch (error) {
    //console.log(error);
    return {
      'result': false,
      'reason': 'Error Sending Request'
    };
  }
}
