import React from 'react';

const makeNiceString = (list) => {
  var i;
  var text = "";

  for (i = 0; i < list.length; i++) {
    text += list[i];
    if (i < list.length - 2) {
      text += ', ';
    } else if (i < list.length - 1) {
      text += ' and ';
    }
  }
  return text;
}
/**
 * 
 * @param {*} list players names
 * @returns rpsheet linked short list of players
 */
const makeNiceRpsheetLinks = (list) => {
  const max = list.length;
  if (max === 0) {
    return (<span>no-one</span>);
  }

  return (
    <>
      {list.map((item, idx) =>
        <span key={item}>
          <a className="dryLink" href={`/player/rpsheet/${item}`}>
            {item}
          </a>
          {(idx < max - 2) ? <span>, </span> : null}
          {(idx == ( max - 2)) ? <span> and </span> : null}
        </span>
      )}
    </>
  );
}

const nth = (d) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

const timeConverter = (UNIX_timestamp, dateOnly) => {
  var showTime = true;
  if(dateOnly!==undefined) {
    showTime = !dateOnly;
  }

  var a = new Date(UNIX_timestamp * 1);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();

  min = '' + min;
  if (min.length < 2) {
    min = '0' + min;
  }

  sec = '' + sec;
  if (sec.length < 2) {
    sec = '0' + sec;
  }
  var time = date + nth(date) + ' ' + month + ' ' + year + (showTime?' ' + hour + ':' + min + ':' + sec:'');
  return time;
}

function elapsedTime(elapsed) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds';
  }

  else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes';
  }

  else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours';
  }

  else if (elapsed < msPerMonth) {
    return 'approximately ' + Math.round(elapsed / msPerDay) + ' days';
  }

  else if (elapsed < msPerYear) {
    return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months';
  }

  else {
    return 'approximately ' + Math.round(elapsed / msPerYear) + ' years';
  }
}

function timeDifference(current, previous) {

  var elapsed =  current - previous;
  return elapsedTime(elapsed);
}


export { makeNiceString, nth, timeConverter, elapsedTime, makeNiceRpsheetLinks };