import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card, Tabs, Tab, Button } from 'react-bootstrap';
import { getScene, getSceneBackground, updateSceneBackground, lockScene, updateSceneActivity, deleteSceneActivity } from './../components/sceneRequests';
import SceneHeader from './scene/SceneHeader';
import ColouredText from '../components/ColouredText';
import { useParams } from 'react-router-dom';
import MarkdownEditor from '../components/MarkdownEditor';
import { useAuthState } from '../components';
import PlayerSceneActivity from './PlayerSceneActivity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import checkIcon from './../../assets/check.svg';
import useToast from '../toast/useToasts';
import SceneActivityEditor from './scene/SceneActivityEditor';
import EomMarkdown from '../components/EomMarkdown';


function PlayerScene() {

    let match = useParams();
    const { addToastMessage } = useToast(false);

    let scene_id = undefined;
    if (match.scene_id !== undefined) {
        scene_id = match.scene_id;
    }

    const { roles, currentUser } = useAuthState();
    const [scene, setScene] = useState();
    const [background, setBackground] = useState(undefined);
    const [editingActivity, setEditingActivity] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editActivity, setEditActivity] = useState(undefined);
    const [showToast, setShowToast] = useState(false);
    const [toastList, setToastList] = useState([]);

    const displayError = (error) => {
        if (error.length > 0) {
            setShowToast(true);
        }
    }

    const getSceneFromServer = async (scene_id) => {
        const data = await getScene(scene_id);
        if (data.result) {
            setScene(data.scene);
            setBackground(data.scene.scene_background);
        } else {
            setScene();

        }
    }

    useEffect(() => {
        getSceneFromServer(scene_id);
    }, [scene_id]);

    const selectBackground = async (forceUpdate = false) => {
        if (forceUpdate || (scene.scene_background === undefined)) {
            const data = await getSceneBackground(scene_id);
            if (data.result) {
                scene.scene_background = data.scene_background.scene_background;
                setScene({ ...scene });
                setBackground(data.scene_background.scene_background);
            } else {
                setBackground('');
            }
        }
    }

    const saveBackGround = async (text) => {
        //console.log('Save Background', text);
        const scenekey = 'scene_background';
        const scene_data = {
            [scenekey]: text
        };
        //        scene_data.scene_background = text;
        //console.log('Save Background', scene_data);
        let data = await updateSceneBackground(scene.scene_id, scene_data);
        if (data.result) {
            setScene(data.scene);
            setBackground(data.scene.scene_background);
            unlockSceneForCurrentUser(false);
        } else {
            setScene();
        }
    }

    const unlockSceneForCurrentUser = async (forceRefresh = false) => {
        const data = await lockScene(scene_id, false);
        if (data.result) {
            setEditing(false);
            selectBackground(forceRefresh);
        }
    }

    const lockSceneForCurrentUser = async () => {
        const data = await lockScene(scene_id, true);
        if (data.result) {
            setEditing(true);

            setToastList([{
                id: 1,
                title: 'Success',
                description: 'You have locked the scene for editing',
                backgroundColor: '#5cb85c',
                autoDelete: true,
                icon: checkIcon
            }]);
            setShowToast(true);
            addToastMessage(`Editing Scene ${scene.scene_title}`, `You have locked scene ${scene_id} for editing.`);
        }
    }


    const getBackgroundSection = () => {
        if (background === undefined) {
            selectBackground();
            return (<h5>loading background...</h5>);
        }
        else {
            return (
                <>
                    {canEditScene() ?
                        <div>
                            <FontAwesomeIcon className="fa fa-lg m-2 fa-eom" icon={"edit"} onClick={() => lockSceneForCurrentUser()} />
                            <FontAwesomeIcon className="fa fa-lg m-2 fa-eom" icon={"undo"} onClick={() => unlockSceneForCurrentUser()} />
                        </div>
                        : null}
                    <MarkdownEditor editing={editing} documentToEdit={background} saveDocument={(data) => {
                        saveBackGround(data);
                    }} />
                </>
            );
        }
    }

    const canEditScene = () => {
        let admin = (roles !== undefined) && (roles.indexOf('webadmin') > -1);
        let participant = (scene.scene_storyteller === currentUser) || (scene.scene_participants.findIndex(u => u.name == currentUser) > -1);
        let unlocked = (scene.scene_lockedby.length == 0) || (scene.scene_lockedby == currentUser);

        return (unlocked && (participant || participant)) || admin;
    }

    const handleCloseModalActivity = () => {
        setEditingActivity(false);
    }

    const createActivity = () => {
        let activity = {
            activity_title: "A new Title",
            activity_text: "",
            activity_author: currentUser,
            activity_date: 0,
            activity_index: -1,
        };
        setEditActivity(activity);
        setEditingActivity(true);
    }

    const editSelectedActivity = (index) => {
        const i = scene.activity.findIndex(a => a.activity_index == index);
        if (i > -1) {
            setEditActivity(scene.activity[i]);
            setEditingActivity(true);

        }
    }

    const deleteSelectedActivity = async (index) => {
        const i = scene.activity.findIndex(a => a.activity_index == index);

        const data = await deleteSceneActivity(scene.scene_id, index);
        if (data.result) {
            setScene(undefined);
            getSceneFromServer(scene.scene_id);
        }
    }

    const saveSceneActivity = async (sceneActivity) => {
        const data = await updateSceneActivity(scene.scene_id, sceneActivity.activity_index, sceneActivity);
        if (data.result) {
            setEditingActivity(false);
            setEditActivity(undefined);
            setScene(undefined);
            getSceneFromServer(scene.scene_id);
        }
    }

    return (
        <div className="container">
            {scene !== undefined ?
                <>
                    <SceneActivityEditor activity={editActivity} show={editingActivity} handleClose={handleCloseModalActivity} saveSceneActivity={saveSceneActivity} />
                    <SceneHeader scene={scene} />
                    <h3>Description</h3>
                    <ColouredText className={"textarea eom-box"} ColouredText={scene.scene_description} />
                    {scene.scene_nexteventinfo !== '' ?
                        <><h3>Next Event</h3>
                            {scene.scene_nexteventdate === '1970-01-01 00:00' ? null :
                                <h5>{scene.scene_nexteventdate}</h5>}
                            <EomMarkdown markdown={scene.scene_nexteventinfo.replace('`', '\\`')} />

                        </>
                        : <></>}
                    <div className={"eom-scene"}>
                        <Tabs defaultActiveKey="activity" id="uncontrolled-tab-example">
                            <Tab eventKey="activity" title="Activity">
                                <Button className={"my-1"} variant="secondary" onClick={() => {
                                    createActivity();
                                }}>
                                    Create Activity
                                </Button>

                                <Accordion defaultActiveKey={[]} alwaysOpen >
                                    {
                                        scene.activity
                                            .sort((a, b) => a.activity_index > b.activity_index ? 1 : -1)
                                            .map((activity, index) => {
                                                return <PlayerSceneActivity key={index} sceneId={scene.scene_id} activity={activity}
                                                    editActivity={editSelectedActivity}
                                                    deleteActivity={deleteSelectedActivity} />
                                            })
                                    }


                                </Accordion>
                            </Tab>
                            <Tab eventKey="background" title="Background" >
                                {getBackgroundSection()}
                            </Tab>
                        </Tabs>
                    </div>

                </>
                : <></>}
        </div>
    )
}

export default PlayerScene;