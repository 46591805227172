import React, { useState, useReducer, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { removeColourCodes } from '../../utils/ColourMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip as ReactTooltip } from 'react-tooltip'

function RoomContents(props) {
    let contents = props.contents;
    let depth = props.depth;
    let selectItem = props.selectItem;
    let deleteItem = props.deleteItem;

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    var depthString = "";
    for (var i = 0; i < depth; i++) {
        depthString += "-";
    }
    if (depth > 0) {
        depthString += ">";
    }

    if (contents === undefined) {
        return null;
    }

    const hasContents = (item) => {
        if (item.contents === undefined) {
            return false;
        }
        return item.contents.length > 0;
    }

    const setEexpandToggle = (item) => {

        if (item.expanded === undefined) {
            item.expanded = true;
        } else
            item.expanded = !item.expanded;
        console.log(item.expanded);
        return item.expanded;
    }

    const isExpanded = (item) => {
        if (item.expanded === undefined) {
            return false;
        }
        return item.expanded;
    }

    return (
        <>
            {contents.map((item, index) =>
                <div key={index}>
                    <Row key={index} name={item.uniqueid} >
                        <Col xs={1} >
                            <FontAwesomeIcon data-tip data-tooltip-id="tooltip-delete" onClick={() => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    deleteItem(item.name, item.uniqueid);
                            }} className="fa fa-lg fa-eom" icon={"backspace"} />
                        </Col>
                        <Col xs={1} >
                            {hasContents(item) ?
                                <FontAwesomeIcon data-tip data-tooltip-id="tooltip-expand" onClick={() => {
                                    setEexpandToggle(item);
                                    forceUpdate();
                                }} className="fa fa-lg fa-eom" icon={"angle-double-" + (isExpanded(item) ? "down" : "right")} />
                                : null}
                        </Col>
                        {depth !== undefined
                            ?
                            <span>
                                {depthString}
                            </span>
                            : null}
                        <Col onClick={() => selectItem(item.uniqueid)}>
                            <span>{removeColourCodes(item.name)}   </span>
                        </Col>

                    </Row>
                    {isExpanded(item)
                        ? <RoomContents contents={item.contents} depth={depth + 1} selectItem={selectItem} />
                        : null}
                </div>
            )}
        </>);
}

export default RoomContents;