import React from 'react';


function RealmMajorCities() {

    return (
        <div className="container" id="divMainContent">
            <br />
            <h1>Major Cities of the Realm</h1>
            <br />
            <h2>Waygate City</h2>
            <div className="row fluid">
                <div className="col-md-3">
                    <img src="/images/cities/Waygate.jpg" className="img-thumbnail" alt="" />
                </div>
                <div className="col-md-9">
                    <p>The floating city of Waygate, floating above the land of neulandra, bellow and stuffkins, also known as the cloud city, where a demon or angel, can make there way or strange kitten will pounce you and molest you...</p>
                    <p><strong>Points of Interest:</strong> Waygate Square, Blue Skies Inn, Waygate Park</p>

                </div>
            </div>
            <br />

            <h2>Shou Lung</h2>
            <div className="row fluid">
                <div className="col-md-9">
                    <p>Shou Lung is an ancient city that has been part of Neulandra for a very long time. The city was attacked repeatedly by the Hafran nation when it was governed by a non-human, though the attacks quelled once Tyler was annointed as mayor. Since the Hafran war's final battle, Hyp has provided leadership to this quaint city. Shou Lung is also home of the martial artist trainer and several dojos.</p>
                    <p><strong>Points of Interest: </strong>The hot springs, the tea house</p>
                </div>
                <div className="col-md-3">
                    <img src="/images/cities/ShouLung.jpg" className="img-thumbnail" alt="" />
                </div>
            </div>
            <br />

            <h2>Ravenclaw</h2>
            <div className="row fluid">
                <div className="col-md-3"><img src="/images/cities/Ravenclaw.jpg" className="img-thumbnail" alt="" />
                </div>
                <div className="col-md-9">
                    <p>This city exists on another sky island south of waygate, Raven Claw has a larger, more sprawling structure than Waygate, but less traffic, most of it's land taken over with Private Homes, though there are still shops and public areas to visit.</p>
                    <p><strong>Points of Interest: </strong>Crafty Fox</p>
                </div>
            </div>
            <br />

            <h2>Rigor Mortis</h2>
            <div className="row fluid">
                <div className="col-md-9">
                    <p>Rigor Mortis is a city of night, a city of monsters, a place where 'evil' lives freely. Orcs, goblins, gnolls, imps, demons, devils, vampires and even a lich call this place home. Come and go as you will, but be aware, should you start a fight you can't finish, no one will come to help you.</p>
                    <p><strong>Points of Interest:</strong>The Warg's Piss Inn</p>
                </div>
                <div className="col-md-3">
                    <img src="/images/cities/RigorMortis_Small.png" className="img-thumbnail" alt="" />
                </div>
            </div>
        </div>
    )
}

export default RealmMajorCities;