import { getProductList, getProfessions } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import SchematicEditor from './SchematicEditor';
import { ProfessionProvider } from './ProfessionProvider';
import SchemaSearch from './SchemaSearch';
import { useParams } from 'react-router-dom';

function BuilderSchematicManager() {
    return (<ProfessionProvider><SchematicManager  /></ProfessionProvider>);
}

function SchematicManager(props) {
    const params = useParams();
    const [schematicId, setSchematicId] = useState(null);
    const [products, setProducts] = useState([]);
    const [professions, setProfessions] = useState([]);
    
    const selectSchematic = async (schematic_id) => {
        console.log("Selecting ", schematic_id);
        if(schematic_id!==undefined) {
            setSchematicId(schematic_id);
        } else {
            setSchematicId(null);
        }

    }

    useEffect(() => {
        console.log("Selected", schematicId);


    },[schematicId]);

    const  updateData = async () => {
        let data = await getProductList();
        if (data.result) {
            setProducts(data.products);
        } else {
            setProducts([]);
        }

        let data2 = await getProfessions();
        //console.log('Profession Data', data2);
        if (data2.result) {
            setProfessions(data2.professions);
        } else {
            setProfessions([]);
        }
    }

    useEffect(() => {
        updateData();
    }, []);

    useEffect(() => {
        setSchematicId(params.schematic_id);
    }, [params.schematic_id]);

    const onCloseEditor = () => {
        setSchematicId(null);
    }

    return (
        <div className="container-eom" id="divMainContent">
            <Row>
                <Col xs={12} md={4}>
                    <SchemaSearch professions={professions} products={products} selectSchematic={selectSchematic} params={params} />
                </Col>

                <Col xs={12} md={8}>
                    <SchematicEditor onCloseEditor={onCloseEditor} schematic_id={schematicId} />
                </Col>
            </Row>
        </div>
    );
}

export default BuilderSchematicManager;