import { getWornLayers, getMaterials } from '../BuilderUtils'
import { getAttributeList, getDispositionList } from '../config/Disposition';

export const getFieldsMap = (item) => {
    switch (item.type) {
        case 'GenWallpaper':
            return getItemFields(['type', 'name', 'readable', 'readabletext']);
        default: {
            const headers = Object.keys(item);
            // console.log('item', item);
            // console.log('headers', headers);
            return getItemFields(headers);
        }
    }
}

export const showAffects = (itemtype) => {
    switch (itemtype) {
        case 'GenWallpaper':
            return false;
        default:
            return true;
    }
}

const getItemFields = (fields) => {
    let itemfieldsMap = {};
    //console.log(fields);
    const headers = Object.keys(fullFieldsMap);

    headers.map(header => {
        if (fields.indexOf(header) > -1) {
            //console.log('Found', header);
            let field = fullFieldsMap[header];
            if (field !== undefined) {
                field.field = header;
                itemfieldsMap[header] = field;
            }
        }
    });
    return itemfieldsMap;
}

export const getCraftedItemFields = () => {
    let fields = ['maker', 'makers_marque', 'embellishment_name', 'schema_id', 'constructed_from'];
    let itemfieldsMap = {};
    //console.log(fields);
    const headers = Object.keys(craftedFieldsMap);

    headers.map(header => {
        if (fields.indexOf(header) > -1) {
            let field = craftedFieldsMap[header];
            if (field !== undefined) {
                field.field = header;
                itemfieldsMap[header] = field;
            }
        }
    });
    return itemfieldsMap;
}

export const FIELD_LONGCOLOUR = 0;
export const FIELD_BOOLEAN = 1;
export const FIELD_STRING = 2;
export const FIELD_SHORTCOLOUR = 3;
export const FIELD_READONLY = 4;
export const FIELD_LIST = 5;

const craftedFieldsMap = {
    'maker': { description: 'Maker', type: FIELD_STRING },
    'makers_marque': { description: 'Makers Marque', type: FIELD_SHORTCOLOUR },
    'schema_id': { description: 'Schematic Id', type: FIELD_STRING },
    'embellishment_name': { description: 'Embellishment', type: FIELD_SHORTCOLOUR },
    'constructed_from': { description: 'Composition', type: FIELD_STRING },
    'sealed': { description: 'Sealed', type: FIELD_BOOLEAN },
}

const fullFieldsMap = {
    'type': { description: 'Type', type: 2 },
    'name': { description: 'Name', type: 3 },
    'catalog': { description: 'Cataloged', type: FIELD_BOOLEAN },
    'display': { description: 'Display', type: 3 },
    'description': { description: 'Description', type: 0 },
    'readable': { description: 'Is Readable', type: 1 },
    'readabletext': { description: 'Readable Text', type: 0, conditional: 'readable' },
    'material': { description: 'Material', type: 5, choices: getMaterials },
    'weight': { description: 'Weight', type: 2 },
    'worncode': { description: 'Worn On', type: 7 },
    'value': { description: 'Value', type: 2 },
    'ability': { description: 'Ability', type: 2 },
    'level': { description: 'Level', type: 2 },
    'exit': { description: 'Exit', type: 2 },
    'hasadoor': { description: 'Has a Door', type: 1 },
    'hasalock': { description: 'Has a lock', type: 1 },
    'islocked': { description: 'Is Locked', type: 1, conditional: 'hasalock' },
    'keycode': { description: 'Key Code', type: 2, conditional: 'hasalock' },
    'hasalid': { description: 'Has a lid', type: 1 },
    'isopen': { description: 'Is Open', type: 1 },
    'capacity': { description: 'Xapacity', type: 2 },
    'containtypes': { description: 'Contain Types', type: 2 },
    'ridebasis': { description: 'ridebasis', type: 2 },
    'mobsheld': { description: 'mobsheld', type: 2 },
    'exitname': { description: 'exitname', type: 2 },
    'closedtext': { description: 'closedtext', type: 2 },
    'mount': { description: 'Mount String', type: 2 },
    'dismount': { description: 'Dismount String', type: 2 },
    'put': { description: 'put', type: 2 },
    'armour': { description: 'Armour', type: 2 },
    'damage': { description: 'Damage', type: 2 },
    'attack': { description: 'Attack', type: 2 },
    'minrange': { description: 'Min Range', type: 2 },
    'maxrange': { description: 'Max Range', type: 2 },
    'armourclass': { description: 'Armour Class', type: 2 },
    'armourstyle': { description: 'Armour Style', type: 2 },
    //  'armourtype': { description: 'Armour Type', type: 2 },
    //  'layer': { description: 'Armour Layer', type: 5, choices : getWornLayers },
    'playermade': { description: 'Player Made', type: 1 },
    'secretidentity': { description: 'Secret Identity', type: 2 },
    'liquid': { description: 'Liquid Type', type: 2 },
    'liquidheld': { description: 'Liquid Held  ', type: 2 },
    'thirstquenched': { description: 'Thirst Quenched', type: 2 },
    'bookid': { description: 'Book Number', type: 2 },
    'nourishment': { description: 'Nourishment', type: 2 },
    'foodtype': { description: 'Food Type', type: 2 },
    'componenttype': { description: 'Component Type', type: 2 },
    'descriptor': { description: 'Descriptor', type: 2 },
    'containerid': { description: 'Container id', type: 2 },
    'attributes': { description: 'Attributes', type: 8, choices: getAttributeList },  
    'disposition': { description: 'Disposition', type: 8, choices: getDispositionList },

};