import React, { useState, useEffect } from 'react';
import EditableField from '../../builder/EditableField';
import { getCharacterData, setCharacterSecurityData } from '../../components/characterRequests';

function CharacterSecurity(props) {
    const [characterSecurity, setCharacterSecurity] = useState();
    const [filter, setFilter] = useState("true");

    useEffect(() => {
        getCharacterData(props.characterName, 'security').then(data => {
            if (data.result) {
                setCharacterSecurity(data.security);
            }
            else {
                setCharacterSecurity();
            }
        }).catch(error => {
            setCharacterSecurity();
        });

    }, [props.characterName]);


    const filterMatch = (flag) => {
        return (flag.flag_name.toLowerCase().indexOf(filter) > -1) || (flag.flag_enabled.toString().toLowerCase().indexOf(filter) > -1);
    }

    const callDispatcher = (flag, value) => {
        console.log(flag, value);

        setCharacterSecurityData(props.characterName, flag, value).then(data => {
            if (data.result) {
                setCharacterSecurity(data.security);
            }
            else {
                setCharacterSecurity();
            }
        }).catch(error => {
            setCharacterSecurity();
        });

    }

    const getSecurityTabContent = () => {
        return (
            <>
                <div className="input-group mb-3">
                    <div className="input-group-prepend" >
                        <button className="btn btn-themed-dark" type="submit">Filter</button>
                    </div>
                    <input type="text" defaultValue="true" className="form-control" onChange={(e)=>{setFilter(e.target.value)}} />
                </div>

                <h2>Groups</h2>
                {
                    characterSecurity.user_groups.filter(flag => filterMatch(flag)).map((flag, index) => {
                        return (
                                <EditableField key={index} editing={true}
                                    field={flag.flag_name}
                                    value={flag.flag_enabled}
                                    callDispatcher={callDispatcher}
                                    description={flag.flag_name}
                                    fieldType={1}
                                />
                        );
                    })
                }

                <h2>Flags</h2>
                {
                    characterSecurity.user_flags.filter(flag => filterMatch(flag)).map((flag, index) => {
                        return (
      
                                <EditableField key={index} editing={true}
                                    field={flag.flag_name}
                                    value={flag.flag_enabled}
                                    callDispatcher={callDispatcher}
                                    description={flag.flag_name}
                                    fieldType={1}
                                />
            
                        );
                    })
                }
            </>

        )
    }
    if (characterSecurity === undefined)
        return (<span>Loading...</span>)
    else
        return getSecurityTabContent();
}

export default CharacterSecurity;